import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { PVMCE } from "utility/variables";

import {
    composeValidators,
    dpi,
    email,
    integer,
    maxLength,
    minLength,
    NIT,
    required,
} from "utility/validation";

import { departamentosOptions, municipiosOptions } from "utility/variables";
import {
    AsyncSelectField,
    renderCurrency,
    renderDatePicker,
    renderField,
    renderFieldRadio,
    renderNumber,
    renderSelectField,
    renderSwitch,
    renderFieldCheck,
    renderNumberFormat,
} from "Utils/renderField/renderField";

// ********************************** NUEVO **********************************
import PhotoUploader from "./PhotoUploader/PhotoUploader";
import MultiUploadFile from "./MultipleUploadFile/MultiUploadFile";

const renderPhotoUploader = ({
    className,
    disabled,
    placeholder,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;

    return (
        <div>
            <PhotoUploader
                onChange={(e) => {
                    input.onChange(e);
                }}
                disabled={disabled || false}
                file={input.value}
                className={className}
            />
            {invalid && <div className="d-flex invalid-feedback">{error}</div>}
        </div>
    );
};
// ********************************** NUEVO **********************************

import { api } from "api";

export const listaProveedores = [];

import FormPermisos from "./FormPermisos/FormPermisos";

const UsuarioForm = (props) => {
    //  Valores de checkbox de permisos
    const {
        usuariosDatos,
        onSubmit,
        view = false,
        update,
        id,
        files,
        onChangeFile,
        onRemoveFile,
        onRemoveOldFile,
        oldFiles,
        data,

        tipo_punto_venta,

        changeValue,
        reportes,
        configuracion,
        despacho,
        sucursales,
        changePermisos,
        rolValue,
        esGlobal,
        id_empresa,
    } = props;

    const [permiso, setPermiso] = useState("");
    const [sucursal, setSucursal] = useState(false);
    // State para el collapse
    const [collapse, setCollapse] = useState(!update);

    const getSucursales = (search) => {
        return api
            .get("sucursales", { search })
            .catch(() => {})
            .then((data) => {
                data.results.forEach((item) => {
                    if (!_.find(listaProveedores, { id: item.id }))
                        listaProveedores.push(item);
                });
                return { options: listaProveedores };
            });
    };

    let roles = [
        { id: 1, value: "", label: "Seleccione un rol" },
        {
            id: "es_encargado_tienda",
            value: "es_encargado_tienda",
            label: "Encargado de Tienda",
        },
        {
            id: "es_gerente",
            value: "es_gerente",
            label: "Gerente",
        },
        {
            id: "es_auxiliar_gerencia",
            value: "es_auxiliar_gerencia",
            label: "Auxiliar de gerencia",
        },
        {
            id: "es_jefe_bodega",
            value: "es_jefe_bodega",
            label: "Jefe de bodega",
        },
        {
            id: "es_vendedor",
            value: "es_vendedor",
            label: "Vendedores",
        },
        {
            id: "es_vendedor_mayorista",
            value: "es_vendedor_mayorista",
            label: "Vendedor mayorista",
        },
        {
            id: "es_vendedor_distribuidor",
            value: "es_vendedor_distribuidor",
            label: "Vendedor Distribuidor",
        },
        {
            id: "es_motorista",
            value: "es_motorista",
            label: "Motoristas",
        },
        {
            id: "personalizado",
            value: "personalizado",
            label: "Personalizado",
        },
    ];

    useEffect(() => {
        const radio_true = document.querySelector("#radio-true");
        radio_true.click();
    }, []);

    useEffect(() => {
        if (update) {
            if (data.es_global === "false") {
                const radio_true = document.querySelector("#radio-false");
                radio_true.click();
            }
        }

        if (data.rol) {
            setPermiso(data.rol);
        }
    }, [data]);

    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit} // validation
                initialValues={{
                    comisiones: false,
                    viaticos: 0,
                    activo: true,
                    ...data,
                }}
                render={({ handleSubmit, submitting, values, form }) => {
                    if (
                        values.rol &&
                        values.rol == "personalizado" &&
                        values.ov_mayorista
                    ) {
                        form.change("ov_minorista", false);
                        form.change("ov_distribuidor", false);
                    }

                    if (
                        values.rol &&
                        values.rol == "personalizado" &&
                        values.ov_minorista
                    ) {
                        form.change("ov_mayorista", false);
                        form.change("ov_distribuidor", false);
                    }

                    if (
                        values.rol &&
                        values.rol == "personalizado" &&
                        values.ov_distribuidor
                    ) {
                        form.change("ov_minorista", false);
                        form.change("ov_mayorista", false);
                    }

                    if (values.rol && values.rol != "personalizado") {
                        form.change("ov_minorista", false);
                        form.change("ov_mayorista", false);
                        form.change("ov_distribuidor", false);
                    }
                    return (
                        <form
                            onSubmit={handleSubmit}
                            className="form-validate mb-lg"
                        >
                            <div className="mb-5 card card-small px-3 py-4">
                                <h3 className="contenido-1 border-bottom txt-bold black mb-4">
                                    Datos del Usuario
                                </h3>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">Estado</label>
                                        <div className="d-flex">
                                            <label className="mr-2">
                                                Desactivado
                                            </label>
                                            <Field
                                                name="activo"
                                                component={renderSwitch}
                                                // placeholder="Ingrese nombre del donante"
                                                type="text"
                                                className="form-control"
                                            />
                                            <label className="ml-2">
                                                Activado
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3"></div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">Nombre *</label>
                                        <Field
                                            name="first_name"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                            validate={required}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Apellidos *
                                        </label>
                                        <Field
                                            name="last_name"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                            validate={required}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>

                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Fecha de nacimiento
                                        </label>
                                        <Field
                                            name="birthday"
                                            component={renderDatePicker}
                                            placeholder=""
                                            type="date"
                                            className="form-control"
                                            validate={required}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="direccion">
                                            Dirección*
                                        </label>
                                        <Field
                                            name="direccion"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                            validate={required}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="telefono">
                                            Telefono
                                        </label>
                                        <Field
                                            name="telefono"
                                            component={renderField}
                                            placeholder="#### ####"
                                            type="text"
                                            className="form-control"
                                            validate={composeValidators(
                                                required,
                                                minLength(8),
                                                maxLength(8),
                                                integer
                                            )}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="email">
                                            Correo electónico
                                        </label>
                                        <Field
                                            name="email"
                                            component={renderField}
                                            placeholder="@"
                                            type="text"
                                            className="form-control"
                                            validate={composeValidators(
                                                required,
                                                email
                                            )}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Departamento de nacimiento
                                        </label>

                                        <Field
                                            name="departamento"
                                            component={renderSelectField}
                                            options={departamentosOptions}
                                            placeholder="Seleccionar"
                                            type="text"
                                            className="form-control"
                                            validate={required}
                                            disabled={view}
                                            parse={(value) => {
                                                form.change(
                                                    "municipio",
                                                    undefined
                                                );
                                                return value;
                                            }}
                                        />
                                    </div>

                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Municipio nacimiento
                                        </label>

                                        <Field
                                            key={
                                                values.municipio
                                                    ? `key_mun${values.municipio}`
                                                    : `key_mun_02`
                                            }
                                            name="municipio"
                                            component={renderSelectField}
                                            options={
                                                values.departamento
                                                    ? municipiosOptions[
                                                          values.departamento
                                                      ]
                                                    : []
                                            }
                                            placeholder="Seleccionar"
                                            type="text"
                                            className="form-control"
                                            valueKey="value"
                                            validate={required}
                                            disabled={
                                                view || values.departamento
                                                    ? false
                                                    : true
                                            }
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>

                                <h3 className="contenido-1 border-bottom txt-bold black mb-4 pt-4">
                                    Sucursales
                                </h3>
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="empresa-sucursal">
                                        Selección de sucursales
                                    </label>
                                    <div className="d-flex">
                                        <div className="flex-1">
                                            <Field
                                                type="radio"
                                                name="es_global"
                                                label="Puede acceder a toda la empresa"
                                                value="true"
                                                id="radio-true"
                                                component={renderFieldRadio}
                                                parse={(value) => {
                                                    setSucursal(false);
                                                    return value;
                                                }}
                                            />
                                        </div>
                                        <div className="flex-2">
                                            <Field
                                                type="radio"
                                                name="es_global"
                                                label="Puede acceder solo a una sucursal"
                                                id="radio-false"
                                                value="false"
                                                component={renderFieldRadio}
                                                parse={(value) => {
                                                    setSucursal(true);
                                                    return value;
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-2"></div>
                                </div>

                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        {sucursal && (
                                            <React.Fragment>
                                                <label htmlFor="sucursales">
                                                    Selección de sucursales
                                                </label>
                                                <Field
                                                    name="sucursales"
                                                    component={AsyncSelectField}
                                                    loadOptions={getSucursales}
                                                    labelKey="nombre"
                                                    valueKey="id"
                                                    validate={required}
                                                    className="form-control"
                                                />
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3"></div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>

                                <h3 className="contenido-1 border-bottom txt-bold black mb-4 pt-4">
                                    Accesos
                                </h3>

                                <div className="form-group has-feedback m-2 flex-3"></div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="usuario">Usuario</label>
                                        <Field
                                            name="usuario"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                            validate={required}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">Contraseña</label>
                                        <Field
                                            name="password"
                                            component={renderField}
                                            placeholder=""
                                            type="password"
                                            className="form-control"
                                            validate={update ? "" : required}
                                            disabled={view || update}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>

                                <h3 className="contenido-1 border-bottom txt-bold black mb-4 pt-4">
                                    Documentos personales
                                </h3>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">DPI</label>
                                        <Field
                                            name="dpi"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                            validate={composeValidators(
                                                required,
                                                dpi
                                            )}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">NIT</label>
                                        <Field
                                            name="nit"
                                            component={renderField}
                                            placeholder=""
                                            type="text"
                                            className="form-control"
                                            validate={composeValidators(
                                                required,
                                                NIT
                                            )}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="foto">Fotografía</label>
                                        <Field
                                            name="foto"
                                            component={renderPhotoUploader}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Viáticos semanalmente
                                        </label>
                                        <Field
                                            name="viaticos"
                                            component={renderNumber}
                                            type="text"
                                            className="form-control"
                                            validate={required}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="rol">
                                            Selección de roles
                                        </label>

                                        <Field
                                            name="rol"
                                            component={renderSelectField}
                                            options={roles}
                                            placeholder="Seleccionar"
                                            type="text"
                                            parse={(value) => {
                                                setPermiso(value);
                                                return value;
                                            }}
                                            className="form-control"
                                            validate={required}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>

                                    <div className="form-group has-feedback m-2 flex-3 d-flex">
                                        {(permiso === "es_gerente" ||
                                            permiso === "es_encargado_tienda" ||
                                            permiso === "es_vendedor" ||
                                            permiso ===
                                                "es_vendedor_mayorista" ||
                                            permiso ===
                                                "es_vendedor_distribuidor" ||
                                            values.ov_mayorista ||
                                            values.ov_minorista ||
                                            values.ov_distribuidor) && (
                                            <React.Fragment>
                                                <div className="form-group has-feedback flex-1">
                                                    <label htmlFor="realiza_descuentos">
                                                        Realiza descuentos:
                                                    </label>
                                                    <div className="d-flex align-items-center">
                                                        <label className="mr-2">
                                                            No
                                                        </label>
                                                        <Field
                                                            name="realiza_descuentos"
                                                            component={
                                                                renderSwitch
                                                            }
                                                            type="text"
                                                            className="form-control"
                                                        />
                                                        <label className="ml-2">
                                                            Si
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="form-group has-feedback flex-1">
                                                    <label htmlFor="realiza_venta_costo">
                                                        Realiza venta al costo:
                                                    </label>
                                                    <div className="d-flex align-items-center">
                                                        <label className="mr-2">
                                                            No
                                                        </label>
                                                        <Field
                                                            name="realiza_venta_costo"
                                                            component={
                                                                renderSwitch
                                                            }
                                                            type="text"
                                                            className="form-control"
                                                        />
                                                        <label className="ml-2">
                                                            Si
                                                        </label>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                {/* Listado de permisos */}

                                <FormPermisos
                                    nombre_permiso={permiso}
                                    is_update={update}
                                    collapse={collapse}
                                    setCollapse={setCollapse}
                                />

                                {/* Permisos de ov */}
                                {values.rol &&
                                    values.rol == "personalizado" &&
                                    collapse == true && (
                                        <div className="container_permiso mt-5">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="name">
                                                    Ordenes de Venta
                                                </label>
                                                <div className="d-flex flex-column pl-4">
                                                    <div className="d-flex ">
                                                        <Field
                                                            name={
                                                                "ov_mayorista"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>Mayorista</label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "ov_minorista"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>Minorista</label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "ov_distribuidor"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            Distribuidor
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Carga de archivos
                                        </label>

                                        <MultiUploadFile
                                            id={id}
                                            files={files}
                                            onChangeFile={onChangeFile}
                                            onRemoveFile={onRemoveFile}
                                            onRemoveOldFile={onRemoveOldFile}
                                            data={oldFiles}
                                        />
                                    </div>
                                </div>

                                <h3 className="contenido-1 border-bottom txt-bold black mb-4 pt-4">
                                    Comisiones
                                </h3>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Aplicar Comisiones
                                        </label>
                                        <div className="d-flex">
                                            <label className="mr-2">No</label>
                                            <Field
                                                name="comisiones"
                                                component={renderSwitch}
                                                type="text"
                                                className="form-control"
                                            />
                                            <label className="ml-2">Si</label>
                                        </div>
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3"></div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Comisión crédito
                                        </label>
                                        {values.comisiones && (
                                            <Field
                                                name="comision_credito"
                                                component={renderNumber}
                                                placeholder="%"
                                                type="text"
                                                className="form-control"
                                                validate={required}
                                            />
                                        )}
                                        {!values.comisiones && (
                                            <Field
                                                name="comision_credito"
                                                component={renderNumber}
                                                placeholder="%"
                                                type="text"
                                                className="form-control"
                                                disabled={true}
                                            />
                                        )}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Días de validéz
                                        </label>
                                        {values.comisiones && (
                                            <Field
                                                name="validez"
                                                component={renderNumber}
                                                type="text"
                                                className="form-control"
                                                validate={required}
                                            />
                                        )}
                                        {!values.comisiones && (
                                            <Field
                                                name="validez"
                                                component={renderNumber}
                                                type="text"
                                                className="form-control"
                                                disabled={true}
                                            />
                                        )}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-5">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Comisión contado
                                        </label>
                                        {values.comisiones && (
                                            <Field
                                                name="comision_contado"
                                                component={renderNumber}
                                                placeholder="%"
                                                type="text"
                                                className="form-control"
                                                validate={required}
                                            />
                                        )}
                                        {!values.comisiones && (
                                            <Field
                                                name="comision_contado"
                                                component={renderNumber}
                                                placeholder="%"
                                                type="text"
                                                className="form-control"
                                                disabled={true}
                                            />
                                        )}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Meta de venta mensual
                                        </label>
                                        {values.comisiones && (
                                            <Field
                                                name="meta_venta"
                                                component={renderCurrency}
                                                placeholder="Q"
                                                type="text"
                                                className="form-control"
                                                validate={required}
                                            />
                                        )}

                                        {!values.comisiones && (
                                            <Field
                                                name="meta_venta"
                                                component={renderCurrency}
                                                placeholder="Q"
                                                type="text"
                                                className="form-control"
                                                disabled={true}
                                            />
                                        )}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>

                                <div className="d-flex flex-column flex-md-row mb-5">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="porcentaje_comision_mensual">
                                            Comisión meta de venta mensual
                                        </label>
                                        {values.comisiones && (
                                            <Field
                                                name="porcentaje_comision_mensual"
                                                component={renderNumberFormat}
                                                placeholder="%"
                                                suffix={"%"}
                                                type="text"
                                                className="form-control"
                                                validate={required}
                                            />
                                        )}
                                        {!values.comisiones && (
                                            <Field
                                                name="porcentaje_comision_mensual"
                                                component={renderNumber}
                                                placeholder="%"
                                                type="text"
                                                className="form-control"
                                                disabled={true}
                                            />
                                        )}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>

                                    <div className="form-group has-feedback m-2 flex-3" />
                                    <div className="form-group has-feedback m-2 flex-1" />
                                </div>
                            </div>

                            <div className="d-flex flex-column-reverse flex-md-row justify-content-between py-4">
                                <Link
                                    to={`/empresas/editar/${id_empresa}`}
                                    className="btn btn-outline-primary mt-4 mt-md-0"
                                >
                                    Regresar
                                </Link>

                                <button
                                    type="submit"
                                    className="btn btn-secondary"
                                    disabled={submitting}
                                >
                                    {update ? "Actualizar" : "Agregar"} Usuario
                                </button>
                            </div>
                        </form>
                    );
                }}
            />
        </React.Fragment>
    );
};

export default UsuarioForm;
