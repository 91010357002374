import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { api } from "api";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import LoadMask from "Utils/LoadMask/LoadMask";
import { Async } from "react-select";
import ProductosForm from "./ProductosForm";
let listaProductos = [];
export const DetalleAccion = ({
    titulo,
    data,
    loader,
    llaves,
    quitar,
    agregar = false,
    cambio_buscador = false,
    producto_form = false,
    tipo_origen = 2,
    producto_actual = {},
    setProductoActual = () => {},
    ov_field = false,
    cliente_id = undefined,
}) => {
    const [productos, setProductos] = useState("");
    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader
        ? "Cargando..."
        : "Seleccione un producto para agregar al listado";

    const getProductos = (search) => {
        return api
            .get("bodegas", { search, filtro_sucursal: true })
            .catch(() => {})
            .then((data) => {
                data.results.forEach((item) => {
                    if (!_.find(listaProductos, { id: item.id }))
                        listaProductos.push(item);
                });
                return { options: listaProductos };
            });
    };

    useEffect(() => {
        return () => {
            listaProductos = [];
    }}, []);
        

    return (
        <div
            className="grid-container mt0 d-flex justify-content-around flex-column "
            style={{ borderBottom: "none", borderRadius: "0px" }}
        >
            <div
                className="grid-titulo padding-5"
                style={{
                    borderBottom: "none",
                }}
            >
                <div
                    className={`w-100 d-flex flex-column ${
                        !producto_form ? "flex-md-row pb-2" : ""
                    } justify-content-between border-bottom mb-3 `}
                >
                    <h2
                        style={{
                            color: "black",
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                        }}
                        className={`flex-3 ${
                            producto_form ? "border-bottom pb-2" : ""
                        } `}
                    >
                        {titulo}
                    </h2>
                    {agregar && !producto_form && (
                        <Async
                            disabled={false}
                            value={productos}
                            className={"form-control flex-1"}
                            onChange={(e) => {
                                setProductos(!!e ? e.id : null);
                                agregar(e);
                                setProductos(null);
                            }}
                            placeholder="Seleccionar"
                            searchPromptText="Escriba para buscar"
                            valueKey={"id"}
                            labelKey={"codigo_nombre"}
                            loadOptions={getProductos}
                        />
                    )}
                    {agregar && producto_form && (
                        <ProductosForm
                            productos={data}
                            producto_actual={producto_actual}
                            setProductoActual={setProductoActual}
                            agregarProducto={agregar}
                            tipo_origen={tipo_origen}
                            ov_field={ov_field}
                            cliente_id={cliente_id}
                        />
                    )}
                    {cambio_buscador && (
                        <button
                            className="d-lg-none d-xl-none btn btn-outline-primary mb-1 fnt-sm-10"
                            style={{ flex: "0.5", margin: "0 5px 0 10px " }}
                            type="button"
                            onClick={cambio_buscador}
                        >
                            <i className="fa fa-plus"></i> Agregar
                        </button>
                    )}
                </div>
            </div>
            <div className="r-encajar" style={{ padding: "0 2px" }}>
                <LoadMask loading={loader} light blur radius>
                    <BootstrapTable
                        data={loader ? [] : data}
                        hover
                        options={options}
                        containerStyle={{ backgroundColor: "#FFF" }}
                        bodyStyle={{ minHeight: "200px" }}
                    >
                        <TableHeaderColumn
                            width="0"
                            isKey
                            dataField="id"
                        ></TableHeaderColumn>

                        {llaves.map((llave) => {
                            return (
                                <TableHeaderColumn
                                    key={llave.nombre}
                                    width={llave.width}
                                    dataField={llave.nombre}
                                    dataFormat={llave.format}
                                    dataSort={llave.sort}
                                    dataAlign={llave.align}
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                >
                                    {llave.caption}
                                </TableHeaderColumn>
                            );
                        })}

                        {quitar && (
                            <TableHeaderColumn
                                width="5%"
                                dataField="id"
                                dataFormat={(cell, row, data, index) => {
                                    return (
                                        <button
                                            type="button"
                                            title="Eliminar de la lista"
                                            className="btn-delete fnt-18"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                quitar(row, index);
                                            }}
                                        >
                                            <em className="fa fa-times" />
                                        </button>
                                    );
                                }}
                            ></TableHeaderColumn>
                        )}
                    </BootstrapTable>
                </LoadMask>
            </div>
        </div>
    );
};

DetalleAccion.propTypes = {
    titulo: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    llaves: PropTypes.array.isRequired,
    loader: PropTypes.bool.isRequired,
};
