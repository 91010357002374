import { connect } from 'react-redux'
import Editar from './EmpresaEditar'
import {actions} from '../../../../redux/modules/empresas/empresas'
import { print } from '../../../../redux/modules/print/print'

const ms2p = (state) => ({
    empresa: state.empresas.editando,
    loading: state.empresas.loader,
    formato_factura: state.empresas.formato_factura,
    datos_ejemplo: state.empresas.datos_ejemplo,
    categorias: state.empresas.categorias,
    categorias_seleccionados: state.empresas.categorias_seleccionados,
    categorias_expanded: state.empresas.categorias_expanded,
    print_state: state.print
})

const md2p = {
    ...actions,
    submit: archivos => dispatch=> dispatch(actions.editarEmpresa(archivos)),
    print: (name) => dispatch => dispatch(print(name)),
}

export default connect(ms2p, md2p)(Editar)
