// imprime un elemento por medio de su
// nombre unico
import { handleActions } from "redux-actions";

// ------------------------------------
// Constants
// ------------------------------------

export const PRINT_FLAG = "PRINT_FLAG";
export const PRINT_ITEM = "PRINT_ITEM";

// ------------------------------------
// Actions
// ------------------------------------

export const print = (name) => (dispatch) => {
    dispatch(setItem(name));
    dispatch(setFlag(true));

};

export const banderaDown = () => (dispatch) => {
    dispatch(setItem(""));
    dispatch(setFlag(false));
};

// ------------------------------------
// PureActions
// ------------------------------------

export const setFlag = flag => ({
    type: PRINT_FLAG,
    flag,
});

export const setItem = item => ({
    type: PRINT_ITEM,
    item,
});

export const actions = {
    print,
    banderaDown,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [PRINT_FLAG]: (state, { flag }) => {
        return {
            ...state,
            flag,
        };
    },
    [PRINT_ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    item: "none",
    flag: false,
};

export default handleActions(reducers, initialState);