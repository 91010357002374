import { connect } from 'react-redux'
import CategoriaListado from './CategoriaListado'
import { actions } from "../../../../redux/modules/categoria";

const ms2p = (state) => ({
    ...state.categoria
})

const md2p = {
    ...actions
}

export default connect(ms2p, md2p)(CategoriaListado)