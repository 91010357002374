export const tipo_movimientos = {
    INGRESO: 10,
    VENTA: 30,
    COMPRA: 20,
    RETIRO: 40,
    BAJA: 50,
    REAJUSTE: 60,
    DEVOLUCION: 70,
    ABONO_CUENTA: 80,
};

export const niveles_usuario = {
    PROPIETARIO: 100,
    ADMIN: 75,
    BODEGUERO: 50,
    CAJERO: 25,
};

export const opcionesDevolucion = [
    { label: "El producto tenía defectos", value: 10 },
    { label: "Garantía", value: 30 },
    { label: "No cumple con expectativas del cliente", value: 20 },
    { label: "No coincide con la publicidad o empaque", value: 40 },
];

export const descuentoNC = [
    { label: "El producto tenía defectos", value: 10 },
    { label: "Garantía", value: 30 },
    { label: "No cumple con expectativas del cliente", value: 20 },
    { label: "No coincide con la publicidad o empaque", value: 40 },
    { label: "Descuento", value: 50 },
];

export const opcionesReingreso = [
    { label: "Sí regresa", value: true },
    { label: "No regresa", value: false },
];

// LA DESCRIPCIÓN VA AQUI PORQUE NO LA NECESITAMOS EN BACKEND
// TODO poner una descripcion apropiada
export const acciones_bitacora = [
    { descripcion: "Login", label: "Login" },
    { descripcion: "Crear producto", label: "Crear producto" },
    { descripcion: "Editar producto", label: "Editar producto" },
    { descripcion: "Eliminar producto", label: "Eliminar producto" },
    { descripcion: "Crear usuario", label: "Crear usuario" },
    { descripcion: "Editar usuario", label: "Editar usuario" },
    { descripcion: "Eliminar usuario", label: "Eliminar usuario" },
    { descripcion: "Crear cliente", label: "Crear cliente" },
    { descripcion: "Editar cliente", label: "Editar cliente" },
    { descripcion: "Eliminar cliente", label: "Eliminar cliente" },
    { descripcion: "Crear Proveedor", label: "Crear Proveedor" },
    { descripcion: "Editar Proveedor", label: "Editar Proveedor" },
    { descripcion: "Eliminar Proveedor", label: "Eliminar Proveedor" },
    { descripcion: "Movimiento del inventario", label: "Movimiento del inventario", },
    { descripcion: "Ingreso de productos", label: "Ingreso de productos" },
    { descripcion: "Recepcion de Traslado", label: "Recepcion de Traslado" },
    { descripcion: "Reajuste", label: "Reajuste" },
    { descripcion: "Abono a cuenta por pagar", label: "Abono a cuenta por pagar", },
    { descripcion: "Abono a cuenta por cobrar", label: "Abono a cuenta por cobrar", },
    { descripcion: "Pago Multiple cuenta por pagar", label: "Pago Multiple cuenta por pagar", },
    { descripcion: "Pago Multiple por pagar", label: "Pago Multiple por pagar", },
    { descripcion: "Editar Banco", label: "Editar Banco", },
    { descripcion: "Crear Banco", label: "Crear Banco", },
    { descripcion: "Eliminar Banco", label: "Eliminar Banco", },
    { descripcion: "Ingreso de Documento Banco", label: "Ingreso de Documento Banco", },
    { descripcion: "Editar Documento Banco", label: "Editar Documento Banco", },
    { descripcion: "Eliminacion de Documento Banco", label: "Eliminacion de Documento Banco", },
    { descripcion: "Crear transporte", label: "Crear transporte", },
    { descripcion: "Modificar transporte", label: "Modificar transporte", },
    { descripcion: "Eliminar transporte", label: "Eliminar transporte", },
    { descripcion: "Devolucion al Proveedor", label: "Devolucion al Proveedor", },
    { descripcion: "Devolucion al cliente", label: "Devolucion al cliente", },
    { descripcion: "Comisión por descuento", label: "Comisión por descuento", },
    { descripcion: "Emisión de traslado", label: "Emisión de traslado", },
    { descripcion: "Orden de compra", label: "Orden de compra", },
    { descripcion: "Crear Sucursal", label: "Crear Sucursal", },
    { descripcion: "Eliminar Sucursal", label: "Eliminar Sucursal", },
    { descripcion: "Editar Sucursal", label: "Editar Sucursal", },
    { descripcion: "Crear Familia", label: "Crear Familia", },
    { descripcion: "Editar Familia", label: "Editar Familia", },
    { descripcion: "Eliminar Familia", label: "Eliminar Familia", },
    { descripcion: "Crear Orden de Venta", label: "Crear Orden de Venta", },
    { descripcion: "Crear Proveedor", label: "Crear Proveedor", },
    { descripcion: "Editar Proveedor", label: "Editar Proveedor", },
    { descripcion: "Eliminar Proveedor", label: "Eliminar Proveedor", },
];

//  TIPOS DE PAGO
export const pago_total = 100;
export const pago_credito = 200;

//  TIPOS DE UNIDAD DE MEDIDA
export const UNIDAD = 10;
export const CAPACIDAD = 20;
export const PESO = 30;
export const LONGITUD = 40;
export const AREA = 50;
export const VOLUMEN = 60;
export const OTROS = 100;

//  ESTADOS DE FACTURA
export const PROCESO = 10;
export const FACTURADO = 20;
export const ANULADA = 30;
export const ERROR = 40;

// ESTADOS DESPACHO
export const DESPACHO_PENDIENTE = 10;
export const DESPACHO_COMPLETO = 20;

// TIPO PUNTO DE VENTA
export const PVN = "PVN"; // Normal
export const PVMCE = "PVMCE"; // Mostrador-Caja-Entrega

// TIPO METODOS
export const METODO_PAGO = 10;
export const METODO_ENTREGA = 20;

// Tipos de proveedor
export const TIPO_PROVEEDOR = [
    // { id: 0, value: "", label: "Seleccione un tipo de proveedor" },
    { id: 1, value: 1, label: "Importación" },
    { id: 2, value: 2, label: "Local" },
    { id: 3, value: 3, label: "Servicios" },
];

// Tipos de compra
export const TIPO_COMPRA = [
    { id: 0, value: "", label: "Seleccione un tipo de compra" },
    { id: 1, value: 1, label: "Crédito" },
    { id: 2, value: 2, label: "Contado" },
];

export const TIPO_MOVIMIENTO = [
    { id: 0, value: "", label: "Seleccione un tipo de movimiento" },
    { id: 1, value: 1, label: "Boleta de depósito" },
    { id: 2, value: 2, label: "Nota de crédito" },
    { id: 3, value: 3, label: "Ingreso de saldo a bancos" },
    { id: 4, value: 4, label: "Transferencia electrónica" },
    { id: 5, value: 5, label: "Tarjeta" },
];

export const TIPO_MOVIMIENTO_ARTICULOS_ENTRADAS = [
    {value: '1', label: 'Compras al contado'},
    {value: '2', label: 'Compras al crédito' },
    {value: '3', label: 'Recepciones de traslado' },
    {value: '4', label: 'Devoluciones del cliente' },
    {value: '5', label: 'Reajustes positivos' },
];

export const TIPO_MOVIMIENTO_ARTICULOS_SALIDAS = [
    {value: '1', label: "Envíos" },
    {value: '2', label: "Facturas" },
    {value: '3', label: "Ventas al crédito" },
    {value: '4', label: "Traslados" },
    {value: '5', label: "Devoluciones al proveedor" },
    {value: '6', label: "Reajustes negativos" },
];

export const FORMAS_PAGO = [
    { id: 0, value: "", label: "Seleccione una forma de pago" },
    {
        id: 1,
        value: "EFE",
        label: "Efectivo",
    },
    {
        id: 2,
        value: "TAR",
        label: "Tarjeta",
    },
    {
        id: 3,
        value: "CHE",
        label: "Cheque",
    },
    {
        id: 4,
        value: "DEP",
        label: "Depósito",
    },
    {
        id: 5,
        value: "TRA",
        label: "Transferencia",
    },
    {
        id: 6,
        value: "TRE",
        label: "Transferencia electrónica",
    },
];

export const FORMAS_PAGO_RETENCION = [
    ...FORMAS_PAGO,
    {
        id: 7,
        value: "RTC",
        label: "Retención",
    },
];

export const TIPO_MOVIMIENTO_EDICION = [
    { id: 0, value: "", label: "Seleccione un tipo de movimiento" },
    { id: 1, value: 1, label: "Boleta de depósito" },
    { id: 2, value: 2, label: "Nota de crédito" },
    { id: 3, value: 3, label: "Ingreso de saldo a bancos" },
    { id: 4, value: 4, label: "Transferencia electrónica" },
    { id: 5, value: 5, label: "Tarjeta" },
    { id: 6, value: 6, label: "Salida orden de compra" },
    { id: 7, value: 7, label: "Entrada de devolución" },
    { id: 8, value: 8, label: "Pago orden de compra al crédito" },
];

export const ORIGEN = [
    { id: 0, value: "", label: "Seleccione un origen" },
    { id: 1, value: 1, label: "Importación" },
    { id: 2, value: 2, label: "Local" },
    { id: 3, value: 3, label: "Servicios" },
];

export const departamentosOptions = [
    { id: 1, value: "", label: "Seleccione un departamento" },
    { label: "Alta Verapaz", value: "alta_verapaz" },
    { label: "Baja Verapaz", value: "baja_verapaz" },
    { label: "Chimaltenago", value: "chimaltenago" },
    { label: "Chiquimula", value: "chiquimula" },
    { label: "Guatemala", value: "guatemala" },
    { label: "El Progreso", value: "el_Progreso" },
    { label: "Escuintla", value: "escuintla" },
    { label: "Huehuetenango", value: "huehuetenango" },
    { label: "Izabal", value: "izabal" },
    { label: "Jalapa", value: "jalapa" },
    { label: "Jutiapa", value: "jutiapa" },
    { label: "Petén", value: "peten" },
    { label: "Quetzaltenango", value: "quetzaltenango" },
    { label: "Quiché", value: "quiché" },
    { label: "Retalhuleu", value: "retalhuleu" },
    { label: "Sacatepequez", value: "sacatepequez" },
    { label: "San Marcos", value: "san_Marcos" },
    { label: "Santa Rosa", value: "santa_rosa" },
    { label: "Sololá", value: "solola" },
    { label: "Suchitepequez", value: "suchitepequez" },
    { label: "Totonicapán", value: "totonicapan" },
    { label: "Zacapa", value: "zacapa" },
];

export const municipiosOptions = {
    alta_verapaz: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Cobán", value: "coban" },
        { label: "Santa Cruz Verapaz", value: "santa_cruz_verapaz" },
        { label: "San Cristobal Verapaz", value: "san_cristobal_verapaz" },
        { label: "Tactíc", value: "tactic" },
        { label: "Tamahú", value: "tamahu" },
        { label: "San Miguel Tucurú", value: "san_miguel_tucuru" },
        { label: "Panzos", value: "panzos" },
        { label: "Senahú", value: "senahu" },
        { label: "San Pedro Carchá", value: "san_pedro_carcha" },
        { label: "SanJuan Chamelco", value: "san_juan_chamelco" },
        { label: "Lanquín", value: "laquin" },
        { label: "Santa María Cahabón", value: "santa_maria_cahabon" },
        { label: "Chisec", value: "chisec" },
        { label: "Chahal", value: "chahal" },
        {
            label: "Fray Bartolomé de las Casas",
            value: "fray_bartolome_de_las_casas",
        },
        { label: "Santa Catarina La Tinta", value: "santa_catarina_la_tinta" },
    ],

    baja_verapaz: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Salamá", value: "salama" },
        { label: "San Miguel Chicaj", value: "san_miguel_chical" },
        { label: "Rabinal", value: "rabinal" },
        { label: "Cubulco", value: "cubulco" },
        { label: "Granados", value: "granados" },
        { label: "Santa Cruz El Chol", value: "santa_cruz_el_chol" },
        { label: "San Jerónimo", value: "san_jeronimo" },
        { label: "Purulhá", value: "purulha" },
    ],

    chimaltenago: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Chimaltenango", value: "chimaltenago" },
        { label: "San José Poaquil", value: "san_jose_poaquil" },
        { label: "San Martín Jilotepeque", value: "san_martin_jilotepeque" },
        { label: "San Juan Comalapa", value: "san_juan_comalapa" },
        { label: "Santa Apolonia", value: "santa_apolonia" },
        { label: "Tecpán Guatemala", value: "tecpan_guatemala" },
        { label: "Patzun", value: "patzun" },
        { label: "San Miguel Pochuta", value: "san_miguel_pochuta" },
        { label: "Patzicia", value: "patzicia" },
        { label: "Santa Cruz Balanyá", value: "santa_cruz_balanya" },
        { label: "Acatenango", value: "acatenango" },
        { label: "San Pedro Yepocapa", value: "san_pedro_yepocapa" },
        { label: "San Andrés Itzapa", value: "san_andres_itzapa" },
        { label: "Parramos", value: "parramos" },
        { label: "Zaragoza", value: "zaragoza" },
        { label: "El Tejar", value: "el_tejar" },
    ],

    chiquimula: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Chiquimula", value: "chiquimula" },
        { label: "San José La Arada", value: "san_jose_la_arada" },
        { label: "San Juan Hermita", value: "san_juan_hermita" },
        { label: "Jocotán", value: "jocotan" },
        { label: "Camotán", value: "camotan" },
        { label: "Olopa", value: "olopa" },
        { label: "Esquipulas", value: "esquipulas" },
        { label: "Concepción Las Minas", value: "concepcion_las_minas" },
        { label: "Quezaltepeque", value: "quezaltepeque" },
        { label: "San Jacinto", value: "san_jacinto" },
        { label: "Ipala", value: "ipala" },
    ],

    guatemala: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Guatemala", value: "guatemala" },
        { label: "Santa Catarina Pinula", value: "santa_catarina_pinula" },
        { label: "San José Pinula", value: "san_jose_pinula" },
        { label: "San José del Golfo", value: "san_jose_del_golfo" },
        { label: "Palencia", value: "palencia" },
        { label: "Chinautla", value: "chinautla" },
        { label: "San Pedro Ayampuc", value: "san_pedro_ayampuc" },
        { label: "Mixco", value: "mixco" },
        { label: "San Pedro Sacatepequez", value: "san_pedro_sacatepequez" },
        { label: "San Juan Sacatepequez", value: "san_juan_sacatepequez" },
        { label: "San Raymundo", value: "san_raymundo" },
        { label: "Chuarrancho", value: "chuarrancho" },
        { label: "Fraijanes", value: "fraijanes" },
        { label: "Amatitlán", value: "amatitlan" },
        { label: "Villa Nueva", value: "villa_nueva" },
        { label: "Villa Canales", value: "villa_canales" },
        { label: "San Miguel Petapa", value: "san_miguel_petapa" },
    ],

    el_Progreso: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Guastatoya", value: "guastatoya" },
        { label: "Morazán", value: "morazan" },
        {
            label: "San Agustín Acasaguastlan",
            value: "san_agustin_acasaguastlan",
        },
        {
            label: "San Cristóbal Acasaguastlan",
            value: "san_cristobal_acasaguastlan",
        },
        { label: "El Jícaro", value: "el_jicaro" },
        { label: "Sansare", value: "sansare" },
        { label: "Sanarate", value: "sanarate" },
        { label: "San Antonio La Paz", value: "san_antonio_la_paz" },
    ],

    escuintla: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Escuintla", value: "escuintla" },
        {
            label: "Santa Lucía Cotzumalguapa",
            value: "santa_lucia_cotzumalguapa",
        },
        { label: "La Democracia", value: "la_democracia" },
        { label: "Siquinalá", value: "siquinala" },
        { label: "Masagua", value: "masagua" },
        { label: "Pueblo Nuevo Tiquisate", value: "pueblo_nuevo_tiquisate" },
        { label: "La Gomera", value: "la_gomera" },
        { label: "Guanagazapa", value: "guanagazapa" },
        { label: "Puerto de San José", value: "puerto_de_san_jose" },
        { label: "Iztapa", value: "iztapa" },
        { label: "Palín", value: "palin" },
        { label: "San Vicente Pacaya", value: "san_vicente_pacaya" },
        { label: "Nueva Concepción", value: "nueva_concepcion" },
    ],

    huehuetenango: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Huehuetenango", value: "huehuetenango" },
        { label: "Chiantla", value: "chiantla" },
        { label: "Malacatancito", value: "malacatancito" },
        { label: "Cuilco", value: "cuilco" },
        { label: "Nentón", value: "nenton" },
        { label: "San Pedro Necta", value: "san_pedro_necta" },
        { label: "Jacaltenango", value: "jacaltenango" },
        { label: "San Pedro Soloma", value: "san_pedro_soloma" },
        {
            label: "San Ildelfonso Ixtahuac ́n",
            value: "san_ildelfonso_ixtahuac ́n",
        },
        { label: "Santa Bárbara", value: "santa_barbara" },
        { label: "La Libertad", value: "la_libertad" },
        { label: "La Democracia", value: "la_democracia" },
        { label: "San Miguel Acatán", value: "san_miguel_acatan" },
        {
            label: "San Rafael La Independencia",
            value: "san_rafael_la_independencia",
        },
        {
            label: "Todos Santos Chuchcumatán",
            value: "todos_santos_chuchcumatan",
        },
        { label: "San Juan Atitán", value: "san_juan_atitan" },
        { label: "Santa Eulalia", value: "santa_eulalia" },
        { label: "San Mateo Ixtatán", value: "san_mateo_ixtatan" },
        { label: "Colotenango", value: "colotenango" },
        {
            label: "San Sebastián Huehuetenango",
            value: "san_sebastian_huehuetenango",
        },
        { label: "Tectitán", value: "tectitan" },
        { label: "Concepción Huista", value: "concepcion_huista" },
        { label: "San Juan Ixcoy", value: "san_juan_ixcoy" },
        { label: "San Antonio Huista", value: "san_antonio_huista" },
        { label: "San Sebastián Coatán", value: "san_sebastian_coatan" },
        { label: "Santa Cruz Barillas", value: "santa_cruz_barillas" },
        { label: "Aguacatán", value: "aguacatan" },
        { label: "San Rafael Petzal", value: "san_rafael_petzal" },
        { label: "San Gaspar Ixchil", value: "san_gaspar_ixchil" },
        { label: "Santiago Chimaltenango", value: "santiago_chimaltenango" },
        { label: "Santa Ana Huista", value: "santa_ana_huista" },
    ],

    izabal: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Puerto Barrios", value: "puerto_barrios" },
        { label: "Livingston", value: "livingston" },
        { label: "El Estor", value: "el_estor" },
        { label: "Morales", value: "morales" },
        { label: "Los Amates", value: "los_Amates" },
    ],

    jalapa: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Jalapa", value: "jalapa" },
        { label: "San Pedro Pinula", value: "san_pedro_pinula" },
        { label: "San Luis Jilotepeque", value: "san_luis_jilotepeque" },
        { label: "San Manuel Chaparrón", value: "san_manuel_chaparron" },
        { label: "San Carlos Alzatate", value: "san_carlos_alzatate" },
        { label: "Monjas", value: "monjas" },
        { label: "Mataquescuintla", value: "mataquescuintla" },
    ],

    jutiapa: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Jutiapa", value: "jutiapa" },
        { label: "El Progreso", value: "el_Progreso" },
        { label: "Santa Catarina Mita", value: "santa_catarina_mita" },
        { label: "Agua Blanca", value: "agua_blanca" },
        { label: "Asunción Mita", value: "asuncion_mita" },
        { label: "Yupiltepeque", value: "yupiltepeque" },
        { label: "Atescatempa", value: "atescatempa" },
        { label: "Jerez", value: "jerez" },
        { label: "El Adelanto", value: "el_adelanto" },
        { label: "Zapotitlán", value: "zapotitlan" },
        { label: "Comapa", value: "comapa" },
        { label: "Jalpatagua", value: "jalpatagua" },
        { label: "Conguaco", value: "conguaco" },
        { label: "Moyuta", value: "moyuta" },
        { label: "Pasaco", value: "pasaco" },
        { label: "San José Acatempa", value: "san_jose_acatempa" },
        { label: "Quezada", value: "quezada" },
    ],

    peten: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Flores", value: "flores" },
        { label: "San José", value: "san_jose" },
        { label: "San Benito", value: "san_benito" },
        { label: "San Andrés", value: "san_andres" },
        { label: "La Libertad", value: "la_libertad" },
        { label: "San Francisco", value: "san_francisco" },
        { label: "Santa Ana", value: "santa_ana" },
        { label: "Dolores", value: "dolores" },
        { label: "San Luis", value: "san_luis" },
        { label: "Sayaxche", value: "sayaxche" },
        { label: "Melchor de Mencos", value: "melchor_de_mencos" },
        { label: "Poptún", value: "poptun" },
    ],

    quetzaltenango: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Quetzaltenango", value: "quetzaltenango" },
        { label: "Salcajá", value: "salcaja" },
        { label: "Olintepeque", value: "olintepeque" },
        { label: "San Carlos Sija", value: "san_carlos_sija" },
        { label: "Sibilia", value: "sibilia" },
        { label: "Cabrican", value: "cabrican" },
        { label: "Cajola", value: "cajola" },
        { label: "San Miguel Siguilça", value: "san_miguel_siguilça" },
        { label: "San Juan Ostuncalco", value: "san_juan_ostuncalco" },
        { label: "San Mateo", value: "san_mateo" },
        {
            label: "Concepción Chiquirichapa",
            value: "concepcion_chiquirichapa",
        },
        { label: "San Martín Sacatepequez", value: "san_martin_sacatepequez" },
        { label: "Almolonga", value: "almolonga" },
        { label: "Cantel", value: "cantel" },
        { label: "Huitán", value: "huitan" },
        { label: "Zunil", value: "zunil" },
        { label: "Colomba", value: "colomba" },
        { label: "San Francisco La Unión", value: "san_francisco_la_union" },
        { label: "El Palmar", value: "el_palmar" },
        { label: "Coatepeque", value: "coatepeque" },
        { label: "Génova", value: "genova" },
        { label: "Flores Costa Cuca", value: "flores_costa_cuca" },
        { label: "La Esperanza", value: "la_esperanza" },
        { label: "Palestina de los Altos", value: "palestina_de_los_altos" },
    ],

    quiché: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Santa Cruz del Quiche", value: "santa_cruz_del_quiche" },
        { label: "Chiche", value: "chiche" },
        { label: "Chinique", value: "chinique" },
        { label: "Zacualpa", value: "zacualpa" },
        { label: "Chajul", value: "chajul" },
        {
            label: "Santo Tomás Chichicstenango",
            value: "santo_tomas_chichicstenango",
        },
        { label: "Patzité", value: "patzite" },
        { label: "San Antonio Ilotenango", value: "san_antonio_ilotenango" },
        { label: "San Pedro Jocopilas", value: "san_pedro_jocopila" },
        { label: "Cunén", value: "cunen" },
        { label: "San Juan Cotzal", value: "san_juan_cotzal" },
        { label: "Joyabaj", value: "joyabaj" },
        { label: "Santa María Nebaj", value: "santa_maria_nebaj" },
        { label: "San Andrés Sajcabajá", value: "san_andres_sajcabaja" },
        { label: "San Miguel Uspatán", value: "san_miguel_uspatan" },
        { label: "Sacapulas", value: "sacapulas" },
        {
            label: "San Bartolomé Jocotenango",
            value: "san_bartolome_jocotenango",
        },
        { label: "Canilla", value: "canilla" },
        { label: "Chicaman", value: "chicaman" },
        { label: "Playa Grnade - Ixcán", value: "playa_grnade_ixcan" },
        { label: "Pachalúm", value: "pachalum" },
    ],

    retalhuleu: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Retalhuelu", value: "retalhuelu" },
        { label: "San Sebastián", value: "san_sebastian" },
        { label: "Santa Cruz Mulúa", value: "santa_cruz_mulua" },
        { label: "San Martín Zapotitlán", value: "san_martin_zapotitlan" },
        { label: "San Felipe Retalhuleu", value: "san_felipe_retalhuleu" },
        { label: "San Andrés Villa Seca", value: "san_andres_villa_seca" },
        { label: "Champerico", value: "champerico" },
        { label: "Nuevo San Carlos", value: "nuevo_san_carlos" },
        { label: "El Asintal", value: "el_asintal" },
    ],

    sacatepequez: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Antigua Guatemala", value: "antigua_guatemala" },
        { label: "Jocotenango", value: "jocotenango" },
        { label: "Pastores", value: "pastores" },
        { label: "Sumpango", value: "sumpango" },
        { label: "Santo Domingo Xenacoj", value: "santo_domingo_xenacoj" },
        { label: "Santiago Sacatepequez", value: "santiago_sacatepequez" },
        {
            label: "San Bartolomé Milpas Altas",
            value: "san_bartolome_milpas_altas",
        },
        { label: "San Lucas Sacatepequez", value: "san_lucas_sacatepequez" },
        {
            label: "Santa Lucía Milpas Altas",
            value: "santa_lucia_milpas_altas",
        },
        { label: "Magdalena Milpas Altas", value: "magdalena_milpas_altas" },
        { label: "Santa María de Jesús", value: "santa_maria_de_jesus" },
        { label: "Ciudad Vieja", value: "ciudad_vieja" },
        { label: "San Miguel Dueñas", value: "san_miguel_duenas" },
        { label: "San Juan Alotenango", value: "san_juan_alotenango" },
        {
            label: "San Antonio Aguas Calientes",
            value: "san_antonio_aguas calientes",
        },
        { label: "Santa Catarina Barahona", value: "santa_catarina_barahona" },
    ],

    san_Marcos: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "San Marcos", value: "san_marcos" },
        { label: "San Pedro Sacatepéquez", value: "san_pedro_sacatepequez" },
        { label: "Comitancillo", value: "comitancillo" },
        {
            label: "San Antonio Sacatepéquez",
            value: "san_antonio_sacatepequez",
        },
        { label: "San Miguel Ixtahuacan", value: "san_miguel_ixtahuacan" },
        { label: "Concepción Tutuapa", value: "concepcion_tutuapa" },
        { label: "Tacaná", value: "tacana" },
        { label: "Sibinal", value: "sibinal" },
        { label: "Tajumulco", value: "tajumulco" },
        { label: "Tejutla", value: "tejutla" },
        {
            label: "San Rafael Pié de la Cuesta",
            value: "san_rafael_pie_de_la_cuesta",
        },
        { label: "Nuevo Progreso", value: "nuevo_progreso" },
        { label: "El Tumbador", value: "el_tumbador" },
        { label: "San José El Rodeo", value: "san_jose_el_rodeo" },
        { label: "Malacatán", value: "malacatan" },
        { label: "Catarina", value: "catarina" },
        { label: "Ayutla", value: "ayutla" },
        { label: "Ocos", value: "ocos" },
        { label: "San Pablo", value: "san_pablo" },
        { label: "El Quetzal", value: "el_quetzal" },
        { label: "La Reforma", value: "la_reforma" },
        { label: "Pajapita", value: "pajapita" },
        { label: "Ixchiguan", value: "ixchiguan" },
        { label: "San José Ojetenán", value: "san_jose_ojetenan" },
        { label: "San Cristóbal Cucho", value: "san_cristobal_cucho" },
        { label: "Sipacapa", value: "sipacapa" },
        { label: "Esquipulas Palo Gordo", value: "esquipulas_palo_gordo" },
        { label: "Río Blanco", value: "rio_blanco" },
        { label: "San Lorenzo", value: "san_lorenzo" },
    ],

    santa_rosa: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Cuilapa", value: "cuilapa" },
        { label: "Berberena", value: "berberena" },
        { label: "San Rosa de Lima", value: "san_rosa_de_lima" },
        { label: "Casillas", value: "casillas" },
        { label: "San Rafael Las Flores", value: "san_rafael_las_flores" },
        { label: "Oratorio", value: "oratorio" },
        { label: "San Juan Tecuaco", value: "san_juan_tecuaco" },
        { label: "Chiquimulilla", value: "chiquimulilla" },
        { label: "Taxisco", value: "taxisco" },
        { label: "Santa María Ixhuatan", value: "santa_maria_ixhuatan" },
        { label: "Guazacapán", value: "guazacapan" },
        { label: "Santa Cruz Naranjo", value: "santa_cruz_naranjo" },
        { label: "Pueblo Nuevo Viñas", value: "pueblo_nuevo_vinas" },
        { label: "Nueva Santa Rosa", value: "nueva_santa_rosa" },
    ],

    solola: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Sololá", value: "Solola" },
        { label: "San José Chacaya", value: "san_jose_chacaya" },
        { label: "Santa María Visitación", value: "santa_maria_visitacion" },
        { label: "Santa Lucía Utatlán", value: "santa_lucia_utatlan" },
        { label: "Nahualá", value: "nahuala" },
        {
            label: "Santa Catarina Ixtahuacán",
            value: "santa_catarina_ixtahuacan",
        },
        { label: "Santa Clara La Laguna", value: "santa_clara_la_laguna" },
        { label: "Concepción", value: "concepcion" },
        { label: "San Andrés Semetabaj", value: "san_andres_semetabaj" },
        { label: "Panajachel", value: "panajachel" },
        { label: "Santa Catarina Palopó", value: "santa_catarina_palopo" },
        { label: "San Antonio Palopó", value: "san_antonio_palopo" },
        { label: "San Lucas Tolimán", value: "san_lucas_toliman" },
        { label: "Santa Cruz La Laguna", value: "santa_cruz_la_laguna" },
        { label: "San Pablo La Laguna", value: "san_pablo_la_laguna" },
        { label: "San Marcos La Laguna", value: "san_marcos_la_laguna" },
        { label: "San Juan La Laguna", value: "san_juan_la_laguna" },
        { label: "San Pedro La Laguna", value: "san_pedro_la_laguna" },
        { label: "Santiago Atitlán", value: "santiago_atitlan" },
    ],

    suchitepequez: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Mazatenango", value: "mazatenango" },
        { label: "Cuyotenango", value: "cuyotenango" },
        {
            label: "San Francisco Zapotitlán",
            value: "san_francisco_zapotitlan",
        },
        { label: "San Bernardino", value: "san_bernardino" },
        { label: "San José El Ídolo", value: "san_jose_el_idolo" },
        {
            label: "Santo Domingo Suchitepequez",
            value: "santo_domingo_suchitepequez",
        },
        { label: "San Lorenzo", value: "san_lorenzo" },
        { label: "Samayac", value: "samayac" },
        { label: "San Pablo Jocopilas", value: "san_pablo_jocopilas" },
        {
            label: "San Antonio Suchitepéquez",
            value: "san_antonio_suchitepequez",
        },
        { label: "San Miguel Panán", value: "san_miguel_panan" },
        { label: "San Gabriel", value: "san_gabriel" },
        { label: "Chicacao", value: "chicacao" },
        { label: "Patulul", value: "patulul" },
        { label: "Santa Bárbara", value: "santa_barbara" },
        { label: "San Juan Bautista", value: "san_juan_bautista" },
        { label: "Santo Tomás La Unión", value: "santo_tomas_la_union" },
        { label: "Zunilito", value: "zunilito" },
        {
            label: "Pueblo Nuevo Suchitepéquez",
            value: "pueblo_nuevo_suchitepequez",
        },
        { label: "Río Bravo", value: "rio_bravo" },
    ],

    totonicapan: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Totonicapán", value: "totonicapan" },
        {
            label: "San Cristóbal Totonicapán",
            value: "san_cristóbal_totonicapan",
        },
        { label: "San Francisco El Alto", value: "san_francisco_el_alto" },
        { label: "San Andrés Xecul", value: "san_andres_xecul" },
        { label: "Momostenango", value: "momostenango" },
        { label: "Santa María Chiquimula", value: "santa_maria_chiquimula" },
        { label: "Santa Lucía La Reforma", value: "santa_lucia_la_reforma" },
        {
            label: "San Bartolo Aguas Calientes",
            value: "san_bartolo_aguas_calientes",
        },
    ],

    zacapa: [
        { id: 1, value: "", label: "Seleccione un municipio" },
        { label: "Zacapa", value: "zacapa" },
        { label: "Estanzuela", value: "estanzuela" },
        { label: "Río Hondo", value: "rio_hondo" },
        { label: "Gualán", value: "gualan" },
        { label: "Teculután", value: "teculutan" },
        { label: "Usumatlán", value: "usumatlan" },
        { label: "Cabañas", value: "cabañas" },
        { label: "San Diego", value: "san_diego" },
        { label: "La Unión", value: "la_union" },
        { label: "Huite", value: "huite" },
    ],
};

// Tipos de compra
export const TIPO_DEVOLUCION = [
    // { id: 0, value: "", label: "Seleccione un tipo de devolucion" },
    { id: 1, value: 1, label: "Devolución al proveedor" },
    // { id: 2, value: 2, label: "Devolución al cliente" },
    // { id: 3, value: 4, label: "Devolución alterna" },
];

export const TIPO_MOVIMIENTO_BODEGA = [
    { id: 1, value: 50, label: "Devolución Compra" },
    { id: 2, value: 70, label: "Devolución Venta" },
    { id: 4, value: 80, label: "Descuento Venta" },
    { id: 3, value: 60, label: "Reajuste" },
];

export const TIPO_MOVIMIENTO_CP = [
    { id: 1, value: 1, label: "Pendiente" },
    { id: 2, value: 2, label: "Finalizada" },
    { id: 3, value: 3, label: "Vencida" },
];

export const TIPO_MOVIMIENTO_CP_SIMPLIFICADO = [
    { id: 1, value: 2, label: "Finalizadas" },
    { id: 2, value: 4, label: "No finalizadas" }
]

export const TIPO_COTIZACION = [
    { id: 1, value: 1, label: "Pendiente" },
    { id: 2, value: 2, label: "Finalizada" },
];

export const TIPO_VENTA = [
    { id: 1, value: 10000, label: "Envio" },
    { id: 2, value: 30000, label: "Credito" },
    { id: 3, value: 20000, label: "Factura" },
];

export const capitalizeString = (str) => {
    //split the above string into an array of strings
    //whenever a blank space is encountered

    const arr = str.split(" ");

    //loop through each element of the array and capitalize the first letter.

    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    //Join all the elements of the array back into a string
    //using a blankspace as a separator
    const str2 = arr.join(" ");
    return str2;
};

export const TIPO_REPORTE_COMISION = [
    { id: 1, value: 1, label: "Al credito" },
    { id: 2, value: 2, label: "Al contado" },
];

export const ESTADO_INVENTARIO = [
    { id: 1, value: 1, label: "Con inventario" },
    { id: 2, value: 2, label: "Sin inventario" },
    { id: 3, value: 3, label: "Inventario negativo" },
];

export const TIPO_REPORTE = [
    { id: 1, value: 1, label: "Artículos más vendidos" },
    { id: 2, value: 2, label: "Artículos sin venta" },
];

export const TIPO_REPORTE_ENTRADAS = [
    { id: 1, value: 1, label: "Resumido" },
    { id: 2, value: 2, label: "Detallado" },
];

export const TIPO_MOVIMIENTO_ENTRADAS = [
    { id: 1, value: 1, label: "Compra" },
    { id: 2, value: 2, label: "Devolución de cliente" },
    { id: 3, value: 3, label: "Reajuste hacia arriba" },
    { id: 4, value: 4, label: "Recepción de traslados" },
];

export const TIPO_MOVIMIENTO_SALIDAS = [
    { id: 1, value: 1, label: "Venta envio" },
    { id: 2, value: 2, label: "Venta crédito" },
    { id: 3, value: 3, label: "Venta Factura" },
    { id: 4, value: 4, label: "Devolución al proveedor" },
    { id: 5, value: 5, label: "Reajuste hacia abajo" },
    { id: 6, value: 6, label: "Traslados" },
];

export const ID_NIT = 1;
export const ID_DPI = 2;
export const ID_DOC_EXT = 3;

export const TIPOS_ID_CLIENTE = [
    { id: 1, value: ID_NIT, label: "NIT" },
    { id: 2, value: ID_DPI, label: "DPI" },
    { id: 3, value: ID_DOC_EXT, label: "Documento extranjero" },
];

// Tipos de reporte de reajustes
export const REAJUSTES_POSITIVOS = 1
export const REAJUSTES_NEGATIVOS = 2

export const TIPOS_REPORTE_REAJUSTES = [
    { id: 1, value: REAJUSTES_POSITIVOS, label: "Reajustes positivos" },
    { id: 2, value: REAJUSTES_NEGATIVOS, label: "Reajustes negativos" }
]
