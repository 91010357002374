import { connect } from 'react-redux'
import Menu from './Menu'
import { logOut } from '../../../redux/modules/login'

const mstp = state => ({})
const mdtp = {
    logOut
}

export default connect(mstp, mdtp)(Menu)
