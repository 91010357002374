import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SucursalForm from './SucursalCrearForm'

class SucursalCrear extends Component {
    static propTypes = {
        validarSucursal: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)
        this.crear = this.crear.bind(this)
    }

    crear() {
        // obtener el id de la empresa
        const { id } = this.props.match.params;

        // crear la sucursal
        this.props.validarSucursal(id)
    }

    componentWillMount() {
        // leer desde el backend la empresa y la sucursal
        const { id } = this.props.match.params
        this.props.loadEmpresaEdit(id);
    }

    render() {
        // state
        const { empresa } = this.props;
        const { id } = this.props.match.params;
        // bind
        return (
            <div className="row mt-4">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <SucursalForm onSubmit={this.crear} idEmpresa={id} empresa={empresa}/>
                </div>
            </div>
        )
    }
}

export default SucursalCrear
