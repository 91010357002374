import { handleActions } from "redux-actions";
import { ToastStore } from "react-toasts";

import Swal from "sweetalert2";
import { api } from "api";
import _ from "lodash";
import uuidv1 from "uuid/v1";
import { push } from "react-router-redux";
import moment from "moment";

const endpoint = "recepcion_despachos";

// ------------------------------------
// Constants
// ------------------------------------
export const DATA = "RECEPCION_DESPACHOS_DATA";
export const SELECCIONADOS = "RECEPCION_DESPACHOS_SELECCIONADOS";
export const SET_DESPACHO = "RECEPCION_DESPACHOS_SET_DESPACHO";
export const SORT = "RECEPCION_DESPACHOS_SORT";
export const PAGE = "RECEPCION_DESPACHOS_PAGE";
export const BUSCADOR = "RECEPCION_DESPACHOS_BUSCADOR";
export const LOADER = "RECEPCION_DESPACHOS_LOADER";
export const TAB = "RECEPCION_DESPACHOS_TAB";
export const TIENE_ALERTAS = "RECEPCION_DESPACHOS_TIENE_ALERTAS";
export const FECHA_DESPACHO_INICIAL = "FECHA_DESPACHOS_INICIAL";
export const FECHA_DESPACHO_FINAL = "FECHA_DESPACHOS_FINAL";
export const USUARIO = "DESPACHOS_USUARIO";

// ------------------------------------
// PureActions
// ------------------------------------

export const seData = (data) => ({
    type: DATA,
    data,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});

export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});

export const setDespacho = (despacho) => ({
    type: SET_DESPACHO,
    despacho,
});

export const setTab = (tab) => ({
    type: TAB,
    tab,
});
export const setTieneAlertas = (tiene_alertas) => ({
    type: TIENE_ALERTAS,
    tiene_alertas,
});

export const setSeleccionados = (seleccionados) => ({
    type: SELECCIONADOS,
    seleccionados,
});

export const setFechaCompraInicial = (fecha_compra_inicial) => ({
    type: FECHA_DESPACHO_INICIAL,
    fecha_compra_inicial,
});

export const setFechaCompraFinal = (fecha_compra_final) => ({
    type: FECHA_DESPACHO_FINAL,
    fecha_compra_final,
});

export const setUsuario = (usuario) => ({
    type: USUARIO,
    usuario,
});

// ------------------------------------
// Actions
// ------------------------------------
export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();
        const {
            ordering,
            search,
            tab,
            fecha_compra_final,
            fecha_compra_inicial,
            usuario,
        } = store.recepcion_despacho;

        const { usuario_pendiente, usuario_completado } = usuario;

        // Obtener el id del usuario, si este existe
        const usuario_id_completado = usuario_completado
            ? usuario_completado.id
            : "";
        const usuario_id_pendiente = usuario_pendiente
            ? usuario_pendiente.id
            : "";

        const { fecha_pendiente_inicial, fecha_completado_inicial } =
            fecha_compra_inicial;
        const { fecha_pendiente_final, fecha_completado_final } =
            fecha_compra_final;

        const params = {
            ordering,
            search,
            estado: tab,
            page,
            fecha_inicial:
                tab == 10 ? fecha_pendiente_inicial : fecha_completado_inicial,
            fecha_final:
                tab == 10 ? fecha_pendiente_final : fecha_completado_final,
            usuario: tab == 10 ? usuario_id_pendiente : usuario_id_completado,
        };

        // Si son traslados completados, se filtra por el usuario que envio el traslado
        if (tab == 20) {
            params.usuario_enviado = usuario_id_pendiente;
        }

        api.get(endpoint, params)
            .catch((err) => {})
            .then((data) => {
                if (data) {
                    dispatch(seData(data));
                }
                dispatch(setPage(page));
                dispatch(setLoader(false));
            });
    };

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.sucursal.page;
    dispatch(listar(page));
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const changeTab = (tab) => (dispatch) => {
    dispatch(setTab(tab));
    dispatch(
        setUsuario({
            usuario_pendiente: null,
            usuario_completado: null,
        })
    );
    dispatch(listar());
};

const getDespacho = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`)
        .catch((err) => {
            dispatch(setDespacho(null));
        })
        .then((data) => {
            if (data) {
                let detalles = _.cloneDeep(data.detalles);
                detalles.forEach((item) => {
                    item.cantidad_recibido = item.cantidad;
                    item.tiene_alerta = false;
                });
                dispatch(setTieneAlertas(false));
                dispatch(setSeleccionados(detalles));
                dispatch(setDespacho(data));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const asignarCantidadRecibido =
    (producto, cantidad_recibido) => (dispatch, getStore) => {
        const store = getStore();
        const seleccionados = _.cloneDeep(
            store.recepcion_despacho.seleccionados
        );
        const item = _.find(seleccionados, { id: producto.id });
        const index = seleccionados.indexOf(item);
        if (cantidad_recibido < 0) {
            item.cantidad_recibido = 0;
        } else {
            item.cantidad_recibido =
                cantidad_recibido > producto.cantidad
                    ? producto.cantidad
                    : cantidad_recibido;
        }
        seleccionados.splice(index, 1, item);

        let tiene_alertas = false;
        seleccionados.forEach((item) => {
            if (item.cantidad_recibido < item.cantidad) {
                if (
                    item.nota == undefined ||
                    item.nota == null ||
                    item.nota == ""
                ) {
                    item.tiene_alerta = true;
                    tiene_alertas = true;
                } else {
                    item.tiene_alerta = false;
                }
            } else {
                item.tiene_alerta = false;
            }
        });
        dispatch(setTieneAlertas(tiene_alertas));
        dispatch(setSeleccionados(seleccionados));
    };

const setNotaProducto = (nota, producto) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.recepcion_despacho.seleccionados);
    const item = _.find(seleccionados, { id: producto.id });
    const index = seleccionados.indexOf(item);
    if (item) {
        item.nota = nota;
        seleccionados.splice(index, 1, item);
        let tiene_alertas = false;
        seleccionados.forEach((item) => {
            if (item.cantidad_recibido < item.cantidad) {
                if (
                    item.nota == undefined ||
                    item.nota == null ||
                    item.nota == ""
                ) {
                    item.tiene_alerta = true;
                    tiene_alertas = true;
                } else {
                    item.tiene_alerta = false;
                }
            } else {
                item.tiene_alerta = false;
            }
        });
        dispatch(setTieneAlertas(tiene_alertas));
        dispatch(setSeleccionados(seleccionados));
    }
};

const registrarRecepcion = () => (dispatch, getStore) => {
    const store = getStore();
    const { despacho } = store.recepcion_despacho;
    let { seleccionados } = store.recepcion_despacho;
    let tiene_alertas = false;
    dispatch(setLoader(true));
    seleccionados.forEach((item) => {
        if (!item.cantidad_recibido) item.cantidad_recibido = 0;

        if (item.cantidad_recibido < item.cantidad) {
            if (
                item.nota == undefined ||
                item.nota == null ||
                item.nota == ""
            ) {
                tiene_alertas = true;
            }
        }
    });

    if (!tiene_alertas) {
        api.put(`${endpoint}/${despacho.id}`, { detalles: seleccionados })
            .catch((err) => {
                if (err) {
                    if (err.detail) {
                        Swal("ERROR", err.detail, "error");
                    } else {
                        Swal(
                            "ERROR",
                            "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                            "error"
                        );
                    }
                } else {
                    Swal(
                        "ERROR",
                        "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                        "error"
                    );
                }
            })
            .then((resp) => {
                if (resp) {
                    dispatch(setSeleccionados([]));
                    ToastStore.success("Datos almacenados correctamente");
                    dispatch(setDespacho(resp));
                    dispatch(
                        push(
                            `/recepciones_de_traslados/nota_de_recepcion/${resp.id}`
                        )
                    );
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }
};
//*** */ Filtros por fecha
export const setFecha =
    (key, value, fecha_pendiente = false, field = "") =>
    (dispatch, getStore) => {
        const store = getStore();
        const despacho = store.despacho;
        const { fecha_compra_final, fecha_compra_inicial } = despacho;

        const { fecha_pendiente_final, fecha_completado_final } =
            fecha_compra_final;
        const { fecha_pendiente_inicial, fecha_completado_inicial } =
            fecha_compra_inicial;

        if (key === "Inicial") {
            const fecha_inicial = new Date(value);
            let fecha_final = new Date();

            // Si la fecha es en el tab, traslados pendientes
            if (fecha_pendiente) {
                fecha_final = new Date(fecha_pendiente_final);
            } else {
                fecha_final = new Date(fecha_completado_final);
            }

            // if (fecha_final >= fecha_inicial)
            dispatch(
                setFechaCompraInicial({
                    ...fecha_compra_inicial,
                    [field]: value,
                })
            );
        } else {
            let fecha_inicial = new Date();
            const fecha_final = new Date(value);

            // Si la fecha es en el tab, traslados pendientes
            if (fecha_pendiente) {
                // const fecha_inicial = new Date(despacho.fecha_compra_inicial);
                fecha_inicial = new Date(fecha_pendiente_inicial);
            } else {
                fecha_inicial = new Date(fecha_completado_inicial);
            }

            if (fecha_final >= fecha_inicial)
                dispatch(
                    setFechaCompraFinal({
                        ...fecha_compra_final,
                        [field]: value,
                    })
                );
        }
        dispatch(listar());
    };

export const setUsuarioValue =
    (value, usuario_pendiente = false) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { usuario } = store.despacho;

        const name = usuario_pendiente
            ? "usuario_pendiente"
            : "usuario_completado";

        dispatch(setUsuario({ ...usuario, [name]: value }));
        dispatch(listar());
    };

// Limpiar estado de filtros
export const limpiar = () => (dispatch) => {
    dispatch(
        setFechaCompraInicial({
            fecha_pendiente_inicial: moment().format("YYYY-MM-DD"),
            fecha_completado_inicial: moment().format("YYYY-MM-DD"),
        })
    );
    dispatch(
        setFechaCompraFinal({
            fecha_pendiente_final: moment().format("YYYY-MM-DD"),
            fecha_completado_final: moment().format("YYYY-MM-DD"),
        })
    );
    dispatch(
        setUsuario({
            usuario_pendiente: null,
            usuario_completado: null,
        })
    );
    dispatch(listar());
};

export const actions = {
    listar,
    sortChange,
    buscar,
    changeTab,
    getDespacho,
    asignarCantidadRecibido,
    setNotaProducto,
    registrarRecepcion,
    setFecha,
    setUsuarioValue,
    limpiar,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SET_DESPACHO]: (state, { despacho }) => {
        return {
            ...state,
            despacho,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [TAB]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
    [SELECCIONADOS]: (state, { seleccionados }) => {
        return {
            ...state,
            seleccionados,
        };
    },
    [TIENE_ALERTAS]: (state, { tiene_alertas }) => {
        return {
            ...state,
            tiene_alertas,
        };
    },
    [FECHA_DESPACHO_FINAL]: (state, { fecha_compra_final }) => {
        return {
            ...state,
            fecha_compra_final,
        };
    },
    [USUARIO]: (state, { usuario }) => {
        return {
            ...state,
            usuario,
        };
    },
    [FECHA_DESPACHO_INICIAL]: (state, { fecha_compra_inicial }) => {
        return {
            ...state,
            fecha_compra_inicial,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    despacho: null,
    loader: false,
    page: 1,
    search: "",
    ordering: "-creado",
    tab: 10,
    seleccionados: [],
    tiene_alertas: false,
    fecha_compra_final: {
        fecha_pendiente_final: moment().format("YYYY-MM-DD"),
        fecha_completado_final: moment().format("YYYY-MM-DD"),
    },
    fecha_compra_inicial: {
        fecha_pendiente_inicial: moment().format("YYYY-MM-DD"),
        fecha_completado_inicial: moment().format("YYYY-MM-DD"),
    },
    usuario: {
        usuario_pendiente: null,
        usuario_completado: null,
    },
};

export default handleActions(reducers, initialState);
