import { connect } from 'react-redux';
import { logOut } from '../../../redux/modules/login';
import Navbar from './Navbar';


const ms2p = (state) => ({});

const md2p = { logOut };

export default connect(ms2p, md2p)(Navbar);
