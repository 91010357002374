import React from "react";

const Checkbox = ({
    titulo = "Checkbox",
    checked = true,
    disabled = true,
    subitem = false,
}) => {
    return (
        <div
            className={`checkbox c-checkbox ${
                subitem === 1 ? "pt-3" : subitem === 2 ? "" : "py-3"
            }`}
        >
            <label className="needsclick">
                <input type="checkbox" checked={checked} disabled={disabled} />
                <span className="fa fa-check" />
                <label
                    className={`ml-1 ${
                        checked ? "txt-black" : "text-black-50"
                    }`}
                >
                    {titulo}
                </label>
            </label>
        </div>
    );
};

export default Checkbox;
