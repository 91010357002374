import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import { activeFormatter } from "../../../../Util/Acciones/Acciones";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

function formatoNivel(cell, row) {
    const {
        es_encargado_tienda,
        es_auxiliar_gerencia,
        es_gerente,
        es_jefe_bodega,
        es_motorista,
        es_vendedor,
        es_vendedor_mayorista,
    } = row;

    if (es_encargado_tienda) return "Encargado de tienda";
    if (es_auxiliar_gerencia) return "Auxiliar de Gerencia";
    if (es_gerente) return "Gerente";
    if (es_jefe_bodega) return "Jefe de Bodega";
    if (es_motorista) return "Motorista";
    if (es_vendedor) return "Vendedor";
    if (es_vendedor_mayorista) return "Vendedor Mayorista";
    return "---";
}

function formatoEstado(cell, row) {
    const { activo } = row;
    if (activo) return <span style={{ color: "#067618" }}>Activo</span>;
    return <span style={{ color: "#F20004" }}>Inactivo</span>;
}

function formatoSucursales(cell, row) {
    if (row.es_global) return "Todas";
    if (!cell.length > 0) {
        return "Todas";
    }
    return (
        <div>
            {cell.map((item, index) => {
                return (
                    <span key={index}>
                        {index != 0 ? ", " : ""}
                        {item.nombre}
                    </span>
                );
            })}
        </div>
    );
}

export class UsuariosListado extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        idEmpresa: PropTypes.number.isRequired,
    };

    static defaultProps = {
        idEmpresa: 0,
    };

    constructor(props) {
        super(props);
    }

    render() {
        // state
        const { data, idEmpresa } = this.props;

        // bind
        const { eliminar } = this.props;

        const options = _.cloneDeep(tableOptions);
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <LoadMask loading={false} dark blur>
                        <BootstrapTable
                            data={false ? [] : data}
                            remote
                            hover
                            fetchInfo={{ dataTotalSize: data.length }}
                            options={options}
                        >
                            <TableHeaderColumn dataField="first_name">
                                Nombre
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="last_name">
                                Apellido
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="usuario">
                                Usuario
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                dataFormat={formatoNivel}
                            >
                                Rol
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="activo"
                                dataFormat={formatoEstado}
                            >
                                Estado
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="sucursales"
                                dataFormat={formatoSucursales}
                            >
                                Sucursales
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey
                                dataAlign="center"
                                width="20%"
                                dataFormat={(cell, row) => {
                                    if (row.sucursal === null) return "";
                                    return activeFormatter({
                                        editar: `/empresas/${idEmpresa}/usuarios/editar`,
                                        eliminar,
                                    })(cell, row);
                                }}
                            >
                                Acciones
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </LoadMask>
                </div>
            </div>
        );
    }
}
