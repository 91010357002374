import { handleActions } from "redux-actions";
import { api } from "api";
import moment from "moment";

// ------------------------------------
// Constants
// ------------------------------------

const LOADING = "BITACORA_LOADING";
const DATA = "BITACORA_DATA";
const PAGE = "BITACORA_PAGE";
const ACCION = "BITACORA_ACCION";
const FECHA_INICIAL = "BITACORA_FECHA_INICIAL";
const FECHA_FINAL = "BITACORA_FECHA_FINAL";
const USUARIO = "BITACORA_USUARIO";
const BITACORA = "BITACORA_BITACORA";

export const constants = {
    LOADING,
    DATA,
    PAGE,
    ACCION,
    FECHA_INICIAL,
    FECHA_FINAL,
    BITACORA,
    USUARIO,
};

// ------------------------------------
// Actions
// ------------------------------------
export const setUsuario = (usuario) => (dispatch) => {
    dispatch(_setUsuario(usuario));
    dispatch(getBitacoras());
};

export const getBitacora = (id) => (dispatch) => {
    dispatch(loading());
    api.get(`bitacora/${id}`)
        .then((data) => {
            dispatch(setBitacora(data));
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            dispatch(loading(false));
        });
};

export const getBitacoras =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(loading());
        const bitacora = getStore().bitacora;
        const params = {
            fecha_inicial: bitacora.fecha_inicial,
            fecha_final: bitacora.fecha_final,
            page,
        };
        if (!!bitacora.accion) {
            params.accion = bitacora.accion.label;
        }
        if (!!bitacora.usuario) {
            params.usuario__id = bitacora.usuario.id;
        }
        api.get("bitacora", params)
            .then((data) => {
                // set data
                dispatch(setData(data));
                dispatch(setPage(page));
            })
            .catch((err) => {
                console.log("error", err);
            })
            .finally(() => {
                dispatch(loading(false));
            });
    };

export const setAccion = (value) => (dispatch) => {
    dispatch(_setAccion(value));
    dispatch(getBitacoras());
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const bitacora = store.bitacora;
    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(bitacora.fecha_final);
        // if (fecha_final >= fecha_inicial)
        dispatch(setFechaInicial(value));
    } else {
        const fecha_inicial = new Date(bitacora.fecha_inicial);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) dispatch(setFechaFinal(value));
    }
    dispatch(getBitacoras());
};

// limpiar filtros
export const limpiar = () => (dispatch) => {
    dispatch(setFechaInicial(moment().format("YYYY-MM-DD")));
    dispatch(setFechaFinal(moment().format("YYYY-MM-DD")));
    dispatch(setAccion(null));
    dispatch(setUsuario(null));
};

// ------------------------------------
// PureActions
// ------------------------------------

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setBitacora = (bitacora) => ({
    type: BITACORA,
    bitacora,
});

export const loading = (loading = true) => ({
    type: LOADING,
    loading,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});

export const setFechaInicial = (fecha_inicial) => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});

export const setFechaFinal = (fecha_final) => ({
    type: FECHA_FINAL,
    fecha_final,
});

export const _setAccion = (accion) => ({
    type: ACCION,
    accion,
});

export const _setUsuario = (usuario) => ({
    type: USUARIO,
    usuario,
});

export const actions = {
    getBitacoras,
    getBitacora,
    setAccion,
    setUsuario,
    setFecha,
    limpiar,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [LOADING]: (state, { loading }) => {
        return {
            ...state,
            loading,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ACCION]: (state, { accion }) => {
        return {
            ...state,
            accion,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [BITACORA]: (state, { bitacora }) => {
        return {
            ...state,
            bitacora,
        };
    },
    [USUARIO]: (state, { usuario }) => {
        return {
            ...state,
            usuario,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    loading: false,
    data: {},
    bitacora: {
        usuario: {},
        accion: "",
        detalle: null,
        detalle_anterior: null,
        creado: moment().format("YYYY-MM-DD"),
    },
    page: 1,
    accion: null,
    usuario: null,
    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
};

export default handleActions(reducers, initialState);
