import CrearActualizar from "./CrearActualizar";
import { connect } from "react-redux";
import { actions } from "../../../../../../redux/modules/usuario/usuario";

const ms2p = (state) => {
    let reportes = false, configuracion = false, despacho = false;
    try {
        const data = state.form.UsuarioCrear.values;
        if (
            data.reporte_balance_general ||
            data.reporte_apertura_cierre ||
            data.reporte_bodega ||
            data.reporte_venta ||
            data.reporte_devolucion ||
            data.reporte_cuenta_cobrar ||
            data.reporte_gasto ||
            data.reporte_productos_mas_vendidos ||
            data.reporte_mejores_clientes ||
            data.reporte_total_invertido ||
            data.reporte_ganancia_bruta ||
            data.reporte_despachos
        )
            reportes = true;
        if (
            data.conf_tienda ||
            data.conf_usuario ||
            data.conf_sucursal ||
            data.conf_cliente ||
            data.conf_proveedor ||
            data.conf_caja
        )
            configuracion = true;
        if (data.despacho_despachos || data.despacho_recepciones)
            despacho = true
    } catch (e) {
        // Entra aqui cuando no existen valores el form todavia
    }
    

    return {
        ...state.usuario,
        reportes,
        configuracion,
        despacho
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearActualizar);
