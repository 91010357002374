import { connect } from 'react-redux'
import EmpresasListado from './EmpresasListado'
import { actions } from '../../../../redux/modules/empresas/empresas'

const ms2p = (state) => ({
    ...state.empresas
})

const md2p = {
    ...actions
}

export default connect(ms2p, md2p)(EmpresasListado)
