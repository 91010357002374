import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import login from './modules/login';
import empresas from './modules/empresas/empresas'
import usuario from "./modules/usuario/usuario";
import bienvenida from "./modules/bienvenida/bienvenida";
import categoria from "./modules/categoria";
import print from './modules/print/print'

export default combineReducers({
    login,
    routing,
    form: formReducer,
    empresas,
    usuario,
    bienvenida,
    categoria,
    print
});
