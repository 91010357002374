import _ from "lodash";
import { api } from "api";
import moment from "moment";
import uuidv1 from "uuid/v1";
import Swal from "sweetalert2";
import { ToastStore } from "react-toasts";
import { handleActions } from "redux-actions";
import { goBack, push } from "react-router-redux";
import { initialize as initializeForm, change as changeForm } from "redux-form";
import { listaProveedores } from "../../../common/components/OrdenCompra/Crear/DatosOCForm";

// ------------------------------------
// Constants
// ------------------------------------
// OC
export const TAB = "OC_TAB";
export const SET_OC = "OC_SET_OC";
export const PAGE_OC = "OC_PAGE_OC";
export const SORT_OC = "OC_SORT_OC";
export const MODAL_PAGO = "OC_MODAL_PAGO";
export const BUSCADOR_OC = "OC_BUSCADOR_OC";
export const SET_PAGE_RETIROS = "OC_SET_PAGE_RETIROS";
export const SET_LOADER_RETIROS = "OC_SET_LOADER_RETIROS";
export const SET_FECHA_I_RETIROS = "OC_SET_FECHA_I_RETIROS";
export const SET_FECHA_F_RETIROS = "OC_SET_FECHA_F_RETIROS";
export const SET_MOVIMIENTO_CAJA = "OC_SET_MOVIMIENTO_CAJA";
export const SET_ORDERING_RETIROS = "OC_SET_ORDERING_RETIROS";
export const SET_MOVIMIENTOS_RETIROS = "OC_SET_MOVIMIENTOS_RETIROS";
export const FECHA_COMPRA_INICIAL = "FECHA_COMPRA_INICIAL";
export const FECHA_COMPRA_FINAL = "FECHA_COMPRA_FINAL";
export const FECHA_INICIAL_COMPRA_FINALIZADA =
    "FECHA_INICIAL_COMPRA_FINALIZADA";
export const FECHA_FINAL_COMPRA_FINALIZADA = "FECHA_FINAL_COMPRA_FINALIZADA";
export const ESTADO_DESCARGA_2 =
    "ESTADO_DESCARGA_LISTADO_OREDENES_COMPRA_FINALIZADAS";
export const ESTADO_DESCARGA_1 =
    "ESTADO_DESCARGA_LISTADO_OREDENES_COMPRA_ACTIVAS";
// NUEVA OC
export const PAGE = "OC_PAGE";
export const SORT = "OC_SORT";
export const LOADER = "OCOADER";
export const DATOS = "OC_DATOS";
export const LISTADO = "OC_LISTADO";
export const BUSCADOR = "OC_BUACADOR";
export const PRODUCTOS = "OC_PRODUCTOS";
export const VER_BUSCADOR = "OC_VER_BUSCADOR";
export const SELECCIONADOS = "OC_SELECCIONADOS";
export const PRODUCTO_ACTUAL = "OC_PRODUCTO_ACTUAL";
export const DATOS_TIPO_PAGO = "OC_DATOS_TIPO_PAGO";
export const UUID_REQ_PRODUCTOS = "OC_UUID_REQ_PRODUCTOS";
const COSTO_IMPORTACION = "OC_COSTO_IMPORTACION";
const TIPO_CAMBIO = "OC_TIPO_CAMBIO";
const TIPO_COMPRA = "OC_TIPO_COMPRA";
const DETALLE_PROVEDOR = "OC_DETALLE_PROVEDOR";

const bodegaEndpoint = "bodegas";
const endpoint = "ordenes_compra";
const proveedoresEndpoint = "proveedores";
const cuentasPagarEndpoint = "cuenta_pagar";

// ------------------------------------
// Actions
// ------------------------------------
export const listarOC =
    (page = 1) =>
    (dispatch, getStore) => {
        console.log("change");
        dispatch(setLoader(true));
        const store = getStore();
        const {
            search_oc,
            tab,
            ordering_oc,
            fecha_compra_final,
            fecha_compra_inicial,
            fecha_final_compra_finalizada,
            fecha_inicial_compra_finalizada,
        } = store.ordenes_compra;
        api.get(endpoint, {
            page,
            ordering: ordering_oc,
            search: search_oc,
            estado: tab,
            fecha_inicial:
                tab == 10
                    ? fecha_compra_inicial
                    : fecha_inicial_compra_finalizada,
            fecha_final:
                tab == 10 ? fecha_compra_final : fecha_final_compra_finalizada,
        })
            .catch((err) => {})
            .then((data) => {
                if (data) {
                    dispatch(setListadoOC(data));
                }
                dispatch(setPageOC(page));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();
        const { search } = store.ordenes_compra;
        let { ordering } = store.ordenes_compra;
        //  GENERAR EL UUID
        const uuid = uuidv1();
        dispatch(setUuidReqProductos(uuid));
        if (!ordering) {
            ordering = "-creado";
        }
        api.get(bodegaEndpoint, { page, ordering, search })
            .catch((err) => {})
            .then((data) => {
                if (data) {
                    data.results.forEach(
                        (item) =>
                            (item.costo =
                                item.usa_costo_promedio == true
                                    ? item.costo_promedio_ponderado
                                    : item.costo)
                    );
                    const otroUuid =
                        getStore().ordenes_compra.uuid_req_productos;
                    if (otroUuid === uuid) dispatch(setProductos(data));
                }
                dispatch(setPage(page));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.ordenes_compra.page;
    dispatch(listar(page));
};

export const crearProveedor = (data) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post(proveedoresEndpoint, data)
        .then((data) => {
            ToastStore.success("Nuevo proveedor almacenado");
            const form = getStore().form.NuevaOrdenCompra.values;
            listaProveedores.push({ ...data });
            dispatch(
                initializeForm("NuevaOrdenCompra", {
                    ...form,
                    proveedor: data.id,
                })
            );
        })
        .catch((err) => {
            console.log(err);
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const agregar =
    (producto, cantidad = 0, costo = 0) =>
    (dispatch, getStore) => {
        const store = getStore();
        const seleccionados = store.ordenes_compra.seleccionados;
        if (_.find(seleccionados, { id: producto.id })) {
            dispatch(listar());
            dispatch(_setProductoActual({}));
        } else {
            let producto_data = _.cloneDeep(producto);
            // producto_data.id_unidad_de_medida = producto.producto.a_granel
            //     ? producto.unidad_de_medida.id
            //     : null;
            // producto_data.costo =
            //     producto_data.costo != "" && producto_data.costo != null
            //         ? producto_data.costo
            //         : 0;

            producto_data.costo = costo;
            producto_data.costo_dolares = costo;
            producto_data.cantidad = cantidad;

            dispatch(setSeleccionados([producto_data, ...seleccionados]));
            dispatch(_setProductoActual({}));
        }
    };

export const setProductoActual = (producto) => (dispatch) => {
    dispatch(_setProductoActual(producto));
};

export const quitar = (producto, index) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.ordenes_compra.seleccionados);
    const item = _.find(seleccionados, { id: producto.id });
    if (item) {
        seleccionados.splice(index, 1);
        dispatch(setSeleccionados(seleccionados));
    }
};

export const asignarCantidad = (producto, cantidad) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.ordenes_compra.seleccionados);
    const item = _.find(seleccionados, { id: producto.id });
    const index = seleccionados.indexOf(item);
    if (cantidad < 0) {
        item.cantidad = 0;
    } else {
        item.cantidad = cantidad;
    }
    seleccionados.splice(index, 1, item);
    dispatch(setSeleccionados(seleccionados));
};

export const asignarCosto =
    (producto, costo, origen = 2) =>
    (dispatch, getStore) => {
        const store = getStore();
        const seleccionados = _.cloneDeep(store.ordenes_compra.seleccionados);
        const item = _.find(seleccionados, { id: producto.id });
        const index = seleccionados.indexOf(item);
        if (costo < 0) {
            // *** Prodcuto con origen de importacion
            if (origen === "1") {
                item.costo_dolares = 0;
            } else {
                item.costo = 0;
            }
        } else {
            // *** Prodcuto con origen de importacion
            if (origen === "1") {
                item.costo_dolares = costo;
            } else {
                item.costo = costo;
            }
        }
        seleccionados.splice(index, 1, item);
        dispatch(setSeleccionados(seleccionados));
    };

export const asignarDatos = () => (dispatch, getStore) => {
    const store = getStore();
    const values = store.form.NuevaOrdenCompra.values;
    dispatch(setDatos(values));
};

export const irTipoPago = () => (dispatch, getStore) => {
    const store = getStore();
    const { seleccionados } = store.ordenes_compra;
    if (seleccionados.length) {
        dispatch(asignarDatos());
        dispatch(push("/ordenes_de_compra/nueva/tipo_de_pago"));
    } else {
        ToastStore.error("La orden de compra no tiene productos");
    }
};

export const limpiarDatosOC = () => (dispatch) => {
    dispatch(setProductos({}));
    dispatch(setVerBuscador(false));
    dispatch(setUuidReqProductos(""));
    dispatch(setPage(1));
    dispatch(setBuscador(""));
    dispatch(setSort(""));
    dispatch(setLoader(false));
    dispatch(setSeleccionados([]));
    dispatch(setDatos({ fecha: moment().format("YYYY-MM-DD") }));
    dispatch(
        setDatosTipoPago({
            fecha_limite: moment().format("YYYY-MM-DD"),
            plazo_pago: "inmediato",
        })
    );
};

export const cambioPlazo = (plazo) => (dispatch, getStore) => {
    const store = getStore();
    const { datos_tipo_pago } = store.ordenes_compra;
    const { values } = store.form.OCTipoPago;
    let fecha_limite = moment();
    let periodo_pago = 0;
    if (plazo != "inmediato") {
        if (plazo == "personalizado") {
            fecha_limite = moment(values.fecha_limite, "YYYY-MM-DD");
            periodo_pago = fecha_limite.diff(moment(), "days") + 1;
        } else {
            periodo_pago = parseInt(plazo);
            fecha_limite = moment().add(periodo_pago, "days");
        }
    }
    fecha_limite = fecha_limite.format("YYYY-MM-DD");
    dispatch(changeForm("OCTipoPago", "fecha_limite", fecha_limite));
    dispatch(
        setDatosTipoPago({ ...datos_tipo_pago, fecha_limite, periodo_pago })
    );
};

export const seleccionFechaLimite = (fecha_limite) => (dispatch, getStore) => {
    const store = getStore();
    const { datos_tipo_pago } = store.ordenes_compra;
    let fecha_li = moment(fecha_limite, "YYYY-MM-DD");
    let periodo_pago = fecha_li.diff(moment(), "days") + 1;
    fecha_li = fecha_li.format("YYYY-MM-DD");
    dispatch(changeForm("OCTipoPago", "fecha_limite", fecha_li));
    dispatch(
        setDatosTipoPago({
            ...datos_tipo_pago,
            fecha_limite: fecha_li,
            periodo_pago,
        })
    );
};

export const asignarDatosTipoPago = () => (dispatch, getStore) => {
    const store = getStore();
    const { datos_tipo_pago } = store.ordenes_compra;
    const values = store.form.OCTipoPago.values;
    dispatch(setDatosTipoPago({ ...datos_tipo_pago, ...values }));
};

export const nuevaOrdenCompra = () => (dispatch, getStore) => {
    const store = getStore();
    const { seleccionados, datos, datos_tipo_pago, detalle_proveedor, total } =
        store.ordenes_compra;
    const { values } = store.form.OCTipoPago;

    let data_files = [];
    if (datos.foto) {
        data_files = [...datos.foto];
        let nameFile = datos.foto.name;
        let newFile = new File([datos.foto], "foto", {
            type: datos.foto.type,
        });

        data_files.push({ name: newFile.name, file: newFile });
        datos.foto = nameFile;
    }

    console.log(values);

    if (
        seleccionados.length &&
        (datos.proveedor != undefined || datos.proveedor != "")
    ) {
        dispatch(setLoader(true));

        // Tipos de compra:
        // 1: Compra al credito
        // 2: Compra al contado
        const tipo_pago = datos.tipo_compra === "2" ? 1000 : 2000;
        const periodo_pago =
            datos.tipo_compra === "1" ? datos_tipo_pago.periodo_pago : 0;

        const data = {
            proveedor: datos.proveedor,
            sucursal_destino: values.sucursal_destino,
            fecha: datos.fecha,
            descripcion: datos.descripcion || "",
            num_documento: datos.num_documento,
            tipo_pago: tipo_pago,
            periodo_pago: periodo_pago,
            productos: seleccionados,

            foto: datos.foto || [],
            costo_importacion: datos.costo_importacion,
            origen: datos.origen,
            tipo_cambio: datos.tipo_cambio,
            tipo_compra: datos.tipo_compra,
            numero_documento: datos.numero_documento || "",
            banco: values.banco || "",
        };

        let total = 0;
        // *** Calculo del total a pagar
        seleccionados.forEach((producto) => {
            // Tipo de origen, 1 importacion o 2 local
            const origen = datos.origen || 2;
            const tipo_cambio = datos.tipo_cambio || 0;

            // Costo del producto, depende del tipo de origen
            const costo_valor =
                origen === "1"
                    ? producto.costo_dolares * tipo_cambio
                    : producto.costo;

            const costo = costo_valor ? parseFloat(costo_valor) : 0;
            const cantidad = producto.cantidad
                ? parseFloat(producto.cantidad)
                : 0;

            // Calculo del porcentaje del costo de importacion
            const costo_importacion = (datos.costo_importacion || 0) / 100;
            const total_temp = costo * cantidad; // Total sin el porcentaje
            const porcentaje =
                costo_importacion >= 0 ? total_temp * costo_importacion : 0;
            total = total_temp + porcentaje + total; // Total con el porcentaje, si es mayor a 0
        });

        const limite_de_credito = detalle_proveedor.limite_credito;

        // Verificar si el limite de credito es mayor al total
        if (total > limite_de_credito && datos.tipo_compra === "1") {
            dispatch(setLoader(false));
            return Swal(
                "Atencion",
                `El total de la orden de compra supera el limite de credito del proveedor, el limite de credito es de <strong>Q. ${limite_de_credito} </strong>`,
                "warning"
            );
        }
        // el total a pagar
        data["total"] = total;

        // Swal para la confirmacion de la orden de compra
        Swal({
            title: "¿Finalizar orden de compra?",
            text: "¡No podrá revertir esta acción!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Continuar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
        })
            .then((result) => {
                if (result.value) {
                    dispatch(setLoader(true));
                    api.postAttachments(endpoint, data, data_files)
                        .then(async (orden_nueva) => {
                            if (orden_nueva) {
                                //**** Obtener la orden de compra, y finalizarlo
                                const orden_compra = await api.get(
                                    `${endpoint}/${orden_nueva.id}`
                                );
                                if (orden_compra) {
                                    // *** Finalizar la orden de compra
                                    await dispatch(
                                        finalizarOrdenCompra(
                                            orden_compra.id,
                                            orden_compra,
                                            parseInt(datos.tipo_compra)
                                        )
                                    );
                                }

                                dispatch(limpiarDatosOC());
                                dispatch(push("/ordenes_de_compra"));
                            }
                        })
                        .catch((err) => {
                            Swal(
                                "ERROR",
                                err.detail ||
                                    "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                                "error"
                            );
                        })
                        .finally(() => {
                            dispatch(setLoader(false));
                        });
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    } else {
        ToastStore.error("Los datos de la orden de compra son incompletos");
    }
};

export const agregarPrimer = (search) => (dispatch, getStore) => {
    const store = getStore();
    const producto = store.bodega;
    const { ordering } = producto;
    api.get(bodegaEndpoint, { ordering, search })
        .catch((err) => {})
        .then((data) => {
            if (data) {
                if (data.count > 0) {
                    const seleccionados = store.bodega.seleccionados;
                    let producto = data.results[0];
                    if (!_.find(seleccionados, { id: producto.id })) {
                        producto.cantidad = 1;
                        dispatch(
                            setSeleccionados([producto, ...seleccionados])
                        );
                    }
                }
            }
        })
        .finally(() => {
            dispatch(buscar(""));
            dispatch(setLoader(false));
        });
};

// OC
export const changeTab = (tab) => (dispatch) => {
    dispatch(setTab(tab));
    dispatch(listarOC());
};
export const buscarOC = (search) => (dispatch) => {
    dispatch(setBuscadorOC(search));
    dispatch(listarOC());
};

export const sortChangeOC = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSortOC(sortName));
    } else {
        dispatch(setSortOC(`-${sortName}`));
    }
    const store = getStore();
    const page_oc = store.ordenes_compra.page_oc;
    dispatch(listarOC(page_oc));
};

export const getOC = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`)
        .catch((err) => {})
        .then((data) => {
            if (data) {
                dispatch(setOC(data));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const finalizarOC = (id) => (dispatch) => {
    Swal({
        title: "¿Finalizar orden de compra?",
        text: "¡No podrá revertir esta acción!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, finalizar!",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.put(`${endpoint}/${id}/finalizar`)
                .catch((err) => {
                    Swal(
                        "ERROR",
                        err.detail ||
                            "Ha ocurrido un error, intenta más tarde.",
                        "error"
                    );
                })
                .then((resp) => {
                    ToastStore.success(
                        "Orden de compra finalizada exitosamente"
                    );
                })
                .finally(() => {
                    dispatch(setLoader(false));
                    dispatch(getOC(id));
                });
        }
    });
};

export const marcarEntregaOC = (id, body) => (dispatch) => {
    Swal({
        title: "¿Marcar orden de compra como entregada?",
        text: "¡No podrá revertir esta acción!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, Marcar!",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.put(`${endpoint}/${id}/marcar_entrega_completada`, body)
                .catch((err) => {
                    Swal(
                        "ERROR",
                        err.detail ||
                            "Ha ocurrido un error, intenta más tarde.",
                        "error"
                    );
                })
                .then((resp) => {
                    ToastStore.success("Datos actualizados exitosamente");
                })
                .finally(() => {
                    dispatch(setLoader(false));
                    dispatch(getOC(id));
                });
        }
    });
};

export const finalizarOrdenCompra = (id, body, tipo_compra) => (dispatch) => {
    api.put(`${endpoint}/${id}/marcar_entrega_completada`, body)
        .then((_) => {
            ToastStore.success("Orden de compra registrada exitosamente");

            // *** Si el tipo de compra es al contado, finalizar el pago
            if (tipo_compra === 2) {
                dispatch(finalizarPagoOrdenCompra(id));
            }
        })
        .catch((err) => {
            Swal(
                "ERROR",
                err.detail || "Ha ocurrido un error, intenta más tarde.",
                "error"
            );
        });
};

export const finalizarPagoOrdenCompra = (id) => (dispatch) => {
    dispatch(setLoader(true));

    api.put(`${endpoint}/${id}/marcar_pago_fianlizado`)
        .catch((err) => {
            Swal(
                "ERROR",
                err.detail || "Ha ocurrido un error, intenta más tarde.",
                "error"
            );
        })
        .then((resp) => {
            // ToastStore.success("Datos actualizados exitosamente");
        })
        .finally(() => {
            dispatch(setLoader(false));
            dispatch(getOC(id));
        });
};

export const marcarPagoFinalizadoOC = (id) => (dispatch) => {
    Swal({
        title: "¿Finalizar pago de la orden de compra?",
        text: "¡No podrá revertir esta acción!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, Finalizar!",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.put(`${endpoint}/${id}/marcar_pago_fianlizado`)
                .catch((err) => {
                    Swal(
                        "ERROR",
                        err.detail ||
                            "Ha ocurrido un error, intenta más tarde.",
                        "error"
                    );
                })
                .then((resp) => {
                    ToastStore.success("Datos actualizados exitosamente");
                })
                .finally(() => {
                    dispatch(setLoader(false));
                    dispatch(getOC(id));
                });
        }
    });
};

export const reveritirMarcarPagoFinalizadoOC = (id) => (dispatch) => {
    Swal({
        title: "¿Revertir el estado de pago?",
        text: "¡No podrá revertir esta acción!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, Revertir!",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.put(`${endpoint}/${id}/revertir_marcar_pago_finalizdo`)
                .catch((err) => {
                    Swal(
                        "ERROR",
                        err.detail ||
                            "Ha ocurrido un error, intenta más tarde.",
                        "error"
                    );
                })
                .then((resp) => {
                    ToastStore.success("Datos actualizados exitosamente");
                })
                .finally(() => {
                    dispatch(setLoader(false));
                    dispatch(getOC(id));
                });
        }
    });
};

// DETALLE OC
export const listarMovimientos =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoaderRetiros(true));
        const store = getStore();
        let { ordering_retiros, fecha_inicial, fecha_final } =
            store.ordenes_compra;

        api.get(`${cuentasPagarEndpoint}/retiros_caja`, {
            page,
            ordering: ordering_retiros,
            fecha_inicial,
            fecha_final,
        })
            .catch((err) => {
                ToastStore.error("Error al obtener los movimientos de caja");
            })
            .then((resp) => {
                if (resp) {
                    dispatch(setMovimientosRetiros(resp));
                }
                dispatch(setPageRetiros(page));
            })
            .finally(() => {
                dispatch(setLoaderRetiros(false));
            });
    };

export const sortChangeRetiros =
    (sortName, sortOrder) => (dispatch, getStore) => {
        if (sortOrder === "asc") {
            dispatch(setSortRetiros(sortName));
        } else {
            dispatch(setSortRetiros(`-${sortName}`));
        }
        const store = getStore();
        const { page_retiros } = store.ordenes_compra;
        dispatch(listarMovimientos(page_retiros));
    };

export const changeFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const ordenes_compra = store.ordenes_compra;
    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(ordenes_compra.fecha_final);
        if (fecha_final >= fecha_inicial) {
            dispatch(setFechaIRetiros(value));
        }
    } else {
        const fecha_inicial = new Date(ordenes_compra.fecha_inicial);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) {
            dispatch(setFechaFRetiros(value));
        }
    }

    dispatch(listarMovimientos());
};

export const limpiarDatosPago = () => (dispatch) => {
    dispatch(setMovimientosRetiros({}));
    dispatch(setPageRetiros(1));
    dispatch(setSortRetiros(""));
    dispatch(setFechaIRetiros(moment().format("YYYY-MM-DD")));
    dispatch(setFechaFRetiros(moment().format("YYYY-MM-DD")));
    dispatch(setMovimientoCaja(null));
};

const changeOrigen = (tipo_provedor) => (dispatch) => {
    dispatch(changeForm("NuevaOrdenCompra", "origen", String(tipo_provedor)));
};

export const registrarPagoAbono = (idOC) => (dispatch, getStore) => {
    dispatch(setLoaderRetiros(true));
    const store = getStore();
    const { movimiento_caja } = store.ordenes_compra;
    const { values } = store.form.PagoOrdenCompra;
    let data = {
        orden_compra: idOC,
        monto: 0,
        forma_pago: "EFE",
        num_documento: "",
        tipo_abono: values.tipo_abono,
        sucursal: 0,
    };

    if (data.tipo_abono == 1000) {
        data.movimiento_caja = movimiento_caja.id;
        data.monto = movimiento_caja.total;
        data.sucursal = movimiento_caja.apertura_cierre_caja.caja.sucursal;
    } else {
        data.sucursal = values.sucursal;
        data.forma_pago = values.forma_pago;
        data.monto = values.monto;
        data.num_documento = values.num_documento ? values.num_documento : "";
        (data.aplicar_descuento = values.aplicar_descuento
            ? values.aplicar_descuento
            : false),
            (data.descuento =
                values.aplicar_descuento && values.descuento
                    ? values.descuento
                    : 0);
        data.nota_de_descuento =
            values.aplicar_descuento && values.nota_de_descuento
                ? values.nota_de_descuento
                : null;
    }

    api.post(`${cuentasPagarEndpoint}/abono`, data)
        .catch((error) => {
            Swal(
                "ERROR",
                error.detail ||
                    "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        })
        .then((resp) => {
            if (resp) {
                ToastStore.success("Pago registrado exitosamente");
                dispatch(setOpenModalPago(false));
                dispatch(limpiarDatosPago());
                dispatch(getOC(idOC));
            }
        })
        .finally(() => {
            dispatch(setLoaderRetiros(false));
            dispatch(listarMovimientos());
        });
};

export const descargarListadoOCA = () => (dispatch, getStore) => {
    const store = getStore();
    const { search_oc, fecha_compra_final, fecha_compra_inicial } =
        store.ordenes_compra;
    let params = {
        search: search_oc,
        fecha_compra_final,
        fecha_compra_inicial,
    };
    dispatch(setEstadoDescarga1(true));
    api.get(`ordenes_compra/descargar_excel_OCA`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga1(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga1(true));
                dispatch(esperarDescarga1(data.id));
            }
        });
};

const esperarDescarga1 = (id) => (dispatch) => {
    let intervalPromise;
    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA
    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga1(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga1(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "ordenes_de_compra_activas.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga1(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }
    listener();
    intervalPromise = setInterval(listener, 1000);
};

const esperarDescarga2 = (id) => (dispatch) => {
    let intervalPromise;
    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA
    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga2(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga2(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "ordenes_de_compra_finalizadas.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };
                    dispatch(setEstadoDescarga2(false));
                    dispatch(descargaArchivo2(context));
                }
            });
    }
    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga1(false));
    ToastStore.success("Archivo descargado exitosamente");
};
const descargaArchivo2 = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga2(false));
    ToastStore.success("Archivo descargado exitosamente");
};
export const descargarListadoOCF = () => (dispatch, getStore) => {
    const store = getStore();
    const {
        search_oc,
        fecha_final_compra_finalizada,
        fecha_inicial_compra_finalizada,
    } = store.ordenes_compra;
    let params = {
        search: search_oc,
        fecha_inicial: fecha_inicial_compra_finalizada,
        fecha_final: fecha_final_compra_finalizada,
    };
    dispatch(setEstadoDescarga2(true));
    api.get(`ordenes_compra/descargar_excel_OCF`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga2(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga2(true));
                dispatch(esperarDescarga2(data.id));
            }
        });
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const ordenes_compra = store.ordenes_compra;
    if (key === "Inicial") {
        const fecha_compra_inicial = new Date(value);
        const fecha_compra_final = new Date(ordenes_compra.fecha_compra_final);
        // if (fecha_compra_final >= fecha_compra_inicial)
        dispatch(setFechaCompraInicial1(value));
    } else {
        const fecha_compra_inicial = new Date(
            ordenes_compra.fecha_compra_inicial
        );
        const fecha_compra_final = new Date(value);
        if (fecha_compra_final >= fecha_compra_inicial)
            dispatch(setFechaCompraFinal1(value));
    }
    dispatch(listarOC());
};

export const setFechaOCF = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const ordenes_compra = store.ordenes_compra;
    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(
            ordenes_compra.fecha_final_compra_finalizada
        );
        // if (fecha_final >= fecha_inicial)
        dispatch(setFechaCompraInicial2(value));
    } else {
        const fecha_inicial = new Date(
            ordenes_compra.fecha_inicial_compra_finalizada
        );
        const fecha_compra_final = new Date(value);
        if (fecha_compra_final >= fecha_inicial)
            dispatch(setFechacCompraFinal2(value));
    }
    dispatch(listarOC());
};

// anulacion de orden de compra
export const anularODC = (id, concepto_anulado) => (dispatch, getStore) => {
    const store = getStore();
    const page = store.ordenes_compra.page_oc;
    dispatch(setLoader(true));
    api.post(`${endpoint}/anular_ODC`, { id, concepto_anulado })
        .catch((err) => {
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "No se ha podido anular la orden, intente más tarde.",
                    "error"
                );
            }
        })
        .then((data) => {
            if (data) {
                ToastStore.success("Orden de compra anulada correctamente");
                dispatch(listarOC(page));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const setDetalleProveedor = (id_proveedor) => (dispatch) => {
    dispatch(setLoader(true));

    api.get(`proveedores/${id_proveedor}`)
        .then((resp) => {
            dispatch(setDetalleProveedorValue(resp));
        })
        .catch((err) => {
            ToastStore.error("Error al obtener los datos del proveedor");
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

// limpiar estdo redux
export const limpiar = () => (dispatch) => {
    dispatch(setDetalleProveedorValue({}));
    dispatch(setTipoCompra(0));
    dispatch(setTipoCambio(0));
    dispatch(setCostoImportacion(0));
    dispatch(setFechaCompraInicial1(moment().format("YYYY-MM-DD")));
    dispatch(setFechaCompraFinal1(moment().format("YYYY-MM-DD")));
    dispatch(setFechaCompraInicial2(moment().format("YYYY-MM-DD")));
    dispatch(setFechacCompraFinal2(moment().format("YYYY-MM-DD")));
    dispatch(setSeleccionados([]));
    dispatch(_setProductoActual({}));
    dispatch(listarOC());
};

export const setCostoImportacionValue = (value) => (dispatch) => {
    dispatch(setCostoImportacion(value));
};

export const setTipoCambioValue = (value) => (dispatch) => {
    dispatch(setTipoCambio(value));
};

export const setTipoCompraValue = (value) => (dispatch) => {
    dispatch(setTipoCompra(value));
};

export const setDetalleProveedorValue = (detalle_proveedor) => ({
    type: DETALLE_PROVEDOR,
    detalle_proveedor,
});

export const setTipoCompra = (tipo_compra_value) => ({
    type: TIPO_COMPRA,
    tipo_compra_value,
});

export const setTipoCambio = (tipo_cambio_value) => ({
    type: TIPO_CAMBIO,
    tipo_cambio_value,
});

export const setCostoImportacion = (costo_importacion_value) => ({
    type: COSTO_IMPORTACION,
    costo_importacion_value,
});

export const setFechaCompraInicial1 = (fecha_compra_inicial) => ({
    type: FECHA_COMPRA_INICIAL,
    fecha_compra_inicial,
});

export const setFechaCompraFinal1 = (fecha_compra_final) => ({
    type: FECHA_COMPRA_FINAL,
    fecha_compra_final,
});

export const setFechaCompraInicial2 = (fecha_inicial_compra_finalizada) => ({
    type: FECHA_INICIAL_COMPRA_FINALIZADA,
    fecha_inicial_compra_finalizada,
});

export const setFechacCompraFinal2 = (fecha_final_compra_finalizada) => ({
    type: FECHA_FINAL_COMPRA_FINALIZADA,
    fecha_final_compra_finalizada,
});

export const setEstadoDescarga1 = (estado_descarga) => ({
    type: ESTADO_DESCARGA_1,
    estado_descarga,
});

export const setEstadoDescarga2 = (estado_descarga2) => ({
    type: ESTADO_DESCARGA_2,
    estado_descarga2,
});

// ------------------------------------
// PureActions
// ------------------------------------
export const setProductos = (productos) => ({
    type: PRODUCTOS,
    productos,
});
export const setVerBuscador = (ver_buscador) => ({
    type: VER_BUSCADOR,
    ver_buscador,
});
export const setUuidReqProductos = (uuid_req_productos) => ({
    type: UUID_REQ_PRODUCTOS,
    uuid_req_productos,
});
export const setPage = (page) => ({
    type: PAGE,
    page,
});
export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});
export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});
export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});
export const setSeleccionados = (seleccionados) => ({
    type: SELECCIONADOS,
    seleccionados,
});
export const _setProductoActual = (producto_actual) => ({
    type: PRODUCTO_ACTUAL,
    producto_actual,
});
export const setDatos = (datos) => ({
    type: DATOS,
    datos,
});

// OC
export const setTab = (tab) => ({
    type: TAB,
    tab,
});
export const setListadoOC = (datos_listado) => ({
    type: LISTADO,
    datos_listado,
});
export const setPageOC = (page_oc) => ({
    type: PAGE_OC,
    page_oc,
});
export const setBuscadorOC = (search_oc) => ({
    type: BUSCADOR_OC,
    search_oc,
});
export const setSortOC = (ordering_oc) => ({
    type: SORT_OC,
    ordering_oc,
});
export const setOC = (orden_compra) => ({
    type: SET_OC,
    orden_compra,
});
export const setMovimientosRetiros = (retiros) => ({
    type: SET_MOVIMIENTOS_RETIROS,
    retiros,
});
export const setPageRetiros = (page_retiros) => ({
    type: SET_PAGE_RETIROS,
    page_retiros,
});
export const setSortRetiros = (ordering_retiros) => ({
    type: SET_ORDERING_RETIROS,
    ordering_retiros,
});
export const setFechaIRetiros = (fecha_inicial) => ({
    type: SET_FECHA_I_RETIROS,
    fecha_inicial,
});
export const setFechaFRetiros = (fecha_final) => ({
    type: SET_FECHA_F_RETIROS,
    fecha_final,
});
export const setLoaderRetiros = (loader_retiros) => ({
    type: SET_LOADER_RETIROS,
    loader_retiros,
});
export const setDatosTipoPago = (datos_tipo_pago) => ({
    type: DATOS_TIPO_PAGO,
    datos_tipo_pago,
});
export const setMovimientoCaja = (movimiento_caja) => ({
    type: SET_MOVIMIENTO_CAJA,
    movimiento_caja,
});

export const setOpenModalPago = (open_modal_pago) => ({
    type: MODAL_PAGO,
    open_modal_pago,
});

export const actions = {
    changeOrigen,
    setLoader,
    getOC,
    listar,
    buscar,
    quitar,
    agregar,
    listarOC,
    buscarOC,
    changeTab,
    anularODC,
    irTipoPago,
    sortChange,
    finalizarOC,
    changeFecha,
    cambioPlazo,
    sortChangeOC,
    asignarDatos,
    asignarCosto,
    agregarPrimer,
    limpiarDatosOC,
    crearProveedor,
    asignarCantidad,
    marcarEntregaOC,
    nuevaOrdenCompra,
    setOpenModalPago,
    limpiarDatosPago,
    setMovimientoCaja,
    listarMovimientos,
    sortChangeRetiros,
    registrarPagoAbono,
    asignarDatosTipoPago,
    seleccionFechaLimite,
    marcarPagoFinalizadoOC,
    reveritirMarcarPagoFinalizadoOC,
    descargarListadoOCA,
    descargarListadoOCF,
    setFecha,
    setFechaOCF,
    setCostoImportacionValue,
    setTipoCambioValue,
    setTipoCompraValue,
    setDetalleProveedor,
    setProductoActual,
    limpiar,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [PRODUCTOS]: (state, { productos }) => {
        return {
            ...state,
            productos,
        };
    },
    [VER_BUSCADOR]: (state, { ver_buscador }) => {
        return {
            ...state,
            ver_buscador,
        };
    },
    [UUID_REQ_PRODUCTOS]: (state, { uuid_req_productos }) => {
        return {
            ...state,
            uuid_req_productos,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SELECCIONADOS]: (state, { seleccionados }) => {
        return {
            ...state,
            seleccionados,
        };
    },
    [PRODUCTO_ACTUAL]: (state, { producto_actual }) => {
        return {
            ...state,
            producto_actual,
        };
    },
    [DATOS]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DATOS_TIPO_PAGO]: (state, { datos_tipo_pago }) => {
        return {
            ...state,
            datos_tipo_pago,
        };
    },
    [TAB]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
    [LISTADO]: (state, { datos_listado }) => {
        return {
            ...state,
            datos_listado,
        };
    },
    [PAGE_OC]: (state, { page_oc }) => {
        return {
            ...state,
            page_oc,
        };
    },
    [BUSCADOR_OC]: (state, { search_oc }) => {
        return {
            ...state,
            search_oc,
        };
    },
    [SORT_OC]: (state, { ordering_oc }) => {
        return {
            ...state,
            ordering_oc,
        };
    },
    [SET_OC]: (state, { orden_compra }) => {
        return {
            ...state,
            orden_compra,
        };
    },
    [SET_MOVIMIENTOS_RETIROS]: (state, { retiros }) => {
        return {
            ...state,
            retiros,
        };
    },
    [SET_PAGE_RETIROS]: (state, { page_retiros }) => {
        return {
            ...state,
            page_retiros,
        };
    },
    [SET_ORDERING_RETIROS]: (state, { ordering_retiros }) => {
        return {
            ...state,
            ordering_retiros,
        };
    },
    [SET_LOADER_RETIROS]: (state, { loader_retiros }) => {
        return {
            ...state,
            loader_retiros,
        };
    },
    [SET_FECHA_I_RETIROS]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [SET_FECHA_F_RETIROS]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [SET_MOVIMIENTO_CAJA]: (state, { movimiento_caja }) => {
        return {
            ...state,
            movimiento_caja,
        };
    },
    [MODAL_PAGO]: (state, { open_modal_pago }) => {
        return {
            ...state,
            open_modal_pago,
        };
    },
    [FECHA_COMPRA_FINAL]: (state, { fecha_compra_final }) => {
        return {
            ...state,
            fecha_compra_final,
        };
    },
    [FECHA_COMPRA_INICIAL]: (state, { fecha_compra_inicial }) => {
        return {
            ...state,
            fecha_compra_inicial,
        };
    },
    [FECHA_FINAL_COMPRA_FINALIZADA]: (
        state,
        { fecha_final_compra_finalizada }
    ) => {
        return {
            ...state,
            fecha_final_compra_finalizada,
        };
    },
    [FECHA_INICIAL_COMPRA_FINALIZADA]: (
        state,
        { fecha_inicial_compra_finalizada }
    ) => {
        return {
            ...state,
            fecha_inicial_compra_finalizada,
        };
    },
    [ESTADO_DESCARGA_1]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
    [ESTADO_DESCARGA_2]: (state, { estado_descarga2 }) => {
        return {
            ...state,
            estado_descarga2,
        };
    },
    [COSTO_IMPORTACION]: (state, { costo_importacion_value }) => {
        return {
            ...state,
            costo_importacion_value,
        };
    },
    [TIPO_CAMBIO]: (state, { tipo_cambio_value }) => {
        return {
            ...state,
            tipo_cambio_value,
        };
    },
    [TIPO_COMPRA]: (state, { tipo_compra_value }) => {
        return {
            ...state,
            tipo_compra_value,
        };
    },
    [DETALLE_PROVEDOR]: (state, { detalle_proveedor }) => {
        return {
            ...state,
            detalle_proveedor,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------
export const initialState = {
    datos_listado: {},
    page_oc: 1,
    search_oc: "",
    ordering_oc: "",
    orden_compra: null,

    productos: {},
    ver_buscador: false,
    uuid_req_productos: "",
    page: 1,
    search: "",
    ordering: "",
    loader: false,
    seleccionados: [],
    producto_actual: {},
    datos: { fecha: moment().format("YYYY-MM-DD") },
    datos_tipo_pago: {
        fecha_limite: moment().format("YYYY-MM-DD"),
        plazo_pago: "inmediato",
    },
    tab: 10,

    // RETIROS
    retiros: {},
    page_retiros: 1,
    loader_retiros: false,
    ordering_retiros: "",
    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
    movimiento_caja: null,
    open_modal_pago: false,
    fecha_compra_final: moment().format("YYYY-MM-DD"),
    fecha_compra_inicial: moment().format("YYYY-MM-DD"),
    fecha_final_compra_finalizada: moment().format("YYYY-MM-DD"),
    fecha_inicial_compra_finalizada: moment().format("YYYY-MM-DD"),
    estado_descarga: false,
    estado_descarga2: false,
    costo_importacion_value: 0,
    tipo_cambio_value: 0,
    tipo_compra_value: 0,
    detalle_proveedor: {},
};

export default handleActions(reducers, initialState);
