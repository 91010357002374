import React, { Component } from 'react'
import Modal from "react-responsive-modal";
import { ComponentChilds } from "./componentsCategoria";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

class Listado extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            tipo: "nueva"
        }
    }

    componentWillMount() {
        this.props.listar();
    }

    isExpandableRow(row) {
        if (row.childs.length) return true;
        else return false;
    }

    expandComponent(row) {
        if(row.childs.length) {
            return (
                <ComponentChilds data={row.childs}/>
            );
        }else {
            return <span>No data</span>;
        }
    }

    onOpenModal = (tipo) => {
        this.setState({ open: true, tipo });
    }

    onCloseModal = () => {
        this.setState({ open: false });
    }

    render() {
        //  State
        const { open, tipo } = this.state;
        const { data } = this.props;

        return (
            <div className="mt-4">
                <div className="row">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="grid-titulo">
                                <h5 className="m-2">Categorías</h5>
                            </div>
                        </div>
                        <div className="card-body bg-white">
                            {/* <button className="btn btn-primary mb-3" onClick={() => this.onOpenModal("nueva")}><em className="fa fa-plus"></em> Nueva categoría</button> */}
                            <BootstrapTable data={data}
                                expandableRow={this.isExpandableRow}
                                expandComponent={this.expandComponent}
                                expandColumnOptions={{ expandColumnVisible: true }}>
                                <TableHeaderColumn dataField='id' isKey hidden>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='nombre'>Nombre</TableHeaderColumn>
                                <TableHeaderColumn dataField='slug'>Slug</TableHeaderColumn>
                                <TableHeaderColumn dataField='creado'>Fecha</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                    </div>

                    {/* MODAL PARA CREAR/EDITAR CATEGORIAS (SUBS) */}
                    <Modal open={open} onClose={this.onCloseModal} classNames={{modal:"modal-sm"}}>
                        <div style={{padding:'1.2rem', maxWidth: '400px'}}>
                            <h3 className="text-capitalize">{tipo}</h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                                pulvinar risus non risus hendrerit venenatis. Pellentesque sit amet
                                hendrerit risus, sed porttitor quam.
                            </p>
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default Listado;