import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { activeFormatter } from "../Util/Acciones/Acciones";
import { tableOptions } from "Utils/tableOptions";
import { Link } from 'react-router-dom';

class Bienvenida extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {

    }

    componentDidMount() {
        this.props.listar();
    }

    render() {
        // state
        const {data, loader, page} = this.props

        // bind
        const { listar, eliminar } = this.props;

        // local
        const options = {
            ...tableOptions,
            noDataText: loader ? 'Cargando...' : tableOptions.noDataText,
            onPageChange: listar,
            onSortChange: () => console.log,
            page,
        }

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                    <h1>Vistas de bienvenida</h1>
                    <Link className="btn btn-primary mb mt1" to="/bienvenida/crear/">+ Agregar Bienvenida</Link>

                    <BootstrapTable
                        data={loader ? [] : data.results}
                        fetchInfo={{ dataTotalSize: data.count }}
                        options={options}
                        striped hover remote pagination>
                        <TableHeaderColumn isKey dataSort dataField='id'>ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='version' dataSort={true} >Version</TableHeaderColumn>
                        <TableHeaderColumn dataField='habilitado' dataFormat={(cell, row) => {
                            return(
                                <div className="w-100">
                                    {
                                        cell ? (
                                            <strong className="text-success">Si</strong>
                                        ): (
                                            <strong className="text-danger">No</strong>
                                        )
                                    }
                                </div>
                            )
                        }}>Habilitado</TableHeaderColumn>
                        <TableHeaderColumn dataField='id' dataAlign="center" width="20%"
                            dataFormat={activeFormatter({editar: '/bienvenida/editar', eliminar})}>Acciones</TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        );
    }
}

Bienvenida.propTypes = {
    data: PropTypes.object.isRequired,
    // eliminarEmpresa: PropTypes.func.isRequired,
    loader: PropTypes.bool
};

export default Bienvenida;
