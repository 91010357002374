import { handleActions } from "redux-actions";
import { api } from "api";
import { push, goBack } from "react-router-redux";
import { ToastStore } from "react-toasts";
import _ from "lodash";
import Swal from "sweetalert2";
import { initialize as initializeForm, change as changeForm } from "redux-form";
import uuidv1 from "uuid/v1";
import { CAPACIDAD, PESO } from "utility/variables";
import { icons } from "icons";
import { node } from "prop-types";

// ------------------------------------
// Constants
// ------------------------------------
export const DATA = "PRODUCTO_DATA";
export const PRODUCTO = "PRODUCTO_PRODUCTO";
export const FRACCIONES = "PRODUCTO_FRACCIONES";
export const EDITCREATEFRACCION = "PRODUCTO_EDITCREATEFRACCION";
export const LOADER = "PRODUCTO_LOADER";
export const PAGE = "PRODUCTO_PAGE";
export const BUSCADOR = "PRODUCTO_BUSCADOR";
export const SORT = "PRODUCTO_SORT";
export const ESTADO_DESCARGA = "PRODUCTO_ESTADO_DESCARGA";
export const UUID_REQ_PRODUCTOS = "VENTA_UUID_REQ_PRODUCTOS";
export const SET_UNIDADES_MEDIDA = "VENTA_SET_UNIDADES_MEDIDA";
export const CATEGORIAS = "PRODUCTO_CATEGORIAS";
export const CATEGORIAS_SELECCIONADOS = "PRODUCTO_CATEGORIAS_SELECCIONADOS";
export const CATEGORIAS_EXPANDED = "PRODUCTO_CATEGORIAS_EXPANDED";
export const FAMILIA = "PRODUCTO_FAMILIA";
export const SUB_FAMILIA = "PRODUCTO_SUB_FAMILIA";

const endpoint = "productos";
const endpointCategorias = "categoria";

const estructuraProducto = {
    categoria: null,
    nombre: null,
    precio: null,
    precio2: null,
    precio3: null,
    precio4: null,
    precio5: null,
    porcent_precio2: null,
    porcent_precio3: null,
    porcent_precio4: null,
    porcent_precio5: null,
    codigo_barras: null,
    marca: null,
    descripcion: null,
    inventariable: true,
    vendible: true,
    tipo_producto: "1",
    minimo_existencias: null,
    fracciones: [],
    alertar: false,
    unidad_de_medida: null,
    tienda_online: false,
};

// ------------------------------------
// Actions
// ------------------------------------

export const isLoading = (loading = true) => ({
    type: LOADER,
    loader: loading,
});

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get(`${endpoint}/estado_descarga`, { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = error.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Listado_clientes.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();

    const { ordering, search, familia, sub_familia } = store.producto;
    // Obtener el id de la familia, si este existe
    const familia_id = familia ? familia.id : "";

    // Obtener el id de la sub familia, si este existe
    const sub_familia_id = sub_familia ? sub_familia.id : "";

    dispatch(setEstadoDescarga(true));
    api.get(`${endpoint}/descargar_excel`, {
        ordering,
        search,
        familia_id,
        sub_familia_id,
    })
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

/**
 * Action para listar los productos
 */

////////////////////////////////
/////      MOVIMIENTO        /////
////////////////////////////////
export const listarProductos =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(isLoading(true));

        // params
        const store = getStore();
        const { ordering, search, familia, sub_familia } = store.producto;
        // Obtener el id de la familia, si este existe
        const familia_id = familia ? familia.id : "";

        // Obtener el id de la sub familia, si este existe
        const sub_familia_id = sub_familia ? sub_familia.id : "";

        // const ordering = store.usuarios.sort;
        //  GENERAR EL UUID
        const uuid = uuidv1();
        dispatch(setUuidReqProductos(uuid));
        api.get(endpoint, {
            page,
            ordering,
            search,
            familia_id,
            sub_familia_id,
        })
            .catch((error) => {
                dispatch(isLoading(false));
            })
            .then((data) => {
                if (data) {
                    const otroUuid = getStore().producto.uuid_req_productos;
                    if (otroUuid === uuid) {
                        dispatch(changePage(page));
                        dispatch({ type: DATA, data });
                    }
                }
                dispatch(changePage(page));
                dispatch(isLoading(false));
            });
    };

export const inicializarProducto = () => (dispatch, getStore) => {
    dispatch(setProducto(estructuraProducto));
    dispatch(setFracciones([]));
};

const fraccionDefault = (data) => {
    return {
        id: null,
        nombre: "Unidad",
        capacidad_maxima: 1,
        codigo_barras: data.codigo_barras || "",
        descripcion_codigo_barras: data.descripcion_codigo_barras || "",
        precio: data.precio,
        precio2: data.precio2,
        precio3: data.precio3,
        precio4: data.precio4,
        precio5: data.precio5,
        porcent_precio2: data.porcent_precio2,
        porcent_precio3: data.porcent_precio3,
        porcent_precio4: data.porcent_precio4,
        porcent_precio5: data.porcent_precio5,
        precio_online: data.precio_online ? data.precio_online : 0,
        minimo_existencias: data.minimo_existencias,
        vendible: data.vendible,
        alertar: data.alertar,
        precios_sucursal: data.precios_sucursal,
    };
};
const validarFracciones = (
    dataProducto,
    fracciones,
    unidades_de_medida_empresa
) => {
    let valido = false;
    let producto = _.cloneDeep(dataProducto);
    const tipo_producto = dataProducto.tipo_producto;

    //  Si es de tipo 100 es fraccionado
    producto.tiene_fracciones = parseInt(tipo_producto) == 100 ? true : false;
    // Si es de tipo 1000(Peso) o 2000(capacidad) es a granel
    producto.a_granel =
        parseInt(tipo_producto) == 1000 || parseInt(tipo_producto) == 2000
            ? true
            : false;

    if (producto.a_granel) {
        producto.tipo_unidad_medida =
            parseInt(tipo_producto) == 1000 ? PESO : CAPACIDAD;
    }
    if (producto.inventariable) {
        if (producto.tiene_fracciones) {
            if (fracciones.length > 1) {
                producto.fracciones = fracciones;
                valido = true;
            } else {
                valido = false;
            }
        } else {
            if (!producto.fracciones) producto.fracciones = [];
            // CASO 1
            // SI NO TENIA LA FRACCION POR DEFECTO SE LE ASIGNA
            if (producto.fracciones.length != 1)
                producto.fracciones = [fraccionDefault(producto)];
            else {
                producto.fracciones[0].codigo_barras = producto.codigo_barras;
                producto.fracciones[0].descripcion_codigo_barras =
                    producto.descripcion_codigo_barras;
                producto.fracciones[0].minimo_existencias =
                    producto.minimo_existencias;
                producto.fracciones[0].alertar = producto.alertar;
                producto.fracciones[0].precio = producto.precio;
                producto.fracciones[0].precio2 = producto.precio2;
                producto.fracciones[0].precio3 = producto.precio3;
                producto.fracciones[0].precio4 = producto.precio4;
                producto.fracciones[0].precio5 = producto.precio5;

                producto.fracciones[0].porcent_precio2 =
                    producto.porcent_precio2;
                producto.fracciones[0].porcent_precio3 =
                    producto.porcent_precio3;
                producto.fracciones[0].porcent_precio4 =
                    producto.porcent_precio4;
                producto.fracciones[0].porcent_precio5 =
                    producto.porcent_precio5;
                producto.fracciones[0].precio_online = producto.precio_online
                    ? producto.precio_online
                    : 0;
                producto.fracciones[0].precios_sucursal =
                    producto.precios_sucursal;
                producto.fracciones[0].vendible = producto.vendible;
            }

            // ASIGNAR LA UNIDAD DE MEDIDA PARA LOS PRODUCTOS A GRANEL
            if (producto.a_granel) {
                const unidad_medida = _.find(
                    unidades_de_medida_empresa,
                    (unidad) => {
                        return unidad.id == producto.unidad_de_medida;
                    }
                );
                producto.fracciones[0].nombre = unidad_medida
                    ? unidad_medida.nombre
                    : "Unidad";
                producto.fracciones[0].unidad_de_medida =
                    producto.unidad_de_medida;
            } else {
                producto.fracciones[0].nombre = "Unidad";
                producto.fracciones[0].unidad_de_medida = null;
            }
            valido = true;
        }
    } else {
        if (!producto.fracciones) producto.fracciones = [];
        if (producto.fracciones.length != 1)
            producto.fracciones = [fraccionDefault(producto)];
        else {
            producto.fracciones[0].codigo_barras = producto.codigo_barras;
            producto.fracciones[0].descripcion_codigo_barras =
                producto.descripcion_codigo_barras;
            producto.fracciones[0].minimo_existencias =
                producto.minimo_existencias;
            producto.fracciones[0].alertar = producto.alertar;
            producto.fracciones[0].precio = producto.precio;
            producto.fracciones[0].precio2 = producto.precio2;
            producto.fracciones[0].precio3 = producto.precio3;
            producto.fracciones[0].precio4 = producto.precio4;
            producto.fracciones[0].precio5 = producto.precio5;

            producto.fracciones[0].porcent_precio2 = producto.porcent_precio2;
            producto.fracciones[0].porcent_precio3 = producto.porcent_precio3;
            producto.fracciones[0].porcent_precio4 = producto.porcent_precio4;
            producto.fracciones[0].porcent_precio5 = producto.porcent_precio5;
            producto.fracciones[0].precio_online = producto.precio_online
                ? producto.precio_online
                : 0;
            producto.fracciones[0].precios_sucursal = producto.precios_sucursal;
            producto.fracciones[0].vendible = producto.vendible;
        }
        valido = true;
    }

    return {
        valido: valido,
        data: producto,
    };
};

export const crear = (fotos, deBodega) => (dispatch, getStore) => {
    dispatch(isLoading(true));
    const store = getStore();
    let producto = store.form.ProductoCrear.values;
    const unidades_de_medida_empresa = store.usuario.unidades_de_medida_empresa;
    const fracciones = store.producto.fracciones;
    // const { unidades_medidas } = store.producto;

    const validacion = validarFracciones(
        producto,
        fracciones,
        unidades_de_medida_empresa
    );

    if (validacion.valido) {
        //  asignacion de unidades de medida  para los productos a granel
        let dataProducto = _.cloneDeep(validacion.data);
        if (producto.subfamilia) dataProducto.subfamilia = producto.subfamilia;
        //console.log("Esxiste subFam");
        // if (dataProducto.a_granel)
        //     dataProducto.unidades_medidas = unidades_medidas;

        let producto_online_valido = false;
        let categorias_valida = false;

        if (!dataProducto.precio) {
            dataProducto.precio = 0;
        }

        if (dataProducto.tienda_online) {
            if (
                fotos[0].file != null &&
                fotos[0].file != undefined &&
                fotos[0].file != ""
            )
                producto_online_valido = true;

            // Validacion de categorías
            const { categorias_seleccionados } = store.producto;
            if (!categorias_seleccionados.length) {
                categorias_valida = false;
                producto_online_valido = false;
            } else {
                categorias_valida = true;
                dataProducto.categorias = categorias_seleccionados;
            }
        } else {
            producto_online_valido = true;
        }

        if (producto_online_valido) {
            api.postAttachments(endpoint, dataProducto, fotos)
                .catch((err) => {
                    if (err) {
                        Swal(
                            "ERROR",
                            err.detail ||
                                "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                            "error"
                        );
                    } else {
                        Swal(
                            "ERROR",
                            "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                            "error"
                        );
                    }
                })
                .then((resp) => {
                    if (resp) {
                        ToastStore.success("Datos almacenados correctamente");
                        // if (deBodega)
                        //     dispatch(push('/bodega/ingreso_de_mercaderia/'));
                        // else
                        //     dispatch(push('/productos'));

                        dispatch(goBack());
                    }
                    dispatch(isLoading(false));
                })
                .finally(() => {
                    dispatch(isLoading(false));
                });
        } else {
            dispatch(isLoading(false));
            if (categorias_valida) {
                Swal(
                    "ERROR",
                    "El producto para la tienda online debe tener al menos una imagen.",
                    "error"
                );
            }
        }
    } else {
        dispatch(isLoading(false));
        Swal(
            "Error",
            "El producto debe tener un mínimo de 2 fracciones.",
            "error"
        );
    }
};

// Funcion para mesclar los datos del producto con el que se recibio
const parseData = (data, getStore) => {
    const store = getStore();
    const { conf_tienda_online } = store.usuario;
    let producto = { ...estructuraProducto, ...data };
    const fraccones = producto.fracciones;
    if (fraccones.length == 1) {
        producto.codigo_barras = fraccones[0].codigo_barras;
        producto.descripcion_codigo_barras =
            fraccones[0].descripcion_codigo_barras;
        producto.precio = fraccones[0].precio;
        producto.precio2 = fraccones[0].precio2;
        producto.precio3 = fraccones[0].precio3;
        producto.precio4 = fraccones[0].precio4;
        producto.precio5 = fraccones[0].precio5;

        producto.porcent_precio2 = fraccones[0].porcent_precio2;
        producto.porcent_precio3 = fraccones[0].porcent_precio3;
        producto.porcent_precio4 = fraccones[0].porcent_precio4;
        producto.porcent_precio5 = fraccones[0].porcent_precio5;
        producto.precio_online = fraccones[0].precio_online;
        producto.minimo_existencias = fraccones[0].minimo_existencias;
        producto.precios_sucursal = fraccones[0].precios_sucursal;
        if (producto.minimo_existencias) producto.alertar = true;
        if (producto.a_granel) {
            producto.unidad_de_medida = fraccones[0].unidad_de_medida;
        }
    }
    //  VALIDACION PARA EL TIPO DEL PRODUCTO EN EL FORMULARIO
    if (producto.a_granel) {
        producto.tipo_producto =
            producto.fracciones[0].tipo_unidad_medida == PESO ? "1000" : "2000";
    } else {
        producto.tipo_producto = producto.tiene_fracciones ? "100" : "1";
    }

    // //  Si no tiene habilitado la tienda online se setea el campo a false
    // if(!conf_tienda_online) {
    //     producto.tienda_online = false
    // }
    return producto;
};

export const leer = (id) => (dispatch, getStore) => {
    dispatch(isLoading(true));
    dispatch(setProducto(estructuraProducto));
    api.get(`${endpoint}/${id}`, { unidad_de_medida: false })
        .catch((err) => {
            Swal(
                "ERROR",
                "Ha ocurrido un error, por favor intente más tarde.",
                "error"
            );
        })
        .then((resp) => {
            if (resp) {
                const producto = parseData(resp, getStore);
                let fracciones = [];
                if (producto.fracciones.length > 1) {
                    producto.fracciones.forEach(function (fraccion, index) {
                        let dataFraccion = fraccion;
                        if (fraccion.minimo_existencias) {
                            dataFraccion.alertar = true;
                        } else {
                            dataFraccion.alertar = false;
                        }
                        if (producto.fracciones[index + 1]) {
                            dataFraccion.contenido =
                                producto.fracciones[index + 1].nombre;
                        } else {
                            dataFraccion.contenido = null;
                        }
                        fracciones.push(dataFraccion);
                    });
                }
                dispatch(setFracciones(fracciones));
                dispatch(setProducto(producto));

                //  SE SETEAN LAS UNIDADES DE MEDID QUE MANEJA EL PRODUCTO
                // if (producto.a_granel) {
                //     let unidades_medidas_list = producto.fracciones[0].unidades_de_medida;

                //     dispatch(setUnidadesMedida(unidades_medidas_list));
                // }

                //  Se valida que sea un producto de la tienda online
                if (producto.tienda_online) {
                    const categorias = [];
                    const expanded = [];
                    //  Se recorren las categorías para obtener los ids
                    //  para poder renderizarlos en el componente (arbol de categorias)
                    producto.categorias.forEach((categoria) => {
                        categorias.push(categoria.id.toString());

                        //  Se recorren los antecesores (padres) de la categoría
                        //  para poder mostrar las categorías ya expandidas
                        categoria.ancestors.forEach((id_ancestor) => {
                            const find = _.find(
                                expanded,
                                function (id_expanded) {
                                    return (
                                        id_expanded == id_ancestor.toString()
                                    );
                                }
                            );
                            //  Se valida que sea unico en el array de ids de antecesores
                            if (!find) {
                                expanded.push(id_ancestor.toString());
                            }
                        });
                    });
                    dispatch(setCategoriasSeleccionados(categorias));
                    dispatch(setCategoriasExpanded(expanded));
                } else {
                    dispatch(setCategoriasSeleccionados([]));
                }
                producto.precio = resp.precio;
                dispatch(initializeForm("ProductoEditar", producto));
            }
            dispatch(isLoading(false));
        });
};

export const editar = (fotos_producto) => (dispatch, getStore) => {
    dispatch(isLoading(true));
    const store = getStore();
    let producto = store.form.ProductoEditar.values;
    const unidades_de_medida_empresa = store.usuario.unidades_de_medida_empresa;
    const fracciones = store.producto.fracciones;
    // const { unidades_medidas } = store.producto;

    const validacion = validarFracciones(
        producto,
        fracciones,
        unidades_de_medida_empresa
    );
    let fotos = [];
    fotos_producto.forEach(function (foto, index) {
        // debugger;
        if (foto.file == undefined && foto.name == "imagen")
            foto.file = producto.imagen;

        if (foto.name.includes("catalogo_")) {
            if (foto.file == undefined) foto.file = producto["imagen_" + index];
        }
        fotos.push(foto);
    });

    if (validacion.valido) {
        //  asignacion de unidades de medida  para los productos a granel
        let dataProducto = _.cloneDeep(validacion.data);
        if (dataProducto.subfamilia)
            dataProducto.subfamilia = dataProducto.subfamilia.id;
        // if (dataProducto.a_granel)
        //     dataProducto.unidades_medidas = unidades_medidas;

        let producto_online_valido = false;
        let categorias_valida = false;

        if (dataProducto.tienda_online) {
            if (
                fotos[0].file != null &&
                fotos[0].file != undefined &&
                fotos[0].file != ""
            )
                producto_online_valido = true;

            // Validacion de categorías
            const { categorias_seleccionados } = store.producto;
            if (!categorias_seleccionados.length) {
                categorias_valida = false;
                producto_online_valido = false;
            } else {
                categorias_valida = true;
                dataProducto.categorias = categorias_seleccionados;
            }
        } else {
            producto_online_valido = true;
        }

        if (producto_online_valido) {
            api.putAttachments(
                `${endpoint}/${dataProducto.id}`,
                dataProducto,
                fotos
            )
                .catch((err) => {
                    if (err) {
                        Swal("ERROR", err.detail, "error");
                    } else {
                        Swal(
                            "ERROR",
                            "No se ha podido almacenar los datos, intente más tarde.",
                            "error"
                        );
                    }
                })
                .then((resp) => {
                    if (resp) {
                        ToastStore.success("Datos almacenados correctamente");
                        dispatch(push("/productos"));
                    }
                    dispatch(isLoading(false));
                });
        } else {
            dispatch(isLoading(false));
            if (categorias_valida) {
                Swal(
                    "ERROR",
                    "El producto para la tienda online debe tener al menos una imagen.",
                    "error"
                );
            }
        }
    } else {
        dispatch(isLoading(false));
        Swal(
            "Error",
            "El producto debe tener un mínimo de 2 fracciones.",
            "error"
        );
    }
};

export const eliminar = (id) => (dispatch) => {
    dispatch(isLoading(true));
    api.eliminar(`${endpoint}/${id}`).then(
        (response) => {
            ToastStore.success("Registro eliminado.");

            dispatch(isLoading(false));
            dispatch(listarProductos());
        },
        (error) => {
            console.log("error", error);
            Swal("¡Error!", error.detail, "error").then(() => {});

            dispatch(isLoading(false));
            dispatch(listarProductos());
        }
    );
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listarProductos());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.producto.page;
    dispatch(listarProductos(page));
};

//////////////////////////////////
/////      FRACCIONES        /////
//////////////////////////////////

/**
 * Funcion para agregar nuevas fracciones
 * @param fraccion : nueva fraccion a agregar
 * @param onCloseModal: funcion para cerrar la modal
 */
export const agregarNuevaFraccion =
    (fraccion, onCloseModal) => (dispatch, getStore) => {
        let store = getStore();
        let editCreateFraccion = store.producto.editCreateFraccion;
        const fracciones = store.producto.fracciones;
        let dataFracciones = _.cloneDeep(fracciones);
        const nombreFraccion = fraccion.nombre.trim();
        let estructuraEditCreateFraccion = {
            data: null,
            arriba: null,
            abajo: null,
            tipo: "create",
            posicion: null,
        };

        let estructuraFraccion = {
            id: null,
            nombre: fraccion.nombre,
            capacidad_maxima: fraccion.capacidad_maxima,
            contenido: null,
            precio: fraccion.precio,
            precio2: fraccion.precio2,
            precio3: fraccion.precio3,
            precio4: fraccion.precio4,
            precio5: fraccion.precio5,
            porcent_precio2: fraccion.porcent_precio2,
            porcent_precio3: fraccion.porcent_precio3,
            porcent_precio4: fraccion.porcent_precio4,
            porcent_precio5: fraccion.porcent_precio5,
            codigo_barras: fraccion.codigo_barras,
            descripcion_codigo_barras: fraccion.descripcion_codigo_barras,
            minimo_existencias: fraccion.minimo_existencias,
            vendible: fraccion.vendible,
            alertar: fraccion.alertar,
            precios_sucursal: fraccion.precios_sucursal,
        };
        // Se valida que no se vaya ingresar una fraccion repetida
        const fracionEncontrados = _.find(fracciones, function (fra) {
            return fra.nombre.toLowerCase() == nombreFraccion.toLowerCase();
        });
        if (!fracionEncontrados) {
            if (fracciones.length != 0) {
                // Se valida la posicion de la fraccion a ingresar
                if (editCreateFraccion.posicion === "arriba") {
                    // Si tiene alguna posicion arriba se asimila que se esta ingresando en medio de 2 fracciones
                    if (editCreateFraccion.arriba) {
                        estructuraFraccion.capacidad_maxima =
                            fraccion.capacidad_maxima_abajo;
                        estructuraFraccion.contenido = editCreateFraccion.abajo
                            ? editCreateFraccion.abajo.nombre
                            : null;
                        fracciones.forEach(function (item, index) {
                            if (
                                item.nombre === editCreateFraccion.arriba.nombre
                            ) {
                                dataFracciones[index].capacidad_maxima =
                                    fraccion.capacidad_maxima_arriba;
                                dataFracciones[index].contenido =
                                    fraccion.nombre;
                                dataFracciones.splice(
                                    index + 1,
                                    0,
                                    estructuraFraccion
                                );
                            }
                        });
                    } else {
                        fracciones.forEach(function (item, index) {
                            if (
                                item.nombre === editCreateFraccion.abajo.nombre
                            ) {
                                estructuraFraccion.capacidad_maxima =
                                    fraccion.capacidad_maxima_abajo;
                                estructuraFraccion.contenido =
                                    editCreateFraccion.abajo
                                        ? editCreateFraccion.abajo.nombre
                                        : null;
                                dataFracciones.splice(
                                    index,
                                    0,
                                    estructuraFraccion
                                );
                            }
                        });
                    }
                } else {
                    // Si tiene alguna posicion abajo se asimila que se esta ingresando en medio de 2 fracciones
                    if (editCreateFraccion.abajo) {
                        estructuraFraccion.capacidad_maxima =
                            fraccion.capacidad_maxima_abajo;
                        estructuraFraccion.contenido = editCreateFraccion.abajo
                            ? editCreateFraccion.abajo.nombre
                            : null;
                    } else {
                        estructuraFraccion.capacidad_maxima = 1;
                        estructuraFraccion.contenido = null;
                    }
                    fracciones.forEach(function (item, index) {
                        if (item.nombre === editCreateFraccion.arriba.nombre) {
                            dataFracciones[index].capacidad_maxima =
                                fraccion.capacidad_maxima_arriba;
                            dataFracciones[index].contenido = fraccion.nombre;
                            dataFracciones.splice(
                                index + 1,
                                0,
                                estructuraFraccion
                            );
                        }
                    });
                }
            } else {
                dataFracciones.push(estructuraFraccion);
            }
            dispatch(setFracciones(dataFracciones));
            dispatch(setEditCreateFraccion(estructuraEditCreateFraccion));
            onCloseModal();
        } else {
            Swal(
                "ERROR",
                "No fue posible agregar la fracción porque ya tienes una fracción con el mismo nombre.",
                "error"
            );
        }
    };

export const agregarPrimeraFraccion = () => (dispatch) => {
    let editCreateFraccion = {
        data: null,
        arriba: null,
        abajo: null,
        tipo: "create",
        posicion: null,
    };

    let estructuraFraccion = {
        id: null,
        nombre: null,
        capacidad_maxima: null,
        capacidad_maxima_arriba: null,
        capacidad_maxima_abajo: null,
        precio: null,
        precio2: null,
        precio3: null,
        precio4: null,
        precio5: null,
        porcent_precio2: null,
        porcent_precio3: null,
        porcent_precio4: null,
        porcent_precio5: null,
        codigo_barras: null,
        descripcion_codigo_barras: null,
        minimo_existencias: null,
        vendible: true,
        alertar: false,
    };

    dispatch(setEditCreateFraccion(editCreateFraccion));
    dispatch(initializeForm("FraccionCrear", estructuraFraccion));
};

export const agregarFaccion = (nombre, onOpenModal) => (dispatch, getStore) => {
    const store = getStore();
    const fracciones = store.producto.fracciones;
    let editCreateFraccion = {
        data: null,
        arriba: null,
        abajo: null,
        tipo: "create",
        posicion: null,
    };

    let estructuraFraccion = {
        id: null,
        nombre: null,
        capacidad_maxima: null,
        capacidad_maxima_arriba: null,
        capacidad_maxima_abajo: null,
        precio: null,
        precio2: null,
        precio3: null,
        precio4: null,
        precio5: null,
        porcent_precio2: null,
        porcent_precio3: null,
        porcent_precio4: null,
        porcent_precio5: null,
        codigo_barras: null,
        descripcion_codigo_barras: null,
        minimo_existencias: null,
        vendible: true,
        alertar: false,
    };

    Swal({
        showCancelButton: true,
        confirmButtonClass: "btn-swal-fracciones",
        cancelButtonClass: "btn-swal-fracciones",
        customClass: "swal-fracciones",
        confirmButtonText: `
            <div class="d-flex flex-column align-items-center justify-content-center">
                <img src=${icons.fraccion_mayor} class="iconos-swal-fracciones"/>
                Fracción mayor que ${nombre}
            </div>`,
        cancelButtonText: `
            <div class="d-flex flex-column align-items-center justify-content-center">
                <img src=${icons.fraccion_menor} class="iconos-swal-fracciones"/>
                Fracción menor que ${nombre}
            </div>`,
    }).then((result) => {
        if (result.value == true) {
            // Arriba
            fracciones.forEach(function (fraccion, index) {
                if (fraccion.nombre === nombre) {
                    editCreateFraccion.abajo = _.cloneDeep(fraccion);
                    editCreateFraccion.arriba = _.cloneDeep(
                        fracciones[index - 1]
                    );
                    editCreateFraccion.posicion = "arriba";
                    editCreateFraccion.data = estructuraFraccion;
                }
            });
            dispatch(setEditCreateFraccion(editCreateFraccion));
            dispatch(initializeForm("FraccionCrear", editCreateFraccion.data));
            onOpenModal();
        } else {
            // abajo
            if (result.dismiss === "cancel") {
                fracciones.forEach(function (fraccion, index) {
                    if (fraccion.nombre === nombre) {
                        editCreateFraccion.abajo = _.cloneDeep(
                            fracciones[index + 1]
                        );
                        editCreateFraccion.arriba = _.cloneDeep(fraccion);
                        editCreateFraccion.posicion = "abajo";
                        editCreateFraccion.data = estructuraFraccion;
                    }
                });
                dispatch(setEditCreateFraccion(editCreateFraccion));
                dispatch(
                    initializeForm("FraccionCrear", editCreateFraccion.data)
                );
                onOpenModal();
            }
        }
    });
};
/**
 * Funcion para seleccionar la fraccion que se quiere editar
 * con las fracciones que tiene arriba y abajo
 * @param nombre : nombre unica de la fraccion para buscarla
 */
export const selectFraccionAEditar = (nombre) => (dispatch, getStore) => {
    const store = getStore();
    // let producto = store.producto.producto;
    const fracciones = store.producto.fracciones;
    let editCreateFraccion = {
        data: null,
        arriba: null,
        abajo: null,
        tipo: "edit",
    };
    fracciones.forEach(function (fraccion, index) {
        if (fraccion.nombre === nombre) {
            let data = _.cloneDeep(fraccion);
            editCreateFraccion.arriba = _.cloneDeep(fracciones[index - 1]);
            editCreateFraccion.abajo = _.cloneDeep(fracciones[index + 1]);
            if (editCreateFraccion.arriba)
                data.capacidad_maxima_arriba =
                    editCreateFraccion.arriba.capacidad_maxima;

            if (editCreateFraccion.abajo)
                data.capacidad_maxima_abajo = fraccion.capacidad_maxima;

            editCreateFraccion.data = data;

            dispatch(initializeForm("FraccionCrear", editCreateFraccion.data));
        }
    });
    dispatch(setEditCreateFraccion(editCreateFraccion));
};

/**
 * Funcion para editar una fraccion
 * @param fraccion : fraccion editado
 * @param onCloseModal : funcion para cerrar la modal
 */
export const editarFraccion =
    (fraccion, onCloseModal) => (dispatch, getStore) => {
        dispatch(isLoading(true));
        const store = getStore();
        const editCreateFraccion = store.producto.editCreateFraccion;
        const fracciones = store.producto.fracciones;
        const nombreFraccion = fraccion.nombre.trim();

        // Se valida que no se vaya ingresar una fraccion repetida
        let fracionEncontrados = null;
        if (editCreateFraccion.data.nombre != fraccion.nombre) {
            fracionEncontrados = _.find(fracciones, function (fra) {
                return fra.nombre.toLowerCase() == nombreFraccion.toLowerCase();
            });
        }
        if (!fracionEncontrados) {
            // Se generan las nuevas validaciones
            fracciones.forEach(function (item) {
                // Primero se edita la fraccion
                if (item.nombre === editCreateFraccion.data.nombre) {
                    item.nombre = fraccion.nombre;
                    item.precio = fraccion.precio;
                    item.precio2 = fraccion.precio2;
                    item.precio3 = fraccion.precio3;
                    item.precio4 = fraccion.precio4;
                    item.precio5 = fraccion.precio5;
                    item.porcent_precio2 = fraccion.porcent_precio2;
                    item.porcent_precio3 = fraccion.porcent_precio3;
                    item.porcent_precio4 = fraccion.porcent_precio4;
                    item.porcent_precio5 = fraccion.porcent_precio5;
                    item.codigo_barras = fraccion.codigo_barras;
                    item.descripcion_codigo_barras =
                        fraccion.descripcion_codigo_barras;
                    item.vendible = fraccion.vendible;
                    item.alertar = fraccion.alertar;
                    item.minimo_existencias =
                        fraccion.minimo_existencias || null;
                    item.precios_sucursal = fraccion.precios_sucursal;

                    // Si tiene fraccion abajo se le asigna la capacidad maxima
                    if (editCreateFraccion.abajo) {
                        item.capacidad_maxima = fraccion.capacidad_maxima_abajo;
                        item.contenido = editCreateFraccion.abajo
                            ? editCreateFraccion.abajo.nombre
                            : null;
                    } else {
                        // De lo contrario se asimila que es el ultimo por lo tanto por defecto es 1
                        item.capacidad_maxima = 1;
                        item.contenido = null;
                    }
                }
                // se actualizan la fraccion de arriba
                if (editCreateFraccion.arriba) {
                    if (item.nombre === editCreateFraccion.arriba.nombre) {
                        item.capacidad_maxima =
                            fraccion.capacidad_maxima_arriba;
                        item.contenido = fraccion.nombre;
                    }
                }
            });
            // Se setean las fracciones en el store y se cierra la modal
            dispatch(setFracciones(fracciones));
            dispatch(isLoading(false));
            onCloseModal();
        } else {
            onCloseModal();
            Swal(
                "ERROR",
                "No fue posible agregar la fracción porque ya tienes una fracción con el mismo nombre.",
                "error"
            );
        }
    };

/**
 * Funcion para eliminar una fraccion
 * @param nombre : nombre unica de la fraccion
 */
export const eliminarFraccion = (nombre) => (dispatch, getStore) => {
    const store = getStore();
    let fracciones = _.cloneDeep(store.producto.fracciones);
    Swal({
        title: "Eliminar fracción?",
        text: "No podrá revertir esta acción!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar!",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            fracciones = _.remove(fracciones, function (fraccion) {
                return fraccion.nombre != nombre;
            });
            const cantidadFin = fracciones.length;

            const fracionUnidad = _.find(fracciones, function (fra) {
                return parseInt(fra.capacidad_maxima) == 1;
            });
            if (!fracionUnidad) {
                if (cantidadFin > 0) {
                    fracciones[cantidadFin - 1].capacidad_maxima = 1;
                    fracciones[cantidadFin - 1].contenido = null;
                }
            }
            dispatch(setFracciones(fracciones));
        }
    });
};

// export const changeUnidadesMedidas = (estado, id_unidad) => (dispatch, getStore) => {
//     const store = getStore();
//     let unidades_medidas_list = _.cloneDeep(store.producto.unidades_medidas);
//     if (estado==true) {
//         const unidad_medida = _.find(unidades_medidas_list, function(id) { return id == id_unidad});
//         if (!unidad_medida) {
//             unidades_medidas_list.push(id_unidad);
//         }
//     } else {
//         unidades_medidas_list = _.remove(unidades_medidas_list, function(id) { return id != id_unidad});
//         dispatch(setUnidadesMedida(unidades_medidas_list));
//     }
//     dispatch(setUnidadesMedida(unidades_medidas_list));
// }

/**
 * Funcion para cambiar la unidad de medida default
 * para cada unidad (PESO O CAPACIDAD)
 * @param tipo: tipo de producto
 */
export const cambioTipoProducto = (tipo, accion) => (dispatch, getStore) => {
    const store = getStore();
    let producto =
        accion == "crear"
            ? _.cloneDeep(store.form.ProductoCrear.values)
            : _.cloneDeep(store.form.ProductoEditar.values);
    const unidades_de_medida_empresa = store.usuario.unidades_de_medida_empresa;

    //  Si la opcion de tienda online esta activo y el tipo no es simple (1)
    //  se lanza un error
    if (producto.tienda_online && tipo != "1") {
        producto.tipo_producto = "1";
        Swal(
            "ERROR",
            "La opcion de tienda online esta activo, solo puede manejar productos simples.",
            "error"
        ).then((result) => {
            if (result.value) {
                if (accion == "crear")
                    dispatch(initializeForm("ProductoCrear", producto));
                else dispatch(initializeForm("ProductoEditar", producto));
            }
        });
    } else {
        // Validaciones para los productos a granel
        if (tipo == "1000") {
            const unidades_medida_peso = _.filter(
                unidades_de_medida_empresa,
                (unidad) => {
                    return unidad.tipo == PESO;
                }
            );
            const unidades_medida_pequenio = _.find(
                unidades_medida_peso,
                (unidad) => {
                    return unidad.capacidad == 1;
                }
            );
            if (unidades_medida_pequenio) {
                producto.unidad_de_medida = unidades_medida_pequenio.id;
            }
        } else if (tipo == "2000") {
            const unidades_medida_capacidad = _.filter(
                unidades_de_medida_empresa,
                (unidad) => {
                    return unidad.tipo == CAPACIDAD;
                }
            );
            const unidades_medida_pequenio = _.find(
                unidades_medida_capacidad,
                (unidad) => {
                    return unidad.capacidad == 1;
                }
            );
            if (unidades_medida_pequenio) {
                producto.unidad_de_medida = unidades_medida_pequenio.id;
            }
        } else {
            // tipo 1 (simple) o 100 (fraccionado)

            producto.unidad_de_medida = null;
        }
    }
    if (accion == "crear") dispatch(initializeForm("ProductoCrear", producto));
    else dispatch(initializeForm("ProductoEditar", producto));
};

//  CATEGORIAS
const getChilds = (node) => (dispatch) => {
    if (node.childs.length) {
        let tree = [];
        node.childs.forEach((sub_categoria) => {
            let child = {
                value: sub_categoria.id,
                label: sub_categoria.nombre,
            };
            if (sub_categoria.childs.length) {
                const result = dispatch(getChilds(sub_categoria));
                if (result) {
                    child.children = result;
                    tree.push(child);
                }
            } else {
                tree.push(child);
            }
        });
        return tree;
    } else {
        return false;
    }
};
const formatCategorias = (data) => (dispatch) => {
    let tree = [];
    data.forEach((categoria) => {
        let child = {
            value: categoria.id,
            label: categoria.nombre,
        };
        if (categoria.childs.length) {
            const result = dispatch(getChilds(categoria));
            if (result) {
                child.children = result;
                tree.push(child);
            }
        } else {
            tree.push(child);
        }
    });
    return tree;
};

export const getCategorias = () => (dispatch, getStore) => {
    dispatch(isLoading(true));
    api.get(`${endpointCategorias}/tree`)
        .catch((err) => {
            console.log("ERROR: ", err);
            ToastStore.error("No fue posible obtener el listado de categorías");
        })
        .then((resp) => {
            if (resp) {
                const tree = dispatch(formatCategorias(resp.data));
                dispatch(setCategorias(tree));
            }
        })
        .finally(() => {
            dispatch(isLoading(false));
        });
};

export const asignarCategoria = (seleccionados) => (dispatch, getStore) => {
    const store = getStore();
    const { categorias_seleccionados } = store.producto;
    if (seleccionados.length <= 3) {
        dispatch(setCategoriasSeleccionados(seleccionados));
    } else {
        ToastStore.error(
            "El producto para la tienda online debe tener un máximo de 3 categorías"
        );
        // Swal("ERROR", "El producto para la tienda online debe tener un máximo de 3 categorías", "error")
    }
};

export const cambioPorcentajePrecio =
    (campo, nombreFormulario, esPrecioSucursal = false, index = null) =>
    (dispatch, getStore) => {
        const store = getStore();
        const conf_porcentaje_precio = store.usuario.conf_porcentaje_precio;
        const forms = store.form;

        const form = forms[nombreFormulario];
        let values = _.cloneDeep(form.values);
        if (esPrecioSucursal) {
            values = values["precios_sucursal"][index];
        }
        const precio = values.precio
            ? parseFloat(parseFloat(values.precio).toFixed(2))
            : 0;
        const valor_campo = values[campo]
            ? parseFloat(parseFloat(values[campo]).toFixed(2))
            : 0;
        let monto_calculado = 0;

        // debugger;

        if (conf_porcentaje_precio) {
            switch (campo) {
                case "porcent_precio2":
                    monto_calculado = parseFloat(
                        (precio * (valor_campo / 100)).toFixed(2)
                    );
                    // dispatch(initializeForm(nombreFormulario, nuevos_valores));
                    if (esPrecioSucursal) {
                        dispatch(
                            changeForm(
                                nombreFormulario,
                                `precios_sucursal[${index}].precio2`,
                                monto_calculado
                            )
                        );
                    } else {
                        dispatch(
                            changeForm(
                                nombreFormulario,
                                "precio2",
                                monto_calculado
                            )
                        );
                    }
                    break;
                case "porcent_precio3":
                    monto_calculado = parseFloat(
                        (precio * (valor_campo / 100)).toFixed(2)
                    );
                    if (esPrecioSucursal) {
                        dispatch(
                            changeForm(
                                nombreFormulario,
                                `precios_sucursal[${index}].precio3`,
                                monto_calculado
                            )
                        );
                    } else {
                        dispatch(
                            changeForm(
                                nombreFormulario,
                                "precio3",
                                monto_calculado
                            )
                        );
                    }
                    break;
                case "porcent_precio4":
                    monto_calculado = parseFloat(
                        (precio * (valor_campo / 100)).toFixed(2)
                    );
                    if (esPrecioSucursal) {
                        dispatch(
                            changeForm(
                                nombreFormulario,
                                `precios_sucursal[${index}].precio4`,
                                monto_calculado
                            )
                        );
                    } else {
                        dispatch(
                            changeForm(
                                nombreFormulario,
                                "precio4",
                                monto_calculado
                            )
                        );
                    }
                    break;
                case "porcent_precio5":
                    monto_calculado = parseFloat(
                        (precio * (valor_campo / 100)).toFixed(2)
                    );
                    if (esPrecioSucursal) {
                        dispatch(
                            changeForm(
                                nombreFormulario,
                                `precios_sucursal[${index}].precio5`,
                                monto_calculado
                            )
                        );
                    } else {
                        dispatch(
                            changeForm(
                                nombreFormulario,
                                "precio5",
                                monto_calculado
                            )
                        );
                    }
                    break;
                default:
                    break;
            }
        } else {
            switch (campo) {
                case "precio2":
                    if (precio > 0) {
                        monto_calculado = parseFloat(
                            ((valor_campo * 100) / precio).toFixed(2)
                        );
                    } else {
                        monto_calculado = 0;
                    }
                    if (esPrecioSucursal) {
                        dispatch(
                            changeForm(
                                nombreFormulario,
                                `precios_sucursal[${index}].porcent_precio2`,
                                monto_calculado
                            )
                        );
                    } else {
                        dispatch(
                            changeForm(
                                nombreFormulario,
                                "porcent_precio2",
                                monto_calculado
                            )
                        );
                    }
                    break;
                case "precio3":
                    if (precio > 0) {
                        monto_calculado = parseFloat(
                            ((valor_campo * 100) / precio).toFixed(2)
                        );
                    } else {
                        monto_calculado = 0;
                    }
                    if (esPrecioSucursal) {
                        dispatch(
                            changeForm(
                                nombreFormulario,
                                `precios_sucursal[${index}].porcent_precio3`,
                                monto_calculado
                            )
                        );
                    } else {
                        dispatch(
                            changeForm(
                                nombreFormulario,
                                "porcent_precio3",
                                monto_calculado
                            )
                        );
                    }
                    break;
                case "precio4":
                    if (precio > 0) {
                        monto_calculado = parseFloat(
                            ((valor_campo * 100) / precio).toFixed(2)
                        );
                    } else {
                        monto_calculado = 0;
                    }
                    if (esPrecioSucursal) {
                        dispatch(
                            changeForm(
                                nombreFormulario,
                                `precios_sucursal[${index}].porcent_precio4`,
                                monto_calculado
                            )
                        );
                    } else {
                        dispatch(
                            changeForm(
                                nombreFormulario,
                                "porcent_precio4",
                                monto_calculado
                            )
                        );
                    }
                    break;
                case "precio5":
                    if (precio > 0) {
                        monto_calculado = parseFloat(
                            ((valor_campo * 100) / precio).toFixed(2)
                        );
                    } else {
                        monto_calculado = 0;
                    }
                    if (esPrecioSucursal) {
                        dispatch(
                            changeForm(
                                nombreFormulario,
                                `precios_sucursal[${index}].porcent_precio5`,
                                monto_calculado
                            )
                        );
                    } else {
                        dispatch(
                            changeForm(
                                nombreFormulario,
                                "porcent_precio5",
                                monto_calculado
                            )
                        );
                    }
                    break;
                default:
                    break;
            }
        }
    };

export const setFamiliaValue = (value) => (dispatch) => {
    dispatch(setFamilia(value));
    dispatch(listarProductos());
};

export const setSubFamiliaValue = (value) => (dispatch) => {
    dispatch(setSubFamilia(value));
    dispatch(listarProductos());
};

// limpiar filtros
export const limpiar = () => (dispatch) => {
    dispatch(setFamilia(""));
    dispatch(setSubFamilia(""));
};
// ------------------------------------
// PureActions
// ------------------------------------
export const changePage = (page) => ({
    type: PAGE,
    page,
});

export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});
export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

export const setProducto = (producto) => ({
    type: PRODUCTO,
    producto,
});

export const setFracciones = (fracciones) => ({
    type: FRACCIONES,
    fracciones,
});

export const setEditCreateFraccion = (editCreateFraccion) => ({
    type: EDITCREATEFRACCION,
    editCreateFraccion,
});

const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const setUuidReqProductos = (uuid_req_productos) => ({
    type: UUID_REQ_PRODUCTOS,
    uuid_req_productos,
});

export const setCategorias = (categorias) => ({
    type: CATEGORIAS,
    categorias,
});
export const setCategoriasSeleccionados = (categorias_seleccionados) => ({
    type: CATEGORIAS_SELECCIONADOS,
    categorias_seleccionados,
});
export const setCategoriasExpanded = (categorias_expanded) => ({
    type: CATEGORIAS_EXPANDED,
    categorias_expanded,
});

export const setFamilia = (familia) => ({
    type: FAMILIA,
    familia,
});

export const setSubFamilia = (sub_familia) => ({
    type: SUB_FAMILIA,
    sub_familia,
});
// export const setUnidadesMedida = (unidades_medidas) => ({
//     type: SET_UNIDADES_MEDIDA,
//     unidades_medidas
// })

export const actions = {
    listarProductos,
    inicializarProducto,
    crear,
    eliminar,
    agregarNuevaFraccion,
    selectFraccionAEditar,
    editarFraccion,
    eliminarFraccion,
    buscar,
    sortChange,
    descargarListado,
    // changeUnidadesMedidas,
    // setUnidadesMedida,
    cambioTipoProducto,
    leer,
    getCategorias,
    asignarCategoria,
    setCategoriasExpanded,
    cambioPorcentajePrecio,
    setFamiliaValue,
    setSubFamiliaValue,
    limpiar,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PRODUCTO]: (state, { producto }) => {
        return {
            ...state,
            producto,
        };
    },
    [FRACCIONES]: (state, { fracciones }) => {
        return {
            ...state,
            fracciones,
        };
    },
    [EDITCREATEFRACCION]: (state, { editCreateFraccion }) => {
        return {
            ...state,
            editCreateFraccion,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
    [UUID_REQ_PRODUCTOS]: (state, { uuid_req_productos }) => {
        return {
            ...state,
            uuid_req_productos,
        };
    },
    [SET_UNIDADES_MEDIDA]: (state, { unidades_medidas }) => {
        return {
            ...state,
            unidades_medidas,
        };
    },
    [CATEGORIAS]: (state, { categorias }) => {
        return {
            ...state,
            categorias,
        };
    },
    [CATEGORIAS_SELECCIONADOS]: (state, { categorias_seleccionados }) => {
        return {
            ...state,
            categorias_seleccionados,
        };
    },
    [CATEGORIAS_EXPANDED]: (state, { categorias_expanded }) => {
        return {
            ...state,
            categorias_expanded,
        };
    },
    [FAMILIA]: (state, { familia }) => {
        return {
            ...state,
            familia,
        };
    },
    [SUB_FAMILIA]: (state, { sub_familia }) => {
        return {
            ...state,
            sub_familia,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {
        count: 0,
        page: 1,
        results: [],
    },
    producto: estructuraProducto,
    fracciones: [],
    editCreateFraccion: {
        data: null,
        arriba: null,
        abajo: null,
        tipo: "create",
    },
    page: 1,
    search: "",
    ordering: "",
    loader: false,
    estado_descarga: false,
    uuid_req_productos: "",
    unidades_medidas: [],
    categorias: [],
    categorias_seleccionados: [],
    categorias_expanded: [],
    familia: "",
    sub_familia: "",
};

export default handleActions(reducers, initialState);
