import _ from "lodash";
import { api } from "api";
import uuidv1 from "uuid/v1";
import Swal from "sweetalert2";
import { push } from "react-router-redux";
import { ToastStore } from "react-toasts";
import { handleActions } from "redux-actions";
import { initialize as initializeForm } from "redux-form";
import moment from "moment";

// ------------------------------------
// Constants
// ------------------------------------
export const LOADER = "OR_ONLINE_LOADER";
export const DATA = "OR_ONLINE_DATA";
export const DETALLE = "OR_ONLINE_DETALLE";
export const SORT = "OR_ONLINE_SORT";
export const PAGE = "OR_ONLINE_PAGE";
export const OPENMODAL = "OR_ONLINE_OPENMODAL";
export const MODAL_PAGO = "OR_ONLINE_MODAL_PAGO";
export const MODAL_NOTA = "OR_ONLINE_MODAL_NOTA";
export const MODAL_ASIGNACION = "OR_ONLINE_MODAL_ASIGNACION";
export const BUSCADOR = "OR_BUSCADOR";
export const ESTADO = "OR_ESTADO";
export const DATA_NOTA = "OR_ONLINE_DATA_NOTA";
// EDITAR
export const SET_VER_BUSCADOR = "OR_ONLINE_SET_VER_BUSCADOR";
export const DATA_PRODUCTOS = "OR_ONLINE_DATA_PRODUCTOS";
export const SORT_PRODUCTOS = "OR_ONLINE_SORT_PRODUCTOS";
export const PAGE_PRODUCTOS = "OR_ONLINE_PAGE_PRODUCTOS";
export const SEARCH_PRODUCTOS = "OR_ONLINE_SEARCH_PRODUCTOS";
export const UUID_REQ_PRODUCTOS = "OR_ONLINE_UUID_REQ_PRODUCTOS";
export const PRODUCTOS_SELECCIONADOS = "OR_ONLINE_PRODUCTOS_SELECCIONADOS";

// Filtros
export const FECHA_FINAL = "OR_ONLINE_FECHA_FINAL";
export const FECHA_INICIAL = "OR_ONLINE_FECHA_INICIAL";
export const VENDEDOR = "OR_ONLINE_VENDEDOR";
export const CLIENTE = "OR_ONLINE_CLIENTE";

const TAB = "OR_ONLINE_TAB";

const endpoint = "tienda_linea";
const endpoint_notas = "notas";

// ------------------------------------
// Actions
// ------------------------------------

export const corfirmarPago = (orden) => (dispatch, getStore) => {
    const store = getStore();
    const { detalle } = store.ordenes_online;
    let data = null;
    if (store.form && store.form.OrdenOnlinePagoForm)
        data = store.form.OrdenOnlinePagoForm.values;
    dispatch(setLoader(true));
    api.put(`${endpoint}/${orden}/confirmar_pago_orden`, data)
        .catch((err) => {
            dispatch(setLoader(false));
            Swal(
                "ERROR",
                err.detail || "Ha ocurrido un error, intenta más tarde.",
                "error"
            );
        })
        .then((resp) => {
            if (resp) {
                ToastStore.success("Se ha confirmado el pago ");
                dispatch(setDetalle(resp));
                dispatch(setModalPago(false));
            }
            dispatch(setLoader(false));
        });
};

export const confirmarEnvio = (orden) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const { values } = store.form.OrdenOnlineEntregaForm;
    const params = { info_envio: values.info };
    api.put(`${endpoint}/${orden}/confirmar_envio_orden`, params)
        .catch((err) => {
            dispatch(setLoader(false));
            Swal(
                "ERROR",
                err.detail || "Ha ocurrido un error, intenta más tarde.",
                "error"
            );
        })
        .then((resp) => {
            if (resp) {
                ToastStore.success("Se ha confirmado el envío ");
                dispatch(setDetalle(resp));
                dispatch(setModal(false));
            }
            dispatch(setLoader(false));
        });
};

export const listar =
    (page = 1, pedidos_linea_usuario = 0) =>
        (dispatch, getStore) => {
            dispatch(setLoader(true));
            const store = getStore();
            const {
                ordering,
                search,
                estado,
                fecha_inicial,
                fecha_final,
                vendedor,
                cliente,
                tab,
            } = store.ordenes_online;

            const params = {
                search,
                ordering,
                page,
                anulado: !estado,
                pedidos_linea_usuario,
                fecha_inicial,
                fecha_final,
                vendedor,
                cliente,
                tipo_listado: tab,
            };
            api.get(`${endpoint}`, params)
                .catch((err) => { })
                .then((data) => {
                    if (data) {
                        dispatch(setData(data));
                    }
                    dispatch(setPage(page));
                    dispatch(setLoader(false));
                });
        };

export const cargarOrden =
    (orden, edicion = true) =>
        (dispatch, getStore) => {
            dispatch(setLoader(true));
            api.get(`${endpoint}/${orden}`)
                .catch((err) => {
                    dispatch(setLoader(false));
                })
                .then((resp) => {
                    if (resp) {
                        console.log(resp);
                        dispatch(setDetalle(resp));
                        if (edicion) {
                            dispatch(listarProducto());
                            //  SE FORMATEAN LOS DETALLES
                            const seleccionados = [];
                            resp.detalles.forEach((detalle) => {
                                let item = {
                                    detalle_id: detalle.id,
                                    id: detalle.fraccion.id,
                                    key: uuidv1(),
                                    collapsed: false,
                                    es_vale: false,
                                    producto: detalle.fraccion.producto,
                                    codigo_barras: detalle.fraccion.codigo_barras,
                                    precio: detalle.precio,
                                    desc_monto: detalle.desc_monto,
                                    desc_porcentaje: detalle.desc_porcentaje,
                                    precio_original: detalle.precio_original,
                                    cantidad: detalle.cantidad,
                                    nombre: detalle.fraccion.nombre,
                                    unidad_de_medida:
                                        detalle.fraccion.unidad_de_medida,
                                };
                                item.id_unidad_de_medida = item.producto.a_granel
                                    ? item.unidad_de_medida
                                    : null;
                                seleccionados.push(item);
                            });

                            dispatch(setSeleccionados(seleccionados));
                            dispatch(
                                initializeForm("SeleccionMetodosForm", {
                                    metodo_pago: resp.forma_pago,
                                    metodo_entrega: resp.metodo_envio,
                                })
                            );
                        }
                    }
                    dispatch(setLoader(false));
                });
        };

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.ordenes_online.page;
    dispatch(listar(page));
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const anular = (id, concepto_anulado) => (dispatch, getStore) => {
    const store = getStore();
    const page = store.ordenes_online.page;
    dispatch(setLoader(true));
    api.post(`${endpoint}/anular_orden`, { id, concepto_anulado })
        .catch((err) => {
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "No se ha podido anular la orden, intente más tarde.",
                    "error"
                );
            }
        })
        .then((data) => {
            if (data) {
                ToastStore.success("Orden anulada correctamente");
                dispatch(listar(page));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const estadoChange = (estado) => (dispatch) => {
    dispatch(_setEstado(estado));
    dispatch(listar());
};

export const listarProducto =
    (page = 1) =>
        (dispatch, getStore) => {
            dispatch(setLoader(true));
            const store = getStore();
            const { ordering_productos, search_productos } = store.ordenes_online;
            // SE GENERA EL UUID
            const uuid = uuidv1();
            dispatch(setUuidReqProductos(uuid));
            api.get(`${endpoint}/listado_productos`, {
                page,
                ordering: ordering_productos,
                search: search_productos,
            })
                .catch((err) => { })
                .then((data) => {
                    if (data) {
                        const otroUuid =
                            getStore().ordenes_online.uuid_req_productos;
                        if (otroUuid === uuid) {
                            dispatch(setDataProductos(data));
                            dispatch(setPageProductos(page));
                            dispatch(setLoader(false));
                        }
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        };

export const buscarProducto = (search) => (dispatch) => {
    dispatch(setBuscadorProductos(search));
    dispatch(listarProducto());
};

export const sortChangeProducto =
    (sortName, sortOrder) => (dispatch, getStore) => {
        if (sortOrder === "asc") {
            dispatch(setSortProductos(sortName));
        } else {
            dispatch(setSortProductos(`-${sortName}`));
        }
        const store = getStore();
        const page_productos = store.ordenes_online.page_productos;
        dispatch(listarProducto(page_productos));
    };

export const quitar = (index) => (dispatch, getStore) => {
    const store = getStore();
    let seleccionados = _.cloneDeep(store.ordenes_online.seleccionados);
    const item = seleccionados[index];
    if (item) {
        seleccionados.splice(index, 1);
        dispatch(setSeleccionados(seleccionados));
    }
};

export const restar = (index) => (dispatch, getStore) => {
    const store = getStore();
    let seleccionados = _.cloneDeep(store.ordenes_online.seleccionados);
    let item = seleccionados[index];
    if (item) {
        item.cantidad -= 1;
        if (item.cantidad > 0) {
            //  Se calcula el precio del producto con el descuento
            if (item.desc_porcentaje) {
                item.desc_monto = parseFloat(
                    (
                        item.precio_original *
                        (item.desc_porcentaje / 100)
                    ).toFixed(2)
                );
            } else {
                item.desc_monto = 0;
            }
            seleccionados.splice(index, 1, item);
        } else {
            seleccionados.splice(index, 1);
        }
        dispatch(setSeleccionados(seleccionados));
    }
};

export const sumar = (index) => (dispatch, getStore) => {
    const store = getStore();
    let seleccionados = _.cloneDeep(store.ordenes_online.seleccionados);
    let item = seleccionados[index];
    if (item) {
        item.cantidad += 1;
        //  Se calcula el precio del producto con el descuento
        if (item.desc_porcentaje) {
            item.desc_monto = parseFloat(
                (item.precio_original * (item.desc_porcentaje / 100)).toFixed(2)
            );
        } else {
            item.desc_monto = 0;
        }
        seleccionados.splice(index, 1, item);
        dispatch(setSeleccionados(seleccionados));
    }
};

export const ingresoCantidad = (index, cantidad) => (dispatch, getStore) => {
    const store = getStore();
    let seleccionados = _.cloneDeep(store.ordenes_online.seleccionados);
    let item = seleccionados[index];
    if (item) {
        item.cantidad = cantidad;

        if (item.cantidad > 0) {
            //  Se calcula el precio del producto con el descuento
            if (item.desc_porcentaje) {
                item.desc_monto = parseFloat(
                    (
                        item.precio_original *
                        (item.desc_porcentaje / 100)
                    ).toFixed(2)
                );
            } else {
                item.desc_monto = 0;
            }
            seleccionados.splice(index, 1, item);
        } else {
            seleccionados.splice(index, 1);
        }
        dispatch(setSeleccionados(seleccionados));
    }
};

export const agregar = (producto_seleccionado) => (dispatch, getStore) => {
    const store = getStore();
    let seleccionados = _.cloneDeep(store.ordenes_online.seleccionados);
    let producto = _.cloneDeep(producto_seleccionado);
    let item = {
        id: producto.id,
        key: uuidv1(),
        collapsed: false,
        es_vale: false,
        producto: producto.producto,
        codigo_barras: producto.codigo_barras,
        precio: producto.precio_online,
        desc_monto: 0,
        desc_porcentaje: 0,
        precio_original: producto.precio_online,
        cantidad: 1,
        nombre: producto.nombre,
        unidad_de_medida: producto.unidad_de_medida,
    };
    item.id_unidad_de_medida = item.producto.a_granel
        ? item.unidad_de_medida
        : null;
    seleccionados.push(item);
    dispatch(setSeleccionados(seleccionados));
};

export const editarOrden = () => (dispatch, getStore) => {
    const store = getStore();
    const { seleccionados, detalle } = store.ordenes_online;
    const { values } = store.form.SeleccionMetodosForm;
    const { metodos_pago, metodos_entrega } = store.usuario;

    dispatch(setLoader(true));

    const item_pago = _.find(metodos_pago, function (item) {
        return item.prefijo === values.metodo_pago;
    });
    const item_entrega = _.find(metodos_entrega, function (item) {
        return item.nombre === values.metodo_entrega;
    });

    let data = {
        productos: seleccionados,
        metodo_entrega: item_entrega.nombre,
        total_envio: item_entrega.precio_empresa,
        metodo_pago: item_pago.prefijo,
    };
    if (seleccionados.length) {
        if (item_pago && item_entrega) {
            api.put(`${endpoint}/${detalle.id}`, data)
                .catch((err) => {
                    Swal(
                        "Error",
                        err.detail ||
                        "Error al editar la orden, intenta más tarde",
                        "error"
                    );
                })
                .then((resp) => {
                    ToastStore.success("Se ha editado la orden exitosamente");
                    dispatch(push("/ordenes_online"));
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        } else {
            if (!item_pago) {
                Swal("Error", "El método de pago es requerido", "error");
            } else {
                Swal("Error", "El método de entrega es requerido", "error");
            }
        }
    } else {
        Swal("Error", "La orden no tiene productos", "error");
    }
};

export const cambioBuscador = () => (dispatch, getStore) => {
    const store = getStore();
    const ver_buscador = store.ordenes_online.ver_buscador;
    dispatch(setVerBuscador(!ver_buscador));
};

export const guardarNota = (movimiento) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const { values } = store.form.NotaOrdenOnlineForm;
    const data = {
        nota: values.nota,
        movimiento_id: movimiento,
        tipo: values.tipo,
    };
    api.post(endpoint_notas, data)
        .catch((err) => {
            dispatch(setLoader(false));
            Swal(
                "ERROR",
                err.detail || "Ha ocurrido un error, intenta más tarde.",
                "error"
            );
        })
        .then((resp) => {
            dispatch(setLoader(false));
            if (resp) {
                ToastStore.success("Se ha creado la nota existosamente");
                dispatch(setModalNota(false));
                dispatch(cargarOrden(movimiento, false));
            }
        });
};

export const cargarNota = (item) => (dispatch) => {
    dispatch(setDataNota(item));
    dispatch(setModalNota(true));

    if (item) {
        dispatch(
            initializeForm("NotaOrdenOnlineForm", {
                nota: item.texto,
                tipo: item.tipo.toString(),
            })
        );
    }
};

export const actualizarNota = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const { values } = store.form.NotaOrdenOnlineForm;
    const { detalle, data_nota } = store.ordenes_online;
    const data = { nota: values.nota, tipo: values.tipo };
    api.put(`${endpoint_notas}/${data_nota.id}`, data)
        .catch((err) => {
            dispatch(setLoader(false));
            Swal(
                "ERROR",
                err.detail || "Ha ocurrido un error, intenta más tarde.",
                "error"
            );
        })
        .then((resp) => {
            dispatch(setLoader(false));
            if (resp) {
                ToastStore.success("Se ha actualizado la nota existosamente");
                dispatch(setModalNota(false));
                dispatch(cargarOrden(detalle.id, false));
            }
        });
};

export const eliminarNota = (id) => (dispatch, getStore) => {
    const store = getStore();
    Swal({
        title: "¿Eliminar?",
        text: "¡No podrá revertir esta acción!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, eliminar!",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            const { detalle } = store.ordenes_online;
            api.eliminar(`${endpoint_notas}/${id}`)
                .catch((err) => {
                    dispatch(setLoader(false));
                    Swal(
                        "ERROR",
                        "Ha ocurrido un error, intenta más tarde.",
                        "error"
                    );
                })
                .then((resp) => {
                    dispatch(setLoader(false));
                    if (resp) {
                        ToastStore.success(
                            "La nota fue eliminada existosamente"
                        );
                        dispatch(cargarOrden(detalle.id, false));
                    }
                });
        }
    });
};

export const asignarSucursal = () => (dispatch, getStore) => {
    const store = getStore();
    const { detalle } = store.ordenes_online;
    const { values } = store.form.AsignarSucursalForm;

    api.put(`${endpoint}/${detalle.id}/asignar_sucursal_orden`, {
        sucursal: values.sucursal,
    })
        .catch((err) => {
            dispatch(setLoader(false));
            Swal(
                "ERROR",
                err.detail || "Ha ocurrido un error, intenta más tarde.",
                "error"
            );
        })
        .then((resp) => {
            dispatch(setLoader(false));
            if (resp) {
                ToastStore.success("La sucursal fue asignada existosamente");
                dispatch(setDetalle(resp));
                dispatch(setModalAsignacion(false));
            }
        });
};

export const marcarEnviado =
    (data, es_proceso = false, onClose = () => { }) =>
        (dispatch, getStore) => {
            const store = getStore();
            const { detalle } = store.ordenes_online;

            // console.log(data);
            // console.log(detalle);

            const endpoint_enviar = es_proceso
                ? "marcar_enviado"
                : "marcar_entregado";

            if (!es_proceso) {
                data.fecha_entrega = moment(data.fecha_entrega).format(
                    "YYYY-MM-DD"
                );
            }

            dispatch(setLoader(true));
            api.put(`${endpoint}/${detalle.id}/${endpoint_enviar}`, { data })
                .then((resp) => {
                    dispatch(setLoader(false));
                    if (resp) {
                        ToastStore.success("La orden fue marcada como enviada");
                        onClose();
                        dispatch(cargarOrden(detalle.id, false));
                    }
                })
                .catch((err) => {
                    dispatch(setLoader(false));
                    Swal(
                        "ERROR",
                        err.detail || "Ha ocurrido un error, intenta más tarde.",
                        "error"
                    );
                });
        };

export const cambiarCantidad = (data = false, onClose = () => { }, selectedRow) => 
        (dispatch, getStore) => {
            const store = getStore();
            const { detalle } = store.ordenes_online;

            const updatedData = [{ id: selectedRow.id, value: data }];
            const endpoint_cambiar_cantidad = "update_quantity"

            dispatch(setLoader(true));
            api.put(`${endpoint}/${detalle.id}/${endpoint_cambiar_cantidad}`, { updatedData })
                .then((resp) => {
                    dispatch(setLoader(false));
                    if (resp) {
                        ToastStore.success("La cantidad del producto fue cambiada");
                        onClose();
                        dispatch(cargarOrden(detalle.id, false));
                    }
                })
                .catch((err) => {
                    dispatch(setLoader(false));
                    Swal(
                        "ERROR",
                        err.detail || "Ha ocurrido un error, intenta más tarde.",
                        "error"
                    );
                });
        };


export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const reporte_articulos_venta = store.reporte_articulos_venta;
    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(reporte_articulos_venta.fecha_final);
        // if (fecha_final >= fecha_inicial)
        dispatch(setFechaInicial(value));
    } else {
        const fecha_inicial = new Date(reporte_articulos_venta.fecha_inicial);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) dispatch(setFechaFinal(value));
    }
    dispatch(listar());
};

export const setVendedor = (vendedor) => (dispatch) => {
    dispatch(_setVendedor(vendedor));
    dispatch(listar());
};

export const setCliente = (cliente) => (dispatch) => {
    dispatch(_setCliente(cliente));
    dispatch(listar());
};

export const changeTab = (tab) => (dispatch) => {
    dispatch(setTab(tab));
    dispatch(listar());
};
// ------------------------------------
// PureActions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setDetalle = (detalle) => ({
    type: DETALLE,
    detalle,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});

export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

export const setModal = (modal) => ({
    type: OPENMODAL,
    modal,
});

export const setModalPago = (modal_pago) => ({
    type: MODAL_PAGO,
    modal_pago,
});
export const setModalNota = (modal_nota) => ({
    type: MODAL_NOTA,
    modal_nota,
});
export const setModalAsignacion = (modal_asignacion) => ({
    type: MODAL_ASIGNACION,
    modal_asignacion,
});
export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});

export const _setEstado = (estado) => ({
    type: ESTADO,
    estado,
});

export const setFechaFinal = (fecha_final) => ({
    type: FECHA_FINAL,
    fecha_final,
});

export const setFechaInicial = (fecha_inicial) => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});

export const _setVendedor = (vendedor) => ({
    type: VENDEDOR,
    vendedor,
});

export const _setCliente = (cliente) => ({
    type: CLIENTE,
    cliente,
});

// EDITAR
export const setVerBuscador = (ver_buscador) => ({
    type: SET_VER_BUSCADOR,
    ver_buscador,
});
export const setDataProductos = (data_productos) => ({
    type: DATA_PRODUCTOS,
    data_productos,
});
export const setSortProductos = (ordering_productos) => ({
    type: SORT_PRODUCTOS,
    ordering_productos,
});
export const setPageProductos = (page_productos) => ({
    type: PAGE_PRODUCTOS,
    page_productos,
});
export const setBuscadorProductos = (search_productos) => ({
    type: SEARCH_PRODUCTOS,
    search_productos,
});

export const setUuidReqProductos = (uuid_req_productos) => ({
    type: UUID_REQ_PRODUCTOS,
    uuid_req_productos,
});
export const setSeleccionados = (seleccionados) => ({
    type: PRODUCTOS_SELECCIONADOS,
    seleccionados,
});
export const setDataNota = (data_nota) => ({
    type: DATA_NOTA,
    data_nota,
});

export const setTab = (tab) => ({
    type: TAB,
    tab,
});

export const actions = {
    listar,
    sortChange,
    cargarOrden,
    corfirmarPago,
    confirmarEnvio,
    setModal,
    setModalPago,
    setModalNota,
    buscar,
    anular,
    estadoChange,
    guardarNota,
    cargarNota,
    actualizarNota,
    eliminarNota,
    setModalAsignacion,
    asignarSucursal,
    // Editar
    sumar,
    restar,
    quitar,
    agregar,
    editarOrden,
    listarProducto,
    buscarProducto,
    cambioBuscador,
    ingresoCantidad,
    sortChangeProducto,

    // filtros
    setFecha,
    setVendedor,
    setCliente,

    // Cambio de estado del pedido
    marcarEnviado,
    changeTab,

    //cambiar la cantidad de producto
    cambiarCantidad,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DETALLE]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [OPENMODAL]: (state, { modal }) => {
        return {
            ...state,
            modal,
        };
    },
    [MODAL_PAGO]: (state, { modal_pago }) => {
        return {
            ...state,
            modal_pago,
        };
    },
    [MODAL_NOTA]: (state, { modal_nota }) => {
        return {
            ...state,
            modal_nota,
        };
    },
    [MODAL_ASIGNACION]: (state, { modal_asignacion }) => {
        return {
            ...state,
            modal_asignacion,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [ESTADO]: (state, { estado }) => {
        return {
            ...state,
            estado,
        };
    },
    // EDITAR
    [SET_VER_BUSCADOR]: (state, { ver_buscador }) => {
        return {
            ...state,
            ver_buscador,
        };
    },
    [DATA_PRODUCTOS]: (state, { data_productos }) => {
        return {
            ...state,
            data_productos,
        };
    },
    [SORT_PRODUCTOS]: (state, { ordering_productos }) => {
        return {
            ...state,
            ordering_productos,
        };
    },
    [PAGE_PRODUCTOS]: (state, { page_productos }) => {
        return {
            ...state,
            page_productos,
        };
    },
    [SEARCH_PRODUCTOS]: (state, { search_productos }) => {
        return {
            ...state,
            search_productos,
        };
    },
    [UUID_REQ_PRODUCTOS]: (state, { uuid_req_productos }) => {
        return {
            ...state,
            uuid_req_productos,
        };
    },
    [PRODUCTOS_SELECCIONADOS]: (state, { seleccionados }) => {
        return {
            ...state,
            seleccionados,
        };
    },
    [DATA_NOTA]: (state, { data_nota }) => {
        return {
            ...state,
            data_nota,
        };
    },
    [FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [VENDEDOR]: (state, { vendedor }) => {
        return {
            ...state,
            vendedor,
        };
    },
    [CLIENTE]: (state, { cliente }) => {
        return {
            ...state,
            cliente,
        };
    },
    [TAB]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    loader: false,
    page: 1,
    ordering: "-creado",
    detalle: {},
    modal: false,
    modal_pago: false,
    modal_nota: false,
    modal_asignacion: false,
    search: "",
    estado: true,
    data_nota: null,
    // EDITAR
    data_productos: {},
    page_productos: 1,
    ordering_productos: "-creado",
    search_productos: "",
    ver_buscador: false,
    uuid_req_productos: "",
    seleccionados: [],

    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
    vendedor: "",
    cliente: "",
    tab: 10,
};

export default handleActions(reducers, initialState);
