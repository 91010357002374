import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

//import fileIcon from "../../../../../assets/img/upload.png";

import "./FileUploader.css";

export default function FileMultiUploader(props) {
    const [fileName, setFileName] = React.useState(undefined);
    const input = useRef();
    const disabled = props.disabled || false;

    useEffect(() => {
        if (typeof props.file === "string") {
            setFileName(props.file);
        } else if (props.file instanceof File) {
            setFileName(props.file.name);
        }
    }, [props.file]);

    const onFileChange = (e, file) => {
        file = file || e.target.files[0];
        const fileType = ".jpg|.jpeg|.png|.doc|.docx|.pdf";
        if (file && !!file.type.match(fileType)) {
            props.onChange(file);
        }
    };
    const onClick = () => {
        if (!disabled) input.current.click();
    };

    const onDrop = (e) => {
        e.preventDefault();
        if (!disabled) onFileChange(e, e.dataTransfer.files[0]);
    };
    const onDragOver = (e) => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <div
                key="label"
                className="upload-container"
                onDrop={onDrop}
                onDragOver={onDragOver}
            >
                <input
                    type="file"
                    accept="image/*,.doc,.docx,.pdf"
                    onChange={onFileChange}
                    style={{ display: "none" }}
                    ref={input}
                ></input>
                <br className="d-none d-sm-flex" />
                <br />
                {props.file ? (
                    <div className="flex-1 d-flex flex-row justify-content-center align-items-center px-2 mb-2">
                        <p className="uploaded-file-text m-0">{fileName}</p>
                    </div>
                ) : (
                    <p className="upload-text m-0 pb-2">
                        Arrastra o haz clic en el botón para cargar documento.
                    </p>
                )}
                <div className="d-flex justify-content-center">
                    <button
                        className={`btn ${
                            props.file ? "btn btn-uploader" : "btn btn-uploader"
                        }`}
                        type="button"
                        disabled={disabled}
                        onClick={onClick}
                    >
                        {props.file ? "Volver a cargar" : "Haz click aquí"}
                    </button>
                </div>
                <br className="d-none d-sm-flex" />
                <br />
            </div>
        </React.Fragment>
    );
}

FileMultiUploader.propTypes = {
    onChange: PropTypes.func.isRequired,
};
