import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import LoadMask from "Utils/LoadMask/LoadMask";
import { DropDownMenu, DropDownDirection } from "react-dropdown-advanced";
import { icons } from "icons";
import { Collapse } from "react-collapse";
import DatePicker from "Utils/DatePicker";
import moment from "moment";
import { Field, reduxForm } from "redux-form";
import {
    renderTextArea,
    renderSearchSelect,
} from "Utils/renderField/renderField";
import { api } from "api";
import useAsyncSearch from "../../../hooks/useAsyncSearch";
import { Async } from "react-select";

export const listaProveedores = [];
const listadoClientes = [];
export class ListadoAccion extends Component {
    static propTypes = {
        titulo: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
            .isRequired,
        buscar: PropTypes.func.isRequired,
        listar: PropTypes.func.isRequired,
        sortChange: PropTypes.func.isRequired,
        data: PropTypes.object.isRequired,
        llaves: PropTypes.array.isRequired,
        loader: PropTypes.bool.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = { collapse: false };
    }

    render() {
        // state
        const {
            proveedor_cliente,
            titulo,
            buscar,
            data,
            loader,
            llaves,
            acciones,
            itemsAyuda,
            collapse = true,
            animationCollapse = true,
            filtros_fecha = false,
            valores_filtros_fecha = false,
            grid_filtros = false,
            setFiltros = () => {},
            buscarCodigo = () => {},
            codigo_doc = "",
            setProveedorCliente = () => {},
            tipoDevolucion = "",
        } = this.props;
        // bind
        const { onClickOtrasAcciones, actionAyuda } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;

        const { getOptions: getProveedores } = useAsyncSearch(
            "proveedores",
            listaProveedores
        );

        const { getOptions: getClientes } = useAsyncSearch(
            "clientes",
            listadoClientes
        );

        return (
            <div className="grid-container mt0 d-flex justify-content-around flex-column ">
                <div className={`${acciones ? "d-flex flex-row" : ""}`}>
                    <div className="col-12 mb-2 d-flex flex1 d-flex justify-content-around flex-column mt">
                        <div className="d-flex flex-column flex-lg-row">
                            <div
                                className="d-flex flex-column flex-lg-row"
                                style={{ flex: "5" }}
                            >
                                <h2
                                    style={{
                                        color: "black",
                                        fontSize: "1.5rem",
                                        fontWeight: "bold",
                                    }}
                                    className="flex-1"
                                >
                                    {titulo}
                                </h2>

                                {collapse && (
                                    <button
                                        type="button"
                                        className="button-link"
                                        onClick={() =>
                                            this.setState({
                                                collapse: !this.state.collapse,
                                            })
                                        }
                                    >
                                        {this.state.collapse
                                            ? "Ocultar"
                                            : "Mostrar"}{" "}
                                        tabla
                                    </button>
                                )}
                            </div>
                            {!grid_filtros && (
                                <div className="flex-3">{buscar()}</div>
                            )}
                        </div>
                    </div>
                    {filtros_fecha && valores_filtros_fecha && (
                        <div className="border-bottom border-top mb-4 pt-3 pb-2 pl-2">
                            {grid_filtros && (
                                <div className="d-flex flex-column flex-lg-row flex-2 mb-4">
                                    <div className="flex-1 align-items-end pr-5 d-flex">
                                        <div style={{ flex: 7 }}>
                                            <label htmlFor="">
                                                Buscar por{" "}
                                                {tipoDevolucion == 1
                                                    ? "proveedor"
                                                    : "cliente"}
                                            </label>
                                            <Async
                                                key={`async-${tipoDevolucion}`}
                                                className="form-control"
                                                labelKey="nombre"
                                                valueKey="id"
                                                searchPromptText="Escriba para buscar"
                                                loadOptions={
                                                    tipoDevolucion == 1
                                                        ? getProveedores
                                                        : tipoDevolucion == 2
                                                        ? getClientes
                                                        : () => {}
                                                }
                                                placeholder={"Usuario"}
                                                value={proveedor_cliente}
                                                onChange={(e) => {
                                                    setProveedorCliente(e);
                                                }}
                                            />
                                            {/* <Field
                                                name="proveedor_cliente"
                                                component={renderSearchSelect}
                                                loadOptions={getProveedores}
                                                labelKey="nombre"
                                                valueKey="id"
                                                className="form-control"
                                            /> */}
                                        </div>
                                        <div className="flex-1"></div>
                                    </div>

                                    <div className="flex-1">
                                        <div className="flex-1 align-items-end pr-5 d-flex">
                                            <div style={{ flex: 7 }}>
                                                <label htmlFor="">
                                                    Buscar por codigo de
                                                    documento
                                                </label>
                                                <input
                                                    placeholder={"Buscar"}
                                                    type={"text"}
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        buscarCodigo(
                                                            e.target.value
                                                        );
                                                    }}
                                                    value={codigo_doc}
                                                />
                                            </div>
                                            <div className="flex-1"></div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="d-flex flex-column flex-lg-row flex-2">
                                <div className="flex-1">
                                    <div className="flex-1 d-flex pr-5">
                                        <div
                                            className="d-flex flex-column"
                                            style={{ flex: 16 }}
                                        >
                                            <label htmlFor="">
                                                Fecha inicial
                                            </label>
                                            <DatePicker
                                                value={
                                                    valores_filtros_fecha.fecha_compra_inicial
                                                }
                                                maxDate={
                                                    new Date(
                                                        moment(
                                                            moment().format(
                                                                "YYYY-MM-DD"
                                                            ),
                                                            "YYYY-MM-DD"
                                                        )
                                                    )
                                                }
                                                onChange={(date) => {
                                                    valores_filtros_fecha.setFecha(
                                                        "Inicial",
                                                        date
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="flex-1"></div>
                                    </div>
                                </div>
                                <div className="form-group flex-1 d-flex flex-column ml-0 ml-lg-5">
                                    <div className="flex-1">
                                        <div className="flex-1 d-flex pr-5">
                                            <div
                                                className="d-flex flex-column"
                                                style={{ flex: 7 }}
                                            >
                                                <label htmlFor="">
                                                    Fecha final
                                                </label>
                                                <DatePicker
                                                    value={
                                                        valores_filtros_fecha.fecha_compra_final
                                                    }
                                                    minDate={
                                                        new Date(
                                                            moment(
                                                                valores_filtros_fecha.fecha_compra_inicial,
                                                                "YYYY-MM-DD"
                                                            )
                                                        )
                                                    }
                                                    onChange={(date) => {
                                                        valores_filtros_fecha.setFecha(
                                                            "Final",
                                                            date
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="flex-1"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex flex-column flex-lg-row flex-2 justify-content-end mt-1 mb-2">
                                <button
                                    type="button"
                                    className="btn btn-primary px-4"
                                    // disabled={submitting}
                                    onClick={() => setFiltros(tipoDevolucion)}
                                >
                                    Aplicar filtros
                                </button>
                            </div>
                        </div>
                    )}
                    {acciones && (
                        <div className="flex07 d-lg-flex d-none align-items-end justify-content-center">
                            <button
                                className="btn btn-tertiary-drop fnt-14 d-flex align-items-center justify-content-center mb-3"
                                style={{ position: "relative" }}
                            >
                                <span className="mr">Otras acciones</span>
                                <em className="ml-2 fa fa-caret-down"></em>
                                {acciones.length > 0 && (
                                    <DropDownMenu
                                        items={acciones}
                                        onClick={onClickOtrasAcciones}
                                        direction={DropDownDirection.DownLeft}
                                        className="fnt-10"
                                    />
                                )}
                            </button>
                        </div>
                    )}
                    {itemsAyuda && actionAyuda && (
                        <div style={{ marginTop: "10px", marginRight: "10px" }}>
                            <button
                                className="btn btn-ayuda btn-ayuda-flotante fnt-16 d-flex align-items-center justify-content-center"
                                style={{ width: "2rem", height: "2rem" }}
                            >
                                <img
                                    src={icons.ayuda}
                                    alt="ayuda"
                                    title="Ayuda"
                                    style={{ margin: "0", height: "1.1rem" }}
                                />
                                <DropDownMenu
                                    items={itemsAyuda}
                                    onClick={actionAyuda}
                                    direction={DropDownDirection.DownLeft}
                                    className="fnt-10"
                                />
                            </button>
                        </div>
                    )}
                </div>
                <div>
                    <div
                        className={`${
                            animationCollapse ? "animation-collapse" : ""
                        }`}
                    >
                        <Collapse
                            isOpened={!collapse ? true : this.state.collapse}
                        >
                            <LoadMask loading={loader} light blur radius>
                                <BootstrapTable
                                    data={loader ? [] : data.results}
                                    remote
                                    pagination
                                    hover
                                    fetchInfo={{ dataTotalSize: data.count }}
                                    options={options}
                                    bodyContainerClass="r-encajar-listado-productos"
                                >
                                    <TableHeaderColumn
                                        width="2px"
                                        isKey
                                        dataField="id"
                                        dataFormat={(cell) => <span></span>}
                                    ></TableHeaderColumn>

                                    {llaves.map((llave) => {
                                        return (
                                            <TableHeaderColumn
                                                key={llave.nombre}
                                                width={llave.width}
                                                dataField={llave.nombre}
                                                dataFormat={llave.format}
                                                dataSort={llave.sort}
                                                dataAlign={llave.align}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                            >
                                                {llave.caption}
                                            </TableHeaderColumn>
                                        );
                                    })}
                                </BootstrapTable>
                            </LoadMask>
                        </Collapse>
                    </div>
                </div>
            </div>
        );
    }
}
