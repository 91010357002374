import React from "react";
import Checkbox from "../Checkbox";

export const CheckboxContenedor = ({ nombre_permiso, permisos }) => {
    return (
        <React.Fragment>
            <div className="container_permiso w-100">
                {Object.keys(permisos).map((permiso, index) => {
                    const { titulo, checked, subItems } = permisos[permiso];
                    if (Object.keys(subItems).length > 0) {
                        return (
                            <div className="d-flex flex-column" key={index}>
                                <Checkbox
                                    titulo={titulo}
                                    checked={checked}
                                    subitem={1}
                                />
                                <div className="d-flex flex-column pl-4">
                                    {Object.keys(subItems).map(
                                        (subItem, index) => {
                                            const { titulo, checked } =
                                                subItems[subItem];
                                            return (
                                                <div
                                                    className="d-flex flex-row w-100"
                                                    key={index}
                                                >
                                                    <Checkbox
                                                        titulo={titulo}
                                                        checked={checked}
                                                        subitem={2}
                                                    />
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        );
                    }
                    return (
                        <Checkbox
                            titulo={titulo}
                            checked={checked}
                            disabled={true}
                            key={index}
                        />
                    );
                })}
            </div>
        </React.Fragment>
    );
};
