import React, { Component } from "react";
import PropTypes from "prop-types";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "../../Util/Acciones/Acciones";
import EmpresaForm from "./EmpresaEditarForm";
import { SucursalesListado } from "./Sucursales/Listado";
import { UsuariosListado } from "./Usuarios/Listado";
import TituloBorde from "../../Utils/TituloBorde/TituloBorde";
import { Link } from "react-router-dom";

class EmpresaEditar extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        empresa: PropTypes.object.isRequired,
    };

    static defaultProps = {
        loading: true,
        empresa: {},
    };

    constructor(props) {
        super(props);
        this.state = {
            certificado: null,
            llave: null,
        };
    }

    componentWillMount() {
        this.props.asignarCategoria([]);
        this.props.setCategoriasExpanded([]);
        const id = this.props.match.params.id;
        this.props.loadEmpresaEdit(id);
    }

    handleselectedFileCertificado = (event) => {
        this.setState({
            certificado: event.target.files[0],
        });
    };
    handleselectedFileLlave = (event) => {
        this.setState({
            llave: event.target.files[0],
        });
    };
    onChangeArchivo = (tipo, url) => {
        if (tipo == "llave") this.setState({ llave: url });
        else this.setState({ certificado: url });
    };

    editarEmpresa = () => {
        const archivos = [
            { name: "certificado", file: this.state.certificado },
            { name: "llave", file: this.state.llave },
        ];

        this.props.submit(archivos);
    };

    render() {
        // state
        const {
            empresa,
            loading,
            formato_factura,
            datos_ejemplo,
            categorias,
            categorias_seleccionados,
            categorias_expanded,
            print_state,
        } = this.props;
        const { certificado, llave } = this.state;

        // bind
        const {
            cambioFormatoFactura,
            eliminarUsuario,
            asignarCategoria,
            setCategoriasExpanded,
            cambioTipoFormatoFactura,
            print,
        } = this.props;

        const dummy = () => {
            console.log("dummy call");
        };

        return (
            <div className="row mt-4">
                <TituloBorde contenido={empresa.nombre} className="my-3" />
                <div className="card py-4 col-lg-12 col-md-12 col-sm-12">
                    {loading && <p>Cargando...</p>}
                    <EmpresaForm
                        empresa={empresa}
                        print_state={print_state}
                        datos_ejemplo={datos_ejemplo}
                        formato_factura={formato_factura}
                        certificado={certificado}
                        llave={llave}
                        onSubmit={this.editarEmpresa}
                        cambioFormatoFactura={cambioFormatoFactura}
                        handleselectedFileLlave={this.handleselectedFileLlave}
                        handleselectedFileCertificado={
                            this.handleselectedFileCertificado
                        }
                        onChangeArchivo={this.onChangeArchivo}
                        categorias={categorias}
                        categorias_seleccionados={categorias_seleccionados}
                        categorias_expanded={categorias_expanded}
                        asignarCategoria={asignarCategoria}
                        setCategoriasExpanded={setCategoriasExpanded}
                        cambioTipoFormatoFactura={cambioTipoFormatoFactura}
                        print={print}
                    />
                    <div
                        className="d-flex justify-content-between border-bottom blue mx-3 mb-4"
                        style={{ marginTop: 80 }}
                    >
                        <h3
                            className="contenido-1 txt-bold black"
                            style={{ marginBottom: 0 }}
                        >
                            Usuarios
                        </h3>

                        <Link
                            className="btn btn-primary mb-1"
                            to={`/empresas/${empresa.id}/usuarios/crear`}
                        >
                            + Agregar Usuario
                        </Link>
                    </div>
                    {/* <h1>Usuarios</h1> */}
                    <UsuariosListado
                        data={empresa.usuarios}
                        idEmpresa={empresa.id}
                        eliminar={eliminarUsuario}
                    />

                    <div
                        className="d-flex justify-content-between border-bottom blue mx-3 mb-4"
                        style={{ marginTop: 80 }}
                    >
                        <h3
                            className="contenido-1 txt-bold black"
                            style={{ marginBottom: 0 }}
                        >
                            Sucursales
                        </h3>

                        <Link
                            className="btn btn-primary mb-1"
                            to={`/empresas/${empresa.id}/sucursales/crear`}
                        >
                            + Agregar Sucursal
                        </Link>
                    </div>
                    {empresa && empresa.utiliza_fel && (
                        <span className="alert alert-primary mx-3" role="alert">
                            {" "}
                            <em className="fa fa-info-circle"></em> Recuerda
                            completar los datos para FEL en las sucursales
                        </span>
                    )}
                    <br />
                    <SucursalesListado
                        data={empresa.sucursales}
                        idEmpresa={empresa.id}
                        loader={false}
                        eliminar={dummy}
                    />
                    <br />
                </div>
            </div>
        );
    }
}

export default EmpresaEditar;
