import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './acciones.css';
import Swal from 'sweetalert2';

// Iconos
import editar_icono from "../../../../../assets/img/iconos/editar-icono.svg";
import eliminar_icono from "../../../../../assets/img/iconos/eliminar-icono.svg";

class Acciones extends Component {
  constructor(props) {
    super(props);
    this.state = {redirect: false, url: ''};
    this.redirect = this.redirect.bind(this);
    this.eliminar = this.eliminar.bind(this);
    this.editar = this.editar.bind(this);
  }
  redirect(url) {
    return () => {
      this.setState({url});
      this.setState({redirect: true});
    };
  }
  eliminar(id) {
    return () => {
      Swal({
        title: '¿Eliminar?',
        text: '¡No podrá revertir esta acción!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: '¡Sí, eliminar!',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.props.eliminar(id);
        }
      });
    }
  }

    anular(id) {
        return () => {
            Swal({
                title: "¿Anular?",
                text: "Ingresa el motivo de la anulación",
                input: "textarea",
                inputAttributes: {
                    autocapitalize: "off"
                },
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "¡Sí, anular!",
                cancelButtonText: "No, cancelar",
                reverseButtons: true,
                preConfirm: (txtMotivo) => {
                    if (!txtMotivo) {
                        Swal.showValidationError(
                            "Campo requerido"
                        )
                    }

                }
            }).then((result) => {
                if (result.value) {
                    const motivo = result.value;
                    this.props.anular(id, motivo);
                }
            });
        }
    }

  editar(id) {
    return () => {
     this.props.editar(id);
    }
  }

  render() {
    const { id, ver, editar, editar_activo, eliminar, adicional, anular, anular_activo, id_ver, id_adicional, datos_adicional } = this.props;
    if (this.state.redirect){
      return (<Redirect to={`${this.state.url}/${id}`} />);
    }
    let offset = 0;
    if (ver !== undefined){
      offset += 1;
    }
    if (editar !== undefined){
      offset += 1;
    }
    if (eliminar !== undefined){
      offset += 1;
    }
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          {(adicional !== undefined) && adicional(id_adicional ? id_adicional : id, datos_adicional ? datos_adicional:null) }
          {(ver !== undefined) && (
            <Link to={`${ver}/${ id_ver ? id_ver : id}/`} ><img className="action-img" title="Ver" src={require("../../../../../assets/img/icons/ver.png")} alt="Ver" width="17"/></Link>
          )}
          {(editar !== undefined) && ((typeof editar) === "string") && (
              <span>
                    {
                        editar_activo !== false ? (
                            <Link to={`${editar}/${id}/`} ><img className="action-img" title="Editar" src={editar_icono} alt="Editar" width="17" /></Link>
                        ): (
                            <img className="action-img" style={{ opacity:'0.5', cursor:'not-allowed'}} title="Editar" src={editar_icono} alt="Editar" width="17" />
                        )
                    }
              </span>
          )}
          {(editar !== undefined) && ((typeof editar) !== "string") && (
            <img onClick={this.editar(id)} className="action-img" title="Editar" src={editar_icono} alt="Editar" width="17" />
          )}
          {(eliminar !== undefined) && (
            <img onClick={this.eliminar(id)} className="action-img" title="Eliminar" src={eliminar_icono} alt="Eliminar" width="17"/>
          )}
          {(anular !== undefined) && (
              <span>
                  {
                      anular_activo !== false ? (
                          <img onClick={this.anular(id)} className="action-img" style={{width: '20px'}} title="Anular" src={require("../../../../../assets/img/icons/anular.png")} alt="Anular"/>
                      ):(
                        <img className="action-img" style={{width: '20px', opacity:'0.5', cursor:'not-allowed'}} title="Anular" src={require("../../../../../assets/img/icons/anular.png")} alt="Anular"/>
                      )
                  }
              </span>
          )}
        </div>
      </div>
    );
  }
}
Acciones.propTypes = {
};

export function activeFormatter(acciones) {
  return (cell, row) => {
    return ( <Acciones id={cell} {...acciones}/> )
  };
}
