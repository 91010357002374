import _, { fromPairs } from "lodash";
import { api } from "api";
import uuidv1 from "uuid/v1";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { ToastStore } from "react-toasts";
import { reset } from "redux-form";

import { push } from "react-router-redux";
import { handleActions } from "redux-actions";
import { PVN, PVMCE } from "utility/variables";
import { actions as printActions } from "../print/print";
import { actions as cajaActions } from "../caja/caja";
import { actions as usuarioActions } from "../usuario/usuario";
import { listarOC } from "../cuenta_cobrar/cuenta_cobrar";
import { listarOV } from "../orden_venta";
import { pago_total } from "utility/variables";
import { envio, factura, credito, cotizacion } from "utility/constantes";
import {
    initialize as initializeForm,
    change as changeForm,
    change,
} from "redux-form";

import {
    DEPARTAMENTOS,
    MUNICIPIOS as ListaMunicipios,
} from "Utils/departamentos_municipios";
import { consultarEstadoNotaFEL } from "../devolucion/devolucion";
import { ID_NIT } from "../../../utility/variables";

// ------------------------------------
// Constants
// ------------------------------------

export const PAGE = "OV_PAGE";
export const SORT = "OV_SORT";
export const PASO = "OV_PASO";
export const BUSCADOR = "OV_BUSCADOR";
export const PRODUCTOS = "OV_PRODUCTOS";
export const SELECCIONADOS = "OV_SELECCIONADOS";
export const LOADER = "OV_LOADER";
export const MOVIMIENTO = "OV_MOVIMIENTO";
export const SET_IMPRESION = "OV_SET_IMPRESION";
export const SET_TIPO_PAGO = "OV_SET_TIPO_PAGO";
export const SET_TIPO_VENTA = "OV_SET_TIPO_VENTA";
export const SET_FECHA_VENCIMIENTO_CREDITO = "OV_SET_FECHA_VENCIMIENTO_CREDITO";
export const SET_EFECTIVO = "OV_SET_EFECTIVO";
export const SET_TARJETA = "OV_SET_TARJETA";
export const SET_CHEQUE = "OV_SET_CHEQUE";
export const SET_TRANSFERENCIA = "OV_SET_TRANSFERENCIA";
export const SET_DEPOSITO = "OV_SET_DEPOSITO";
export const SET_RETENCION_IVA = "OV_SET_RETENCION_IVA";
export const SET_EXENCION_IVA = "OV_SET_EXENCION_IVA";
export const SET_NOTA = "OV_SET_NOTA";
export const SET_NOTA_CREDITO = "OV_SET_NOTA_CREDITO";
export const SET_CODIGO_NOTA_CREDITO = "OV_SET_CODIGO_NOTA_CREDITO";
export const UUID_REQ_PRODUCTOS = "OV_UUID_REQ_PRODUCTOS";
export const SET_NIT_CLIENTE = "OV_SET_NIT_CLIENTE";

export const SET_NOMBRE_CLIENTE = "OV_SET_NOMBRE_CLIENTE";
export const SET_VER_BUSCADOR = "OV_SET_VER_BUSCADOR";
export const SET_VER_COBRO = "OV_SET_VER_COBRO";
export const SET_REDONDEO = "OV_SET_REDONDEO";
export const SET_VENTA_COTIZACION = "OV_SET_VENTA_COTIZACION";
export const SET_VER_IMPRESION = "OV_SET_VER_IMPRESION";
export const SET_COTIZACION_CARGADA = "OV_SET_COTIZACION_CARGADA";
export const SET_NOTA_CREDITO_CARGADA = "OV_SET_NOTA_CREDITO_CARGADA";
export const SET_ESTADO_FORM_CLIENTE = "OV_SET_ESTADO_FORM_CLIENTE";
export const SET_DATOS_CLIENTE = "OV_SET_DATOS_CLIENTE";

//  CLIENTES
export const PAGE_CLIENTES = "OV_PAGE_CLIENTES";
export const DATA_CLIENTES = "OV_DATA_CLIENTES";
export const LOADER_CLIENTES = "OV_LOADER_CLIENTES";
export const BUSCADOR_CLIENTES = "OV_BUSCADOR_CLIENTES";
export const MODAL_NUEVO_CLIENTE = "OV_MODAL_NUEVO_CLIENTE";
export const MODAL_LISTADO_CLIENTE = "OV_MODAL_LISTADO_CLIENTE";
export const MUNICIPIOS = "OV_CLIENTE_MUNCIPIOS";
export const DIRECCIONES = "OV_CLIENTE_DIRECCIONES";

//NAV PRODUCTS
export const PRODUCTO_ACTUAL = "OV_PRODUCTO_ACTUAL";
export const SET_VENTA_COSTO = "OV_SET_VENTA_COSTO";

export const ESTADO_DESCARGA = "OV_ESTADO_DESCARGA";

const endpoint = "ventas";
const endpoint_prods_ventas = "productos_venta";
const endpoint_datos_impresion_venta = "historial_ventas/reimpresion_venta";
const endpoint_clientes = "clientes";
const endpoint_cliente = "clientes_formal";
const endpoint_cotizacion = "cotizaciones";
const obj_tipo_pago = {
    monto: NaN,
    descripcion: "",
};

export const initial_state_cliente = {
    datos_cambiados: false,
    tipo_documento: ID_NIT,
    cliente: {
        nombre_cliente: null,
        no_doc_cliente: null,
    },
};

// ------------------------------------
// Actions
// ------------------------------------

export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        // dispatch(setLoader(true));

        const store = getStore();
        const producto = store.venta;
        const { ordering, search } = producto;

        // CLIENTE
        //const { cliente_id } = store.form.wizardCrearOVForm.values;

        //  GENERAR EL UUID
        const uuid = uuidv1();
        dispatch(setUuidReqProductos(uuid));

        //if (cliente_id) {
        api.get(endpoint_prods_ventas, {
            page,
            ordering,
            search,
            activo: true,
            cliente: "",
        })
            .catch((err) => {})
            .then((data) => {
                if (data) {
                    const otroUuid = getStore().venta.uuid_req_productos;
                    if (otroUuid === uuid) {
                        dispatch(setProductos(data));
                        dispatch(setPage(page));
                        dispatch(setLoader(false));
                    }
                }
            });
        //} else {
        //    dispatch(setLoader(false));
        //    dispatch(setProductos({ results: [], count: 0 }));
        //}
    };

export const cambiarPrecio = (index, precio) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    // const item = _.find(seleccionados, { id: producto.id });
    const item = seleccionados[index];
    if (item) {
        // const index = seleccionados.indexOf(item);
        item.precio_original = precio;
        if (item.desc_porcentaje) {
            item.desc_monto = parseFloat(
                (item.precio_original * (item.desc_porcentaje / 100)).toFixed(2)
            );
            item.precio = item.precio_original - item.desc_monto;
        } else {
            item.desc_monto = 0;
            item.precio_final = "";
            item.precio = precio;
        }
        item.modelo_precio = precio;
        seleccionados.splice(index, 1, item);
        dispatch(setSeleccionados(seleccionados));
    } else {
        Swal(
            "Cambio de precio",
            "No fue posible cambiar el precio del producto seleccionado.",
            "warning"
        );
    }
};

export const cambiarPrecioEditable =
    (index, precio_producto) => (dispatch, getStore) => {
        const store = getStore();
        const seleccionados = _.cloneDeep(store.venta.seleccionados);
        // const item = _.find(seleccionados, { id: producto.id });
        const item = seleccionados[index];
        if (item) {
            // const index = seleccionados.indexOf(item);
            if (precio_producto != null && precio_producto != "") {
                let precio = precio_producto >= 0 ? precio_producto : 0;
                // item.precio_original = item.precio;
                if (item.desc_porcentaje) {
                    item.desc_porcentaje = 0;
                    item.precio_final = "";
                    item.precio = precio;
                } else {
                    item.desc_monto = 0;
                    item.precio_final = "";
                    item.precio = precio;
                }
                item.modelo_precio = precio;
                seleccionados.splice(index, 1, item);
                dispatch(setSeleccionados(seleccionados));
            }
        } else {
            Swal(
                "Cambio de precio",
                "No fue posible cambiar el precio del producto seleccionado.",
                "warning"
            );
        }
    };

export const cambiarDescuento =
    (producto, index, descuento) => (dispatch, getStore) => {
        if (descuento > 100) {
            producto.desc_porcentaje = 100;
        } else if (descuento < 0) {
            producto.desc_porcentaje = 0;
        } else {
            producto.desc_porcentaje = descuento;
        }
        //  Se calcula el precio del producto con el descuento
        if (producto.desc_porcentaje) {
            producto.desc_monto = parseFloat(
                Number(
                    Number(
                        (
                            producto.precio_original *
                            (producto.desc_porcentaje / 100)
                        ).toFixed(4)
                    )
                ).toFixed(2)
            );
            producto.precio = producto.precio_original - producto.desc_monto;
        } else {
            producto.desc_monto = 0;
            producto.precio = producto.precio_original;
        }

        const store = getStore();
        const seleccionados = _.cloneDeep(store.venta.seleccionados);
        // const item = _.find(seleccionados, { id: producto.id });
        const item = seleccionados[index];
        if (item) {
            // const index = seleccionados.indexOf(item);
            seleccionados.splice(index, 1, producto);
            dispatch(setSeleccionados(seleccionados));
        } else {
            Swal(
                "Cambio de descuento",
                "No fue posible cambiar el descuento del producto seleccionado.",
                "warning"
            );
        }
    };

export const cambiarPrecioFinal =
    (producto, index, precio_final) => (dispatch, getStore) => {
        if (precio_final > producto.precio_original) {
            producto.precio_final = producto.precio_original;
        } else if (precio_final < 0) {
            producto.precio_final = 0;
        } else {
            producto.precio_final = precio_final;
        }

        //  Se calcula el porcentaje de descuento con el precio final
        if (producto.precio_final) {
            producto.desc_monto = parseFloat(
                (producto.precio_original - producto.precio_final).toFixed(2)
            );
            producto.desc_porcentaje = parseFloat(
                (producto.desc_monto / producto.precio_original) * 100
            );
            producto.precio = producto.precio_final;
        } else {
            producto.desc_monto = 0;
            producto.precio = producto.precio_original;
        }

        const store = getStore();
        const seleccionados = _.cloneDeep(store.venta.seleccionados);
        const item = seleccionados[index];
        if (item) {
            seleccionados.splice(index, 1, producto);
            dispatch(setSeleccionados(seleccionados));
        } else {
            Swal(
                "Cambio de precio final",
                "No fue posible cambiar el precio final del producto seleccionado.",
                "warning"
            );
        }
    };

export const desplegarItem = (producto, index) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    // const item = _.find(seleccionados, { id: producto.id });
    const item = seleccionados[index];
    if (item) {
        // const index = seleccionados.indexOf(item);
        producto.collapsed = !item.collapsed;
        seleccionados.splice(index, 1, producto);

        //  SE CIERRAN LOS PRODUCTOS ANTERIORES
        seleccionados.forEach((prod, idx) => {
            if (index !== idx) {
                prod.collapsed = false;
            }
        });
        dispatch(setSeleccionados(seleccionados));
    } else {
        Swal(
            "Desplegar producto",
            "No fue posible desplegar mas opciones del producto seleccionado.",
            "warning"
        );
    }
};

export const asignarCantidad = (producto, cantidad) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    const item = _.find(seleccionados, { id: producto.id });
    const index = seleccionados.indexOf(item);
    if (cantidad < 0) {
        item.cantidad = 0;
    } else {
        item.cantidad = cantidad;
    }
    seleccionados.splice(index, 1, item);
    dispatch(setSeleccionados(seleccionados));
};

export const agregar =
    (productoSeleccionado, cantidad = 0, precio = 0) =>
    (dispatch, getStore) => {
        const store = getStore();
        const seleccionados = _.cloneDeep(store.venta.seleccionados);
        let producto = _.cloneDeep(productoSeleccionado);
        const { conf_cotizacion } = store.usuario;
        // ? VERIFICACION DEL STOCK DEL PRODUCTO
        // if (producto.existe_descontable || conf_cotizacion) {
        const items = _.filter(seleccionados, { id: producto.id });

        const cantidad_seleccionado = seleccionados.length + 1;

        if (cantidad_seleccionado > 15) {
            Swal(
                "Atención",
                "No se puede agregar mas de 15 productos a la venta.",
                "warning"
            );
            return;
        }

        // if (item) {
        //     const index = seleccionados.indexOf(item);
        //     item.cantidad += 1;
        //     //  Se calcula el precio del producto con el descuento
        //     if (item.desc_porcentaje) {
        //         item.desc_monto = parseFloat((item.precio_original * (item.desc_porcentaje/100)).toFixed(2));
        //     }else {
        //         item.desc_monto = 0;
        //     }

        //     seleccionados.splice(index, 1, item);
        //     dispatch(setSeleccionados(seleccionados));
        // } else {

        producto.key = uuidv1();
        producto.cantidad = cantidad;
        //producto.precio = precio;

        //  SE SETEA EL INVENTARIO TOTAL COMO REFERENCIA
        if (items.length) {
            producto.inventario_total = items[0].inventario_total;
        } else {
            producto.inventario_total = producto.inventario_calculado;
        }
        //  SE CALCULA EL INVENTARIO DEL MISMO PRODUCTO SI SE INGRESO VARIOS
        let cantidad_total = producto.cantidad;

        //  SE SUMA TODAS LAS CANTIDADES DE LOS PRODUCTOS ENCONTRADOS
        items.forEach((item) => {
            cantidad_total += item.cantidad;
        });
        //  SE CALCULA EL INVENTARIO FINAL
        const inventario_final = producto.inventario_total - cantidad_total;

        //  SI SE ENCUENTRAN PRODUCTOS SE ACTUALIZAN SUS INVENTARIO
        if (items.length) {
            items.forEach((item) => {
                const indexSeleccionado = _.indexOf(seleccionados, item);
                item.inventario = inventario_final >= 0 ? inventario_final : 0;
                item.cantidad_total = cantidad_total;
                seleccionados.splice(indexSeleccionado, 1, item);
            });
        }
        producto.cantidad_total = cantidad_total;
        producto.inventario = inventario_final >= 0 ? inventario_final : 0;
        producto.collapsed = false;
        producto.es_vale = false;
        // Se conserva el precio principal
        producto.precio_original = producto.precio;
        producto.precio1 = producto.precio;
        producto.num_precios = 0;
        producto.listado_precios = [];
        producto.id_unidad_de_medida = producto.producto.a_granel
            ? producto.unidad_de_medida
            : null;

        const { nombres_precios } = store.usuario;

        if (precio >= producto.precio) {
            //Swal(
            //    "Precio",
            //    "El precio asignado debe ser mayor al establecido.",
            //    "warning"
            //);

            producto.precio = precio;
            dispatch(setSeleccionados([...seleccionados, producto]));
            dispatch(_setProductoActual({}));
            //dispatch(setProductoActual({}));
        } else {
            let producto_data = _.cloneDeep(producto);
            // producto_data.id_unidad_de_medida = producto.producto.a_granel
            //     ? producto.unidad_de_medida.id
            //     : null;
            // producto_data.costo =
            //     producto_data.costo != "" && producto_data.costo != null
            //         ? producto_data.costo
            //         : 0;

            //producto_data.precio = precio;
            producto_data.cantidad = 1;

            dispatch(setSeleccionados([producto_data, ...seleccionados]));
            dispatch(_setProductoActual({}));
            //dispatch(setProductoActual({}));
        }
        //  Se valida que si tiene varios precios para dar la opcion de seleccionar
        /*if (producto.precio) {
                producto.num_precios += 1;
                producto.modelo_precio = { id: 1, value: producto.precio };
                producto.listado_precios.push({
                    id: 1,
                    value: producto.precio.toFixed(2),
                    nombre: nombres_precios
                        ? nombres_precios.nombre_precio
                        : "Precio",
                    porcentaje: "100.00",
                });
            }
            if (producto.precio2) {
                producto.num_precios += 1;
                producto.listado_precios.push({
                    id: 2,
                    value: producto.precio2.toFixed(2),
                    nombre: nombres_precios
                        ? nombres_precios.nombre_precio2
                        : "Precio 2",
                    porcentaje: producto.porcent_precio2
                        ? producto.porcent_precio2.toFixed(2)
                        : 0,
                });
            }
            if (producto.precio3) {
                producto.num_precios += 1;
                producto.listado_precios.push({
                    id: 3,
                    value: producto.precio3.toFixed(2),
                    nombre: nombres_precios
                        ? nombres_precios.nombre_precio3
                        : "Precio 3",
                    porcentaje: producto.porcent_precio3
                        ? producto.porcent_precio3.toFixed(2)
                        : 0,
                });
            }
            if (producto.precio4) {
                producto.num_precios += 1;
                producto.listado_precios.push({
                    id: 4,
                    value: producto.precio4.toFixed(2),
                    nombre: nombres_precios
                        ? nombres_precios.nombre_precio4
                        : "Precio 4",
                    porcentaje: producto.porcent_precio4
                        ? producto.porcent_precio4.toFixed(2)
                        : 0,
                });
            }
            if (producto.precio5) {
                producto.num_precios += 1;
                producto.listado_precios.push({
                    id: 5,
                    value: producto.precio5.toFixed(2),
                    nombre: nombres_precios
                        ? nombres_precios.nombre_precio5
                        : "Precio 5",
                    porcentaje: producto.porcent_precio5
                        ? producto.porcent_precio5.toFixed(2)
                        : 0,
                });
            }*/

        // }
        // ? VERIFICACION DEL STOCK DEL PRODUCTO
        // } else {
        //     Swal(
        //         "ERROR",
        //         "No cuenta con inventario para este producto",
        //         "error"
        //     );
        // }
        // ? VERIFICACION DEL STOCK DEL PRODUCTO
    };

const agregarVale = (codigo) => (dispatch, getStore) => {
    api.get("devoluciones", { codigo })
        .then((data) => {
            if (data.results.length > 0) {
                const vale = data.results[0];
                const store = getStore();
                const seleccionados = _.cloneDeep(store.venta.seleccionados);
                dispatch(
                    setSeleccionados([
                        ...seleccionados,
                        {
                            ...vale,
                            es_vale: true,
                        },
                    ])
                );
            } else {
                Swal("ERROR", "El código ingresado no es válido", "warning");
            }
        })
        .catch((e) => {})
        .finally(() => {});
};

export const sumar = (producto, index) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    // const item = _.find(seleccionados, { id: producto.id });
    const item = seleccionados[index];
    if (item) {
        item.cantidad += 1;
        if (item.cantidad > 0) {
            //  Se calcula el precio del producto con el descuento
            if (item.desc_porcentaje) {
                item.desc_monto = parseFloat(
                    (
                        item.precio_original *
                        (item.desc_porcentaje / 100)
                    ).toFixed(2)
                );
            } else {
                item.desc_monto = 0;
            }
            //  SE FILTRAN LOS PRODUCTOS IGUALES
            const items = _.filter(seleccionados, { id: producto.id });

            //  SE CALCULA EL INVENTARIO DEL MISMO PRODUCTO SI SE INGRESO VARIOS
            let cantidad_total = 0;

            //  SE SUMA TODAS LAS CANTIDADES DE LOS PRODUCTOS ENCONTRADOS
            items.forEach((item) => {
                cantidad_total += item.cantidad;
            });
            //  SE CALCULA EL INVENTARIO FINAL
            const inventario_final = item.inventario_total - cantidad_total;

            //  SI SE ENCUENTRAN PRODUCTOS SE ACTUALIZAN SUS INVENTARIO
            if (items.length) {
                items.forEach((item) => {
                    const indexSeleccionado = _.indexOf(seleccionados, item);
                    item.inventario =
                        inventario_final >= 0 ? inventario_final : 0;
                    item.cantidad_total = cantidad_total;
                    seleccionados.splice(indexSeleccionado, 1, item);
                });
            }
            item.cantidad_total = cantidad_total;
            item.inventario = inventario_final >= 0 ? inventario_final : 0;
            seleccionados.splice(index, 1, item);
        } else {
            seleccionados.splice(index, 1);
        }
        dispatch(setSeleccionados(seleccionados));
    }
};

export const restar = (producto, index) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    // const item = _.find(seleccionados, { id: producto.id });
    const item = seleccionados[index];
    if (item) {
        // const index = seleccionados.indexOf(item);
        item.cantidad -= 1;
        if (item.cantidad > 0) {
            //  Se calcula el precio del producto con el descuento
            if (item.desc_porcentaje) {
                item.desc_monto = parseFloat(
                    (
                        item.precio_original *
                        (item.desc_porcentaje / 100)
                    ).toFixed(2)
                );
            } else {
                item.desc_monto = 0;
            }

            //  SE FILTRAN LOS PRODUCTOS IGUALES
            const items = _.filter(seleccionados, { id: producto.id });

            //  SE CALCULA EL INVENTARIO DEL MISMO PRODUCTO SI SE INGRESO VARIOS
            let cantidad_total = 0;

            //  SE SUMA TODAS LAS CANTIDADES DE LOS PRODUCTOS ENCONTRADOS
            items.forEach((item) => {
                cantidad_total += item.cantidad;
            });
            //  SE CALCULA EL INVENTARIO FINAL
            const inventario_final = item.inventario_total - cantidad_total;

            //  SI SE ENCUENTRAN PRODUCTOS SE ACTUALIZAN SUS INVENTARIO
            if (items.length) {
                items.forEach((item) => {
                    const indexSeleccionado = _.indexOf(seleccionados, item);
                    item.inventario =
                        inventario_final >= 0 ? inventario_final : 0;
                    item.cantidad_total = cantidad_total;
                    seleccionados.splice(indexSeleccionado, 1, item);
                });
            }
            item.cantidad_total = cantidad_total;
            item.inventario = inventario_final >= 0 ? inventario_final : 0;

            seleccionados.splice(index, 1, item);
        } else {
            seleccionados.splice(index, 1);
        }
        dispatch(setSeleccionados(seleccionados));
        // dispatch(buscar(""));
        // dispatch(listar());
    }
};

export const asignarPrecio = (index, precio) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    let item = seleccionados[index];
    if (item) {
        // const index = seleccionados.indexOf(item);
        item.precio_original = precio;
        if (item.desc_porcentaje) {
            item.desc_monto = parseFloat(
                (item.precio_original * (item.desc_porcentaje / 100)).toFixed(2)
            );
            item.precio = item.precio_original - item.desc_monto;
        } else {
            item.desc_monto = 0;
            item.precio = precio;
        }
        item.modelo_precio = precio;
        seleccionados.splice(index, 1, item);
        dispatch(setSeleccionados(seleccionados));
    } else {
        Swal(
            "Cambio de precio",
            "No fue posible cambiar el precio del producto seleccionado.",
            "warning"
        );
    }
};

export const quitar = (producto, index) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    // const item = producto.es_vale ? _.find(seleccionados, { codigo: producto.codigo }) : _.find(seleccionados, { id: producto.id });
    const item = producto.es_vale
        ? _.find(seleccionados, { codigo: producto.codigo })
        : seleccionados[index];
    if (item) {
        // const index = seleccionados.indexOf(item);
        seleccionados.splice(index, 1);
        //  SE FILTRAN LOS PRODUCTOS IGUALES
        const items = _.filter(seleccionados, { id: producto.id });

        //  SE CALCULA EL INVENTARIO DEL MISMO PRODUCTO SI SE INGRESO VARIOS
        let cantidad_total = 0;

        //  SE SUMA TODAS LAS CANTIDADES DE LOS PRODUCTOS ENCONTRADOS
        items.forEach((item) => {
            cantidad_total += item.cantidad;
        });
        //  SE CALCULA EL INVENTARIO FINAL
        const inventario_final = item.inventario_total - cantidad_total;

        //  SI SE ENCUENTRAN PRODUCTOS SE ACTUALIZAN SUS INVENTARIO
        if (items.length) {
            items.forEach((item) => {
                const indexSeleccionado = _.indexOf(seleccionados, item);
                item.inventario = inventario_final >= 0 ? inventario_final : 0;
                item.cantidad_total = cantidad_total;
                seleccionados.splice(indexSeleccionado, 1, item);
            });
        }

        dispatch(setSeleccionados(seleccionados));
    }
};

export const ingresoCantidad =
    (cantidad, producto, index) => (dispatch, getStore) => {
        const store = getStore();
        const seleccionados = _.cloneDeep(store.venta.seleccionados);
        // const item = _.find(seleccionados, { id: producto.id });
        const item = seleccionados[index];
        // debugger;
        if (item) {
            const index = seleccionados.indexOf(item);
            item.cantidad = cantidad;
            if (item.cantidad > 0) {
                //  Se calcula el precio del producto con el descuento
                if (item.desc_porcentaje) {
                    item.desc_monto = parseFloat(
                        (
                            item.precio_original *
                            (item.desc_porcentaje / 100)
                        ).toFixed(2)
                    );
                } else {
                    item.desc_monto = 0;
                }
                seleccionados.splice(index, 1, item);
            } else {
                item.desc_monto = 0;
            }

            //  SE FILTRAN LOS PRODUCTOS IGUALES
            const items = _.filter(seleccionados, { id: producto.id });

            //  SE CALCULA EL INVENTARIO DEL MISMO PRODUCTO SI SE INGRESO VARIOS
            let cantidad_total = 0;

            //  SE SUMA TODAS LAS CANTIDADES DE LOS PRODUCTOS ENCONTRADOS
            items.forEach((item) => {
                cantidad_total += item.cantidad;
            });
            //  SE CALCULA EL INVENTARIO FINAL
            const inventario_final = item.inventario_total - cantidad_total;

            //  SI SE ENCUENTRAN PRODUCTOS SE ACTUALIZAN SUS INVENTARIO
            if (items.length) {
                items.forEach((item) => {
                    const indexSeleccionado = _.indexOf(seleccionados, item);
                    item.inventario =
                        inventario_final >= 0 ? inventario_final : 0;
                    item.cantidad_total = cantidad_total;
                    seleccionados.splice(indexSeleccionado, 1, item);
                });
            }
            item.cantidad_total = cantidad_total;
            item.inventario = inventario_final >= 0 ? inventario_final : 0;

            dispatch(setSeleccionados(seleccionados));
        }
    };

export const asignarUnidadDeMedida =
    (producto, id_unidad_medida) => (dispatch, getStore) => {
        const store = getStore();
        const seleccionados = _.cloneDeep(store.venta.seleccionados);
        let item = _.find(seleccionados, { id: producto.id });
        const index = seleccionados.indexOf(item);
        item.id_unidad_de_medida = parseInt(id_unidad_medida);
        seleccionados.splice(index, 1, item);
        dispatch(setSeleccionados(seleccionados));
    };

export const agregarPrimer = (search) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const producto = store.venta;
    const { ordering } = producto;
    api.get(endpoint, { ordering, search })
        .catch((err) => {})
        .then((data) => {
            if (data) {
                if (data.count === 1) {
                    dispatch(agregar(data.results[0]));
                    // dispatch(seleccionarProducto(data.results[0]))
                }
            }
        })
        .finally(() => {
            dispatch(buscar(""));
            dispatch(listar());
            dispatch(setLoader(false));
        });
};

export const pagar = (total) => (dispatch, getStore) => {
    const store = getStore();
    const {
        efectivo,
        tarjeta,
        tipo_pago,
        redondeo,
        seleccionados,
        cheque,
        deposito,
        retencion_iva,
        exencion_iva,
        nota_credito,
        nota,
    } = store.venta;
    const movimiento = store.venta.movimiento;
    const { conf_venta_redondeo } = store.usuario;
    let pagos = {
        efectivo: efectivo ? parseFloat(efectivo) : 0,
        tarjeta: tarjeta ? parseFloat(tarjeta) : 0,
        cheque: cheque ? parseFloat(cheque) : 0,
        deposito: deposito ? parseFloat(deposito) : 0,
        retencion_iva: retencion_iva ? parseFloat(retencion_iva) : 0,
        exencion_iva: exencion_iva ? parseFloat(exencion_iva) : 0,
        nota_credito: nota_credito ? parseFloat(nota_credito) : 0,
        nota: nota ? nota : "",
    };
    if (movimiento.sucursal !== undefined) {
        // dispatch(push('/ventas'));
        dispatch(setMovimiento({}));
        dispatch(setSeleccionados([]));
        dispatch(limpiarDatosCliente());
        dispatch(setVerBuscador(false));
        dispatch(setVerCobro(false));
        dispatch(_setEfectivo(""));
        dispatch(_setTarjeta(""));
        dispatch(_setCheque(""));
        dispatch(_setDeposito(""));
        dispatch(_setRetencionIVA(""));
        dispatch(_setExencionIVA(""));
        dispatch(_setNotaCredito(""));
        dispatch(setNota(""));
        dispatch(setFechaVencimientoCredito(moment().format("YYYY-MM-DD")));
        dispatch(setTipoPago(pago_total));
        dispatch(setBuscador(""));
        dispatch(setCodigoNotaCredito(""));
        dispatch(setNotaCreditoCargada(null));
        dispatch(listar());
    } else {
        total = total ? parseFloat(total) : 0;
        //  VALIDACION DE MONTO TOTAL
        let monto_valido = false;
        const total_pago =
            pagos.tarjeta +
            pagos.efectivo +
            pagos.cheque +
            pagos.deposito +
            pagos.retencion_iva +
            pagos.exencion_iva +
            pagos.nota_credito;
        if (tipo_pago == 100) {
            if (total_pago < total) {
                Swal(
                    "ERROR",
                    "Debe cancelar el monto total de la venta.",
                    "error"
                );
                monto_valido = false;
            } else {
                monto_valido = true;
            }
        } else {
            const monto_a_pagar = total_pago;

            if (monto_a_pagar >= total) {
                Swal(
                    "ERROR",
                    "El anticipo debe ser menor al monto total de la venta.",
                    "error"
                );
                monto_valido = false;
            } else {
                monto_valido = true;
            }
        }
        if (monto_valido) {
            if (conf_venta_redondeo) {
                // CALCULO DE RECIDUO PARA EL REDONDEO
                let total = 0;
                seleccionados.forEach((producto) => {
                    if (!producto.es_vale) {
                        const precio = producto.precio
                            ? parseFloat(producto.precio)
                            : 0;
                        const cantidad = producto.cantidad
                            ? parseFloat(producto.cantidad)
                            : 0;
                        total = total + precio * cantidad;
                    } else {
                        total -= producto.total_importe;
                    }
                });

                const decimal = parseFloat((total % 1).toFixed(2));
                const moneda_mas_pequeno = 0.05;
                const cantidad_monedas = Math.floor(
                    decimal / moneda_mas_pequeno
                );
                const reciduo = parseFloat(
                    (decimal - cantidad_monedas * moneda_mas_pequeno).toFixed(2)
                );

                if (reciduo > 0 && !redondeo) {
                    //  REDONDEO HACIA ARRIBA O ABAJO
                    Swal({
                        title: "¿Cómo quieres redondear el total de la venta?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Hacia arriba",
                        cancelButtonText: "Hacia abajo",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.value) {
                            dispatch(setRedondeo("arriba"));
                        } else if ((result.dismiss = "cancel")) {
                            dispatch(setRedondeo("abajo"));
                        }
                    });
                } else {
                    dispatch(validarCliente());
                }
            } else {
                dispatch(registrarVenta({}));
                //dispatch(validarCliente());
            }
        }
    }
};

const forzarFinalizarVenta = () => (dispatch) => {
    dispatch(setMovimiento({}));
    dispatch(setSeleccionados([]));
    dispatch(limpiarDatosCliente());
    dispatch(setVerBuscador(false));
    dispatch(setVerCobro(false));
    dispatch(_setEfectivo(""));
    dispatch(_setTarjeta(""));
    dispatch(_setCheque(""));
    dispatch(_setDeposito(""));
    dispatch(_setRetencionIVA(""));
    dispatch(_setExencionIVA(""));
    dispatch(_setNotaCredito(""));
    dispatch(setNota(""));
    dispatch(setFechaVencimientoCredito(moment().format("YYYY-MM-DD")));
    dispatch(setTipoPago(pago_total));
    dispatch(setBuscador(""));
    dispatch(setCodigoNotaCredito(""));
    dispatch(setNotaCreditoCargada(null));
    dispatch(listar());
};

const validarCliente = () => (dispatch, getStore) => {
    //  VALIDAR CLIENTE
    const store = getStore();
    const data_cliente = store.form.ClienteVentaForm.values;
    const errores = store.form.ClienteVentaForm.syncErrors
        ? store.form.ClienteVentaForm.syncErrors
        : false;
    if (!errores) {
        if (data_cliente.nit != "") {
            if (data_cliente.nit == "CF" || data_cliente.nit == "cf") {
                // si ingreso CF en el campo nit
                const data_cliente_cf = {
                    nit: "C.F.",
                    nombre:
                        data_cliente.nombre == ""
                            ? "Consumidor Final"
                            : data_cliente.nombre, //Guardar nombre del cliente si fue escrito
                };
                dispatch(registrarVenta(data_cliente_cf));
            } else {
                if (data_cliente.nombre == "") {
                    // si ingreso nit pero no esta el nombre presente en el formulario
                    Swal(
                        "Nombre del cliente",
                        "Por favor de ingresar el nombre del cliente.",
                        "error"
                    );
                } else {
                    // si ingreso nit y nombre
                    dispatch(registrarVenta(data_cliente));
                }
            }
        } else {
            if (data_cliente.nombre == "" && data_cliente.nit == "") {
                // SI no ingreso NIT ni nombre mostar modal
                Swal({
                    title: "¿Registrar venta con cliente Consumidor Final?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Sí",
                    cancelButtonText: "No",
                    reverseButtons: true,
                }).then((result) => {
                    if (result.value) {
                        const data_cliente_cf = {
                            nit: "C.F.",
                            nombre: "Consumidor Final",
                        };
                        dispatch(registrarVenta(data_cliente_cf));
                    }
                });
            } else {
                // Si ingreso solo el nombre y no el nit
                const data_cliente_cf = {
                    nit: "C.F.",
                    nombre:
                        data_cliente.nombre != ""
                            ? data_cliente.nombre
                            : "Consumidor Final",
                };
                dispatch(registrarVenta(data_cliente_cf));
            }
        }
    }
};

const registrarOV =
    (total, finalizar_cotizacion = false, id_cotizacion = null) =>
    async (dispatch, getStore) => {
        dispatch(setLoader(true));
        dispatch(setEstadoDescarga(true));
        console.log("REGISTRAR OV");
        const store = getStore();
        const form = store.form.wizardCrearOVForm.values;
        const sucursal_id = store.usuario.sucursal;
        //const form_pago = store.form.MediosPagosForm.values;

        const {
            efectivo,
            tarjeta,
            cheque,
            deposito,
            tipo_pago,
            //fecha_vencimiento,
            redondeo,
            nota_credito_cargada,
            transferencia,
            retencion_iva,
            exencion_iva,
            nota,
            nota_credito,
            seleccionados,
            estado_descarga,
            datos_cliente,
        } = store.venta;

        const {
            cliente: datos_cliente_nuveo,
            tipo_documento,
            datos_cambiados,
        } = datos_cliente;

        let pagos = {
            efectivo: efectivo["monto"]
                ? { ...efectivo, monto: parseFloat(efectivo["monto"]) }
                : { ...obj_tipo_pago, monto: 0 },
            tarjeta: tarjeta["monto"]
                ? { ...tarjeta, monto: parseFloat(tarjeta["monto"]) }
                : { ...obj_tipo_pago, monto: 0 },
            cheque: cheque["monto"]
                ? { ...cheque, monto: parseFloat(cheque["monto"]) }
                : { ...obj_tipo_pago, monto: 0 },
            deposito: deposito["monto"]
                ? { ...deposito, monto: parseFloat(deposito["monto"]) }
                : { ...obj_tipo_pago, monto: 0 },
            transferencia: transferencia["monto"]
                ? {
                      ...transferencia,
                      monto: parseFloat(transferencia["monto"]),
                  }
                : { ...obj_tipo_pago, monto: 0 },
            //retencion_iva: retencion_iva ? parseFloat(retencion_iva) : 0,
            //exencion_iva: exencion_iva ? parseFloat(exencion_iva) : 0,
            //nota_credito: nota_credito ? parseFloat(nota_credito) : 0,
            //nota: nota ? nota : "", ///--
        };

        const productos = store.venta.seleccionados;
        const cliente = form.cliente_id;
        const fecha_vencimiento = form.fecha_limite;

        const {
            descuentos,
            porcentaje_descuento,
            venta_costo,
            descripcion,
            correo,
            enviar_correo,
            //descuentos,
            //venta_costo,
            direccion,
            total_descuento,
            total_costo,
            tipo_venta,
        } = form;

        let monto_valido = false;
        const total_pago =
            pagos.tarjeta["monto"] +
            pagos.efectivo["monto"] +
            pagos.cheque["monto"] +
            pagos.transferencia["monto"];
        //pagos.deposito +
        //pagos.retencion_iva +
        //pagos.exencion_iva +
        //pagos.nota_credito;

        // Validacion
        if (!cliente || cliente == undefined || cliente == null) {
            ToastStore.error("Seleccione un cliente");
            return false;
        }

        if (enviar_correo && enviar_correo == true && !correo) {
            ToastStore.error("Debe ingresar un correo");
            return false;
        }

        if (!direccion || direccion == undefined || direccion == null) {
            ToastStore.error("Seleccione una direccion");
            return false;
        }

        if (enviar_correo && correo) {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(correo)) {
                ToastStore.error("Correo electrónico invalido");
                return false;
            }
        }

        // Si no es una venta al credito
        if (tipo_venta != 30000) {
            // Validacion del pago de la venta
            if (descuentos && total_pago < total_descuento) {
                ToastStore.error("Debe cancelar el monto total de la venta");
                monto_valido = false;
            } else if (!descuentos && venta_costo && total_pago < total_costo) {
                ToastStore.error("Debe cancelar el monto total de la venta");
                monto_valido = false;
            } else if (!descuentos && !venta_costo && total_pago < total) {
                ToastStore.error("Debe cancelar el monto total de la venta");
                monto_valido = false;
            } else {
                monto_valido = true;
            }
        } else {
            monto_valido = true;
        }

        const data = {
            productos,
            cliente,
            referencia: "",
            mostrar_referencia: false,
            pagos,
            tipo_pago,
            fecha_vencimiento,
            redondeo,
            codigo_nota_credito: nota_credito_cargada
                ? nota_credito_cargada.nota_credito_fel.uuid
                : null,
            //Descuentos
            descuentos,
            porcentaje_descuento,
            //Venta precio costo
            venta_costo,
            descripcion,
            direccion,
            enviar_correo,
            correo,
            tipo_venta,
            sucursal_id,

            // Datos del cliente cambiados
            datos_cliente_nuveo,
            tipo_documento,
            datos_cambiados,
        };

        let credito_valido_cliente = false; // Si el cliente tiene credito valido
        // get redux sucursal
        const sucursal = store.usuario.sucursal;

        // total a pagar
        let _total = 0;
        seleccionados.map(
            (producto) =>
                (_total += venta_costo
                    ? producto.costo
                    : producto.precio * producto.cantidad)
        );

        if (descuentos) {
            _total = _total - _total * (porcentaje_descuento / 100);
        }

        // Validacion credito
        try {
            if (tipo_venta == 30000) {
                const resp = await api.get(
                    "ordenes_venta/validar_credito_cliente",
                    {
                        cliente_id: cliente,
                        sucursal_id: sucursal,
                        total: _total,
                    }
                );
                credito_valido_cliente = await resp.credito;
            }
        } catch (err) {
            // Si el cliente no tiene credito valido
            const msj = err.detail ? err.detail : "Error al validar credito";
            Swal("ERROR", msj, "error");
            if (err) {
                dispatch(setEstadoDescarga(false));
                credito_valido_cliente = false;
            }
        } finally {
            dispatch(setLoader(false));
        }

        // Si no es una venta al credito, no validar el credito del cliente
        if (tipo_venta != 30000) credito_valido_cliente = true;

        // Si el cliente tiene credito valido
        if (credito_valido_cliente) {
            if (monto_valido) {
                if (finalizar_cotizacion) {
                    data.id_cotizacion = id_cotizacion;
                    Swal({
                        title: "¿Finalizar cotizacion?",
                        text: "¡No podrá revertir esta acción!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Continuar",
                        cancelButtonText: "Cancelar",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.value) {
                            api.post(endpoint, data)
                                .then((data) => {
                                    dispatch(setEstadoDescarga(true));
                                    if (tipo_venta == 20000) {
                                        if (data.id) {
                                            if (datos_cambiados) {
                                                const datos_cliente = {
                                                    nombre: datos_cliente_nuveo.nombre_cliente,
                                                    nit: datos_cliente_nuveo.no_doc_cliente,
                                                };
                                                dispatch(
                                                    facturarVenta(
                                                        data.id_venta,
                                                        true,
                                                        () => {},
                                                        datos_cliente
                                                    )
                                                );
                                            } else {
                                                // console.log(data.id_venta);
                                                dispatch(
                                                    facturarVenta(
                                                        data.id_venta,
                                                        true
                                                    )
                                                );
                                            }
                                        } else {
                                            Swal(
                                                "ERROR",
                                                "Ha ocurrido un error, espere un momento y vuelva a intentar",
                                                "error"
                                            );
                                        }
                                    } else {
                                        getVentaImpresion(data.id_venta)
                                            .then((_data) => {
                                                dispatch(
                                                    impresionTicketVenta(_data)
                                                );
                                                dispatch(siguientePaso());
                                            })
                                            .catch((err) => {
                                                dispatch(
                                                    impresionTicketVenta(data)
                                                );
                                                dispatch(siguientePaso());
                                            });
                                        // dispatch(impresionTicketVenta(data));
                                        // dispatch(setLoader(false));
                                        // dispatch(siguientePaso());
                                    }
                                })
                                .catch((err) => {
                                    if (err) {
                                        Swal("ERROR", err.detail, "error");
                                    } else {
                                        Swal(
                                            "ERROR",
                                            "Ha ocurrido un error, espere un momento y vuelva a intentar",
                                            "error"
                                        );
                                    }
                                    dispatch(setLoader(false));
                                })
                                .finally(() => {
                                    dispatch(setLoader(false));
                                });
                        }
                    });
                } else {
                    api.post(endpoint, data)
                        .catch((err) => {
                            if (err) {
                                Swal("ERROR", err.detail, "error");
                            } else {
                                Swal(
                                    "ERROR",
                                    "Ha ocurrido un error, espere un momento y vuelva a intentar",
                                    "error"
                                );
                            }
                        })
                        .then((data) => {
                            // console.log(data);
                            dispatch(setEstadoDescarga(true));
                            if (tipo_venta == 20000) {
                                if (data.id) {
                                    // console.log(data.id_venta);
                                    if (datos_cambiados) {
                                        const datos_cliente = {
                                            nombre: datos_cliente_nuveo.nombre_cliente,
                                            nit: datos_cliente_nuveo.no_doc_cliente,
                                        };
                                        dispatch(
                                            facturarVenta(
                                                data.id_venta,
                                                true,
                                                () => {},
                                                datos_cliente
                                            )
                                        );
                                    } else {
                                        // console.log(data.id_venta);
                                        dispatch(
                                            facturarVenta(data.id_venta, true)
                                        );
                                    }
                                } else {
                                    Swal(
                                        "ERROR",
                                        "Ha ocurrido un error, espere un momento y vuelva a intentar",
                                        "error"
                                    );
                                }
                            } else {
                                getVentaImpresion(data.id_venta)
                                    .then((_data) => {
                                        dispatch(impresionTicketVenta(_data));
                                        dispatch(siguientePaso());
                                    })
                                    .catch((err) => {
                                        dispatch(impresionTicketVenta(data));
                                        dispatch(siguientePaso());
                                    });
                            }
                        })
                        .finally(() => {
                            dispatch(setLoader(false));
                        });
                }
            }
        }
    };

const getVentaImpresion = async (id_venta) => {
    let codigo_venta = null;
    try {
        const resp = await api.get(endpoint_datos_impresion_venta, {
            id: id_venta,
        });
        codigo_venta = await resp.codigo_venta;

        if (codigo_venta) {
            return resp;
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
};

const registrarVenta = (data_cliente) => (dispatch, getStore) => {
    const store = getStore();
    const form_cliente = store.form.ClienteVentaForm.values;

    const {
        efectivo,
        tarjeta,
        cheque,
        deposito,
        nota_credito,
        tipo_pago,
        fecha_vencimiento,
        redondeo,
        nota,
        nota_credito_cargada,
        retencion_iva,
        exencion_iva,
    } = store.venta;

    const productos = store.venta.seleccionados;

    let pagos = {
        efectivo: efectivo ? parseFloat(efectivo) : 0,
        tarjeta: tarjeta ? parseFloat(tarjeta) : 0,
        cheque: cheque ? parseFloat(cheque) : 0,
        deposito: deposito ? parseFloat(deposito) : 0,
        retencion_iva: retencion_iva ? parseFloat(retencion_iva) : 0,
        exencion_iva: exencion_iva ? parseFloat(exencion_iva) : 0,
        nota_credito: nota_credito ? parseFloat(nota_credito) : 0,
        nota: nota ? nota : "",
    };

    //dispatch(setLoader(true));

    //  SE CREA O SE OBTIENE AL CLIENTE Y LUEGO SE REGISTRA LA VENTA

    //  SE REGISTRA LA VENTA CON EL CLIENTE REGISTRADO
    const cliente = data.id;
    /*api.post(endpoint, {
        productos,
        cliente,
        referencia: form_cliente.referencia ? form_cliente.referencia : "",
        mostrar_referencia: form_cliente.mostrar_referencia
            ? form_cliente.mostrar_referencia
            : false,
        pagos,
        tipo_pago,
        fecha_vencimiento,
        redondeo,
        codigo_nota_credito: nota_credito_cargada
            ? nota_credito_cargada.nota_credito_fel.uuid
            : null,
    })
        .catch((err) => {
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, espere un momento y vuelva a intentar",
                    "error"
                );
            }
            dispatch(setLoader(false));
        })
        .then((data) => {
            if (data) {
                if (data.id_factura) {
                    Swal(
                        "¡Factura electrónica en proceso!",
                        "Se esta generando la factura, espere por favor",
                        "info"
                    );
                    dispatch(consultarEstadoFEL(data.id_factura));
                } else {
                    dispatch(impresionTicketVenta(data));
                    dispatch(setLoader(false));
                }
            }
        });*/
};

const consultarEstadoFEL =
    (
        id_factura,
        nueva_ov = false,
        setModalFacturar = () => {},
        data_cliente = false
    ) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get("factura_electronica/verificar_fel", { id_factura })
            .then((resp) => {
                status = resp.estado_factura;
                if (status === "proceso") {
                    setTimeout(() => {
                        dispatch(
                            consultarEstadoFEL(
                                id_factura,
                                nueva_ov,
                                setModalFacturar,
                                data_cliente
                            )
                        );
                    }, 1000);
                } else if (status === "facturado") {
                    Swal(
                        "Éxito",
                        "Factura electrónica registrada correctamente",
                        "success"
                    ).then(() => {
                        setTimeout(() => {
                            if (nueva_ov) {
                                dispatch(siguientePaso());
                                dispatch(impresionTicketVenta(resp.factura));
                                // dispatch(setLoader(false));
                            } else {
                                // console.log(setModalFacturar);
                                setModalFacturar({
                                    open: false,
                                    openSecond: false,
                                    data: {},
                                });
                                // dispatch(impresionTicketVenta(resp.factura));
                                dispatch(listarOC());
                                dispatch(listarOV());
                            }
                        }, 500);
                    });
                    dispatch(setLoader(false));
                    dispatch(setEstadoDescarga(false));
                } else {
                    const msj = resp.detail
                        ? `Error en facturación electrónica, ${resp.detail}, la venta se ha registrado y puedes reintentarlo en el listado de ordenes de venta`
                        : "Error en facturación electrónica, la venta se ha registrado y puedes reintentarlo en el listado de ordenes de venta";
                    Swal("ERROR", msj, "error").then((val) => {
                        dispatch(setLoader(false));
                        dispatch(forzarFinalizarVenta());
                    });
                    dispatch(setEstadoDescarga(false));
                }
            })
            .catch((err) => {
                Swal("ERROR", "Error al generar factura electrónica", "error");
            });
    };

const impresionTicketVenta = (movimiento) => (dispatch) => {
    dispatch(setLoader(true));
    let data_movimiento = movimiento;
    const vuelto = data_movimiento.vuelto_efectivo
        ? data_movimiento.vuelto_efectivo
        : 0;
    data_movimiento.monto_efectivo = data_movimiento.monto_efectivo + vuelto;
    dispatch(setMovimiento(data_movimiento));
    dispatch(usuarioActions.getMe());
    dispatch(cajaActions.get_ultimo_cierre());
    api.get(endpoint_datos_impresion_venta, { id: movimiento.id_venta })
        .catch((err) => {
            dispatch(setVentaImpresion(null));
        })
        .then((resp) => {
            if (resp) {
                let data_impresion = resp;
                let vuelto = 0;
                if (data_impresion.length === undefined) {
                    vuelto = data_impresion.vuelto_efectivo
                        ? data_impresion.vuelto_efectivo
                        : 0;
                    data_impresion.monto_efectivo =
                        data_impresion.monto_efectivo + vuelto;
                }
                dispatch(setVentaImpresion(data_impresion));
                if (data_movimiento.tipo_pago == pago_total)
                    dispatch(printActions.print("reciboVenta"));
                else {
                    dispatch(printActions.print("reciboVenta"));
                    // dispatch(printActions.print("reciboVentaCredito"));
                }
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const resetVenta = () => (dispatch) => {
    dispatch(setSeleccionados([]));
    dispatch(setMovimiento({}));
    dispatch(setCotizacionCargada(null));
};

export const setEfectivo = (value, tipo) => (dispatch, getStore) => {
    const store = getStore();
    const { efectivo } = store.venta;

    if (tipo == "monto") {
        if (value < 0) {
            dispatch(_setEfectivo({ ...efectivo, monto: 0 }));
        } else {
            dispatch(_setEfectivo({ ...efectivo, monto: value }));
        }
    }
    if (tipo == "descripcion") {
        dispatch(_setEfectivo({ ...efectivo, descripcion: value }));
    }
};
export const setTarjeta = (value, total, tipo) => (dispatch, getStore) => {
    const store = getStore();
    const { tarjeta } = store.venta;
    const form = store.form.wizardCrearOVForm.values;

    if (tipo == "monto") {
        if (value) {
            if (value < 0) {
                dispatch(_setTarjeta({ ...tarjeta, monto: 0 }));
            } else {
                if (value <= total) {
                    dispatch(_setTarjeta({ ...tarjeta, monto: value }));
                } else {
                    if (form.descuentos) {
                        dispatch(
                            _setTarjeta({
                                ...tarjeta,
                                monto: form.total_descuento,
                            })
                        );
                    } else if (form.venta_costo) {
                        dispatch(
                            _setTarjeta({ ...tarjeta, monto: form.total_costo })
                        );
                    } else {
                        dispatch(_setTarjeta({ ...tarjeta, monto: total }));
                    }
                }
            }
        } else {
            dispatch(_setTarjeta({ ...tarjeta, monto: NaN }));
        }
    }
    if (tipo == "descripcion") {
        dispatch(_setTarjeta({ ...tarjeta, descripcion: value }));
    }
};
export const setCheque = (value, total, tipo) => (dispatch, getStore) => {
    const store = getStore();
    const { cheque } = store.venta;
    const form = store.form.wizardCrearOVForm.values;

    if (tipo == "monto") {
        if (value) {
            if (value < 0) {
                dispatch(_setCheque({ ...cheque, monto: 0 }));
            } else {
                if (value <= total) {
                    dispatch(_setCheque({ ...cheque, monto: value }));
                } else {
                    if (form.descuentos) {
                        dispatch(
                            _setCheque({
                                ...cheque,
                                monto: form.total_descuento,
                            })
                        );
                    } else if (form.venta_costo) {
                        dispatch(
                            _setCheque({ ...cheque, monto: form.total_costo })
                        );
                    } else {
                        dispatch(_setCheque({ ...cheque, monto: total }));
                    }
                }
            }
        } else {
            dispatch(_setCheque({ ...cheque, monto: NaN }));
        }
    }
    if (tipo == "descripcion") {
        dispatch(_setCheque({ ...cheque, descripcion: value }));
    }
};
export const setTransferencia =
    (value, total, tipo) => (dispatch, getStore) => {
        const store = getStore();
        const { transferencia } = store.venta;
        const form = store.form.wizardCrearOVForm.values;

        if (tipo == "monto") {
            if (value) {
                if (value < 0) {
                    dispatch(_setTransferencia({ ...transferencia, monto: 0 }));
                } else {
                    if (value <= total) {
                        dispatch(
                            _setTransferencia({
                                ...transferencia,
                                monto: value,
                            })
                        );
                    } else {
                        if (form.descuentos) {
                            dispatch(
                                _setTransferencia({
                                    ...transferencia,
                                    monto: form.total_descuento,
                                })
                            );
                        } else if (form.venta_costo) {
                            dispatch(
                                _setTransferencia({
                                    ...transferencia,
                                    monto: form.total_costo,
                                })
                            );
                        } else {
                            dispatch(
                                _setTransferencia({
                                    ...transferencia,
                                    monto: total,
                                })
                            );
                        }
                    }
                }
            } else {
                dispatch(_setTransferencia({ ...transferencia, monto: NaN }));
            }
        }
        if (tipo == "descripcion") {
            dispatch(
                _setTransferencia({ ...transferencia, descripcion: value })
            );
        }
    };

export const setDeposito = (monto, total) => (dispatch) => {
    if (monto) {
        if (monto < 0) {
            dispatch(_setDeposito(0));
        } else {
            if (monto <= total) {
                dispatch(_setDeposito(monto));
            } else {
                dispatch(_setDeposito(total));
            }
        }
    } else {
        dispatch(_setDeposito(""));
    }
};

export const setNotaCredito = (monto, total) => (dispatch) => {
    if (monto) {
        if (monto < 0) {
            dispatch(_setNotaCredito(0));
        } else {
            dispatch(_setNotaCredito(monto));
        }
    } else {
        dispatch(_setNotaCredito(""));
    }
};

export const setRetencionIva = (monto, total) => (dispatch) => {
    if (monto) {
        if (monto < 0) {
            dispatch(_setRetencionIVA(0));
        } else {
            if (monto <= total) {
                dispatch(_setRetencionIVA(monto));
            } else {
                dispatch(_setRetencionIVA(total));
            }
        }
    } else {
        dispatch(_setRetencionIVA(""));
    }
};

export const setExencionIva = (monto, total) => (dispatch) => {
    if (monto) {
        if (monto < 0) {
            dispatch(_setExencionIVA(0));
        } else {
            if (monto <= total) {
                dispatch(_setExencionIVA(monto));
            } else {
                dispatch(_setExencionIVA(total));
            }
        }
    } else {
        dispatch(_setExencionIVA(""));
    }
};

export const leerCliente = (nit) => (dispatch) => {
    // esta accion lee un cliente a partir de su nit
    // para cargarlo a una venta, no confundir con leer()
    dispatch(changeForm("ClienteVentaForm", "nombre", ""));
    dispatch(changeForm("ClienteVentaForm", "direccion", ""));
    dispatch(changeForm("ClienteVentaForm", "buscar_nombre", ""));
    api.get(`${endpoint_clientes}/${nit}/por_nit/`)
        .catch((err) => {
            // si no existe retorna un 404,
            // no hacer nada
            if (err.detail) {
                if (err.detail == "El servicio de Infile no esta disponible") {
                    dispatch(setEstadoFormCliente(false));
                    ToastStore.error(err.detail);
                } else {
                    dispatch(setEstadoFormCliente(true));
                }
            } else {
                ToastStore.error(
                    "No fue posible obtener información del cliente"
                );
            }
        })
        .then((data) => {
            // cliente encontrado
            if (data) {
                dispatch(setEstadoFormCliente(true));
                dispatch(setNombreCliente(data.nombre));
                dispatch(changeForm("ClienteVentaForm", "nombre", data.nombre));
                dispatch(changeForm("ClienteVentaForm", "buscar_nombre", ""));
                dispatch(
                    changeForm(
                        "ClienteVentaForm",
                        "direccion",
                        data.direccion ? data.direccion : ""
                    )
                );
            }
        });
};

export const leerClienteNombre = (nombre, nit) => (dispatch) => {
    // esta accion lee un cliente a partir de su nombre
    dispatch(changeForm("ClienteVentaForm", "nit", ""));
    dispatch(changeForm("ClienteVentaForm", "nombre", ""));
    dispatch(changeForm("ClienteVentaForm", "direccion", ""));
    api.get(`${endpoint_clientes}/${nit}/por_nombre/`, { search: nombre })
        .catch((err) => {
            // si no existe retorna un 404,
            // no hacer nada
            if (err.detail) {
                if (err.detail == "El servicio de Infile no esta disponible") {
                    dispatch(setEstadoFormCliente(false));
                    ToastStore.error(err.detail);
                } else {
                    dispatch(setEstadoFormCliente(true));
                }
            } else {
                ToastStore.error(
                    "No fue posible obtener información del cliente"
                );
            }
        })
        .then((data) => {
            // cliente encontrado
            if (data) {
                dispatch(setEstadoFormCliente(true));
                dispatch(setNitCliente(data.nit));
                dispatch(changeForm("ClienteVentaForm", "nit", data.nit));
                dispatch(setNombreCliente(data.nombre));
                dispatch(changeForm("ClienteVentaForm", "nombre", data.nombre));
                dispatch(
                    changeForm(
                        "ClienteVentaForm",
                        "direccion",
                        data.direccion ? data.direccion : ""
                    )
                );
            }
        });
};

export const limpiarDatosCliente = () => (dispatch) => {
    dispatch(setNitCliente(""));
    dispatch(setNombreCliente(""));
    dispatch(changeForm("ClienteVentaForm", "nit", ""));
    dispatch(changeForm("ClienteVentaForm", "nombre", ""));
    dispatch(changeForm("ClienteVentaForm", "direccion", ""));
    dispatch(changeForm("ClienteVentaForm", "referencia", ""));
};

export const changeDatosClientes = (campo, value) => (dispatch, getStore) => {
    const nit = getStore().venta.nit_cliente;
    if (campo === "nit") {
        dispatch(setNitCliente(value));
    } else {
        dispatch(setNombreCliente(value));
    }
};

export const changeDisabled = (value) => (dispatch, getStore) => {
    dispatch(setEstadoFormCliente(value));
};

export const cambioBuscador = () => (dispatch, getStore) => {
    const store = getStore();
    const ver_buscador = store.venta.ver_buscador;
    dispatch(setVerBuscador(!ver_buscador));
};
export const cambioCobro = (total) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = store.venta.seleccionados;
    const { ver_buscador, ver_cobro } = store.venta;
    if (total <= 0) {
        ToastStore.error(
            "El monto de descuento por vale no debe sobrepasar ni igualar el total de la venta"
        );
    } else {
        if (seleccionados.length) {
            dispatch(setVerCobro(!ver_cobro));
            dispatch(setVerBuscador(!ver_buscador));
        } else {
            if (!ver_cobro) {
                ToastStore.error("La venta no tiene productos seleccionados.");
            } else {
                dispatch(setVerCobro(!ver_cobro));
                dispatch(setVerBuscador(!ver_buscador));
            }
        }
    }
};

export const leerClienteCotizacion = (nit) => (dispatch) => {
    // esta accion lee un cliente a partir de su nit
    // para cargarlo a una venta, no confundir con leer()
    api.get(`${endpoint_clientes}/${nit}/por_nit/`)
        .catch((err) => {
            // si no existe retorna un 404,
            // no hacer nada
        })
        .then((data) => {
            // cliente encontrado
            if (data) {
                dispatch(
                    changeForm("ClienteCotizacionForm", "nombre", data.nombre)
                );
                dispatch(
                    changeForm("ClienteCotizacionForm", "correo", data.correo)
                );
            }
        });
};

export const enviarCotizacion = () => (dispatch, getStore) => {
    console.log("ENVIANDO COTIZACION CREAR");
    const store = getStore();
    const productos = store.venta.seleccionados;
    const form = store.form.wizardCrearOVForm.values;

    const { tipo_venta } = store.venta;

    const {
        descuentos,
        porcentaje_descuento,
        venta_costo,
        descripcion,
        cliente_id,
        fecha_limite,
        enviar_correo,
        correo,
        cliente,
        nombre,
        direccion,
        nit_cliente,
    } = form;

    //if (tipo_venta == cotizacion) {
    //    console.log("Es cotizacion");
    //} else false;
    //const cliente = form.cliente_id;
    //const fecha_vencimiento = form.fecha_limite;

    const datos = {
        descuentos,
        porcentaje_descuento,
        venta_costo,
        descripcion,
        cliente_id,
        fecha_limite,
        nit: nit_cliente,
        cliente,
        nombre,
        direccion,
        enviar_correo,
        correo,
    };

    dispatch(setLoader(true));

    api.post(endpoint_cotizacion, { productos, enviado_caja: false, ...datos })
        .catch((err) => {
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, espere un momento y vuelva a intentar",
                    "error"
                );
            }
            dispatch(setLoader(false));
        })
        .then((data) => {
            if (data) {
                let data_venta_cotizacion = data;
                dispatch(setVentaCotizacion(data_venta_cotizacion));
                dispatch(siguientePaso());
                dispatch(setVerImpresion(true));
                ToastStore.success("Cotizacion generada exitosamente");
                dispatch(printActions.print("ticketCotizacion"));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const editarCotizacion = (id_cotizacion) => (dispatch, getStore) => {
    console.log("ENVIANDO COTIZACION EDITAR");
    const store = getStore();
    const productos = store.venta.seleccionados;

    const form = store.form.wizardCrearOVForm.values;

    const { tipo_venta } = store.venta;

    const {
        descuentos,
        porcentaje_descuento,
        venta_costo,
        descripcion,
        cliente_id,
        fecha_limite,
        nit,
        cliente,
        nombre,
        direccion,
        nit_cliente,
    } = form;

    //if (tipo_venta == cotizacion) {
    //    console.log("Es cotizacion");
    //} else false;
    //const cliente = form.cliente_id;
    //const fecha_vencimiento = form.fecha_limite;

    const datos = {
        descuentos,
        porcentaje_descuento,
        venta_costo,
        descripcion,
        cliente_id,
        fecha_limite,
        nit: nit_cliente,
        cliente,
        nombre,
        direccion,
    };

    dispatch(setLoader(true));

    api.put(`${endpoint_cotizacion}/${id_cotizacion}`, {
        productos,
        enviado_caja: false,
        ...datos,
    })
        .catch((err) => {
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, espere un momento y vuelva a intentar",
                    "error"
                );
            }
            dispatch(setLoader(false));
        })
        .then((data) => {
            if (data) {
                let data_venta_cotizacion = data;
                dispatch(setVentaCotizacion(data_venta_cotizacion));
                dispatch(siguientePaso());
                dispatch(setVerImpresion(true));
                ToastStore.success("Cotizacion generada exitosamente");
                dispatch(printActions.print("ticketCotizacion"));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const actualizarCotizacion = (datos) => (dispatch, getStore) => {
    const store = getStore();
    const { seleccionados, cotizacion_cargada } = store.venta;
    dispatch(setLoader(true));
    api.put(`${endpoint_cotizacion}/${cotizacion_cargada.id}`, {
        productos: seleccionados,
        enviado_caja: false,
        ...datos,
    })
        .catch((err) => {
            if (err) {
                Swal(
                    "ERROR",
                    err.detail ||
                        "Error al actualizar la cotización, intenta más tarde",
                    "error"
                );
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, espere un momento y vuelva a intentar",
                    "error"
                );
            }
        })
        .then((data) => {
            if (data) {
                let data_venta_cotizacion = data;
                dispatch(setVentaCotizacion(data_venta_cotizacion));
                dispatch(setVerImpresion(true));
                dispatch(printActions.print("ticketCotizacion"));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const finalizarCotizacion = () => (dispatch) => {
    dispatch(usuarioActions.getMe());
    dispatch(setMovimiento({}));
    dispatch(setSeleccionados([]));
    dispatch(limpiarDatosCliente());
    dispatch(setVerBuscador(false));
    dispatch(setVerCobro(false));
    dispatch(_setEfectivo(""));
    dispatch(_setTarjeta(""));
    dispatch(_setCheque(""));
    dispatch(_setDeposito(""));
    dispatch(_setRetencionIVA(""));
    dispatch(_setExencionIVA(""));
    dispatch(_setNotaCredito(""));
    dispatch(setNota(""));
    dispatch(setFechaVencimientoCredito(moment().format("YYYY-MM-DD")));
    dispatch(setTipoPago(pago_total));
    dispatch(setBuscador(""));
    dispatch(setCodigoNotaCredito(""));
    dispatch(setNotaCreditoCargada(null));
    dispatch(listar());
    dispatch(setVentaCotizacion(null));
    dispatch(setVerImpresion(false));
    dispatch(setCotizacionCargada(null));
};

export const pagarVentaCotizacon = (total) => (dispatch, getStore) => {
    const store = getStore();
    const {
        efectivo,
        tarjeta,
        tipo_pago,
        redondeo,
        seleccionados,
        movimiento,
        cheque,
        deposito,
        nota_credito,
        nota,
        retencion_iva,
        exencion_iva,
    } = store.venta;
    const { conf_venta_redondeo } = store.usuario;
    let pagos = {
        efectivo: efectivo ? parseFloat(efectivo) : 0,
        tarjeta: tarjeta ? parseFloat(tarjeta) : 0,
        cheque: cheque ? parseFloat(cheque) : 0,
        deposito: deposito ? parseFloat(deposito) : 0,
        retencion_iva: retencion_iva ? parseFloat(retencion_iva) : 0,
        exencion_iva: exencion_iva ? parseFloat(exencion_iva) : 0,
        nota_credito: nota_credito ? parseFloat(nota_credito) : 0,
        nota: nota ? nota : "",
    };

    if (movimiento.sucursal !== undefined) {
        dispatch(setMovimiento({}));
        dispatch(setSeleccionados([]));
        dispatch(limpiarDatosCliente());
        dispatch(setVerBuscador(false));
        dispatch(setVerCobro(false));
        dispatch(_setEfectivo(""));
        dispatch(_setTarjeta(""));
        dispatch(_setCheque(""));
        dispatch(_setDeposito(""));
        dispatch(_setRetencionIVA(""));
        dispatch(_setExencionIVA(""));
        dispatch(_setNotaCredito(""));
        dispatch(setNota(""));
        dispatch(setFechaVencimientoCredito(moment().format("YYYY-MM-DD")));
        dispatch(setTipoPago(pago_total));
        dispatch(setBuscador(""));
        dispatch(setCodigoNotaCredito(""));
        dispatch(setNotaCreditoCargada(null));
        dispatch(listar());
        dispatch(setCotizacionCargada(null));
    } else {
        total = total ? parseFloat(total) : 0;
        //  VALIDACION DE MONTO TOTAL
        let monto_valido = false;
        const total_pago =
            pagos.tarjeta +
            pagos.efectivo +
            pagos.cheque +
            pagos.deposito +
            pagos.retencion_iva +
            pagos.exencion_iva +
            pagos.nota_credito;
        if (tipo_pago == 100) {
            if (total_pago < total) {
                Swal(
                    "ERROR",
                    "Debe cancelar el monto total de la venta.",
                    "error"
                );
                monto_valido = false;
            } else {
                monto_valido = true;
            }
        } else {
            const monto_a_pagar = total_pago;

            if (monto_a_pagar >= total) {
                Swal(
                    "ERROR",
                    "El anticipo debe ser menor al monto total de la venta.",
                    "error"
                );
                monto_valido = false;
            } else {
                monto_valido = true;
            }
        }

        if (monto_valido) {
            if (conf_venta_redondeo) {
                // CALCULO DE RECIDUO PARA EL REDONDEO
                let total = 0;
                seleccionados.forEach((producto) => {
                    if (!producto.es_vale) {
                        const precio = producto.precio
                            ? parseFloat(producto.precio)
                            : 0;
                        const cantidad = producto.cantidad
                            ? parseFloat(producto.cantidad)
                            : 0;
                        total = total + precio * cantidad;
                    } else {
                        total -= producto.total_importe;
                    }
                });

                const decimal = parseFloat((total % 1).toFixed(2));
                const moneda_mas_pequeno = 0.05;
                const cantidad_monedas = Math.floor(
                    decimal / moneda_mas_pequeno
                );
                const reciduo = parseFloat(
                    (decimal - cantidad_monedas * moneda_mas_pequeno).toFixed(2)
                );
                if (reciduo > 0 && !redondeo) {
                    //  REDONDEO HACIA ARRIBA O ABAJO
                    Swal({
                        title: "¿Cómo quieres redondear el total de la venta?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Hacia arriba",
                        cancelButtonText: "Hacia abajo",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.value) {
                            dispatch(setRedondeo("arriba"));
                        } else if ((result.dismiss = "cancel")) {
                            dispatch(setRedondeo("abajo"));
                        }
                    });
                } else {
                    dispatch(validarClienteCotizacion());
                }
            } else {
                dispatch(validarClienteCotizacion());
            }
        }
    }
};

const validarClienteCotizacion = () => (dispatch, getStore) => {
    //  VALIDAR CLIENTE
    const store = getStore();
    const data_cliente = store.form.ClienteVentaForm.values;
    if (data_cliente.nit != "") {
        if (data_cliente.nombre == "") {
            Swal(
                "Nombre del cliente",
                "Por favor de ingresar el nombre del cliente.",
                "error"
            );
        } else {
            dispatch(registrarVentaCotizacion(data_cliente));
        }
    } else {
        Swal({
            title: "¿Registrar venta con cliente Consumidor Final?",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Sí",
            cancelButtonText: "No",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                const data_cliente_cf = {
                    nit: "C.F.",
                    nombre: "Consumidor Final",
                };
                dispatch(registrarVentaCotizacion(data_cliente_cf));
            }
        });
    }
};

const registrarVentaCotizacion = (data_cliente) => (dispatch, getStore) => {
    const store = getStore();
    const form_cliente = store.form.ClienteVentaForm.values;
    const {
        efectivo,
        tarjeta,
        tipo_pago,
        fecha_vencimiento,
        redondeo,
        cotizacion_cargada,
        cheque,
        deposito,
        nota_credito,
        nota,
        nota_credito_cargada,
        retencion_iva,
        exencion_iva,
    } = store.venta;
    const productos = store.venta.seleccionados;

    let pagos = {
        efectivo: efectivo ? parseFloat(efectivo) : 0,
        tarjeta: tarjeta ? parseFloat(tarjeta) : 0,
        cheque: cheque ? parseFloat(cheque) : 0,
        deposito: deposito ? parseFloat(deposito) : 0,
        retencion_iva: retencion_iva ? parseFloat(retencion_iva) : 0,
        exencion_iva: exencion_iva ? parseFloat(exencion_iva) : 0,
        nota_credito: nota_credito ? parseFloat(nota_credito) : 0,
        nota: nota ? nota : "",
    };

    dispatch(setLoader(true));

    //  SE CREA O SE OBTIENE AL CLIENTE Y LUEGO SE REGISTRA LA VENTA
    api.post(endpoint_clientes, data_cliente)
        .catch((err) => {
            Swal(
                "ERROR",
                "Ha ocurrido un error al crear el cliente, por favor vuelve a intentar.",
                "error"
            );
            return null;
        })
        .then((data) => {
            if (data) {
                //  SE REGISTRA LA VENTA CON EL CLIENTE REGISTRADO
                const cliente = data.id;
                api.post(`${endpoint}/cotizacion`, {
                    cotizacion: cotizacion_cargada.id,
                    productos,
                    cliente,
                    referencia: form_cliente.referencia
                        ? form_cliente.referencia
                        : "",
                    mostrar_referencia: form_cliente.mostrar_referencia
                        ? form_cliente.mostrar_referencia
                        : false,
                    pagos,
                    tipo_pago,
                    fecha_vencimiento,
                    redondeo,
                    codigo_nota_credito: nota_credito_cargada
                        ? nota_credito_cargada.nota_credito_fel.uuid
                        : null,
                })
                    .catch((err) => {
                        if (err) {
                            Swal("ERROR", err.detail, "error");
                        } else {
                            Swal(
                                "ERROR",
                                "Ha ocurrido un error, espere un momento y vuelva a intentar",
                                "error"
                            );
                        }
                    })
                    .then((data) => {
                        if (data) {
                            let data_movimiento = data;
                            const vuelto = data_movimiento.vuelto_efectivo
                                ? data_movimiento.vuelto_efectivo
                                : 0;
                            data_movimiento.monto_efectivo =
                                data_movimiento.monto_efectivo + vuelto;
                            dispatch(setMovimiento(data_movimiento));
                            dispatch(usuarioActions.getMe());
                            dispatch(cajaActions.get_ultimo_cierre());
                            api.get(endpoint_datos_impresion_venta, {
                                id: data.id_venta,
                            })
                                .catch((err) => {
                                    dispatch(setVentaImpresion(null));
                                })
                                .then((resp) => {
                                    if (resp) {
                                        let data_impresion = resp;
                                        let vuelto = 0;
                                        if (
                                            data_impresion.length === undefined
                                        ) {
                                            vuelto =
                                                data_impresion.vuelto_efectivo
                                                    ? data_impresion.vuelto_efectivo
                                                    : 0;
                                            data_impresion.monto_efectivo =
                                                data_impresion.monto_efectivo +
                                                vuelto;
                                        }
                                        dispatch(
                                            setVentaImpresion(data_impresion)
                                        );
                                        if (
                                            data_movimiento.tipo_pago ==
                                            pago_total
                                        ) {
                                            //console.log("reciboVenta");
                                            dispatch(
                                                printActions.print(
                                                    "reciboVenta"
                                                )
                                            );
                                        } else
                                            dispatch(
                                                printActions.print(
                                                    "reciboVentaCredito"
                                                )
                                            );
                                    }
                                });
                        }
                    })
                    .finally(() => {
                        dispatch(setLoader(false));
                    });
            }
        });
};

export const consultarNotaCredito = (total) => (dispatch, getStore) => {
    const store = getStore();
    const { codigo_nota_credito } = store.venta;

    api.get("notas_credito_fel/consultar", { codigo_nota_credito })
        .catch((err) => {
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, espere un momento y vuelva a intentar",
                    "error"
                );
            }
            dispatch(setNotaCreditoCargada(null));
        })
        .then((resp) => {
            if (resp) {
                //  Validar si se agrega o no la nota de crédito por el monto total y el total de importe
                if (resp.total_importe > total) {
                    Swal(
                        "Info",
                        `No es posible agregar la nota de crédito porque el total de la venta (${total}) es menor al monto de la nota de crédito (${resp.total_importe})`,
                        "info"
                    );
                } else {
                    dispatch(setNotaCreditoCargada(resp));
                    dispatch(setNotaCredito(resp.total_importe, total));
                }
            } else {
                dispatch(setNotaCreditoCargada(null));
            }
        });
};

export const limpiarNotaCredito = () => (dispatch) => {
    dispatch(setCodigoNotaCredito(""));
    dispatch(setNotaCreditoCargada(null));
};

export const siguientePaso = () => (dispatch, getStore) => {
    const store = getStore();
    const { seleccionados, paso, venta_costo } = store.venta;
    const data_form = store.form.wizardCrearOVForm.values;
    const { descuentos, total } = data_form;

    if (paso == 1) {
        if (!seleccionados.length) {
            ToastStore.error("La orden de venta no tiene productos");
            return false;
        }
        // const tipo_venta = data_form.tipo_venta ? data_form.tipo_venta : false;
        // dispatch(setTipoVenta(tipo_venta));
    } else if (paso == 2) {
        const form_venta_costo = data_form.venta_costo
            ? data_form.venta_costo
            : false;
        const descuentos = data_form.descuentos ? data_form.descuentos : false;
        const porcentaje_descuento = data_form.porcentaje_descuento
            ? data_form.porcentaje_descuento
            : NaN;

        if (descuentos && total) {
            let percent = 0;
            let total_descuento = 0;
            percent = ((porcentaje_descuento * total) / 100).toFixed(2);
            total_descuento = (total - percent).toFixed(2);

            dispatch(
                dispatch(
                    change(
                        "wizardCrearOVForm",
                        "total_descuento",
                        total_descuento
                    )
                    //change(
                    //    "wizardCrearOVForm",
                    //    "porcentaje_descuento",
                    //    porcentaje_descuento
                    //)
                )
                //initializeForm("wizardCrearOVForm", {
                //    ...data_form,
                //    total_descuento: false,
                //})
            );
        }
        //--
        dispatch(setCostoVenta(form_venta_costo));
    }
    dispatch(setPaso(paso + 1));
};

export const anteriorPaso = () => (dispatch, getStore) => {
    const store = getStore();

    const { paso } = store.orden_venta;
    // if (paso - 1 < 2) {
    //     dispatch(setTipoVenta(null));
    // }
    if (paso - 1 == 2) {
        dispatch(_setEfectivo(""));
        dispatch(_setTarjeta(""));
        dispatch(_setTransferencia(""));
        dispatch(_setCheque(""));
        dispatch(_setDeposito(""));
    }
    dispatch(setPaso(paso - 1));
};

//  CLIENTE -----------------------------------------------------------------
export const listarCliente =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoaderCliente(true));
        const store = getStore();
        const sucursal_id = store.usuario.sucursal;
        const { search_cliente } = store.orden_venta;
        api.get(endpoint_clientes, {
            page,
            search: search_cliente,
            sucursal_id,
        })
            .catch((err) => {})
            .then((data) => {
                if (data) {
                    dispatch(setDataCliente(data));
                }
                dispatch(setPageCliente(page));
            })
            .finally(() => {
                dispatch(setLoaderCliente(false));
            });
    };

export const buscarCliente = (search) => (dispatch) => {
    dispatch(setBuscadorCliente(search));
    dispatch(listarCliente());
};

export const seleccionarCliente = (data) => (dispatch, getStore) => {
    const store = getStore();
    const form = store.form.wizardCrearOVForm.values;
    const seleccionados = store.orden_venta.seleccionados;

    dispatch(
        initializeForm("wizardCrearOVForm", {
            ...form,
            nit_cliente: data.nit,
            cliente_id: data.id,
            cliente: data.nombre,
            encargado: data.nombre_encargado,
            correo: data.correo,
            sucursal_id: null,
            nombre_sucursal: null,
            direccion_sucursal: null,
            departamento_sucursal: null,
            municipio_sucursal: null,
            direccion_facturacion: null,
            direccion_entrega: false,
        })
    );

    dispatch(setDirecciones(data.direcciones));
    //dispatch(listar());

    dispatch(setModalListadoCliente(false));
};

export const setProductoActual = (producto) => (dispatch) => {
    dispatch(_setProductoActual(producto));
};

export const removerAll = () => (dispatch, getStore) => {
    const store = getStore();
    const form = store.form.wizardCrearOVForm.values;

    dispatch(
        initializeForm("wizardCrearOVForm", {
            fecha: form.fecha,
            // tipo_venta: envio,
        })
    );
    dispatch(setSeleccionados([]));
    dispatch(setProductoActual({}));
    dispatch(setPaso(1));
    dispatch(setMovimiento({}));
    dispatch(limpiarDatosCliente());
    dispatch(setVerBuscador(false));
    dispatch(setVerCobro(false));
    dispatch(_setEfectivo(""));
    dispatch(_setTarjeta(""));
    dispatch(_setTransferencia(""));
    dispatch(_setCheque(""));
    dispatch(_setDeposito(""));
    dispatch(_setRetencionIVA(""));
    dispatch(_setExencionIVA(""));
    dispatch(_setNotaCredito(""));
    dispatch(setNota(""));
    dispatch(setFechaVencimientoCredito(moment().format("YYYY-MM-DD")));
    dispatch(setTipoPago(pago_total));
    dispatch(setBuscador(""));
    dispatch(setCodigoNotaCredito(""));
    dispatch(setNotaCreditoCargada(null));
    dispatch(setDirecciones([]));
    //dispatch(listar());
    dispatch(setCotizacionCargada(null));
    // dispatch(setTipoVenta(null));
    //ReactDOM.unmountComponentAtNode(this.containerEl);
    //store.dispatch(resetStore());
    //super.remove();
    //const store = getStore();
    //console.log(store.initialState);
    //dispatch(initialState());
    //dispatch({
    //    type: ITEM,
    //    item: {},
    //});
};

export const reImpresionVenta = (id_OV, tipo_venta) => (dispatch) => {
    dispatch(setLoader(true));
    //let data_movimiento = movimiento;
    //const vuelto = data_movimiento.vuelto_efectivo
    //    ? data_movimiento.vuelto_efectivo
    //    : 0;
    //data_movimiento.monto_efectivo = data_movimiento.monto_efectivo + vuelto;

    dispatch(setMovimiento(data_movimiento));
    dispatch(usuarioActions.getMe());
    dispatch(cajaActions.get_ultimo_cierre());
    api.get(endpoint_datos_impresion_venta, { id: id_OV })
        .catch((err) => {
            dispatch(setVentaImpresion(null));
        })
        .then((resp) => {
            if (resp) {
                let data_impresion = resp;
                let vuelto = 0;
                if (data_impresion.length === undefined) {
                    vuelto = data_impresion.vuelto_efectivo
                        ? data_impresion.vuelto_efectivo
                        : 0;
                    data_impresion.monto_efectivo =
                        data_impresion.monto_efectivo + vuelto;
                }
                dispatch(setVentaImpresion(data_impresion));

                //import { envio, factura, credito, cotizacion } from "utility/constantes";
                if (tipo_venta == envio)
                    dispatch(printActions.print("reciboVenta"));
                else {
                    dispatch(printActions.print("reciboVenta"));
                    // dispatch(printActions.print("reciboVentaCredito"));
                }
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const reImprimirCotizacion = (id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get(`${endpoint_cotizacion}/${id}`)
        .catch((err) => {
            dispatch(setLoader(false));
        })
        .then((data) => {
            if (data) {
                let data_venta_cotizacion = data;
                dispatch(setVentaCotizacion(data_venta_cotizacion));
                //dispatch(setVerImpresion(true));
                //ToastStore.success("Cotizacion generada exitosamente");
                dispatch(printActions.print("ticketCotizacion"));
            }
            dispatch(setLoader(false));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const nuevoCliente = (nombreTest) => (dispatch, getStore) => {
    dispatch(setLoaderCliente(true));
    const store = getStore();
    const { values } = store.form.wizardClientForm;
    values.tipo = "CLIENTE";
    values.nombre = nombreTest;
    //values.empresa_id = 1;

    const form = store.form.wizardCrearOVForm.values;
    api.post(endpoint_clientes + "/cliente_ov", values)
        .catch((err) => {
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, espere un momento y vuelva a intentar",
                    "error"
                );
            }
        })
        .then((data) => {
            if (data) {
                ToastStore.success("Cliente creado exitosamente.");

                dispatch(
                    initializeForm("wizardCrearOVForm", {
                        ...form,
                        nit_cliente: data.nit,
                        cliente_id: data.id,
                        cliente: data.nombre,
                        correo: data.correo,
                        direccion_facturacion: null,
                        direccion_entrega: false,
                    })
                );

                dispatch(setDirecciones(data.direcciones));

                dispatch(reset("wizardClientForm"));
                dispatch(setModalNuevoCliente(false));
            }
        })
        .finally(() => {
            dispatch(setLoaderCliente(false));
        });
};

export const limpiarVentaCotizacion = () => (dispatch) => {
    dispatch(setVentaCotizacion(null));
};

const changewizardCrearOVForm =
    (
        valor_descuento,
        venta_al_costo,
        descripcion,
        cliente,
        datos_cliente,
        fecha
    ) =>
    (dispatch, getStore) => {
        // dispatch(change("wizardCrearOVForm", "descuentos", true));

        const store = getStore();

        let form = {};
        try {
            form = store.form.wizardCrearOVForm.values;
        } catch (error) {}

        let descuentos = false;

        if (valor_descuento > 0 && !venta_al_costo) {
            descuentos = true;
        }
        dispatch(
            initializeForm("wizardCrearOVForm", {
                ...form,
                fecha: moment().format(fecha),
                descuentos,
                descripcion,
                porcentaje_descuento: valor_descuento,
                venta_costo: venta_al_costo,
                tipo_venta: 40000,
                direccion: datos_cliente.direccion,
                nombre: datos_cliente.nombre,
            })
        );
        dispatch(seleccionarCliente(cliente));
    };

export const facturarVenta =
    (
        id_venta,
        nueva_ov = false,
        setModalFacturar = () => {},
        data_cliente = false
    ) =>
    (dispatch) => {
        console.log("###############facturarVenta");
        dispatch(setLoader(true));
        dispatch(setEstadoDescarga(true));
        // return nuevos_datos;
        const body = {
            id_venta,
        };

        if (data_cliente) {
            body.data_cliente = data_cliente;
        }

        api.post(`factura_electronica/generar_factura`, body)
            .then((data) => {
                if (data) {
                    dispatch(
                        consultarEstadoFEL(
                            data.id_factura,
                            nueva_ov,
                            setModalFacturar,
                            data_cliente
                        )
                    );
                }
            })
            .catch((err) => {
                // dispatch(push("/punto_de_venta"));
                if (err) {
                    Swal("ERROR", err.detail, "error");
                } else {
                    Swal(
                        "ERROR",
                        "Error al generar factura electrónica",
                        "error"
                    );
                }
                console.log(err);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const setEstadoDescargaValue = (estado) => (dispatch) => {
    dispatch(setEstadoDescarga(estado));
};

export const generarDescuento = (id_mov, id_venta, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`ordenes_venta/nc_descuento/`, { id_mov, ...data })
        .then((data) => {
            // dispatch(consultarEstadoNotaFEL(data.id_nota, id_venta, true));
            console.log(data);
            dispatch(
                push(
                    `/bodega/nota_credito_cliente/${id_venta}/vale/${data.id_devolucion}`
                )
            );
            console.log("OK:", data);
        })
        .catch((err) => {
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal("ERROR", "Error al generar descuento", "error");
            }
            console.log(err);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const setDatosCliente = (datos_cliente) => (dispatch) => {
    dispatch(_setDatosCliente(datos_cliente));
};
// ------------------------------------
// PureActions
// ------------------------------------

export const setMovimiento = (movimiento) => ({
    type: MOVIMIENTO,
    movimiento,
});

export const setProductos = (data) => ({
    type: PRODUCTOS,
    data,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});

export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});

export const setNitCliente = (nit_cliente) => ({
    type: SET_NIT_CLIENTE,
    nit_cliente,
});

export const setNombreCliente = (nombre_cliente) => ({
    type: SET_NOMBRE_CLIENTE,
    nombre_cliente,
});

export const setSeleccionados = (seleccionados) => ({
    type: SELECCIONADOS,
    seleccionados,
});

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.venta.page;
    dispatch(listar(page));
};

export const setVentaImpresion = (venta_impresion) => ({
    type: SET_IMPRESION,
    venta_impresion,
});
export const setUuidReqProductos = (uuid_req_productos) => ({
    type: UUID_REQ_PRODUCTOS,
    uuid_req_productos,
});

export const setTipoPago = (tipo_pago) => ({
    type: SET_TIPO_PAGO,
    tipo_pago,
});

export const setTipoVenta = (tipo_venta) => ({
    type: SET_TIPO_VENTA,
    tipo_venta,
});

export const setFechaVencimientoCredito = (fecha_vencimiento) => ({
    type: SET_FECHA_VENCIMIENTO_CREDITO,
    fecha_vencimiento,
});
export const _setEfectivo = (efectivo) => ({
    type: SET_EFECTIVO,
    efectivo,
});
export const _setTarjeta = (tarjeta) => ({
    type: SET_TARJETA,
    tarjeta,
});
export const _setCheque = (cheque) => ({
    type: SET_CHEQUE,
    cheque,
});
export const _setTransferencia = (transferencia) => ({
    type: SET_TRANSFERENCIA,
    transferencia,
});
export const _setDeposito = (deposito) => ({
    type: SET_DEPOSITO,
    deposito,
});
export const _setNotaCredito = (nota_credito) => ({
    type: SET_NOTA_CREDITO,
    nota_credito,
});
export const _setRetencionIVA = (retencion_iva) => ({
    type: SET_RETENCION_IVA,
    retencion_iva,
});
export const _setExencionIVA = (exencion_iva) => ({
    type: SET_EXENCION_IVA,
    exencion_iva,
});
export const setNota = (nota) => ({
    type: SET_NOTA,
    nota,
});
export const setCodigoNotaCredito = (codigo_nota_credito) => ({
    type: SET_CODIGO_NOTA_CREDITO,
    codigo_nota_credito,
});

export const setVerBuscador = (ver_buscador) => ({
    type: SET_VER_BUSCADOR,
    ver_buscador,
});
export const setVerCobro = (ver_cobro) => ({
    type: SET_VER_COBRO,
    ver_cobro,
});
export const setRedondeo = (redondeo) => ({
    type: SET_REDONDEO,
    redondeo,
});

export const setVentaCotizacion = (venta_cotizacion) => ({
    type: SET_VENTA_COTIZACION,
    venta_cotizacion,
});
export const setVerImpresion = (ver_impresion) => ({
    type: SET_VER_IMPRESION,
    ver_impresion,
});

export const setCotizacionCargada = (cotizacion_cargada) => ({
    type: SET_COTIZACION_CARGADA,
    cotizacion_cargada,
});
export const setNotaCreditoCargada = (nota_credito_cargada) => ({
    type: SET_NOTA_CREDITO_CARGADA,
    nota_credito_cargada,
});
export const setEstadoFormCliente = (estado_form_cliente) => ({
    type: SET_ESTADO_FORM_CLIENTE,
    estado_form_cliente,
});

export const _setDatosCliente = (datos_cliente) => ({
    type: SET_DATOS_CLIENTE,
    datos_cliente,
});

export const setPaso = (paso) => ({
    type: PASO,
    paso,
});

//  CLIENTES
export const setPageCliente = (page_cliente) => ({
    type: PAGE_CLIENTES,
    page_cliente,
});
export const setLoaderCliente = (loader_cliente) => ({
    type: LOADER_CLIENTES,
    loader_cliente,
});
export const setDataCliente = (data_cliente) => ({
    type: DATA_CLIENTES,
    data_cliente,
});
export const setBuscadorCliente = (search_cliente) => ({
    type: BUSCADOR_CLIENTES,
    search_cliente,
});

export const setModalNuevoCliente = (modal_cliente) => ({
    type: MODAL_NUEVO_CLIENTE,
    modal_cliente,
});

export const setModalListadoCliente = (modal_listado_cliente) => ({
    type: MODAL_LISTADO_CLIENTE,
    modal_listado_cliente,
});

export const _setProductoActual = (producto_actual) => ({
    type: PRODUCTO_ACTUAL,
    producto_actual,
});

export const setCostoVenta = (venta_costo) => ({
    type: SET_VENTA_COSTO,
    venta_costo,
});

export const setMunicipios = (municipios) => ({
    type: MUNICIPIOS,
    municipios,
});

export const setDirecciones = (direcciones) => ({
    type: DIRECCIONES,
    direcciones,
});

export const actions = {
    listar,
    buscar,
    sortChange,
    setMovimiento,
    cambiarPrecio,
    cambiarPrecioEditable,
    agregarVale,
    agregar,
    quitar,
    agregarPrimer,
    setSeleccionados,
    pagar,
    restar,
    sumar,
    ingresoCantidad,
    cambiarDescuento,
    cambiarPrecioFinal,
    desplegarItem,
    resetVenta,
    setTipoPago,
    setTipoVenta,
    setFechaVencimientoCredito,
    setEfectivo,
    setTarjeta,
    setCheque,
    setTransferencia,
    setDeposito,
    leerCliente,
    limpiarDatosCliente,
    changeDatosClientes,
    cambioBuscador,
    cambioCobro,
    asignarUnidadDeMedida,
    setRedondeo,
    leerClienteCotizacion,
    enviarCotizacion,
    editarCotizacion,
    finalizarCotizacion,
    pagarVentaCotizacon,
    actualizarCotizacion,
    setNota,
    setNotaCredito,
    setCodigoNotaCredito,
    consultarNotaCredito,
    limpiarNotaCredito,
    setRetencionIva,
    setExencionIva,
    setEstadoFormCliente,
    leerClienteNombre,
    changewizardCrearOVForm,

    //STEPER
    siguientePaso,
    anteriorPaso,

    //  CLIENTE
    nuevoCliente,
    setModalNuevoCliente,
    listarCliente,
    buscarCliente,
    seleccionarCliente,
    setModalListadoCliente,
    setMunicipios,
    //setDirecciones,

    //Registrar OV
    registrarOV,
    asignarPrecio,

    setProductoActual,
    asignarCantidad,
    removerAll,

    //ReImpresion
    reImpresionVenta,
    reImprimirCotizacion,
    limpiarVentaCotizacion,

    //Facturacion
    facturarVenta,
    setEstadoDescargaValue,
    generarDescuento,
    setDatosCliente,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MOVIMIENTO]: (state, { movimiento }) => {
        return {
            ...state,
            movimiento,
        };
    },
    [SELECCIONADOS]: (state, { seleccionados }) => {
        return {
            ...state,
            seleccionados,
        };
    },
    [PRODUCTOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SET_NIT_CLIENTE]: (state, { nit_cliente }) => {
        return {
            ...state,
            nit_cliente,
        };
    },
    [SET_NOMBRE_CLIENTE]: (state, { nombre_cliente }) => {
        return {
            ...state,
            nombre_cliente,
        };
    },
    [SET_IMPRESION]: (state, { venta_impresion }) => {
        return {
            ...state,
            venta_impresion,
        };
    },
    [SET_TIPO_PAGO]: (state, { tipo_pago }) => {
        return {
            ...state,
            tipo_pago,
        };
    },
    [SET_FECHA_VENCIMIENTO_CREDITO]: (state, { fecha_vencimiento }) => {
        return {
            ...state,
            fecha_vencimiento,
        };
    },
    [SET_EFECTIVO]: (state, { efectivo }) => {
        return {
            ...state,
            efectivo,
        };
    },
    [SET_TARJETA]: (state, { tarjeta }) => {
        return {
            ...state,
            tarjeta,
        };
    },
    [SET_CHEQUE]: (state, { cheque }) => {
        return {
            ...state,
            cheque,
        };
    },
    [SET_TRANSFERENCIA]: (state, { transferencia }) => {
        return {
            ...state,
            transferencia,
        };
    },
    [SET_DEPOSITO]: (state, { deposito }) => {
        return {
            ...state,
            deposito,
        };
    },
    [SET_RETENCION_IVA]: (state, { retencion_iva }) => {
        return {
            ...state,
            retencion_iva,
        };
    },
    [SET_EXENCION_IVA]: (state, { exencion_iva }) => {
        return {
            ...state,
            exencion_iva,
        };
    },
    [SET_NOTA_CREDITO]: (state, { nota_credito }) => {
        return {
            ...state,
            nota_credito,
        };
    },
    [UUID_REQ_PRODUCTOS]: (state, { uuid_req_productos }) => {
        return {
            ...state,
            uuid_req_productos,
        };
    },
    [SET_VER_BUSCADOR]: (state, { ver_buscador }) => {
        return {
            ...state,
            ver_buscador,
        };
    },
    [SET_VER_COBRO]: (state, { ver_cobro }) => {
        return {
            ...state,
            ver_cobro,
        };
    },
    [SET_REDONDEO]: (state, { redondeo }) => {
        return {
            ...state,
            redondeo,
        };
    },
    [SET_VENTA_COTIZACION]: (state, { venta_cotizacion }) => {
        return {
            ...state,
            venta_cotizacion,
        };
    },
    [SET_VER_IMPRESION]: (state, { ver_impresion }) => {
        return {
            ...state,
            ver_impresion,
        };
    },
    [SET_COTIZACION_CARGADA]: (state, { cotizacion_cargada }) => {
        return {
            ...state,
            cotizacion_cargada,
        };
    },
    [SET_NOTA]: (state, { nota }) => {
        return {
            ...state,
            nota,
        };
    },
    [SET_CODIGO_NOTA_CREDITO]: (state, { codigo_nota_credito }) => {
        return {
            ...state,
            codigo_nota_credito,
        };
    },
    [SET_NOTA_CREDITO_CARGADA]: (state, { nota_credito_cargada }) => {
        return {
            ...state,
            nota_credito_cargada,
        };
    },
    [SET_ESTADO_FORM_CLIENTE]: (state, { estado_form_cliente }) => {
        return {
            ...state,
            estado_form_cliente,
        };
    },
    [SET_DATOS_CLIENTE]: (state, { datos_cliente }) => {
        return {
            ...state,
            datos_cliente,
        };
    },
    [PASO]: (state, { paso }) => {
        return {
            ...state,
            paso,
        };
    },

    //  CLIENTE
    [PAGE_CLIENTES]: (state, { page_cliente }) => {
        return {
            ...state,
            page_cliente,
        };
    },
    [LOADER_CLIENTES]: (state, { loader_cliente }) => {
        return {
            ...state,
            loader_cliente,
        };
    },
    [DATA_CLIENTES]: (state, { data_cliente }) => {
        return {
            ...state,
            data_cliente,
        };
    },
    [BUSCADOR_CLIENTES]: (state, { search_cliente }) => {
        return {
            ...state,
            search_cliente,
        };
    },
    [MODAL_NUEVO_CLIENTE]: (state, { modal_cliente }) => {
        return {
            ...state,
            modal_cliente,
        };
    },
    [MODAL_LISTADO_CLIENTE]: (state, { modal_listado_cliente }) => {
        return {
            ...state,
            modal_listado_cliente,
        };
    },

    //NAV ADD
    [PRODUCTO_ACTUAL]: (state, { producto_actual }) => {
        return {
            ...state,
            producto_actual,
        };
    },

    //NAV ADD
    [SET_VENTA_COSTO]: (state, { venta_costo }) => {
        return {
            ...state,
            venta_costo,
        };
    },
    //TIPO VENTA
    [SET_TIPO_VENTA]: (state, { tipo_venta }) => {
        return {
            ...state,
            tipo_venta,
        };
    },

    [MUNICIPIOS]: (state, { municipios }) => {
        return {
            ...state,
            municipios,
        };
    },
    [DIRECCIONES]: (state, { direcciones }) => {
        return {
            ...state,
            direcciones,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    paso: 1,
    datos: {
        fecha: moment().format("YYYY-MM-DD"),
        fecha_entrega: moment().format("YYYY-MM-DD"),
        fecha_limite: moment().format("YYYY-MM-DD"),
        plazo_pago: "inmediato",
    },
    data: {},
    loader: false,
    page: 1,
    search: "",
    ordering: "",
    movimiento: {},
    seleccionados: [],
    municipios: [],
    nit_cliente: "",
    nombre_cliente: "",
    venta_impresion: null,
    tipo_pago: pago_total,
    tipo_venta: envio,
    fecha_vencimiento: moment().format("YYYY-MM-DD"),
    efectivo: obj_tipo_pago,
    tarjeta: obj_tipo_pago,
    cheque: obj_tipo_pago,
    transferencia: obj_tipo_pago,
    deposito: "",
    nota_credito: "",
    uuid_req_productos: "",
    ver_buscador: false,
    ver_cobro: false,
    redondeo: null,
    venta_cotizacion: null,
    ver_impresion: false,
    cotizacion_cargada: null,
    nota: "",
    codigo_nota_credito: "",
    nota_credito_cargada: null,
    estado_form_cliente: true,
    retencion_iva: "",
    exencion_iva: "",
    //  Cliente
    page_cliente: 1,
    loader_cliente: false,
    data_cliente: {},
    search_cliente: "",
    modal_cliente: false,
    modal_listado_cliente: false,
    //NAV ADD
    producto_actual: {},
    venta_costo: false,
    direcciones: [],
    estado_descarga: false,
    datos_cliente: initial_state_cliente,
};

export default handleActions(reducers, initialState);
