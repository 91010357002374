import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import _ from "lodash";
import { ToastStore } from "react-toasts";
import { initialize as initializeForm } from "redux-form";
import Swal from "sweetalert2";
import { api } from "api";
import { getMe } from "../usuario/usuario";
import { PVMCE, PVN } from "utility/variables";

// ------------------------------------
// Constants
// ------------------------------------

export const PAGE = "USUARIOS_PAGE";
export const LOADER = "USUARIOS_LOADER";
export const SORT = "USUARIOS_SORT";
export const BUSCADOR = "USUARIOS_BUSCADOR";
export const USUARIOS = "USUARIOS_USUARIOS";
const ITEM = "SET_ITEM_USUARIOS";

const endpoint = "usuarios";

// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const usuarios = store.usuarios;
    const { ordering, search } = usuarios;
    api.get(endpoint, { page, ordering, search })
        .catch((err) => {})
        .then((data) => {
            if (data) {
                dispatch(setData(data));
            }
            dispatch(setPage(page));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const crear = (data, files = []) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    // const data = store.form.UsuarioCrear.values;

    // Se setea la bandera al rol seleccionado
    data.es_encargado_tienda = false;
    data.es_admin = false;
    data.es_bodeguero = false;
    data.es_cajero = false;
    data.es_vendedor_mostrador = false;
    data[data.rol] = true;
    // const { usuario } = store;

    // const usuario_sucursales = usuario.me.sucursales
    //     ? usuario.me.sucursales
    //     : [];
    // let sucursales = [];
    // let _sucursales = [];
    const estadoReporte =
        data.reporte_balance_general ||
        data.reporte_apertura_cierre ||
        data.reporte_bodega ||
        data.reporte_venta ||
        data.reporte_devolucion ||
        data.reporte_cuenta_cobrar ||
        data.reporte_gasto ||
        data.reporte_productos_mas_vendidos ||
        data.reporte_mejores_clientes ||
        data.reporte_total_invertido ||
        data.reporte_ganancia_bruta ||
        data.reporte_despachos ||
        data.reporte_historial_precios ||
        data.reporte_movimientos ||
        data.reporte_historial_bajas_salidas ||
        data.reporte_descuentos_recargos;
    const estadoConfig =
        data.conf_tienda ||
        data.conf_usuario ||
        data.conf_sucursal ||
        data.conf_cliente ||
        data.conf_proveedor ||
        data.conf_caja ||
        data.conf_destino_salida ||
        data.conf_adicionales_nota_fel;

    const estadoDespacho =
        data.despacho_despachos ||
        data.despacho_recepciones ||
        data.despacho_recepciones_orden_compra;

    const estadoPVMC = data.punto_venta_mostrador || data.punto_venta_caja;
    // usuario_sucursales.forEach((sucursal) => {
    //     if (sucursal.id !== "empresa") {
    //         _sucursales.push(sucursal);
    //     }
    // });
    // data.sucursales.forEach((item, index) => {
    //     if (!!item) {
    //         if (item[Object.keys(item)[0]]) {
    //             sucursales.push(_sucursales[index]);
    //         }
    //     }
    // });
    data.es_global = data.es_global === "true";
    api.postAttachments(endpoint, {
        ...data,
        reporte: estadoReporte,
        configuracion: estadoConfig,
        despacho: estadoDespacho,
        punto_venta_mostrador_caja: estadoPVMC,
    }, files)
        .then((resp) => {
            if (resp) {
                ToastStore.success("Datos almacenados correctamente");
                dispatch(push("/configuracion/usuarios"));
            }
        })
        .catch((error) => {
            Swal(
                "ERROR",
                error.detail ||
                    "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const editar = (id, data, files = []) => (dispatch, getStore) => {
    const store = getStore();
    // const data = store.form.UsuarioEditar.values;
    dispatch(setLoader(true));
    // Se setea la bandera al rol seleccionado
    data.es_admin = false;
    data.es_bodeguero = false;
    data.es_cajero = false;
    data.es_vendedor_mostrador = false;
    data[data.rol] = true;

    const { usuario } = store;

    // const usuario_sucursales = usuario.me.sucursales
    //     ? usuario.me.sucursales
    //     : [];
    // let sucursales = [];
    // let _sucursales = [];
    const estadoReporte =
        data.reporte_balance_general ||
        data.reporte_apertura_cierre ||
        data.reporte_bodega ||
        data.reporte_venta ||
        data.reporte_devolucion ||
        data.reporte_cuenta_cobrar ||
        data.reporte_gasto ||
        data.reporte_productos_mas_vendidos ||
        data.reporte_mejores_clientes ||
        data.reporte_total_invertido ||
        data.reporte_ganancia_bruta ||
        data.reporte_despachos ||
        data.reporte_historial_precios ||
        data.reporte_movimientos ||
        data.reporte_historial_bajas_salidas ||
        data.reporte_descuentos_recargos;
    const estadoConfig =
        data.conf_tienda ||
        data.conf_usuario ||
        data.conf_sucursal ||
        data.conf_cliente ||
        data.conf_proveedor ||
        data.conf_caja ||
        data.conf_destino_salida ||
        data.conf_adicionales_nota_fel;

    const estadoDespacho =
        data.despacho_despachos ||
        data.despacho_recepciones ||
        data.despacho_recepciones_orden_compra;

    const estadoBodega =
        data.igreso_mercaderia ||
        data.baja_mercaderia ||
        data.salida_mercaderia ||
        data.reajuste_mercaderia;

    // usuario_sucursales.forEach((sucursal) => {
    //     if (sucursal.id !== "empresa") {
    //         _sucursales.push(sucursal);
    //     }
    // });
    // data.sucursales.forEach((item, index) => {
    //     if (!!item) {
    //         if (item[Object.keys(item)[0]]) {
    //             sucursales.push(_sucursales[index]);
    //         }
    //     }
    // });
    data.es_global = data.es_global === "true";
    api.putAttachments(`${endpoint}/${id}`, {
        ...data,
        reporte: estadoReporte,
        configuracion: estadoConfig,
        despacho: estadoDespacho,
        bodega: estadoBodega,
    }, files)
        .then((resp) => {
            if (resp) {
                ToastStore.success("Datos almacenados correctamente");
                if (data.id == usuario.me.id) {
                    dispatch(getMe());
                }
                dispatch(push("/configuracion/usuarios"));
            }
        })
        .catch((error) => {
            Swal(
                "ERROR",
                error.detail ||
                    "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const leer = (id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`)
        .then((resp) => {
            if (resp) {
                // let rol = "personalizado";
                // if (resp.es_admin) rol = "es_admin";
                // else if (resp.es_bodeguero) rol = "es_bodeguero";
                // else if (resp.es_cajero) rol = "es_cajero";
                // else if (resp.es_vendedor_mostrador)
                //     rol = "es_vendedor_mostrador";
                // resp.rol = rol;
                // const { usuario } = getStore();
                // const usuario_sucursales = usuario.me.sucursales
                //     ? usuario.me.sucursales
                //     : [];
                // const sucursales = [];
                // let comprobacion = false;
                // let cont = 0;
                // usuario_sucursales.forEach((item) => {
                //     if (item.id !== "empresa") {
                //         sucursales[cont] = {};
                //         const esta = !!_.find(resp.sucursales, { id: item.id });
                //         sucursales[cont][`sucursal-${item.id}`] = esta;
                //         if (esta) comprobacion = true;
                //         cont += 1;
                //     }
                // });
                // if (sucursales.length > 0 && comprobacion)
                //     resp.sucursales = sucursales;
                // if (sucursales.length > 0) resp.sucursales = sucursales;
                if (resp.es_global) resp.es_global = "true";
                else resp.es_global = "false";
                dispatch(initializeForm("UsuarioEditar", resp));
                // dispatch(changePermisos(rol, "UsuarioEditar"));
                dispatch(setItem(resp));
            }
        })
        .catch((error) => {
            console.log(error);
            Swal("ERROR", error.detail || "Ha ocurrido un error.", "error");
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const sincronizarSucursales = () => (dispatch, getStore) => {
    const { usuario, form } = getStore();
    try {
        const resp = form.UsuarioEditar.values;
        const usuario_sucursales = usuario.me.sucursales
            ? usuario.me.sucursales
            : [];
        const sucursales = [];
        let comprobacion = false;
        let cont = 0;
        usuario_sucursales.forEach((item) => {
            if (item.id !== "empresa") {
                sucursales[cont] = {};
                const esta = !!_.find(resp.sucursales, { id: item.id });
                sucursales[cont][`sucursal-${item.id}`] = esta;
                if (esta) comprobacion = true;
                cont += 1;
            }
        });
        if (sucursales.length > 0 && comprobacion) resp.sucursales = sucursales;
        dispatch(initializeForm("UsuarioEditar", resp));
    } catch (e) {
        console.log(e);
    }
};

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`)
        .then((data) => {
            if (data) {
                ToastStore.success("Registro eliminado.");
                dispatch(listar());
            }
        })
        .catch((error) => {
            Swal(
                "ERROR",
                error.detail ||
                    "Ha ocurrido un error, espere un momento y vuelva a intentar.",
                "error"
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.sucursal.page;
    dispatch(listar(page));
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

// PARA EL CAMBIO DE CHECKS QUE CONTROLAN VARIOS SUBITEMS
export const changeValue = (llave, formulario) => (dispatch, getStore) => {
    try {
        const data = getStore().form[formulario].values
            ? getStore().form[formulario].values
            : {};
        if (llave === "reportes") {
            const estadoReporte =
                data.reporte_balance_general ||
                data.reporte_apertura_cierre ||
                data.reporte_bodega ||
                data.reporte_venta ||
                data.reporte_devolucion ||
                data.reporte_cuenta_cobrar ||
                data.reporte_gasto ||
                data.reporte_productos_mas_vendidos ||
                data.reporte_mejores_clientes ||
                data.reporte_total_invertido ||
                data.reporte_ganancia_bruta ||
                data.reporte_movimientos ||
                data.reporte_historial_precios ||
                data.reporte_despachos ||
                data.reporte_historial_bajas_salidas ||
                data.reporte_descuentos_recargos;
            data.reporte_balance_general = !estadoReporte;
            data.reporte_apertura_cierre = !estadoReporte;
            data.reporte_bodega = !estadoReporte;
            data.reporte_venta = !estadoReporte;
            data.reporte_devolucion = !estadoReporte;
            data.reporte_cuenta_cobrar = !estadoReporte;
            data.reporte_gasto = !estadoReporte;
            data.reporte_productos_mas_vendidos = !estadoReporte;
            data.reporte_mejores_clientes = !estadoReporte;
            data.reporte_total_invertido = !estadoReporte;
            data.reporte_ganancia_bruta = !estadoReporte;
            data.reporte_despachos = !estadoReporte;
            data.reporte_movimientos = !estadoReporte;
            data.reporte_historial_precios = !estadoReporte;
            data.reporte_historial_bajas_salidas = !estadoReporte;
            data.reporte_descuentos_recargos = !estadoReporte;
        } else if (llave === "configuracion") {
            const estadoConfig =
                data.conf_tienda ||
                data.conf_usuario ||
                data.conf_sucursal ||
                data.conf_cliente ||
                data.conf_proveedor ||
                data.conf_caja ||
                data.conf_destino_salida ||
                data.conf_adicionales_nota_fel;
            data.conf_tienda = !estadoConfig;
            data.conf_usuario = !estadoConfig;
            data.conf_sucursal = !estadoConfig;
            data.conf_cliente = !estadoConfig;
            data.conf_proveedor = !estadoConfig;
            data.conf_caja = !estadoConfig;
            data.conf_destino_salida = !estadoConfig;
            data.conf_adicionales_nota_fel = !estadoConfig;
        } else if (llave === "despacho") {
            const estadoDespacho =
                data.despacho_despachos ||
                data.despacho_recepciones ||
                data.despacho_recepciones_orden_compra;

            data.despacho_despachos = !estadoDespacho;
            data.despacho_recepciones = !estadoDespacho;
            data.despacho_recepciones_orden_compra = !estadoDespacho;
        } else if (llave === "punto_venta_mostrador_caja") {
            const estadoPVMC =
                data.punto_venta_mostrador || data.punto_venta_caja;

            data.punto_venta_mostrador = !estadoPVMC;
            data.punto_venta_caja = !estadoPVMC;
        } else if (llave === "bodega") {
            const estadoBodega =
                data.igreso_mercaderia ||
                data.baja_mercaderia ||
                data.salida_mercaderia ||
                data.reajuste_mercaderia;

            data.igreso_mercaderia = !estadoBodega;
            data.baja_mercaderia = !estadoBodega;
            data.salida_mercaderia = !estadoBodega;
            data.reajuste_mercaderia = !estadoBodega;
        }
        dispatch(initializeForm(formulario, { ...data }));
    } catch (e) {
        // aqui entra cuando no tiene valores el form
    }
};

// Funcion para habilitar los permisos para cada rol
export const changePermisos = (rol, formulario) => (dispatch, getStore) => {
    let data = getStore().form[formulario].values
        ? getStore().form[formulario].values
        : {};
    const store = getStore();
    const tipo_punto_venta = store.usuario.tipo_punto_venta;

    let permisos = {
        dashboard: false,
        perfil: false,
        bodega: false,
        igreso_mercaderia: false,
        baja_mercaderia: false,
        salida_mercaderia: false,
        reajuste_mercaderia: false,
        punto_venta: false,
        producto: false,
        gasto: false,
        bitacora: false,
        reporte_balance_general: false,
        reporte_apertura_cierre: false,
        reporte_bodega: false,
        reporte_venta: false,
        reporte_devolucion: false,
        reporte_cuenta_cobrar: false,
        reporte_gasto: false,
        reporte_productos_mas_vendidos: false,
        reporte_mejores_clientes: false,
        reporte_total_invertido: false,
        reporte_ganancia_bruta: false,
        reporte_despachos: false,
        reporte_historial_precios: false,
        reporte_movimientos: false,
        reporte_historial_bajas_salidas: false,
        reporte_descuentos_recargos: false,
        conf_tienda: false,
        conf_usuario: false,
        conf_sucursal: false,
        conf_cliente: false,
        conf_proveedor: false,
        conf_caja: false,
        conf_destino_salida: false,
        conf_adicionales_nota_fel: false,
        despacho_despachos: false,
        despacho_recepciones: false,
        despacho_recepciones_orden_compra: false,
        orden_compra: false,
        orden_online: false,
    };

    if (tipo_punto_venta == PVMCE) {
        permisos.punto_venta_mostrador = false;
        permisos.punto_venta_caja = false;
    }

    if (rol === "es_admin") {
        // Se habilita todos los permisos al Admin
        for (let key in permisos) {
            data[key] = true;
        }
    } else if (rol === "es_vendedor_mostrador") {
        const permisos_cajeros =
            tipo_punto_venta == PVMCE
                ? ["punto_venta_mostrador", "perfil"]
                : ["punto_venta", "perfil"];

        // Se setea a falso todos los permisos
        for (let key in permisos) {
            data[key] = permisos[key];
        }

        // Se habilitan los permisos permitidos para Cajeros
        permisos_cajeros.forEach((permiso) => {
            data[permiso] = true;
        });
    } else if (rol === "es_cajero") {
        const permisos_cajeros =
            tipo_punto_venta == PVMCE
                ? ["punto_venta_caja", "perfil"]
                : ["punto_venta", "perfil"];

        // Se setea a falso todos los permisos
        for (let key in permisos) {
            data[key] = permisos[key];
        }

        // Se habilitan los permisos permitidos para Cajeros
        permisos_cajeros.forEach((permiso) => {
            data[permiso] = true;
        });
    } else if (rol === "es_bodeguero") {
        const permisos_bodegueros = [
            "bodega",
            "igreso_mercaderia",
            "baja_mercaderia",
            "salida_mercaderia",
            "reajuste_mercaderia",
            "producto",
            "perfil",
            "despacho_despachos",
            "despacho_recepciones",
            "despacho_recepciones_orden_compra",
        ];

        // Se setea a falso todos los permisos
        for (let key in permisos) {
            data[key] = permisos[key];
        }

        // Se habilitan los permisos permitidos para Bodegueros
        permisos_bodegueros.forEach((permiso) => {
            data[permiso] = true;
        });
    } else if (rol === "es_encargado_tienda") {
        const permisos_bodegueros = [
            "bodega",
            "igreso_mercaderia",
            "baja_mercaderia",
            "salida_mercaderia",
            "reajuste_mercaderia",
            "producto",
            "perfil",
            "despacho_despachos",
            "despacho_recepciones",
            "despacho_recepciones_orden_compra",
        ];

        // Se setea a falso todos los permisos
        for (let key in permisos) {
            data[key] = permisos[key];
        }

        // Se habilitan los permisos permitidos para Bodegueros
        permisos_bodegueros.forEach((permiso) => {
            data[permiso] = true;
        });
    }
    dispatch(initializeForm(formulario, { ...data }));
};

export const removerItem = () => (dispatch) => {
    dispatch({
        type: ITEM,
        item: {},
    });
};

// ------------------------------------
// PureActions
// ------------------------------------

const setLoader = (loader) => ({
    type: LOADER,
    loader,
});
const setData = (data) => ({
    type: USUARIOS,
    data,
});
const setPage = (page) => ({
    type: PAGE,
    page,
});
const setSort = (ordering) => ({
    type: SORT,
    ordering,
});
const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});

const setItem = (item) => ({
    type: ITEM,
    item,
});

export const actions = {
    listar,
    crear,
    editar,
    leer,
    eliminar,
    buscar,
    sortChange,
    changeValue,
    changePermisos,
    sincronizarSucursales,
    removerItem,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [USUARIOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    loader: false,
    page: 1,
    search: "",
    ordering: "",
    item: {},
};

export default handleActions(reducers, initialState);
