import _ from "lodash";
import { api } from "api";
import Swal from "sweetalert2";
import { push } from "react-router-redux";
import { ToastStore } from 'react-toasts';
import { handleActions } from "redux-actions";
import { initialize as initializeForm } from "redux-form"

const ME = "ME";
const RUTA = "ME_RUTA";
const SUCURSAL = "ME_SUCURSAL";
const SET_FORMATO_FACTURA = "ME_SET_FORMATO_FACTURA"
const SET_FORMATO_FACTURA_FEL = "ME_SET_FORMATO_FACTURA_FEL"
const SET_SUCURSALES_EMPRESA = "USUARIO_SET_SUCURSALES_EMPRESA";
const SET_EMPRESA = "USUARIO_SET_EMPRESA";
const ITEM = "SET_ITEM_USUARIOS";

// ------------------------------------
// Constants
// ------------------------------------
export const LOADER = "USUARIOS_LOADER";
const endpoint = "x_usuarios";

// ------------------------------------
// Actions
// ------------------------------------
export const getMe = () => (dispatch, getState) => {
    api.get("usuarios/me", {})
        .catch(err => {
            localStorage.removeItem("token");
        })
        .then(me => {
            if (me) {
                //dispatch({ type: SUBMIT, autenticado: true });
                dispatch(setMe(me));
                dispatch(setFormatoFactura(me.formato_factura))
                dispatch(setFormatoFacturaFEL(me.formato_factura_fel))
            }
            //dispatch({ type: LOGIN_LOADER, loader: false });
        });
};

export const getInfoEmpresa = (id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    // Se obtiene la empresa
    api.get(`empresas/${id}`)
        .catch((error) => {
            console.log('error en get empresa', error)
            dispatch(setLoader(false))
        })
        .then((data) => {
            if (data) {
                dispatch(setSucursalesEmpresa(data.sucursales));
                dispatch(setIdEmpresa(id));
            }
            dispatch(setLoader(false))
        });
}

export const crear = (data, files = []) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    // const data = _.cloneDeep(store.form.UsuarioCrear.values);

    // Se setea la bandera al rol seleccionado
    // data.es_admin = false;
    // data.es_bodeguero = false;
    // data.es_cajero = false;
    // data[data.rol] = true;
    const { usuario } = store;

    // const usuario_sucursales = usuario.sucursales_empresa ?  usuario.sucursales_empresa : [];
    // let sucursales = [];
    // let _sucursales = [];
    const { id_empresa } = usuario;

    const estadoReporte = (
        data.reporte_balance_general ||
        data.reporte_apertura_cierre ||
        data.reporte_bodega ||
        data.reporte_venta ||
        data.reporte_devolucion ||
        data.reporte_cuenta_cobrar ||
        data.reporte_gasto ||
        data.reporte_productos_mas_vendidos ||
        data.reporte_mejores_clientes ||
        data.reporte_total_invertido ||
        data.reporte_ganancia_bruta ||
        data.reporte_despachos
    );
    const estadoConfig = (
        data.conf_tienda ||
        data.conf_usuario ||
        data.conf_sucursal ||
        data.conf_cliente ||
        data.conf_proveedor ||
        data.conf_caja
    );

    const estadoDespacho = (
        data.despacho_despachos ||
        data.despacho_recepciones
    );
    // usuario_sucursales.forEach(sucursal =>  {
    //     if (sucursal.id !== "empresa") {
    //         _sucursales.push(sucursal)
    //     }
    // });
    // data.sucursales.forEach((item, index) => {
    //     if (!!item) {
    //         if (item[Object.keys(item)[0]]){
    //             sucursales.push(_sucursales[index]);
    //         }
    //     }
    // });
    data.es_global = data.es_global === "true";
    api.postAttachments(endpoint, {...data, reporte: estadoReporte, configuracion: estadoConfig, despacho: estadoDespacho, empresa_id:id_empresa}, files).then(resp => {
        if (resp) {
            Swal(
                'Éxito',
                'Datos almacenados correctamente',
                'success'
            ).then(() => {
                dispatch(push(`/empresas/editar/${id_empresa}`));
            })
        }
    }).catch(error => {
        Swal(
            'ERROR',
            error.detail || 'Ha ocurrido un error, verifique los datos y vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leer = (id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api
        .get(`${endpoint}/${id}`)
        .then(resp => {
            if (resp) {
                // let rol = "personalizado";
                // if (resp.es_admin)
                //     rol = "es_admin";
                // else if (resp.es_bodeguero)
                //     rol = "es_bodeguero";
                // else if (resp.es_cajero)
                //     rol = "es_cajero";
                // resp.rol = rol;
                const { usuario } = getStore();
                const usuario_sucursales = usuario.sucursales_empresa ?  usuario.sucursales_empresa : [];
                const sucursales = [];
                let comprobacion = false;
                let cont = 0;
                usuario_sucursales.forEach((item) => {
                    if (item.id !== "empresa") {
                        sucursales[cont] = {};
                        const esta = !!_.find(resp.sucursales, {id: item.id});
                        sucursales[cont][`sucursal-${item.id}`] = esta;
                        if (esta)
                            comprobacion = true;
                        cont += 1;
                    }
                });

                if (sucursales.length > 0 && comprobacion)
                    resp.sucursales = sucursales;
                if (sucursales.length > 0)
                    resp.sucursales = sucursales;
                if (resp.es_global)
                    resp.es_global = "true";
                else
                    resp.es_global = "false";
                dispatch(initializeForm('UsuarioEditar', resp));
                dispatch(setItem(resp));
            }
        })
        .catch(error => {
            console.log(error);
            Swal(
                'ERROR',
                error.detail || 'Ha ocurrido un error.',
                'error'
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const editar = (id, data, files = []) => (dispatch, getStore) => {
    const store = getStore();
    // const data = _.cloneDeep(store.form.UsuarioEditar.values);
    dispatch(setLoader(true));
    // Se setea la bandera al rol seleccionado
    // data.es_admin = false;
    // data.es_bodeguero = false;
    // data.es_cajero = false;
    // data[data.rol] = true;
    const { usuario,  } = store;

    // const usuario_sucursales = usuario.sucursales_empresa ?  usuario.sucursales_empresa : [];
    // let sucursales = [];
    // let _sucursales = [];
    const { id_empresa } = usuario;
    const estadoReporte = (
        data.reporte_balance_general ||
        data.reporte_apertura_cierre ||
        data.reporte_bodega ||
        data.reporte_venta ||
        data.reporte_devolucion ||
        data.reporte_cuenta_cobrar ||
        data.reporte_gasto ||
        data.reporte_productos_mas_vendidos ||
        data.reporte_mejores_clientes ||
        data.reporte_total_invertido ||
        data.reporte_ganancia_bruta ||
        data.reporte_despachos
    );
    const estadoConfig = (
        data.conf_tienda ||
        data.conf_usuario ||
        data.conf_sucursal ||
        data.conf_cliente ||
        data.conf_proveedor ||
        data.conf_caja
    );

    const estadoDespacho = (
        data.despacho_despachos ||
        data.despacho_recepciones
    );

    // usuario_sucursales.forEach(sucursal =>  {
    //     if (sucursal.id !== "empresa") {
    //         _sucursales.push(sucursal)
    //     }
    // });
    // data.sucursales.forEach((item, index) => {
    //     if (!!item) {
    //         if (item[Object.keys(item)[0]]){
    //             sucursales.push(_sucursales[index]);
    //         }
    //     }
    // });
    data.es_global = data.es_global === "true";
    api.putAttachments(
        `${endpoint}/${id}`,
        {
            ...data,
            reporte: estadoReporte,
            configuracion: estadoConfig,
            despacho: estadoDespacho,
            empresa_id: id_empresa,
        },
        files
    ).then(resp => {
        if (resp) {
            Swal(
                'Éxito',
                'Datos actualizados correctamente',
                'success'
            ).then(() => {
                dispatch(push(`/empresas/editar/${id_empresa}`));
            })
        }
    }).catch(error => {
        Swal(
            'ERROR',
            error.detail || 'Ha ocurrido un error, verifique los datos y vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const sincronizarSucursales = () => (dispatch, getStore) => {
    const { usuario, form } = getStore();
    try {
        const resp = form.UsuarioEditar.values;
        const usuario_sucursales = usuario.sucursales_empresa ?  usuario.sucursales_empresa : [];
        const sucursales = [];
        let comprobacion = false;
        let cont = 0;
        usuario_sucursales.forEach((item) => {
            if (item.id !== "empresa") {
                sucursales[cont] = {};
                const esta = !!_.find(resp.sucursales, {id: item.id});
                sucursales[cont][`sucursal-${item.id}`] = esta;
                if (esta)
                    comprobacion = true;
                cont += 1;
            }
        });
        if (sucursales.length > 0 && comprobacion)
            resp.sucursales = sucursales;
        dispatch(initializeForm('UsuarioEditar', resp));
    }catch (e) {
        console.log(e);
    }
};

// PARA EL CAMBIO DE CHECKS QUE CONTROLAN VARIOS SUBITEMS
export const changeValue = (llave, formulario) => (dispatch, getStore) => {
    try {
        const data = getStore().form[formulario].values ? getStore().form[formulario].values : {};
        if (llave === "reportes") {
            const estadoReporte = (
                data.reporte_balance_general ||
                data.reporte_apertura_cierre ||
                data.reporte_bodega ||
                data.reporte_venta ||
                data.reporte_devolucion ||
                data.reporte_cuenta_cobrar ||
                data.reporte_gasto ||
                data.reporte_productos_mas_vendidos ||
                data.reporte_mejores_clientes ||
                data.reporte_total_invertido ||
                data.reporte_ganancia_bruta ||
                data.reporte_despachos
            );
            data.reporte_balance_general = !estadoReporte;
            data.reporte_apertura_cierre = !estadoReporte;
            data.reporte_bodega = !estadoReporte;
            data.reporte_venta = !estadoReporte;
            data.reporte_devolucion = !estadoReporte;
            data.reporte_cuenta_cobrar = !estadoReporte;
            data.reporte_gasto = !estadoReporte;
            data.reporte_productos_mas_vendidos = !estadoReporte;
            data.reporte_mejores_clientes = !estadoReporte;
            data.reporte_total_invertido = !estadoReporte;
            data.reporte_ganancia_bruta = !estadoReporte;
            data.reporte_despachos = !estadoReporte;
        } else if (llave === "configuracion") {
            const estadoConfig = (
                data.conf_tienda ||
                data.conf_usuario ||
                data.conf_sucursal ||
                data.conf_cliente ||
                data.conf_proveedor ||
                data.conf_caja
            );
            data.conf_tienda = !estadoConfig;
            data.conf_usuario = !estadoConfig;
            data.conf_sucursal = !estadoConfig;
            data.conf_cliente = !estadoConfig;
            data.conf_proveedor = !estadoConfig;
            data.conf_caja = !estadoConfig;
        }else if(llave === "despacho") {
            const estadoDespacho = (
                data.despacho_despachos ||
                data.despacho_recepciones
            );

            data.despacho_despachos = !estadoDespacho;
            data.despacho_recepciones = !estadoDespacho;
        }
        dispatch(initializeForm(formulario, {...data}));
    } catch (e) {
        // aqui entra cuando no tiene valores el form
    }
};

// Funcion para habilitar los permisos para cada rol
export const changePermisos = (rol, formulario) => (dispatch, getStore) => {
    let data = getStore().form[formulario].values ? getStore().form[formulario].values : {};

    let permisos = {
        dashboard: false,
        perfil: false,
        bodega: false,
        punto_venta: false,
        producto: false,
        gasto: false,
        bitacora: false,
        reporte_balance_general: false,
        reporte_apertura_cierre: false,
        reporte_bodega: false,
        reporte_venta: false,
        reporte_devolucion: false,
        reporte_cuenta_cobrar: false,
        reporte_gasto: false,
        reporte_productos_mas_vendidos: false,
        reporte_mejores_clientes: false,
        reporte_total_invertido:false,
        reporte_ganancia_bruta:false,
        reporte_despachos:false,
        conf_tienda: false,
        conf_usuario: false,
        conf_sucursal: false,
        conf_cliente: false,
        conf_proveedor: false,
        conf_caja: false,
        despacho_despachos:false,
        despacho_recepciones: false
    };

    if (rol === "es_admin") {
        // Se habilita todos los permisos al Admin
        for (let key in permisos ) {
            data[key] = true;
        }
    }else if (rol === "es_cajero") {
        const permisos_cajeros = ["punto_venta", "perfil"];

        // Se setea a falso todos los permisos
        for (let key in permisos ) {
            data[key] = permisos[key];
        }

        // Se habilitan los permisos permitidos para Cajeros
        permisos_cajeros.forEach(permiso => {
            data[permiso] = true;
        })
    }else if (rol === "es_bodeguero") {
        const permisos_bodegueros = ["bodega", "producto", "perfil"];

        // Se setea a falso todos los permisos
        for (let key in permisos ) {
            data[key] = permisos[key];
        }

        // Se habilitan los permisos permitidos para Bodegueros
        permisos_bodegueros.forEach(permiso => {
            data[permiso] = true;
        })
    }

    dispatch(initializeForm(formulario, {...data}));

}

export const removerItem = () => (dispatch) => {
    dispatch({
        type: ITEM,
        item: {},
    });
};

// ------------------------------------
// PureActions
// ------------------------------------
export const setMe = me => ({
    type: ME,
    me
});

const setLoader = loader => ({
    type: LOADER,
    loader
});

const setSucursalesEmpresa = sucursales_empresa => ({
    type: SET_SUCURSALES_EMPRESA,
    sucursales_empresa
});

export const setFormatoFactura = formato_factura => ({
    type: SET_FORMATO_FACTURA,
    formato_factura
})

export const setFormatoFacturaFEL = formato_factura_fel => ({
    type: SET_FORMATO_FACTURA_FEL,
    formato_factura_fel
})

export const setRuta = ruta => ({
    type: RUTA,
    ruta
});

export const setIdEmpresa = (id_empresa) => ({
    type: SET_EMPRESA,
    id_empresa
})

const setItem = (item) => ({
    type: ITEM,
    item,
});


export const actions = {
    leer,
    getMe,
    crear,
    editar,
    setRuta,
    changeValue,
    changePermisos,
    getInfoEmpresa,
    sincronizarSucursales,
    removerItem,
};
// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [ME]: (state, { me }) => {
        return {
            ...state,
            me
        };
    },
    [RUTA]: (state, { ruta }) => {
        return {
            ...state,
            ruta
        };
    },
    [SUCURSAL]: (state, { sucursal }) => {
        return {
            ...state,
            sucursal
        };
    },
    [SET_FORMATO_FACTURA]: (state, { formato_factura }) => {
        return {
            ...state,
            formato_factura
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_SUCURSALES_EMPRESA]: (state, { sucursales_empresa }) => {
        return {
            ...state,
            sucursales_empresa,
        };
    },
    [SET_EMPRESA]: (state, { id_empresa }) => {
        return {
            ...state,
            id_empresa,
        };
    },
    [SET_FORMATO_FACTURA_FEL]: (state, { formato_factura_fel }) => {
        return {
            ...state,
            formato_factura_fel,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    me: {},
    ruta: "",
    sucursal: undefined,
    formato_factura: '',
    formato_factura_fel: '',
    loader: false,
    sucursales_empresa: [],
    id_empresa: "",
    item: {},
};

export default handleActions(reducers, initialState);
