import React from "react";
import { icons } from "icons";
import NumberFormat from "react-number-format";
import {
    RenderNumber,
    RenderCurrency,
} from "Utils/renderField/renderReadField";
import { validarConfDecimales } from "../../Utils/validaConfiguracionDecimal";

export function formatoCantidad(props) {
    return (_, producto) => {
        return (
            <div className="d-flex flex-row">
                <button
                    className="btn btn-cantidad-detalle btn-menos"
                    onClick={() => {
                        const cantidad = producto.cantidad
                            ? producto.cantidad
                            : 0;
                        props.asignarCantidad(producto, cantidad - 1);
                    }}
                    type="button"
                >
                    <img src={icons.menos} alt="-" />
                </button>
                <NumberFormat
                    className="form-control bodega-ingreso text-right"
                    placeholder="0"
                    decimalScale={producto.producto.a_granel ? 3 : 0}
                    fixedDecimalScale={
                        producto.producto.a_granel ? true : false
                    }
                    value={producto.cantidad}
                    thousandSeparator={true}
                    prefix={""}
                    style={{ padding: "2px" }}
                    onValueChange={(values) => {
                        let val = "";
                        if (
                            values.value != "" &&
                            values.value != undefined &&
                            values.value != null
                        ) {
                            val = producto.producto.a_granel
                                ? parseFloat(values.value)
                                : parseInt(values.value);
                        }
                        props.asignarCantidad(producto, val);
                    }}
                />
                <button
                    className="btn btn-cantidad-detalle btn-mas"
                    onClick={() => {
                        const cantidad = producto.cantidad
                            ? producto.cantidad
                            : 0;
                        props.asignarCantidad(producto, parseInt(cantidad) + 1);
                    }}
                    type="button"
                >
                    <img src={icons.mas} alt="+" />
                </button>
            </div>
        );
    };
}

export function formatoCosto(props) {
    const { origen } = props;

    return (_, producto) => {
        return (
            <div>
                <NumberFormat
                    className="form-control text-right"
                    placeholder="0"
                    decimalScale={
                        props.conf_empresa
                            ? validarConfDecimales(props.conf_empresa)
                            : 2
                    }
                    fixedDecimalScale={true}
                    // cambia el costo a mostrar, dependiendo el tipo de origen
                    value={
                        origen === "1" ? producto.costo_dolares : producto.costo
                    }
                    thousandSeparator={true}
                    // prefix={props.pais == "GT" ? "Q " : "$ "}
                    prefix={origen === "1" ? "$ " : "Q "}
                    onValueChange={(values) => {
                        props.asignarCosto(producto, values.value, origen);
                    }}
                />
            </div>
        );
    };
}

export function formatoSubtotal(
    _,
    producto,
    costo_importacion,
    origen = 2,
    tipo_cambio = 0
) {
    // cambia el costo a calcular, dependiendo el tipo de origen
    const costo =
        origen === "1" ? producto.costo_dolares * tipo_cambio : producto.costo;
    const calculo_porcentaje = (costo_importacion || 0) / 100;
    let subtotal = 0;
    if (producto.cantidad && costo && calculo_porcentaje <= 0) {
        subtotal = parseFloat(producto.cantidad) * parseFloat(costo);
    } else if (producto.cantidad && costo && calculo_porcentaje > 0) {
        const subtotal_porcentaje =
            parseFloat(producto.cantidad) *
            parseFloat(costo) *
            calculo_porcentaje;
        subtotal =
            parseFloat(producto.cantidad) * parseFloat(costo) +
            subtotal_porcentaje;
    }
    return (
        <div>
            <div>
                <RenderCurrency value={subtotal} />
            </div>
        </div>
    );
}

export function formatoSubtotalUnitario(
    _,
    producto,
    costo_importacion,
    origen = 2,
    tipo_cambio = 0
) {
    // cambia el costo a calcular, dependiendo el tipo de origen
    const costo =
        origen === "1" ? producto.costo_dolares * tipo_cambio : producto.costo;
    const calculo_porcentaje = (costo_importacion || 0) / 100;
    let subtotal = 0;
    if (producto.cantidad && costo && calculo_porcentaje <= 0) {
        subtotal = parseFloat(producto.cantidad) * parseFloat(costo);
    } else if (producto.cantidad && costo && calculo_porcentaje > 0) {
        const subtotal_porcentaje =
            parseFloat(producto.cantidad) *
            parseFloat(costo) *
            calculo_porcentaje;
        subtotal =
            parseFloat(producto.cantidad) * parseFloat(costo) +
            subtotal_porcentaje;
    }
    let subtotal_unitario = subtotal / producto.cantidad
    return (
        <div>
            <div>
                <RenderCurrency value={subtotal_unitario} />
            </div>
        </div>
    );
}

export function formatoCostoDolaresOC(cell, row) {
    const costo_dolares = parseFloat(row.costo_dolares_producto).toFixed(2);
    return `$ ${costo_dolares}`;
}

export function formatoCostoQuetzalesOC(cell, row) {
    const costo_quetzales = parseFloat(row.costo_quetzales || 0).toFixed(2);
    return `Q ${costo_quetzales}`;
}
