import React from "react";

import "./TituloBorde.css";

const TituloBorde = ({ contenido, className = "", id = "titulo" }) => {
    return (
        <h1
            id={id}
            className={`titulo-3 txt-italic titulo-borde ${className}`}
        >
            {contenido}
        </h1>
    );
};



export default TituloBorde;
