import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import "./photo_uploader.css";
import icono_file from "../../../../../assets/img/iconos/icone-document-gris.png";

export default function PhotoUploader(props) {
    const [srcFile, setSrcFile] = React.useState(undefined);
    const input = useRef();
    const disabled = props.disabled || false;

    const { aceptar_archivos = false } = props;

    useEffect(() => {
        const reader = new FileReader();
        reader.onload = (e) => {
            setSrcFile(reader.result);
        };
        if (typeof props.file === "string") {
            setSrcFile(props.file);
        } else if (props.file instanceof File) {
            reader.readAsDataURL(props.file);
        }
    }, [props.file]);

    const onFileChange = (e, file) => {
        file = file || e.target.files[0];
        const fileType = ".jpg|.jpeg|.png";
        if (file && !!file.type.match(fileType)) {
            props.onChange(file);
        }
        if (aceptar_archivos) {
            props.onChange(file);
        }
    };
    const onClick = () => {
        if (!disabled) input.current.click();
    };

    const onDrop = (e) => {
        e.preventDefault();
        if (!disabled) onFileChange(e, e.dataTransfer.files[0]);
    };
    const onDragOver = (e) => {
        e.preventDefault();
    };
    const onMouseOver = (e) => {
        e.preventDefault();
        const element = document.getElementById("uploaded-image-container");
        if (!disabled) element.style = "display: flex;";
    };
    const onMouseLeave = (e) => {
        e.preventDefault();
        const element = document.getElementById("uploaded-image-container");
        element.style = "display: none;";
    };

    return (
        <React.Fragment>
            <div
                key="label"
                className="upload-container"
                onDrop={onDrop}
                onDragOver={onDragOver}
            >
                <input
                    type="file"
                    accept={aceptar_archivos ? "/*" : "image/*"}
                    onChange={onFileChange}
                    style={{ display: "none" }}
                    ref={input}
                ></input>
                {srcFile ? (
                    <React.Fragment>
                        <div
                            id="uploaded-image-container"
                            className="uploaded-image-container"
                        >
                            <button
                                className="btn btn-uploader"
                                type="button"
                                onMouseOver={onMouseOver}
                                onMouseLeave={onMouseLeave}
                                onClick={onClick}
                            >
                                Cambiar{" "}
                                {aceptar_archivos ? "archivo" : "imagen"}
                            </button>
                        </div>
                        <img
                            onMouseOver={onMouseOver}
                            onMouseLeave={onMouseLeave}
                            src={aceptar_archivos ? icono_file : srcFile}
                            className={`uploaded-file-image ${
                                aceptar_archivos ? "max-size-img" : ""
                            }`}
                        />
                        {aceptar_archivos ? props.file.name : ""}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <br className="d-none d-sm-flex" />
                        <br />
                        <p className="upload-text p-2">
                            {props.uploaderText
                                ? props.uploaderText
                                : `Arrastra o haz clic en el botón para cargar ${
                                      aceptar_archivos ? "archivo" : "imagen"
                                  }.`}
                        </p>
                        <div className="d-flex justify-content-center">
                            <button
                                className="btn btn-uploader"
                                type="button"
                                onClick={onClick}
                            >
                                Haz click aquí
                            </button>
                        </div>
                        <br className="d-none d-sm-flex" />
                        <br />
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
}

PhotoUploader.propTypes = {
    onChange: PropTypes.func.isRequired,
};
