import { handleActions } from "redux-actions";
import { api } from "api";
import Swal from 'sweetalert2';
import { push, replace } from 'react-router-redux';

// ------------------------------------
// Constants
// ------------------------------------
const PAGE = 'PAGE_BIENVENIDA';
const DATA = 'DATA_BIENVENIDA';
const GET_EDICION = "GET_EDICION_BIENVENIDA";
const LOADER = 'LOADER_BIENVENIDA';
const REGISTRAR = "REGISTRAR_BIENVENIDA";

const endpoint = "bienvenida";

// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(isLoading(true));

    // params
    const search = "";

    // api get
    api.get(endpoint, { page, search }).catch((error) => {
        dispatch(isLoading(false))
    }).then((data) => {
        if (data) {
            dispatch(changePage(page));
            dispatch(setData(data));
        }
        dispatch(isLoading(false))
    });
};

export const crear = (datos={}) => (dispatch, getState) => {
    let valido = true;

    if (datos.version == undefined || datos.version == "") {
        valido = false;
        Swal(
            'ERROR',
            "La version es requerido, ingresa la version.",
            'error'
        );
    }
    if (datos.formato == undefined || datos.formato == "") {
        valido = false;
        Swal(
            'ERROR',
            "El diseño es requerido, ingresa el diseño.",
            'error'
        );
    }

    if (valido) {
        api.post(endpoint, datos).catch(e => {
            let mensaje = (!_.isEmpty(e) && e.detail) || 'Ha ocurrido un error, verifique los datos y vuelva a intentar.'
            Swal(
                'ERROR',
                mensaje,
                'error'
            );
        }).then(resp => {
            if (resp) {
                Swal(
                    'Éxito',
                    'Datos almacenados correctamente',
                    'success'
                ).then(() => {
                    dispatch(replace('/bienvenida/'));
                })
            }
        })
    }
}

export const editar = (id,datos={}) => (dispatch) => {
    let valido = true;

    if (datos.version == undefined || datos.version == "") {
        valido = false;
        Swal(
            'ERROR',
            "La version es requerido, ingresa la version.",
            'error'
        );
    }
    if (datos.formato == undefined || datos.formato == "") {
        valido = false;
        Swal(
            'ERROR',
            "El diseño es requerido, ingresa la diseño.",
            'error'
        );
    }

    if (valido) {
        api.put(`${endpoint}/${id}`, datos).catch(e => {
            let mensaje = (!_.isEmpty(e) && e.detail) || 'Ha ocurrido un error, verifique los datos y vuelva a intentar.'
            Swal(
                'ERROR',
                mensaje,
                'error'
            );
        }).then(resp => {
            if (resp) {
                Swal(
                    'Éxito',
                    'Datos almacenados correctamente',
                    'success'
                ).then(() => {
                    dispatch(push('/bienvenida/'));
                })
            }
        })
    }
}

export const getBienvenida = (id) => (dispatch) => {
    api.get(`${endpoint}/${id}`).catch(e => {
        console.log("ERROR: ", e);
        Swal(
            'ERROR',
            "Ha ocurrido un error, intenta más tarde.",
            'error'
        );
    }).then(resp => {
        if (resp) {
            dispatch(setDataEdicion(resp))
        }
    })
}

export const eliminar = (id) => (dispatch) => {
    dispatch(isLoading(true))
    api.eliminar(`${endpoint}/${id}`)
    .catch((error) => {
            console.log('Error al eliminar bienvenida', error)
            Swal(
                'ERROR',
                "Error al eliminar bienvenida, intenta más tarde.",
                'error'
            );
        })
        .then((data) => {
            // recargar listado de empresas
            dispatch(listar())
        })
        .finally(() => {
            dispatch(isLoading(false))
        })
}
// ------------------------------------
// PureActions
// ------------------------------------
export const setData = data => ({
    type: DATA,
    data,
});
export const isLoading = (loading = true) => ({
    type: LOADER, loader: loading
})
export const changePage = page => ({
    type: PAGE,
    page,
});
export const setDataEdicion = datos_edicion => ({
    type: GET_EDICION,
    datos_edicion,
});

export const actions = {
    listar,
    crear,
    editar,
    eliminar,
    getBienvenida,
}

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GET_EDICION]: (state, { datos_edicion }) => {
        return {
            ...state,
            datos_edicion,
        };
    },
    // [CARGAR_EDITDATA]: (state, { data }) => {
    //     return { ...state, editando: data }
    // },
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    datos_edicion: null,
    data: {
        count: 0,
        page: 1,
        results: []
    },
    loader:false
}

export default handleActions(reducers, initialState);
