import React from "react";
import { Link } from "react-router-dom";
import { RenderNumber } from "Utils/renderField/renderReadField";
import { icons } from "icons";

export function formatoNombre(cell, row) {
    return row.producto.nombre;
}
export function formatoMarca(cell, row) {
    return <div>{row.producto.marca}</div>;
}

export function formatoCodigo(cell, row) {
    return row.producto.codigo;
}

export function formatoProveedor(cell, row) {
    return row.producto.ultimo_proveedor || "---";
}

export function formatoExistencia(cell, row) {
    return <RenderNumber value={row.inventario} />;
}

export function formatoCostoDolares(cell, row) {
    const costo_dolares = parseFloat(row.costo_dolares).toFixed(2);
    return `$ ${costo_dolares}`;
}

export function formatoCostoQuetzales(cell, row) {
    const costo_quetzales = parseFloat(row.costo || cell || 0).toFixed(2);
    return `Q ${costo_quetzales}`;
}

export function formatoAcciones(props) {
    return (cell, row) => {
        //*** Habilitar la opcion ver detalle de la orden de compara en formatoAcciones, esta propiedad se puede activar desde el container del componente
        const { ver_detalle = false } = props;
        return (
            <div className="w-100 d-flex align-items-center justify-content-end">
                {ver_detalle && (
                    <Link to={`/bodega/detalle_orden/${row.id}`}>
                        <img
                            className="action-img mr-4"
                            title="Ver"
                            src={require("../../../../assets/img/icons/ver.png")}
                            alt="Ver"
                            width="17"
                        />
                    </Link>
                )}
                <img
                    className="icon-img"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => {
                        e.persist();
                        props.agregar(row);
                        props.cambio_buscador();
                    }}
                    src={icons.agregar}
                    alt="Agregar"
                />
            </div>
        );
    };
}

export function buscar(props) {
    return () => {
        let search = "";
        return (
            <input
                placeholder={"Buscar"}
                type={"text"}
                autocomplete="off"
                className="form-control"
                ref={(node) => {
                    search = node;
                    if (search) {
                        search.value = props.search;
                    }
                }}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        if (search.value) {
                            props.agregarPrimer(search.value);
                            search.value = "";
                        }
                    }
                }}
                onChange={() => {
                    if (search) {
                        props.buscar(search.value);
                    }
                }}
            />
        );
    };
}

export function formatoProducto(cell, row) {
    return (
        <div>
            <b>{row.producto.nombre}</b>
        </div>
    );
}

export function formatoAvatar(cell, row) {
    if (row.producto.img_cropped !== "") {
        return (
            <img
                className="imagen-sm"
                src={row.producto.img_cropped}
                alt=""
                style={{ width: "45px", height: "45px" }}
            />
        );
    } else {
        return (
            <img
                className="imagen-sm"
                src={icons.img_default}
                alt=""
                style={{ width: "40px", height: "40px" }}
            />
        );
    }
}

export function formatoAvatarMovil(cell, row) {
    if (row.producto.img_cropped !== "") {
        return (
            <img
                className="imagen-sm"
                src={row.producto.img_cropped}
                alt=""
                style={{
                    width: "70px",
                    height: "70px",
                    border: "2px solid black",
                }}
            />
        );
    } else {
        return (
            <img
                className="imagen-sm"
                src={icons.img_default}
                alt=""
                style={{ width: "70px", height: "70px" }}
            />
        );
    }
}

export function formatoInventario(cell, producto) {
    return (
        <div>
            <RenderNumber value={producto.inventario} />
        </div>
    );
}

export function formatoEmpty(cell) {
    if (cell) return cell;
    return "---";
}

export function formatoAceptaTarjeta(cell) {
    if (cell) return "Si";
    return "No";
}

export function formatoMonedaQuetzales(cell) {
    const costo_quetzales = parseFloat(cell || 0).toFixed(2);
    return `Q ${costo_quetzales}`;
}

export function formatoEquivalente1(cell, producto) {
    if (producto.producto.codigo_equivalente_1) {
        return producto.producto.codigo_equivalente_1;
    }
    return "---";
}

export function formatoNumeroDocumento(cell, orden_compra) {
    if (orden_compra.numero_documento) {
        return orden_compra.numero_documento;
    }
    return "---";
}

export function formatoFecha(cell, orden_compra) {
    if (orden_compra.fecha) {
        return orden_compra.fecha;
    }
    return "---";
}

export function formatoTipoPago(cell, orden_compra) {
    if (orden_compra.tipo_pago === "1000") {
        return "Contado";
    }
    return "Crédito";
}

export function formatoTipoVenta(cell, orden_compra) {
    if (cell == 10000) return "Envío";
    if (cell == 20000) return "Factura";
    if (cell == 30000) return "Crédito";
    if (cell == 40000) return "Cotización";

    return "Otro";
}

export function formatoQuetzales(cell) {
    const costo_dolares = parseFloat(cell).toFixed(2);
    return `Q ${costo_dolares}`;
}

export function formatoCodigoOC(cell) {
    return cell;
}

export function formatoProveedorOC(cell, orden_compra) {
    if (orden_compra.proveedor) {
        return orden_compra.proveedor.nombre || orden_compra.proveedor;
    }

    return "---";
}

export function formatoDefaultValue(cell, row) {
    return cell || "---";
}

export function formatoTrueFalse(cell) {
    if (cell) {
        return <span className="text-success font-weight-bold">Si</span>;
    }
    return <span className="text-danger font-weight-bold">No</span>;
}
