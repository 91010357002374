import React, { Component } from 'react';

class Home extends Component {
    render() {
        return (
            <div className="container">
                <h1>Dashboard </h1>
            </div>
        );
    }
}

export default Home;
