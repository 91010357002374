import React, { useEffect } from "react";
import { Form, Field } from "react-final-form";

import {
    composeValidators,
    minValue,
    required,
} from "../../../../utility/validation";
import {
    renderNumber,
    renderNumberFormat,
    renderSearchSelect,
} from "../renderField/renderField";

import useProductosForm from "../../../hooks/useProductosForm";

const ProductosForm = ({
    productos = [],
    producto_actual,
    setProductoActual,
    agregarProducto,
    tipo_origen,
    ov_field = false,
    cliente_id,
}) => {
    const { prefixMoneda, costo, getProductos, onSubmit, precio } =
        useProductosForm(
            productos,
            tipo_origen,
            producto_actual,
            agregarProducto,
            cliente_id
        );

    useEffect(() => {
        if (ov_field && precio != 0) {
            window.setFormValue("costo", precio);
            window.setFormValue("cantidad", 1);
        } else if (ov_field && precio == 0) {
            window.setFormValue("costo", );
            window.setFormValue("cantidad", 0);
        }
        return () => {};
    }, [precio]);

    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                initialValues={{
                    costo: "",
                    cantidad: "",
                    precio: "",
                }}
                mutators={{
                    // expect (field, value) args from the mutator
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                }}
                render={({ handleSubmit, submitting, form, values }) => {
                    window.setFormValue = form.mutators.setValue;

                    return (
                        <form
                            onSubmit={handleSubmit}
                            className="form-validate mb-lg"
                        >
                            <div className="d-flex flex-column flex-md-row">
                                <div
                                    className="form-group has-feedback m-2"
                                    style={{ flex: 5 }}
                                >
                                    <label htmlFor="codigo">Código</label>
                                    <Field
                                        name="codigo"
                                        component={renderSearchSelect}
                                        loadOptions={getProductos}
                                        labelKey="codigo_nombre"
                                        valueKey="id"
                                        className="form-control"
                                        setFormValue={setProductoActual}
                                        validate={required}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>

                                <div
                                    className="form-group has-feedback m-2 d-flex"
                                    style={{ flex: 5 }}
                                >
                                    <div className="flex-1 d-flex flex-column mr-4">
                                        <label htmlFor="cantidad">
                                            {ov_field
                                                ? "Precio actual"
                                                : "Costo actual"}
                                        </label>
                                        <span
                                            className="mt-2"
                                            style={{ fontSize: 15 }}
                                        >
                                            {!ov_field &&
                                                `${prefixMoneda} ${costo}`}
                                            {ov_field &&
                                                `${prefixMoneda} ${precio}`}
                                        </span>
                                    </div>
                                    <div className="flex-2">
                                        <label htmlFor="costo">
                                            {ov_field ? "Precio " : "Costo"}
                                        </label>
                                        <Field
                                            name="costo"
                                            component={renderNumberFormat}
                                            type="text"
                                            decimalScale={2}
                                            prefix={prefixMoneda}
                                            className="form-control"
                                            validate={
                                                ov_field
                                                    ? ""
                                                    : composeValidators(
                                                          required,
                                                          minValue(0.01)
                                                      )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div
                                    className="form-group has-feedback m-2"
                                    style={{ flex: 5 }}
                                >
                                    <label htmlFor="cantidad">Cantidad</label>
                                    <Field
                                        name="cantidad"
                                        component={renderNumber}
                                        placeholder=""
                                        type="text"
                                        className=""
                                        validate={composeValidators(
                                            required,
                                            minValue(1)
                                        )}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>

                            <div className="d-flex flex-column-reverse flex-md-row justify-content-end pt-2 pb-3">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={submitting}
                                >
                                    Agregar producto
                                </button>
                            </div>
                        </form>
                    );
                }}
            />
        </React.Fragment>
    );
};

export default ProductosForm;
