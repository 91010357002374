import React from "react";
import { Field } from "react-final-form";
import { renderFieldCheck } from "Utils/renderField/renderField";

const FieldContenedor = ({ nombre_permiso, permisos }) => {
    return (
        <React.Fragment>
            <div className="container_permiso w-100">
                {Object.keys(permisos).map((permiso, index) => {
                    const { titulo, name, subItems } = permisos[permiso];
                    if (Object.keys(subItems).length > 0) {
                        return (
                            <div
                                className="d-flex flex-column pt-3"
                                key={index}
                            >
                                <div className="d-flex">
                                    <Field
                                        name={name}
                                        component={renderFieldCheck}
                                    />
                                    <label>{titulo}</label>
                                </div>
                                <div className="d-flex flex-column pl-4">
                                    {Object.keys(subItems).map(
                                        (subItem, index) => {
                                            const { titulo, name } =
                                                subItems[subItem];
                                            return (
                                                <div
                                                    className="d-flex flex-row w-100"
                                                    key={index}
                                                >
                                                    <div className="d-flex">
                                                        <Field
                                                            name={name}
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>{titulo}</label>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        );
                    }
                    return (
                        <div className="d-flex py-3">
                            <Field name={name} component={renderFieldCheck} />
                            <label>{titulo}</label>
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default FieldContenedor;
