import React from "react";
import moment from "moment";
import NumberFormat from "react-number-format";
import { RenderReadCurrency } from "./renderFieldsCurrency";
import {connect} from "react-redux";
import {validarConfDecimales} from "../validaConfiguracionDecimal";

export const RenderNumber = ({ value, decimalScale, className }) => {
    if (!!value) {
        value = parseFloat(value).toFixed(decimalScale ? decimalScale : 0);
    }
    return (
        <NumberFormat
            className={className}
            decimalScale={decimalScale ? decimalScale : 0}
            fixedDecimalScale={true}
            value={value}
            thousandSeparator={true}
            prefix={""}
            displayType={"text"}
        />
    );
};


let RenderCurrency = ({ value, className, conf_empresa }) => {
    if (!!value) {
        value = parseFloat(value).toFixed(validarConfDecimales(conf_empresa));
    }
    return <RenderReadCurrency value={value} className={className} decimalScale={validarConfDecimales(conf_empresa)}/>;
};

const ms2p = (state) => {
    return {
        conf_empresa: state.usuario,
    };
};
const md2p = {};

RenderCurrency = connect(ms2p, md2p)(RenderCurrency);

export { RenderCurrency };

export const RenderCurrencySimple = ({ value, className, prefix = null }) => {
    return (
        <NumberFormat
            className={className}
            decimalScale={2}
            fixedDecimalScale
            value={value}
            thousandSeparator
            prefix={prefix != null ? `${prefix}. ` : "Q. "}
            displayType="text"
        />
    );
};

export const RenderPercentaje = ({ value, className, decimalScale }) => {
    return (
        <NumberFormat
            className={className}
            decimalScale={decimalScale ? decimalScale : 0}
            fixedDecimalScale={false}
            value={value}
            suffix={" %"}
            displayType={"text"}
        />
    );
};

export const RenderDateTime = ({ value, className }) => {
    if (value) {
        const fecha = new Date(value);
        return (
            <span className={className}>
                {fecha.toLocaleDateString()} {fecha.toLocaleTimeString()}
            </span>
        );
    }
    return <span className={className}>{value}</span>;
};
export const RenderDate = ({ value, className }) => {
    if (value) {
        // const fecha = new Date(value);
        const fecha = moment(value).format("DD/MM/YYYY");
        return <span className={className}>{fecha}</span>;
    }
    return <span className={className}>-----</span>;
};

export const ReadFields = {
    renderCurrency: RenderCurrency,
    renderNumber: RenderNumber,
    RenderPercentaje: RenderPercentaje,
    renderCurrencySimple: RenderCurrencySimple,
};
