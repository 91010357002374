import React from "react";
import { icons } from "icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { DEPARTAMENTOS, MUNICIPIOS } from "Utils/departamentos_municipios";
import {
    renderField,
    renderSelectField,
    renderSwitch,
} from "Utils/renderField/renderField";
import TituloBorde from "../../../../Utils/TituloBorde/TituloBorde";
import { nitSinCF } from "utility/validation";

const validate = (values, props) => {
    const errors = {};
    if (!values.nombre) errors.nombre = "Campo requerido";
    if (!values.prefijo) errors.prefijo = "Campo requerido";

    if (props.empresa.utiliza_fel) {
        if (!values.direccion) errors.direccion = "Campo requerido";
        if (!values.codigo_establecimiento)
            errors.codigo_establecimiento = "Campo requerido";
        if (!values.nombre_comercial)
            errors.nombre_comercial = "Campo requerido";
        if (!values.departamento) {
            errors.departamento = "Campo requerido";
        }
        if (!values.municipio || values.municipio === "") {
            errors.municipio = "Campo requerido";
        }

        if (!values.nit_token) {
            errors.nit_token = "Campo requerido";
        } else {
            errors.nit_token = nitSinCF(values.nit_token);
        }
        if (!values.password_token) {
            errors.password_token = "Campo requerido";
        }
    }
    return errors;
};

const Form = (props) => {
    const { handleSubmit, idEmpresa, empresa, municipios } = props;
    return (
        <form onSubmit={handleSubmit}>
            <TituloBorde contenido="Crear sucursal" className="my-3" />
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-md-5 col-sm-12 mt-3">
                            <label htmlFor="nombre">
                                Nombre de la sucursal*
                            </label>
                            <Field
                                name="nombre"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-1 col-0" />

                        <div className="col-md-5 col-sm-12 mt-3">
                            <label htmlFor="direccion">Dirección</label>
                            <Field
                                name="direccion"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-1 col-0" />

                        <div className="col-md-5 col-sm-12 mt-3">
                            <label htmlFor="telefono">Teléfono</label>
                            <Field
                                name="telefono"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-1 col-0" />

                        <div className="col-md-5 col-sm-12 mt-3">
                            <label htmlFor="prefijo">
                                Prefijo <small>(para código de venta)</small>
                            </label>
                            <Field
                                name="prefijo"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>

                        <div className="col-12 mt-5">
                            <h3 className="border-bottom mb-3">
                                Venta en línea
                            </h3>
                        </div>

                        {/* //*** VENTA MAYOREO EN LINEA */}
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group has-feedback">
                                {/* <label htmlFor="descuento">
                                        Acepta descuento?
                                    </label> */}
                                <div className="d-flex">
                                    <label className="mr-2">No</label>
                                    <Field
                                        name="venta_mayoreo_linea"
                                        component={renderSwitch}
                                        type="text"
                                        className="form-control"
                                    />
                                    <label className="ml-2">Si</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 col-0" />
                        {/* //*** VENTA MAYOREO EN LINEA */}

                        {empresa && empresa.utiliza_fel && (
                            <div className="w-100 mt-5">
                                <div className="col-sm-12">
                                    <h3 className="border-bottom mb-3">
                                        Datos FEL
                                    </h3>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-5">
                                        <div className="form-group">
                                            <label htmlFor="codigo_establecimiento">
                                                Código del establecimiento *
                                            </label>
                                            <Field
                                                name="codigo_establecimiento"
                                                component={renderField}
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-1 col-0" />

                                    <div className="col-12 col-md-5">
                                        <label htmlFor="nombre_comercial">
                                            Nombre comercial *
                                        </label>
                                        <Field
                                            name="nombre_comercial"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-md-1 col-0" />

                                    <div className="col-12 col-md-5 form-group">
                                        <label htmlFor="departamento">
                                            Departamento
                                        </label>
                                        <div className="d-flex flex-column flex-md-row pr-0">
                                            <div className="w-100 d-flex flex-column">
                                                <Field
                                                    key="id"
                                                    valueKey="label"
                                                    name="departamento"
                                                    component={
                                                        renderSelectField
                                                    }
                                                    options={DEPARTAMENTOS}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1 col-0" />

                                    <div className="col-12 col-md-5 form-group">
                                        <label htmlFor="municipio">
                                            Municipio
                                        </label>
                                        <div className="d-flex flex-column flex-md-row pr-0">
                                            <div className="w-100 d-flex flex-column">
                                                <Field
                                                    key="id"
                                                    valueKey="label"
                                                    name="municipio"
                                                    component={
                                                        renderSelectField
                                                    }
                                                    options={municipios}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1 col-0" />

                                    <div className="col-12 col-md-5 form-group">
                                        <label htmlFor="codigo_postal">
                                            Código postal
                                        </label>
                                        <Field
                                            name="codigo_postal"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-md-1 col-0" />

                                    <div className="col-12 col-md-5 form-group">
                                        <label htmlFor="es_agente_retenedor_iva">
                                            Es agente retenedor de IVA
                                        </label>
                                        <div className="d-flex">
                                            <label className="mr-2">No</label>
                                            <Field
                                                name="es_agente_retenedor_iva"
                                                component={renderSwitch}
                                                type="text"
                                                className="form-control"
                                            />
                                            <label className="ml-2">Si</label>
                                        </div>
                                    </div>
                                    <div className="col-md-1 col-0" />

                                    {/* //**************TOKEN FEL******************** */}
                                    <div className="col-sm-12 mt-4">
                                        <h3 className="border-bottom mb-3">
                                            Token FEL
                                        </h3>
                                    </div>
                                    <div className="col-12 col-md-5">
                                        <div className="form-group">
                                            <label htmlFor="nit_token">
                                                NIT (usuario) *
                                            </label>
                                            <Field
                                                name="nit_token"
                                                component={renderField}
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-1 col-0" />

                                    <div className="col-12 col-md-5">
                                        <label htmlFor="password_token">
                                            Contraseña *
                                        </label>
                                        <Field
                                            name="password_token"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-md-1 col-0" />
                                </div>
                            </div>
                        )}
                    </div>
                    <br />
                    <div className="d-flex justify-content-center flex-row">
                        <Link
                            className="btn btn-secondary m-1 align-self-center"
                            to={`/empresas/editar/${idEmpresa}`}
                        >
                            <img src={icons.cancelar} alt="" />
                            Cancelar
                        </Link>
                        <button
                            type="submit"
                            className="btn btn-primary m-1 align-self-center"
                        >
                            <img src={icons.save} alt="" />
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

let SucursalForm = reduxForm({
    // a unique name for the form
    form: "SucursalCrear",
    validate,
})(Form);

const selector = formValueSelector("SucursalCrear");
SucursalForm = connect((state) => {
    const departementoValue = selector(state, "departamento");
    let municipios = [];
    const departamento = DEPARTAMENTOS.filter(
        (elemento) => elemento.label === departementoValue
    );
    if (departamento.length) {
        const results = MUNICIPIOS.filter(
            (elemento) => elemento.departamento === departamento[0].id
        );
        results.unshift({});
        municipios = results;
    }

    return {
        municipios,
    };
})(SucursalForm);

export default SucursalForm;
