import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { validations } from "../../../utility/validation";
import './login.css';
import RemocarLogo from "../../../../assets/img/remocar-logo.png"
class Login extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { email: '', password: '' };
  }

  render() {
    const { passError, nameError, submitError } = this.props;
    if (localStorage.getItem('token')) {
      return (<Redirect to="/page" />);
    }
    return (
      <div>
        <div className="row">
          <div className="flex-1 text-center mt-5">
            <h1>Administración</h1>
            <img src={RemocarLogo} className="h-50" />
          </div>
        </div>
        <div className="row login-wrapper">
          <div className="col-lg-4 col-md-4 col-sm-11 offset-lg-4 offset-md-4 offset-sm-3 offset-sm-0">
            <div className="panel panel-flat">
              <div className="panel-body">
                <p className="text-center pv">INGRESE SU USUARIO Y CONTRASEÑA.</p>
                <form name="loginForm" className="form-validate mb-lg" onSubmit={(e) => {
                  e.preventDefault();
                  this.props.onSubmit(this.state);
                }}
                >
                  <div className="form-group has-feedback">
                    <input id="inputEmail" type="text" name="account_email" placeholder="Email"
                           ref={node => {
                             this.userEmail = node;
                           }}
                           onChange={() => {
                             if (this.userEmail) {
                               this.setState({email: this.userEmail.value});
                             }
                           }}
                           autoComplete="off" required="" className="form-control" />
                    <span className="fa fa-user form-control-feedback text-muted" />
                    <p className="form-notice">{validations.required(this.state.email)}</p>
                  </div>
                  <div className="form-group has-feedback">
                    <input id="inputPassword" type="password" name="account_password" placeholder="Contraseña"
                           ref={node => {
                             this.userPassword = node;
                           }}
                           onChange={() => {
                             if (this.userPassword) {
                               this.setState({password: this.userPassword.value});
                             }
                           }}
                           required="" className="form-control" />
                    <span className="fa fa-lock form-control-feedback text-muted" />
                    <p className="form-notice">{validations.required(this.state.password)}</p>
                  </div>
                  <div className="clearfix" />
                  {(this.props.loader) && (<div className="loader-container"><div className="loader" /></div>)}
                  {(!this.props.loader) && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.onSubmit(this.state);
                      }}
                      className="btn btn-block btn-primary mt-lg"
                      type={'submit'}
                    >
                      Login
                    </button>
                  )}
                </form>
                { submitError && (
                  <div className="alert alert-danger text-center">
                    Verifique que ha ingresado bien su nombre de usuario y contraseña.
                  </div>)}
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
