import { api } from "api";

const useAsyncSearch = (
    endpoint,
    listado_options,
    initial_params = {},
    es_async_factura = false
) => {
    const getOptions = (search) => {
        return api
            .get(endpoint, { search, async_options: true, ...initial_params })
            .catch(() => {})
            .then((data) => {
                data.results.forEach((item, index) => {
                    if (es_async_factura) {
                        item.index = index;
                        item.label = `Factura #${index + 1} - ${item.label}`;
                    }

                    if (!_.find(listado_options, { id: item.id }))
                        listado_options.push(item);
                });
                return { options: listado_options };
            });
    };

    return { getOptions };
};

export default useAsyncSearch;
