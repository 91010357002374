import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

export const ComponentChilds =  function (props) {
    const isExpandableRow = (row) => {
        if (row.childs.length) return true;
        else return false;
    }
    const expandComponent = (row) => {
        if(row.childs.length) {
            return (
                <ComponentChilds data={row.childs}/>
            )
        } else {
            return <span>No data</span>;
        }
    }

    //  State
    const { data } = props;
    if (data.length) {
        return (
            <BootstrapTable data={data}
                expandableRow={isExpandableRow}
                expandComponent={expandComponent}
                expandColumnOptions={{ expandColumnVisible: true }}>
                <TableHeaderColumn dataField='id' isKey hidden>ID</TableHeaderColumn>
                <TableHeaderColumn dataField='nombre'>Nombre</TableHeaderColumn>
                <TableHeaderColumn dataField='slug'>Slug</TableHeaderColumn>
                <TableHeaderColumn dataField='creado'>Fecha</TableHeaderColumn>
            </BootstrapTable>
        );
    } else {
        return (<p>?</p>);
    }
}
