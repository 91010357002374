import { useEffect } from "react";
import useAsyncSearch from "./useAsyncSearch";
import Swal from "sweetalert2";

let listado_bodegas = [];
const useProductosForm = (
    productos,
    tipo_origen,
    producto_actual,
    agregarProducto,
    cliente_id
) => {
    const url = window.location.href;

    let origin = "";
    let params = {
        filtro_sucursal: true,
    };

    origin = "bodegas";

    if (url.includes("ordenes_de_venta")) {
        origin = "productos_venta";
        params.cliente = cliente_id;
        params.raw = true
    }

    const { getOptions: getProductos } = useAsyncSearch(
        origin,
        listado_bodegas,
        params
    );

    const prefixMoneda =
        parseInt(tipo_origen) === 2
            ? "Q "
            : parseInt(tipo_origen) === 1
            ? "$ "
            : "";
    const costo_quetzales = producto_actual.costo || 0;
    const costo_dolares = producto_actual.costo_dolares || 0;
    const costo =
        parseInt(tipo_origen) === 2
            ? costo_quetzales
            : parseInt(tipo_origen) === 1
            ? costo_dolares
            : 0
            ? parseInt(tipo_origen) === 3
            : costo_quetzales;

    const precio = Math.ceil(producto_actual.precio) || 0;

    const onSubmit = (values, event) => {
        const select_codigo = document.getElementById("codigo");
        let mismo_producto = false
        
        // Verificando si el producto actual ya está ingresado en el formulario
        productos.forEach(producto => {
            // Si se intenta ingresar el mismo producto más de una vez, se debe mostrar una alerta y parar el proceso
            if (producto.producto.id === producto_actual.id) {
                mismo_producto = true
            }
        })

        // Validación de ingreso de un mismo producto
        if (mismo_producto) {
            Swal({
                title: "Error",
                text: "Este producto ya está ingresado",
                type: "warning",
            })
        }
        else {
            agregarProducto(producto_actual, values.cantidad, values.costo);
        }

        select_codigo.focus();
        // Limpiar el formulario
        event.reset({
            codigo: "",
            costo: "",
            cantidad: "",
            precio: "",
        });
    };

    useEffect(() => {
        return () => {
            listado_bodegas = [];
        };
    }, []);

    return { prefixMoneda, costo, getProductos, onSubmit, precio };
};

export default useProductosForm;
