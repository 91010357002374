import _ from "lodash";
import { api } from "api";
import moment from "moment";
import uuidv1 from "uuid/v1";
import Swal from "sweetalert2";
import { ToastStore } from "react-toasts";
import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm, change as changeForm } from "redux-form";

// ------------------------------------
// Constants
// ------------------------------------
// OC
export const TAB = "OC_TAB";
export const SET_OC = "OC_SET_OC";
export const PAGE_OC = "OC_PAGE_OC";
export const SORT_OC = "OC_SORT_OC";
export const MODAL_PAGO = "OC_MODAL_PAGO";
export const BUSCADOR_OC = "OC_BUSCADOR_OC";
export const SET_PAGE_RETIROS = "OC_SET_PAGE_RETIROS";
export const SET_LOADER_RETIROS = "OC_SET_LOADER_RETIROS";
export const SET_FECHA_I_RETIROS = "OC_SET_FECHA_I_RETIROS";
export const SET_FECHA_F_RETIROS = "OC_SET_FECHA_F_RETIROS";
export const SET_MOVIMIENTO_CAJA = "OC_SET_MOVIMIENTO_CAJA";
export const SET_ORDERING_RETIROS = "OC_SET_ORDERING_RETIROS";
export const SET_MOVIMIENTOS_RETIROS = "OC_SET_MOVIMIENTOS_RETIROS";
export const FECHA_COMPRA_INICIAL = "FECHA_COMPRA_INICIAL";
export const FECHA_COMPRA_FINAL = "FECHA_COMPRA_FINAL";
export const FECHA_INICIAL_COMPRA_FINALIZADA =
    "FECHA_INICIAL_COMPRA_FINALIZADA";
export const FECHA_FINAL_COMPRA_FINALIZADA = "FECHA_FINAL_COMPRA_FINALIZADA";
export const ESTADO_DESCARGA_2 =
    "ESTADO_DESCARGA_LISTADO_OREDENES_COMPRA_FINALIZADAS";
export const ESTADO_DESCARGA_1 =
    "ESTADO_DESCARGA_LISTADO_OREDENES_COMPRA_ACTIVAS";
// NUEVA OC
export const PAGE = "CC_PAGE";
export const SORT = "CC_SORT";
export const LOADER = "CCOADER";
export const DATOS = "CC_DATOS";
export const LISTADO = "CC_LISTADO";
export const BUSCADOR = "CC_BUACADOR";
export const PRODUCTOS = "CC_PRODUCTOS";
export const VER_BUSCADOR = "CC_VER_BUSCADOR";
export const SELECCIONADOS = "CC_SELECCIONADOS";
export const PRODUCTO_ACTUAL = "CC_PRODUCTO_ACTUAL";
export const DATOS_TIPO_PAGO = "CC_DATOS_TIPO_PAGO";
export const UUID_REQ_PRODUCTOS = "CC_UUID_REQ_PRODUCTOS";
const COSTO_IMPORTACION = "CC_COSTO_IMPORTACION";
const TIPO_CAMBIO = "CC_TIPO_CAMBIO";
const TIPO_COMPRA = "CC_TIPO_COMPRA";
const DETALLE_PROVEDOR = "CC_DETALLE_PROVEDOR";
const TIPO_MOVIMIENTO = "CC_TIPO_MOVIMIENTO";
const CLIENTE = "CC_CLIENTE";
const CUENTAS_PAGAR_SELECCIONADAS = "CC_CUENTAS_PAGAR_SELECCIONADAS";
const DOCUMENTOS_PAGOS = "CC_DOCUMENTOS_PAGOS";
const CODIGO_COBRO = "CC_CODIGO_COBRO";

export const VENDEDOR = "CC_VENDEDOR";

const bodegaEndpoint = "bodegas";
const endpoint = "ordenes_venta";
const cuentasPagarEndpoint = "cuenta_cobrar";

// ------------------------------------
// Actions
// ------------------------------------
export const listarOC =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();
        const {
            search_oc,
            fecha_compra_final,
            fecha_compra_inicial,
            tipo_movimiento,
            vendedor,
            cliente,
        } = store.cuenta_cobrar;

        // Obtener el id del usuario, si este existe
        const cliente_id = cliente ? cliente.id : "";
        const vendedor_id = vendedor ? vendedor.id : "";
        {
            console.log("cliente_id");
        }
        api.get(endpoint, {
            page,
            ordering: "-fecha",
            search: search_oc,
            cuentas_cobrar: 1,
            fecha_inicial: fecha_compra_inicial,
            fecha_final: fecha_compra_final,
            tipo_movimiento,
            cliente_id,
            vendedor_id,
            es_venta_credito: 1,
        })
            .catch((err) => {})
            .then((data) => {
                if (data) {
                    dispatch(setListadoOC(data));
                }
                dispatch(setPageOC(page));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();
        const { search } = store.cuenta_pagar;
        let { ordering } = store.cuenta_pagar;
        //  GENERAR EL UUID
        const uuid = uuidv1();
        dispatch(setUuidReqProductos(uuid));
        if (!ordering) {
            ordering = "-fecha";
        }
        api.get(bodegaEndpoint, { page, ordering, search })
            .catch((err) => {})
            .then((data) => {
                if (data) {
                    data.results.forEach(
                        (item) =>
                            (item.costo =
                                item.usa_costo_promedio == true
                                    ? item.costo_promedio_ponderado
                                    : item.costo)
                    );
                    const otroUuid = getStore().cuenta_pagar.uuid_req_productos;
                    if (otroUuid === uuid) dispatch(setProductos(data));
                }
                dispatch(setPage(page));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.cuenta_pagar.page;
    dispatch(listar(page));
};

export const asignarDatos = () => (dispatch, getStore) => {
    const store = getStore();
    const values = store.form.NuevaOrdenCompra.values;
    dispatch(setDatos(values));
};

export const irTipoPago = () => (dispatch, getStore) => {
    const store = getStore();
    const { seleccionados } = store.cuenta_pagar;
    if (seleccionados.length) {
        dispatch(asignarDatos());
        dispatch(push("/ordenes_de_compra/nueva/tipo_de_pago"));
    } else {
        ToastStore.error("La orden de compra no tiene productos");
    }
};

export const limpiarDatosOC = () => (dispatch) => {
    dispatch(setProductos({}));
    dispatch(setVerBuscador(false));
    dispatch(setUuidReqProductos(""));
    dispatch(setPage(1));
    dispatch(setBuscador(""));
    dispatch(setSort(""));
    dispatch(setLoader(false));
    dispatch(setSeleccionados([]));
    dispatch(setCuentasPagar([]));
    dispatch(setDatos({ fecha: moment().format("YYYY-MM-DD") }));
    dispatch(
        setDatosTipoPago({
            fecha_limite: moment().format("YYYY-MM-DD"),
            plazo_pago: "inmediato",
        })
    );
};

export const setSeleccionadosValue = (value) => (dispatch) => {
    dispatch(setSeleccionados(value));
};

export const cambioPlazo = (plazo) => (dispatch, getStore) => {
    const store = getStore();
    const { datos_tipo_pago } = store.cuenta_pagar;
    const { values } = store.form.OCTipoPago;
    let fecha_limite = moment();
    let periodo_pago = 0;
    if (plazo != "inmediato") {
        if (plazo == "personalizado") {
            fecha_limite = moment(values.fecha_limite, "YYYY-MM-DD");
            periodo_pago = fecha_limite.diff(moment(), "days") + 1;
        } else {
            periodo_pago = parseInt(plazo);
            fecha_limite = moment().add(periodo_pago, "days");
        }
    }
    fecha_limite = fecha_limite.format("YYYY-MM-DD");
    dispatch(changeForm("OCTipoPago", "fecha_limite", fecha_limite));
    dispatch(
        setDatosTipoPago({ ...datos_tipo_pago, fecha_limite, periodo_pago })
    );
};

export const seleccionFechaLimite = (fecha_limite) => (dispatch, getStore) => {
    const store = getStore();
    const { datos_tipo_pago } = store.cuenta_pagar;
    let fecha_li = moment(fecha_limite, "YYYY-MM-DD");
    let periodo_pago = fecha_li.diff(moment(), "days") + 1;
    fecha_li = fecha_li.format("YYYY-MM-DD");
    dispatch(changeForm("OCTipoPago", "fecha_limite", fecha_li));
    dispatch(
        setDatosTipoPago({
            ...datos_tipo_pago,
            fecha_limite: fecha_li,
            periodo_pago,
        })
    );
};

export const asignarDatosTipoPago = () => (dispatch, getStore) => {
    const store = getStore();
    const { datos_tipo_pago } = store.cuenta_pagar;
    const values = store.form.OCTipoPago.values;
    dispatch(setDatosTipoPago({ ...datos_tipo_pago, ...values }));
};

// OC
export const changeTab = (tab) => (dispatch) => {
    dispatch(setTab(tab));
    dispatch(listarOC());
};
export const buscarOC = (search) => (dispatch) => {
    dispatch(setBuscadorOC(search));
    dispatch(listarOC());
};

export const sortChangeOC = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSortOC(sortName));
    } else {
        dispatch(setSortOC(`-${sortName}`));
    }
    const store = getStore();
    const page_oc = store.cuenta_pagar.page_oc;
    dispatch(listarOC(page_oc));
};

export const getOC = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`)
        .catch((err) => {})
        .then((data) => {
            if (data) {
                dispatch(setOC(data));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const getDocumentosPagos =
    (id, esVerDocumentoPago = false) =>
    (dispatch) => {
        dispatch(setLoader(true));
        const params = {};
        if (esVerDocumentoPago === true) {
            params.es_pago = 1;
        }
        api.get(`${endpoint}/${id}/documento_pagos`, params)
            .then((data) => {
                if (data) {
                    dispatch(setDocumentosPagos(data));
                }
            })
            .catch((err) => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const marcarPagoFinalizadoOC =
    (id, registrar_pago = false, data = {}) =>
    (dispatch) => {
        let data_pago = {};
        if (registrar_pago) {
            data_pago = {
                orden_compra: id,
                tipo_abono: "2000",
                forma_pago: data.forma_pago,
                aplicar_descuento: false,
                descuento: 0,
                nota_de_descuento: "None",
                num_documento: data.codigo_documento,
            };
        }
        Swal({
            title: "¿Finalizar pago de la orden de compra?",
            text: "¡No podrá revertir esta acción!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "¡Sí, Finalizar!",
            cancelButtonText: "No, cancelar",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                // dispatch(setLoader(true));
                // Si se va a abonar y finalizar el pago
                if (registrar_pago) {
                    api.post(`${cuentasPagarEndpoint}/finalizar_pago`, {
                        ...data_pago,
                        ...data,
                    })
                        .catch((error) => {
                            Swal(
                                "ERROR",
                                error.detail ||
                                    "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                                "error"
                            );
                        })
                        .then((resp) => {
                            if (resp) {
                                ToastStore.success(
                                    "Pago registrado exitosamente"
                                );
                                dispatch(setOpenModalPago(false));
                                dispatch(limpiarDatosPago());
                                dispatch(getOC(id));
                            }
                        })
                        .finally(() => {
                            dispatch(setLoaderRetiros(false));
                            dispatch(listarMovimientos());
                        });
                } else {
                    api.put(`${endpoint}/${id}/marcar_pago_fianlizado`)
                        .catch((err) => {
                            Swal(
                                "ERROR",
                                err.detail ||
                                    "Ha ocurrido un error, intenta más tarde.",
                                "error"
                            );
                        })
                        .then((resp) => {
                            ToastStore.success(
                                "Datos actualizados exitosamente"
                            );
                        })
                        .finally(() => {
                            dispatch(setLoader(false));
                            dispatch(getOC(id));
                        });
                }
            }
        });
    };

export const finalizarMultiplesCuentas =
    (data = {}, totalPagar, closeModal = () => {}) =>
    (dispatch) => {
        if (data.cuentas_a_pagar.length === 0) {
            return Swal(
                "ERROR",
                "Debe seleccionar al menos una cuenta para finalizar el pago.",
                "error"
            );
        }
        let data_pago = {
            aplicar_descuento: false,
            tipo_abono: "2000",
            descuento: 0,
            nota_de_descuento: "None",
            total_pagar: totalPagar,
            ...data,
        };

        Swal({
            title: "¿Finalizar pago de las ordenes de venta?",
            text: "¡No podrá revertir esta acción!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "¡Sí, Finalizar!",
            cancelButtonText: "No, cancelar",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                api.post(
                    `${cuentasPagarEndpoint}/finalizar_pago_multiple`,
                    data_pago
                )
                    .then((resp) => {
                        if (resp) {
                            ToastStore.success("Pago registrado exitosamente");
                            dispatch(limpiarDatosPago());
                            closeModal(false);
                            dispatch(listarOC());
                        }
                    })
                    .catch((error) => {
                        Swal(
                            "ERROR",
                            error.detail ||
                                "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                            "error"
                        );
                    })
                    .finally(() => {
                        dispatch(setLoaderRetiros(false));
                        dispatch(listarMovimientos());
                    });
            }
        });
    };

export const reveritirMarcarPagoFinalizadoOC = (id) => (dispatch) => {
    Swal({
        title: "¿Revertir el estado de pago?",
        text: "¡No podrá revertir esta acción!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, Revertir!",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.put(`${endpoint}/${id}/revertir_marcar_pago_finalizdo`)
                .catch((err) => {
                    Swal(
                        "ERROR",
                        err.detail ||
                            "Ha ocurrido un error, intenta más tarde.",
                        "error"
                    );
                })
                .then((resp) => {
                    ToastStore.success("Datos actualizados exitosamente");
                })
                .finally(() => {
                    dispatch(setLoader(false));
                    dispatch(getOC(id));
                });
        }
    });
};

// DETALLE OC
export const listarMovimientos =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoaderRetiros(true));
        const store = getStore();
        let { ordering_retiros, fecha_inicial, fecha_final } =
            store.cuenta_pagar;

        api.get(`${cuentasPagarEndpoint}/retiros_caja`, {
            page,
            ordering: ordering_retiros,
            fecha_inicial,
            fecha_final,
        })
            .catch((err) => {
                ToastStore.error("Error al obtener los movimientos de caja");
            })
            .then((resp) => {
                if (resp) {
                    dispatch(setMovimientosRetiros(resp));
                }
                dispatch(setPageRetiros(page));
            })
            .finally(() => {
                dispatch(setLoaderRetiros(false));
            });
    };

export const sortChangeRetiros =
    (sortName, sortOrder) => (dispatch, getStore) => {
        if (sortOrder === "asc") {
            dispatch(setSortRetiros(sortName));
        } else {
            dispatch(setSortRetiros(`-${sortName}`));
        }
        const store = getStore();
        const { page_retiros } = store.cuenta_pagar;
        dispatch(listarMovimientos(page_retiros));
    };

export const changeFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const cuenta_pagar = store.cuenta_pagar;
    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(cuenta_pagar.fecha_final);
        if (fecha_final >= fecha_inicial) {
            dispatch(setFechaIRetiros(value));
        }
    } else {
        const fecha_inicial = new Date(cuenta_pagar.fecha_inicial);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) {
            dispatch(setFechaFRetiros(value));
        }
    }

    dispatch(listarMovimientos());
};

export const limpiarDatosPago = () => (dispatch) => {
    dispatch(setMovimientosRetiros({}));
    dispatch(setPageRetiros(1));
    dispatch(setSortRetiros(""));
    dispatch(setFechaIRetiros(moment().format("YYYY-MM-DD")));
    dispatch(setFechaFRetiros(moment().format("YYYY-MM-DD")));
    dispatch(setMovimientoCaja(null));
    dispatch(setSeleccionados([]));
    dispatch(setCuentasPagar([]));
};

export const registrarPagoAbono =
    (idOC, data = {}) =>
    (dispatch, getStore) => {
        dispatch(setLoaderRetiros(true));

        const store = getStore();
        const sucursal = store.usuario.sucursal;

        let dataAbono = {
            movimiento_id: idOC,
            monto: data.monto,
            forma_pago: data.forma_pago,
            num_documento: data.codigo_documento,
            tipo_abono: "2000",
            aplicar_descuento: false,
            descuento: 0,
            nota_de_descuento: "None",
            numero_documento: data.numero_documento,
            observacion: data.observacion || "",
            banco: data.banco,
            numero_recibo: data.numero_recibo,
            sucursal,
            // sucursal: 0,
        };

        api.post(`${cuentasPagarEndpoint}/abono`, dataAbono)
            .catch((error) => {
                Swal(
                    "ERROR",
                    error.detail ||
                        "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                    "error"
                );
            })
            .then((resp) => {
                if (resp) {
                    ToastStore.success("Pago registrado exitosamente");
                    dispatch(setOpenModalPago(false));
                    dispatch(limpiarDatosPago());
                    dispatch(getOC(idOC));
                }
            })
            .finally(() => {
                dispatch(setLoaderRetiros(false));
                dispatch(listarMovimientos());
            });
    };

export const descargarListadoOCA = () => (dispatch, getStore) => {
    const store = getStore();
    const { search_oc, fecha_compra_final, fecha_compra_inicial } =
        store.cuenta_pagar;
    let params = {
        search: search_oc,
        fecha_compra_final,
        fecha_compra_inicial,
    };
    dispatch(setEstadoDescarga1(true));
    api.get(`ordenes_compra/descargar_excel_OCA`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga1(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga1(true));
                dispatch(esperarDescarga1(data.id));
            }
        });
};

const esperarDescarga1 = (id) => (dispatch) => {
    let intervalPromise;
    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA
    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga1(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga1(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "ordenes_de_compra_activas.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga1(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }
    listener();
    intervalPromise = setInterval(listener, 1000);
};

const esperarDescarga2 = (id) => (dispatch) => {
    let intervalPromise;
    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA
    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga2(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga2(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "ordenes_de_compra_finalizadas.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };
                    dispatch(setEstadoDescarga2(false));
                    dispatch(descargaArchivo2(context));
                }
            });
    }
    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga1(false));
    ToastStore.success("Archivo descargado exitosamente");
};
const descargaArchivo2 = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga2(false));
    ToastStore.success("Archivo descargado exitosamente");
};
export const descargarListadoOCF = () => (dispatch, getStore) => {
    const store = getStore();
    const {
        search_oc,
        fecha_final_compra_finalizada,
        fecha_inicial_compra_finalizada,
    } = store.cuenta_pagar;
    let params = {
        search: search_oc,
        fecha_inicial: fecha_inicial_compra_finalizada,
        fecha_final: fecha_final_compra_finalizada,
    };
    dispatch(setEstadoDescarga2(true));
    api.get(`ordenes_compra/descargar_excel_OCF`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga2(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga2(true));
                dispatch(esperarDescarga2(data.id));
            }
        });
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const cuenta_pagar = store.cuenta_pagar;
    if (key === "Inicial") {
        const fecha_compra_inicial = new Date(value);
        const fecha_compra_final = new Date(cuenta_pagar.fecha_compra_final);
        // if (fecha_compra_final >= fecha_compra_inicial)
        dispatch(setFechaCompraInicial1(value));
    } else {
        const fecha_compra_inicial = new Date(
            cuenta_pagar.fecha_compra_inicial
        );
        const fecha_compra_final = new Date(value);
        if (fecha_compra_final >= fecha_compra_inicial)
            dispatch(setFechaCompraFinal1(value));
    }
    dispatch(listarOC());
    dispatch(setSeleccionados([]));
    dispatch(setCuentasPagar([]));
};

export const setFechaOCF = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const cuenta_pagar = store.cuenta_pagar;
    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(
            cuenta_pagar.fecha_final_compra_finalizada
        );
        if (fecha_final >= fecha_inicial)
            dispatch(setFechaCompraInicial2(value));
    } else {
        const fecha_inicial = new Date(
            cuenta_pagar.fecha_inicial_compra_finalizada
        );
        const fecha_compra_final = new Date(value);
        if (fecha_compra_final >= fecha_inicial)
            dispatch(setFechacCompraFinal2(value));
    }
    dispatch(listarOC());
};

// Limpiar estado redux
export const limpiar = () => (dispatch) => {
    dispatch(setFechaCompraInicial1(moment().format("YYYY-MM-DD")));
    dispatch(setFechaCompraFinal1(moment().format("YYYY-MM-DD")));
    dispatch(setFechacCompraFinal2(moment().format("YYYY-MM-DD")));
    dispatch(setFechaCompraInicial2(moment().format("YYYY-MM-DD")));
    dispatch(setSeleccionados([]));
    dispatch(setCuentasPagar([]));
    dispatch(setTipoMovimiento(""));
    dispatch(setCliente(""));
    dispatch(setPage(1));
    dispatch(setBuscador(""));
    dispatch(setSort(""));
    dispatch(setLoader(false));
};

export const setCodigoCobro =
    (reset = false) =>
    (dispatch, getStore) => {
        if (reset) {
            dispatch(_setCodigoCobro(""));
        } else {
            const store = getStore();

            const sucursal_id = store.usuario.sucursal
                ? store.usuario.sucursal
                : 0;

            api.get(`${cuentasPagarEndpoint}/codigo_cobro`, { sucursal_id })
                .then((data) => {
                    dispatch(_setCodigoCobro(data));
                })
                .catch((err) => {
                    Swal(
                        "¡Error al obtener el código!",
                        "Ocurrió un error al obtener el código. Por favor intenté más tarde.",
                        "error"
                    );
                    dispatch(_setCodigoCobro(""));
                });
        }
    };

export const setCostoImportacionValue = (value) => (dispatch) => {
    dispatch(setCostoImportacion(value));
};

export const setTipoCambioValue = (value) => (dispatch) => {
    dispatch(setTipoCambio(value));
};

export const setTipoCompraValue = (value) => (dispatch) => {
    dispatch(setTipoCompra(value));
};

export const setTipoMovimientoValue = (value) => (dispatch) => {
    dispatch(setTipoMovimiento(value));
    dispatch(setSeleccionados([]));
    dispatch(setCuentasPagar([]));
    dispatch(listarOC());
};

export const setClienteValue = (value) => (dispatch) => {
    console.log("redux", value);
    dispatch(setCliente(value));
    dispatch(setSeleccionados([]));
    dispatch(setCuentasPagar([]));
    dispatch(listarOC());
};

export const setCuentasPagar = (value) => (dispatch) => {
    dispatch(setCuentasPagarSeleccionadas(value));
};

export const setDetalleProveedorValue = (detalle_proveedor) => ({
    type: DETALLE_PROVEDOR,
    detalle_proveedor,
});

export const setTipoCompra = (tipo_compra_value) => ({
    type: TIPO_COMPRA,
    tipo_compra_value,
});

export const setTipoCambio = (tipo_cambio_value) => ({
    type: TIPO_CAMBIO,
    tipo_cambio_value,
});

export const setTipoMovimiento = (tipo_movimiento) => ({
    type: TIPO_MOVIMIENTO,
    tipo_movimiento,
});

export const setCliente = (cliente) => ({
    type: CLIENTE,
    cliente,
});

export const setCuentasPagarSeleccionadas = (cuenta_pagar) => ({
    type: CUENTAS_PAGAR_SELECCIONADAS,
    cuenta_pagar,
});

export const setCostoImportacion = (costo_importacion_value) => ({
    type: COSTO_IMPORTACION,
    costo_importacion_value,
});

export const setFechaCompraInicial1 = (fecha_compra_inicial) => ({
    type: FECHA_COMPRA_INICIAL,
    fecha_compra_inicial,
});

export const setFechaCompraFinal1 = (fecha_compra_final) => ({
    type: FECHA_COMPRA_FINAL,
    fecha_compra_final,
});

export const setFechaCompraInicial2 = (fecha_inicial_compra_finalizada) => ({
    type: FECHA_INICIAL_COMPRA_FINALIZADA,
    fecha_inicial_compra_finalizada,
});

export const setFechacCompraFinal2 = (fecha_final_compra_finalizada) => ({
    type: FECHA_FINAL_COMPRA_FINALIZADA,
    fecha_final_compra_finalizada,
});

export const setEstadoDescarga1 = (estado_descarga) => ({
    type: ESTADO_DESCARGA_1,
    estado_descarga,
});

export const setEstadoDescarga2 = (estado_descarga2) => ({
    type: ESTADO_DESCARGA_2,
    estado_descarga2,
});

// ------------------------------------
// PureActions
// ------------------------------------
export const setProductos = (productos) => ({
    type: PRODUCTOS,
    productos,
});
export const setVerBuscador = (ver_buscador) => ({
    type: VER_BUSCADOR,
    ver_buscador,
});
export const setUuidReqProductos = (uuid_req_productos) => ({
    type: UUID_REQ_PRODUCTOS,
    uuid_req_productos,
});
export const setPage = (page) => ({
    type: PAGE,
    page,
});
export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});
export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});
export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});
export const setSeleccionados = (seleccionados) => ({
    type: SELECCIONADOS,
    seleccionados,
});
export const _setProductoActual = (producto_actual) => ({
    type: PRODUCTO_ACTUAL,
    producto_actual,
});
export const setDatos = (datos) => ({
    type: DATOS,
    datos,
});

// OC
export const setTab = (tab) => ({
    type: TAB,
    tab,
});
export const setListadoOC = (datos_listado) => ({
    type: LISTADO,
    datos_listado,
});
export const setPageOC = (page_oc) => ({
    type: PAGE_OC,
    page_oc,
});
export const setBuscadorOC = (search_oc) => ({
    type: BUSCADOR_OC,
    search_oc,
});
export const setSortOC = (ordering_oc) => ({
    type: SORT_OC,
    ordering_oc,
});
export const setOC = (orden_compra) => ({
    type: SET_OC,
    orden_compra,
});

export const setDocumentosPagos = (documentos_pago) => ({
    type: DOCUMENTOS_PAGOS,
    documentos_pago,
});
export const setMovimientosRetiros = (retiros) => ({
    type: SET_MOVIMIENTOS_RETIROS,
    retiros,
});
export const setPageRetiros = (page_retiros) => ({
    type: SET_PAGE_RETIROS,
    page_retiros,
});
export const setSortRetiros = (ordering_retiros) => ({
    type: SET_ORDERING_RETIROS,
    ordering_retiros,
});
export const setFechaIRetiros = (fecha_inicial) => ({
    type: SET_FECHA_I_RETIROS,
    fecha_inicial,
});
export const setFechaFRetiros = (fecha_final) => ({
    type: SET_FECHA_F_RETIROS,
    fecha_final,
});
export const setLoaderRetiros = (loader_retiros) => ({
    type: SET_LOADER_RETIROS,
    loader_retiros,
});
export const setDatosTipoPago = (datos_tipo_pago) => ({
    type: DATOS_TIPO_PAGO,
    datos_tipo_pago,
});
export const setMovimientoCaja = (movimiento_caja) => ({
    type: SET_MOVIMIENTO_CAJA,
    movimiento_caja,
});

export const setOpenModalPago = (open_modal_pago) => ({
    type: MODAL_PAGO,
    open_modal_pago,
});

export const _setCodigoCobro = (codigo_cobro) => ({
    type: CODIGO_COBRO,
    codigo_cobro,
});

export const _setVendedor = (vendedor) => ({
    type: VENDEDOR,
    vendedor,
});

export const setVendedor = (vendedor) => (dispatch) => {
    dispatch(_setVendedor(vendedor));
    dispatch(listarOC());
};

export const actions = {
    getOC,
    listar,
    buscar,
    listarOC,
    buscarOC,
    changeTab,
    irTipoPago,
    sortChange,
    changeFecha,
    cambioPlazo,
    sortChangeOC,
    asignarDatos,
    limpiarDatosOC,
    setOpenModalPago,
    limpiarDatosPago,
    setMovimientoCaja,
    listarMovimientos,
    sortChangeRetiros,
    registrarPagoAbono,
    asignarDatosTipoPago,
    seleccionFechaLimite,
    marcarPagoFinalizadoOC,
    reveritirMarcarPagoFinalizadoOC,
    descargarListadoOCA,
    descargarListadoOCF,
    setFecha,
    setFechaOCF,
    setCostoImportacionValue,
    setTipoCambioValue,
    setTipoCompraValue,
    setTipoMovimientoValue,
    setClienteValue,
    setCuentasPagar,
    setSeleccionadosValue,
    finalizarMultiplesCuentas,
    getDocumentosPagos,
    limpiar,
    setCodigoCobro,
    setVendedor,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [PRODUCTOS]: (state, { productos }) => {
        return {
            ...state,
            productos,
        };
    },
    [VER_BUSCADOR]: (state, { ver_buscador }) => {
        return {
            ...state,
            ver_buscador,
        };
    },
    [UUID_REQ_PRODUCTOS]: (state, { uuid_req_productos }) => {
        return {
            ...state,
            uuid_req_productos,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SELECCIONADOS]: (state, { seleccionados }) => {
        return {
            ...state,
            seleccionados,
        };
    },
    [PRODUCTO_ACTUAL]: (state, { producto_actual }) => {
        return {
            ...state,
            producto_actual,
        };
    },
    [DATOS]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DATOS_TIPO_PAGO]: (state, { datos_tipo_pago }) => {
        return {
            ...state,
            datos_tipo_pago,
        };
    },
    [TAB]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
    [LISTADO]: (state, { datos_listado }) => {
        return {
            ...state,
            datos_listado,
        };
    },
    [PAGE_OC]: (state, { page_oc }) => {
        return {
            ...state,
            page_oc,
        };
    },
    [BUSCADOR_OC]: (state, { search_oc }) => {
        return {
            ...state,
            search_oc,
        };
    },
    [SORT_OC]: (state, { ordering_oc }) => {
        return {
            ...state,
            ordering_oc,
        };
    },
    [SET_OC]: (state, { orden_compra }) => {
        return {
            ...state,
            orden_compra,
        };
    },
    [DOCUMENTOS_PAGOS]: (state, { documentos_pago }) => {
        return {
            ...state,
            documentos_pago,
        };
    },
    [SET_MOVIMIENTOS_RETIROS]: (state, { retiros }) => {
        return {
            ...state,
            retiros,
        };
    },
    [SET_PAGE_RETIROS]: (state, { page_retiros }) => {
        return {
            ...state,
            page_retiros,
        };
    },
    [SET_ORDERING_RETIROS]: (state, { ordering_retiros }) => {
        return {
            ...state,
            ordering_retiros,
        };
    },
    [SET_LOADER_RETIROS]: (state, { loader_retiros }) => {
        return {
            ...state,
            loader_retiros,
        };
    },
    [SET_FECHA_I_RETIROS]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [SET_FECHA_F_RETIROS]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [SET_MOVIMIENTO_CAJA]: (state, { movimiento_caja }) => {
        return {
            ...state,
            movimiento_caja,
        };
    },
    [MODAL_PAGO]: (state, { open_modal_pago }) => {
        return {
            ...state,
            open_modal_pago,
        };
    },
    [FECHA_COMPRA_FINAL]: (state, { fecha_compra_final }) => {
        return {
            ...state,
            fecha_compra_final,
        };
    },
    [FECHA_COMPRA_INICIAL]: (state, { fecha_compra_inicial }) => {
        return {
            ...state,
            fecha_compra_inicial,
        };
    },
    [FECHA_FINAL_COMPRA_FINALIZADA]: (
        state,
        { fecha_final_compra_finalizada }
    ) => {
        return {
            ...state,
            fecha_final_compra_finalizada,
        };
    },
    [FECHA_INICIAL_COMPRA_FINALIZADA]: (
        state,
        { fecha_inicial_compra_finalizada }
    ) => {
        return {
            ...state,
            fecha_inicial_compra_finalizada,
        };
    },
    [ESTADO_DESCARGA_1]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
    [ESTADO_DESCARGA_2]: (state, { estado_descarga2 }) => {
        return {
            ...state,
            estado_descarga2,
        };
    },
    [COSTO_IMPORTACION]: (state, { costo_importacion_value }) => {
        return {
            ...state,
            costo_importacion_value,
        };
    },
    [TIPO_CAMBIO]: (state, { tipo_cambio_value }) => {
        return {
            ...state,
            tipo_cambio_value,
        };
    },
    [TIPO_COMPRA]: (state, { tipo_compra_value }) => {
        return {
            ...state,
            tipo_compra_value,
        };
    },
    [TIPO_MOVIMIENTO]: (state, { tipo_movimiento }) => {
        return {
            ...state,
            tipo_movimiento,
        };
    },
    [CLIENTE]: (state, { cliente }) => {
        return {
            ...state,
            cliente,
        };
    },
    [CUENTAS_PAGAR_SELECCIONADAS]: (state, { cuenta_pagar }) => {
        return {
            ...state,
            cuenta_pagar,
        };
    },
    [DETALLE_PROVEDOR]: (state, { detalle_proveedor }) => {
        return {
            ...state,
            detalle_proveedor,
        };
    },
    [CODIGO_COBRO]: (state, { codigo_cobro }) => {
        return {
            ...state,
            codigo_cobro,
        };
    },
    [VENDEDOR]: (state, { vendedor }) => {
        return {
            ...state,
            vendedor,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------
export const initialState = {
    datos_listado: {},
    page_oc: 1,
    search_oc: "",
    ordering_oc: "",
    orden_compra: null,

    productos: {},
    ver_buscador: false,
    uuid_req_productos: "",
    page: 1,
    search: "",
    ordering: "",
    loader: false,
    seleccionados: [],
    producto_actual: {},
    datos: { fecha: moment().format("YYYY-MM-DD") },
    datos_tipo_pago: {
        fecha_limite: moment().format("YYYY-MM-DD"),
        plazo_pago: "inmediato",
    },
    tab: 10,

    // RETIROS
    retiros: {},
    page_retiros: 1,
    loader_retiros: false,
    ordering_retiros: "",
    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
    movimiento_caja: null,
    open_modal_pago: false,
    fecha_compra_final: moment().format("YYYY-MM-DD"),
    fecha_compra_inicial: moment().format("YYYY-MM-DD"),
    fecha_final_compra_finalizada: moment().format("YYYY-MM-DD"),
    fecha_inicial_compra_finalizada: moment().format("YYYY-MM-DD"),
    estado_descarga: false,
    estado_descarga2: false,
    costo_importacion_value: 0,
    tipo_cambio_value: 0,
    tipo_compra_value: 0,
    detalle_proveedor: {},
    tipo_movimiento: "",
    cliente: "",
    cuenta_pagar: [],
    datos_cuenta_pagar: null,
    codigo_cobro: "",
    vendedor: "",
};

export default handleActions(reducers, initialState);
