import React, { Component } from "react";
import PropTypes from "prop-types";
import SucursalForm from "./SucursalEditarForm";
import LoadMask from "Utils/LoadMask/LoadMask";

class SucursalEditar extends Component {
    static propTypes = {
        validarSucursalEditar: PropTypes.func.isRequired,
        loadEmpresaEdit: PropTypes.func.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
    }
    componentWillMount() {
        // leer desde el backend la empresa y la sucursal
        const { id, idSucursal } = this.props.match.params;
        this.props.loadEmpresaEdit(id, idSucursal);
    }

    render() {
        // state
        const {
            validarSucursalEditar,
            editarSucursal,
            loader,
            empresa,
            frases,
            setFrases,
            data_sucursal,
        } = this.props;
        const { id } = this.props.match.params;
        // bind

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <LoadMask loading={loader} dark blur>
                        <SucursalForm
                            onSubmit={validarSucursalEditar}
                            // onSubmit={editarSucursal}
                            idEmpresa={id}
                            empresa={empresa}
                            frases={frases}
                            data_sucursal={data_sucursal}
                            setFrases={setFrases}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default SucursalEditar;
