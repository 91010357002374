import _ from "lodash";
import { api } from "api";
import moment from "moment";
import uuidv1 from "uuid/v1";
import Swal from "sweetalert2";
import { ToastStore } from "react-toasts";
import { handleActions } from "redux-actions";
import { goBack, push } from "react-router-redux";
//import { resetForm } from "../configuracion/clientes";
import { actions as printActions } from "../print/print";
import { initialize as initializeForm, change as changeForm } from "redux-form";

// ------------------------------------
// Constants
// ------------------------------------
// OC
export const TAB = "OV_TAB";
export const PAGE_OV = "OV_PAGE_OV";
export const SORT_OV = "OV_SORT_OV";
export const BUSCADOR_OV = "OV_BUSCADOR_OV";
export const LOADER_OV = "OV_LOADER_OV";
export const DATA_OV = "OV_DATA_OV";
export const ORDEN_VENTA = "OV_ORDEN_VENTA";
export const DETALLES_OV = "OV_DETALLES_OV";

//  CUENTA CONTABLE OV
export const PAGE_CUENTA_OV = "OV_PAGE_CUENTA_OV";
export const LOADER_CUENTA_OV = "OV_LOADER_CUENTA_OV";
export const DATOS_CUENTA_OV = "OV_DATOS_CUENTA_OV";

// NUEVA OC
export const PAGE = "OV_PAGE";
export const SORT = "OV_SORT";
export const PASO = "OV_PASO";
export const DATOS = "OV_DATOS";
export const LOADER = "OV_LOADER";
export const LISTADO = "OV_LISTADO";
export const BUSCADOR = "OV_BUACADOR";
export const PRODUCTOS = "OV_PRODUCTOS";
export const VER_BUSCADOR = "OV_VER_BUSCADOR";
export const SELECCIONADOS = "OV_SELECCIONADOS";
export const DATOS_TIPO_PAGO = "OV_DATOS_TIPO_PAGO";
export const UUID_REQ_PRODUCTOS = "OV_UUID_REQ_PRODUCTOS";

//  CLIENTES
export const PAGE_CLIENTES = "OV_PAGE_CLIENTES";
export const DATA_CLIENTES = "OV_DATA_CLIENTES";
export const LOADER_CLIENTES = "OV_LOADER_CLIENTES";
export const BUSCADOR_CLIENTES = "OV_BUSCADOR_CLIENTES";
export const MODAL_NUEVO_CLIENTE = "OV_MODAL_NUEVO_CLIENTE";
export const MODAL_LISTADO_CLIENTE = "OV_MODAL_LISTADO_CLIENTE";
//  SUCURSAL CLIENTES
export const PAGE_SUCURSAL_CLIENTES = "OV_PAGE_SUCURSAL_CLIENTES";
export const DATA_SUCURSAL_CLIENTES = "OV_DATA_SUCURSAL_CLIENTES";
export const LOADER_SUCURSAL_CLIENTES = "OV_LOADER_SUCURSAL_CLIENTES";
export const BUSCADOR_SUCURSAL_CLIENTES = "OV_BUSCADOR_SUCURSAL_CLIENTES";
export const MODAL_NUEVO_SUCURSAL_CLIENTE = "OV_MODAL_NUEVO_SUCURSAL_CLIENTE";
export const MODAL_LISTADO_SUCURSAL_CLIENTE =
    "OV_MODAL_LISTADO_SUCURSAL_CLIENTE";

//  NIT CLIENTE
export const LOADER_NIT = "OV_LOADER_NIT";
export const UUID_NIT = "OV_UUID_NIT";
export const ESTADO_FORM_CLIENTE = "OV_ESTADO_FORM_CLIENTE";

//  PAGO OV
export const MODAL_PAGO = "OV_MODAL_PAGO";
export const PASO_PAGO = "OV_PASO_PAGO";
export const SET_CODIGO_NOTA_CREDITO = "OV_SET_CODIGO_NOTA_CREDITO";
export const SET_NOTA_CREDITO_CARGADA = "OV_SET_NOTA_CREDITO_CARGADA";
export const LOADER_PAGO = "OV_LOADER_PAGO";
export const IMPRESION_RECIBO = "OV_IMPRESION_RECIBO";
export const DATOS_PAGO = "OV_DATOS_PAGO";
//  ENTREGA
export const MOVIMIENTO_ENTREGA = "OV_MOVIMIENTO_ENTREGA";

//ReImpresion
export const SET_IMPRESION = "OV_SET_REIMPRESION";

// filtros
export const FECHA_FINAL = "OV_FECHA_FINAL";
export const FECHA_INICIAL = "OV_FECHA_INICIAL";
export const VENDEDOR = "OV_VENDEDOR";
export const CLIENTE = "OV_CLIENTE";
export const TIPO_VENTA = "OV_TIPO_VENTA";

const endpoint = "ordenes_venta";
const endpoint_cliente = "clientes_formal";
const endpoint_productos_ov = "productos_venta/productos_ov";
const endpoint_nit = "consultar_nit";
const endpoint_entrega = "entrega_ordenes_venta";
const endpoint_datos_impresion_venta = "historial_ventas/reimpresion_venta";
// ------------------------------------
// Actions
// ------------------------------------
export const listarOV =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoaderOV(true));
        const store = getStore();
        const {
            search_ov,
            ordering_ov,
            tab,
            // filtros
            fecha_inicial,
            fecha_final,
            vendedor,
            cliente,
            tipo_venta,
        } = store.orden_venta;

        const vendedor_id = vendedor ? vendedor.id : "";
        const cliente_id = cliente ? cliente.id : "";

        let params = {
            page,
            ordering: ordering_ov,
            search: search_ov,
            fecha_inicial,
            fecha_final,
            vendedor_id,
            cliente_id,
            tipo_venta,
        };

        if (tab != 30) {
            params.estado = tab;
            params.activo = true;
        } else {
            params.activo = false;
        }

        api.get(endpoint, params)
            .catch((err) => {})
            .then((data) => {
                if (data) {
                    dispatch(setDataOV(data));
                }
                dispatch(setPageOV(page));
            })
            .finally(() => {
                dispatch(setLoaderOV(false));
            });
    };
export const buscarOV = (search) => (dispatch) => {
    dispatch(setBuscadorOV(search));
    dispatch(listarOV());
};

export const sortChangeOV = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSortOV(sortName));
    } else {
        dispatch(setSortOV(`-${sortName}`));
    }
    const store = getStore();
    const page_ov = store.orden_venta.page_ov;
    dispatch(listarOV(page_ov));
};

export const changeTab = (tab) => (dispatch) => {
    dispatch(setTab(tab));
    dispatch(listarOV());
};

//  NUEVA OV
export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();
        const { search } = store.orden_venta;
        let { ordering } = store.orden_venta;
        const { cliente_id } = store.form.wizardCrearOVForm.values;
        //  GENERAR EL UUID
        const uuid = uuidv1();
        dispatch(setUuidReqProductos(uuid));
        if (!ordering) {
            ordering = "-creado";
        }
        if (cliente_id) {
            api.get(endpoint_productos_ov, {
                page,
                ordering,
                search,
                cliente: cliente_id,
            })
                .catch((err) => {})
                .then((data) => {
                    if (data) {
                        const otroUuid =
                            getStore().orden_venta.uuid_req_productos;
                        if (otroUuid === uuid) dispatch(setProductos(data));
                    }
                    dispatch(setPage(page));
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        } else {
            dispatch(setLoader(false));
            dispatch(setProductos({ results: [], count: 0 }));
        }
    };

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.orden_venta.page;
    dispatch(listar(page));
};

export const siguientePaso = () => (dispatch, getStore) => {
    const store = getStore();
    const { seleccionados, paso } = store.orden_venta;
    if (paso == 1) {
        if (!seleccionados.length) {
            ToastStore.error("La orden de venta no tiene productos");
            return false;
        }
    }
    dispatch(setPaso(paso + 1));
};

export const anteriorPaso = () => (dispatch, getStore) => {
    const store = getStore();

    const { paso } = store.orden_venta;
    dispatch(setPaso(paso - 1));
};

//  CUENTA CONTABLE OV
export const listarCuentaOV =
    (page = 1) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { orden_venta } = store.orden_venta;
        if (orden_venta) {
            dispatch(setLoaderCuentaOV(true));
            api.get(`${endpoint}/${orden_venta.id}/cuenta`, { page })
                .catch((err) => {})
                .then((data) => {
                    if (data) {
                        dispatch(setDatosCuentaOV(data));
                    }
                    dispatch(setPageCuentaOV(page));
                })
                .finally(() => {
                    dispatch(setLoaderCuentaOV(false));
                });
        }
    };

//  CLIENTE
export const listarCliente =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoaderCliente(true));
        const store = getStore();
        const { search_cliente } = store.orden_venta;
        api.get(endpoint_cliente, { page, search: search_cliente })
            .catch((err) => {})
            .then((data) => {
                if (data) {
                    dispatch(setDataCliente(data));
                }
                dispatch(setPageCliente(page));
            })
            .finally(() => {
                dispatch(setLoaderCliente(false));
            });
    };

export const buscarCliente = (search) => (dispatch) => {
    dispatch(setBuscadorCliente(search));
    dispatch(listarCliente());
};

export const nuevoCliente = () => (dispatch, getStore) => {};
export const seleccionarCliente = (data) => (dispatch, getStore) => {
    const store = getStore();
    const form = store.form.wizardCrearOVForm.values;
    const seleccionados = store.orden_venta.seleccionados;
    if (form.cliente_id != data.id) {
        if (seleccionados.length > 0) {
            Swal({
                title: `¿Limpiar listado de productos y cambiar al cliente ${data.nombre}?`,
                text: "Se limpiara el listado de productos ya que puede cambiar los precios para el cliente seleccionado",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Sí, limpiar",
                cancelButtonText: "No, cancelar",
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    dispatch(
                        initializeForm("wizardCrearOVForm", {
                            ...form,
                            cliente_id: data.id,
                            cliente: data.nombre,
                            encargado: data.nombre_encargado,
                            sucursal_id: null,
                            nombre_sucursal: null,
                            direccion_sucursal: null,
                            departamento_sucursal: null,
                            municipio_sucursal: null,
                            direccion_facturacion: null,
                            direccion_entrega: false,
                        })
                    );
                    dispatch(setSeleccionados([]));
                    dispatch(listar());
                }
            });
        } else {
            dispatch(
                initializeForm("wizardCrearOVForm", {
                    ...form,
                    cliente_id: data.id,
                    cliente: data.nombre,
                    encargado: data.nombre_encargado,
                    sucursal_id: null,
                    nombre_sucursal: null,
                    direccion_sucursal: null,
                    departamento_sucursal: null,
                    municipio_sucursal: null,
                    direccion_facturacion: null,
                    direccion_entrega: false,
                })
            );
            dispatch(listar());
        }
    } else {
        dispatch(
            initializeForm("wizardCrearOVForm", {
                ...form,
                cliente_id: data.id,
                cliente: data.nombre,
                encargado: data.nombre_encargado,
            })
        );
        dispatch(listar());
    }
    dispatch(setModalListadoCliente(false));
};

export const agregar = (productoSeleccionado) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = store.orden_venta.seleccionados;
    let producto = _.cloneDeep(productoSeleccionado);

    //  Producto
    producto.cantidad = 1;
    producto.precio_original = producto.precio;
    producto.precio1 = producto.precio;
    producto.num_precios = 0;
    producto.listado_precios = [];
    producto.id_unidad_de_medida = producto.producto.a_granel
        ? producto.unidad_de_medida
        : null;

    const { nombres_precios } = store.usuario;

    if (producto.precio) {
        producto.num_precios += 1;
        producto.modelo_precio = { id: 1, value: producto.precio };
        producto.listado_precios.push({
            id: 1,
            value: producto.precio.toFixed(2),
            nombre: nombres_precios ? nombres_precios.nombre_precio : "Precio",
        });
    }
    if (producto.precio2) {
        producto.num_precios += 1;
        producto.listado_precios.push({
            id: 2,
            value: producto.precio2.toFixed(2),
            nombre: nombres_precios
                ? nombres_precios.nombre_precio2
                : "Precio 2",
        });
    }
    if (producto.precio3) {
        producto.num_precios += 1;
        producto.listado_precios.push({
            id: 3,
            value: producto.precio3.toFixed(2),
            nombre: nombres_precios
                ? nombres_precios.nombre_precio3
                : "Precio 3",
        });
    }
    if (producto.precio4) {
        producto.num_precios += 1;
        producto.listado_precios.push({
            id: 4,
            value: producto.precio4.toFixed(2),
            nombre: nombres_precios
                ? nombres_precios.nombre_precio4
                : "Precio 4",
        });
    }
    if (producto.precio5) {
        producto.num_precios += 1;
        producto.listado_precios.push({
            id: 5,
            value: producto.precio5.toFixed(2),
            nombre: nombres_precios
                ? nombres_precios.nombre_precio5
                : "Precio 5",
        });
    }

    dispatch(setSeleccionados([producto, ...seleccionados]));
};

export const quitar = (producto, index) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.orden_venta.seleccionados);
    let item = seleccionados[index];
    if (item) {
        seleccionados.splice(index, 1);
        dispatch(setSeleccionados(seleccionados));
    }
};

export const asignarCantidad = (index, cantidad) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.orden_venta.seleccionados);
    let item = seleccionados[index];
    if (item) {
        if (cantidad < 0) {
            item.cantidad = 0;
        } else {
            item.cantidad = cantidad;
        }
        seleccionados.splice(index, 1, item);
        dispatch(setSeleccionados(seleccionados));
    }
};

export const asignarPrecio = (index, precio) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.orden_venta.seleccionados);
    let item = seleccionados[index];
    if (item) {
        // const index = seleccionados.indexOf(item);
        item.precio_original = precio;
        if (item.desc_porcentaje) {
            item.desc_monto = parseFloat(
                (item.precio_original * (item.desc_porcentaje / 100)).toFixed(2)
            );
            item.precio = item.precio_original - item.desc_monto;
        } else {
            item.desc_monto = 0;
            item.precio = precio;
        }
        item.modelo_precio = precio;
        seleccionados.splice(index, 1, item);
        dispatch(setSeleccionados(seleccionados));
    } else {
        Swal(
            "Cambio de precio",
            "No fue posible cambiar el precio del producto seleccionado.",
            "warning"
        );
    }
};

//  SUCURSAL CLIENTE
export const listarSucursales =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoaderSucursal(true));
        const store = getStore();
        const { search_sucursal } = store.orden_venta;
        const { values } = store.form.wizardCrearOVForm;
        if (values.cliente_id && values.cliente_id != "") {
            api.get(`${endpoint_cliente}/${values.cliente_id}/sucursales`, {
                page,
                search_sucursal,
            })
                .catch((err) => {})
                .then((data) => {
                    if (data) {
                        dispatch(setDataSucursal(data));
                    }
                    dispatch(setPageSucursal(page));
                })
                .finally(() => {
                    dispatch(setLoaderSucursal(false));
                });
        } else {
            dispatch(setLoaderSucursal(false));
        }
    };

export const buscarSucursal = (search) => (dispatch) => {
    dispatch(setBuscadorSucursal(search));
    dispatch(listarSucursales());
};

export const seleccionarSucursal = (data) => (dispatch, getStore) => {
    const store = getStore();
    const form = store.form.wizardCrearOVForm.values;
    dispatch(
        initializeForm("wizardCrearOVForm", {
            ...form,
            sucursal_id: data.id,
            nombre_sucursal: data.nombre,
            direccion_sucursal: data.direccion,
            departamento_sucursal: data.departamento,
            municipio_sucursal: data.municipio,
        })
    );
    dispatch(setModalListadoSucursal(false));
};

export const nuevaSucursal = () => (dispatch, getStore) => {};

//  PAGO
export const cambioPlazo = (plazo) => (dispatch, getStore) => {
    const store = getStore();
    const { datos } = store.orden_venta;
    const { values } = store.form.wizardCrearOVForm;
    let fecha_limite = moment();
    let periodo_pago = 0;
    if (plazo != "inmediato") {
        if (plazo == "personalizado") {
            fecha_limite = moment(values.fecha_limite, "YYYY-MM-DD");
            periodo_pago = fecha_limite.diff(moment(), "days") + 1;
        } else {
            periodo_pago = parseInt(plazo);
            fecha_limite = moment().add(periodo_pago, "days");
        }
    }
    fecha_limite = fecha_limite.format("YYYY-MM-DD");
    dispatch(changeForm("wizardCrearOVForm", "fecha_limite", fecha_limite));
    dispatch(setDatos({ ...datos, fecha_limite, periodo_pago }));
};

export const seleccionFechaLimite = (fecha_limite) => (dispatch, getStore) => {
    const store = getStore();
    const { datos } = store.orden_venta;
    let fecha_li = moment(fecha_limite, "YYYY-MM-DD");
    let periodo_pago = fecha_li.diff(moment(), "days") + 1;
    fecha_li = fecha_li.format("YYYY-MM-DD");
    dispatch(changeForm("wizardCrearOVForm", "fecha_limite", fecha_li));
    dispatch(
        setDatos({
            ...datos,
            fecha_limite: fecha_li,
            periodo_pago,
        })
    );
};

export const setDireccionEntrega = (estado) => (dispatch, getStore) => {
    const store = getStore();
    const { datos } = store.orden_venta;
    const values = store.form.wizardCrearOVForm.values;
    if (estado) {
        const direccion =
            values.direccion_sucursal != "" && values.direccion_sucursal != null
                ? values.direccion_sucursal
                : "";
        dispatch(
            changeForm("wizardCrearOVForm", "direccion_facturacion", direccion)
        );
    } else {
        dispatch(changeForm("wizardCrearOVForm", "direccion_facturacion", ""));
    }
};

export const registrarOV = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const { datos, seleccionados } = store.orden_venta;
    const values = store.form.wizardCrearOVForm.values;
    const tipo_pago = values.plazo_pago == "inmediato" ? 1000 : 2000;
    const periodo_pago = tipo_pago == 2000 ? datos.periodo_pago : 0;
    let body = {
        fecha: values.fecha,
        cliente_id: values.cliente_id,
        fecha_entrega: values.fecha_entrega,
        tipo_entrega: values.tipo_entrega,
        encargado: values.encargado,
        sucursal_id: values.sucursal_id,
        nombre_sucursal: values.nombre_sucursal,
        direccion_sucursal: values.direccion_sucursal,
        departamento_sucursal: values.departamento_sucursal,
        municipio_sucursal: values.municipio_sucursal,
        descripcion: values.descripcion,
        tipo_pago: tipo_pago,
        periodo_pago: periodo_pago,
        nit: values.nit_facturacion,
        nombre: values.nombre_facturacion,
        direccion: values.direccion_facturacion,
        productos: seleccionados,
    };

    // console.log(body);
    // dispatch(setLoader(false));
    api.post(endpoint, body)
        .catch((err) => {
            Swal(
                "ERROR",
                err.detail ||
                    "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
            dispatch(setLoader(false));
        })
        .then((resp) => {
            if (resp) {
                if (resp.id_factura) {
                    Swal(
                        "¡Factura electrónica en proceso!",
                        "Se esta generando la factura, espere por favor",
                        "info"
                    );
                    dispatch(consultarEstadoFEL(resp.id_factura));
                } else {
                    dispatch(setLoader(false));
                    dispatch(asignarDetalleOV(resp));
                    ToastStore.success(
                        "Orden de venta registrada exitosamente"
                    );
                    dispatch(siguientePaso());
                    dispatch(printActions.print("impresionOV"));
                }
            }
        });
};

export const asignarDetalleOV = (resp) => (dispatch) => {
    let listado_pagina = [];
    const cant_por_pagina = 10;
    const resultados = resp.detalles || [];
    const total_detalles = resultados.length;
    let paginas = Math.ceil(total_detalles / cant_por_pagina);

    //  Construccion por pagina
    for (let i = 0; i < paginas; i++) {
        const pagina_impresion = {
            no_pagina: i + 1,
            detalles: [],
        };
        listado_pagina.push(pagina_impresion);
    }

    //  Asignacion de detalles para cada pagina
    resultados.forEach((detalle, index) => {
        const no_pagina = Math.ceil((index + 1) / cant_por_pagina);
        listado_pagina[no_pagina - 1].detalles.push(detalle);
    });
    dispatch(setOV(resp));
    dispatch(setDetallesOV(listado_pagina));
};

export const finalizarOV = () => (dispatch) => {
    dispatch(limpiarOrdenVentaForm());
    dispatch(push("/ordenes_de_venta"));
    dispatch(setLoader(false));
    dispatch(setLoaderNit(false));
    dispatch(setEstadoFormCliente(true));
};

const consultarEstadoFEL =
    (id_factura, es_fcam = false) =>
    (dispatch, getStore) => {
        if (es_fcam) {
            dispatch(setLoaderOV(true));
        } else {
            dispatch(setLoader(true));
        }
        api.get("factura_electronica/verificar_fel", { id_factura })
            .catch((err) => {
                Swal("ERROR", "Error al generar factura electrónica", "error");
                if (es_fcam) {
                    dispatch(setLoaderOV(false));
                } else {
                    dispatch(setLoader(false));
                }
            })
            .then((resp) => {
                status = resp.estado_factura;
                if (status === "proceso") {
                    setTimeout(() => {
                        dispatch(consultarEstadoFEL(id_factura, es_fcam));
                    }, 1000);
                } else if (status === "facturado") {
                    Swal(
                        "Éxito",
                        "Factura electrónica registrada correctamente",
                        "success"
                    ).then(() => {
                        setTimeout(() => {
                            if (es_fcam) {
                                const store = getStore();
                                const { page_ov, orden_venta } =
                                    store.orden_venta;
                                dispatch(listarOV(page_ov));
                                if (orden_venta) {
                                    dispatch(getDetalleOV(orden_venta.id));
                                }
                            } else {
                                dispatch(setLoader(false));
                                dispatch(asignarDetalleOV(resp.factura));
                                ToastStore.success(
                                    "Orden de venta registrada exitosamente"
                                );
                                dispatch(siguientePaso());
                                dispatch(printActions.print("impresionOV"));
                            }
                        }, 500);
                    });
                    if (es_fcam) {
                        dispatch(setLoaderOV(false));
                    } else {
                        dispatch(setLoader(false));
                    }
                } else if (status === "anulada") {
                    Swal(
                        "Éxito",
                        "Orden de venta y factura electrónica anuladas correctamente",
                        "success"
                    ).then(() => {
                        setTimeout(() => {
                            dispatch(listarOV(page));
                        }, 500);
                    });
                    dispatch(setLoaderOV(false));
                } else {
                    Swal(
                        "ERROR",
                        "Error en facturación electrónica, la orden de venta se ha registrado y puedes reintentarlo en el listado de ordenes de venta",
                        "error"
                    ).then((val) => {
                        if (es_fcam) {
                            dispatch(setLoaderOV(false));
                        } else {
                            dispatch(setLoader(false));
                            dispatch(limpiarOrdenVentaForm());
                            dispatch(push("/ordenes_de_venta"));
                        }
                    });
                }
            });
    };

export const limpiarOrdenVentaForm = () => (dispatch) => {
    dispatch(setProductos({}));
    dispatch(setVerBuscador(false));
    dispatch(setUuidReqProductos(""));
    dispatch(setPage(1));
    dispatch(setBuscador(""));

    dispatch(setDataCliente({}));
    dispatch(setPageCliente(1));
    dispatch(setBuscadorCliente(""));

    dispatch(setDataSucursal({}));
    dispatch(setPageSucursal(1));
    dispatch(setBuscadorSucursal(""));
    dispatch(setSeleccionados([]));
    const datos = {
        fecha: moment().format("YYYY-MM-DD"),
        fecha_entrega: moment().format("YYYY-MM-DD"),
        fecha_limite: moment().format("YYYY-MM-DD"),
        plazo_pago: "inmediato",
    };
    dispatch(setDatos(datos));
    dispatch(initializeForm("wizardCrearOVForm", datos));
    dispatch(setPaso(1));
    dispatch(setOV(null));
    dispatch(setDetallesOV([]));
};

export const retimbradoFEL =
    (id_ov, desde_detalle = false) =>
    (dispatch) => {
        dispatch(setLoaderOV(true));

        api.post("factura_electronica/reintento_fcam", { id_ov })
            .catch((err) => {
                if (err) {
                    Swal("ERROR", err.detail, "error");
                } else {
                    Swal(
                        "ERROR",
                        "Error al generar factura electrónica",
                        "error"
                    );
                }
            })
            .then((data) => {
                if (data.id_factura) {
                    Swal(
                        "¡Factura electrónica en proceso!",
                        "Se esta generando la factura, espere por favor",
                        "info"
                    );
                    dispatch(consultarEstadoFEL(data.id_factura, true));
                }
            })
            .finally(() => {
                dispatch(setLoaderOV(false));
            });
    };

export const seleccionImpresionOFEL =
    (id_ov, tipo_pago, nombre_impresion) => (dispatch) => {
        //
        //api.get(endpoint_datos_impresion_venta, { id: movimiento.id_venta })
        //console.log("Paso aqui..", id_ov);
        dispatch(setLoaderOV(true));
        dispatch(setOV(null));
        dispatch(setDetallesOV([]));

        api.get(endpoint_datos_impresion_venta, { id: id_ov }) //api.get(`${endpoint}/${id_ov}`)
            .catch((err) => {
                dispatch(setVentaImpresion(null));
                Swal(
                    "ERROR",
                    "No fue posible obtenert los datos, intente más tarde",
                    "error"
                );
            })
            .then((resp) => {
                if (resp) {
                    let data_impresion = resp;
                    let vuelto = 0;
                    if (data_impresion.length === undefined) {
                        vuelto = data_impresion.vuelto_efectivo
                            ? data_impresion.vuelto_efectivo
                            : 0;
                        data_impresion.monto_efectivo =
                            data_impresion.monto_efectivo + vuelto;
                    }
                    dispatch(asignarDetalleOV(data_impresion));
                    //if (data_movimiento.tipo_pago == pago_total) {
                    //    console.log("reciboVenta");
                    //    dispatch(printActions.print("reciboVenta"));
                    //} else
                    dispatch(printActions.print(nombre_impresion));
                }
                //if (data) {
                //    dispatch(asignarDetalleOV(data));
                //    dispatch(printActions.print(nombre_impresion));
                //}
            })
            .finally(() => {
                dispatch(setLoaderOV(false));
            });
    };

export const getDetalleOV = (id_ov) => (dispatch) => {
    dispatch(setLoaderOV(true));
    dispatch(setOV(null));
    dispatch(setDetallesOV([]));

    api.get(`${endpoint}/${id_ov}`)
        .catch((err) => {
            Swal(
                "ERROR",
                "No fue posible obtenert los datos, intente más tarde",
                "error"
            );
        })
        .then((data) => {
            if (data) {
                dispatch(asignarDetalleOV(data));
            }
        })
        .finally(() => {
            dispatch(setLoaderOV(false));
        });
};

export const consultarNit = (value) => (dispatch, getStore) => {
    const store = getStore();
    const values = store.form.wizardCrearOVForm.values;
    //  GENERAR EL UUID
    const uuid = uuidv1();
    dispatch(setUuidNit(uuid));
    dispatch(setLoaderNit(true));

    //  VALIDACION DE NIT
    let nit = null;
    const value_nit = value != null ? value.toUpperCase() : "";
    if (value_nit == "C/F" || value_nit == "C.F" || value_nit == "CF") {
        nit = "CF";
    } else {
        if (value_nit.length > 5) {
            nit = value_nit;
        }
    }
    if (nit) {
        dispatch(changeForm("wizardCrearOVForm", "nombre_facturacion", ""));
        dispatch(changeForm("wizardCrearOVForm", "direccion_facturacion", ""));
        api.get(endpoint_nit, { nit })
            .catch((err) => {
                if (err.detail) {
                    if (
                        err.detail == "El servicio de Infile no esta disponible"
                    ) {
                        dispatch(setEstadoFormCliente(false));
                        ToastStore.error(err.detail);
                    } else {
                        dispatch(setEstadoFormCliente(true));
                    }
                } else {
                    ToastStore.error(
                        "No fue posible obtener información del cliente"
                    );
                }
            })
            .then((data) => {
                if (data) {
                    const otroUuid = getStore().orden_venta.uuid_req_nit;
                    if (otroUuid === uuid) {
                        if (data.nit == "CF") {
                            dispatch(setEstadoFormCliente(false));
                            if (values.nit_facturacion != "CF") {
                                dispatch(
                                    changeForm(
                                        "wizardCrearOVForm",
                                        "nit_facturacion",
                                        data.nit
                                    )
                                );
                            }
                        } else {
                            dispatch(setEstadoFormCliente(true));
                        }
                        dispatch(
                            changeForm(
                                "wizardCrearOVForm",
                                "nombre_facturacion",
                                data.nombre
                            )
                        );
                        dispatch(
                            changeForm(
                                "wizardCrearOVForm",
                                "direccion_facturacion",
                                data.direccion
                            )
                        );
                    }
                }
            })
            .finally(() => {
                dispatch(setLoaderNit(false));
            });
    } else {
        dispatch(changeForm("wizardCrearOVForm", "nombre_facturacion", ""));
        dispatch(changeForm("wizardCrearOVForm", "direccion_facturacion", ""));
        dispatch(setLoaderNit(false));
        dispatch(setEstadoFormCliente(false));
    }
};

export const anular = (id, motivo) => (dispatch, getStore) => {
    dispatch(setLoaderOV(true));

    api.eliminar(`${endpoint}/${id}`, { motivo })
        .then((data) => {
            if (data) {
                if (data.id_factura) {
                    Swal(
                        "¡Anulación de factura electrónica en proceso!",
                        "Se esta generando la anulación de la factura, espere por favor",
                        "info"
                    );
                    dispatch(consultarEstadoFEL(data.id_factura, true));
                } else {
                    ToastStore.success("Registro eliminado.");
                    dispatch(listarOV());
                }
            }
        })
        .catch((error) => {
            console.log("error!", error);
            Swal(
                "ERROR",
                error.detail ||
                    "Ha ocurrido un error, espere un momento y vuelva a intentar.",
                "error"
            );
        })
        .finally(() => {
            dispatch(setLoaderOV(false));
        });
};

//  PAGO OV
export const siguientePasoPago = () => (dispatch, getStore) => {
    const store = getStore();
    const { paso_pago } = store.orden_venta;
    dispatch(setPasoPagoOV(paso_pago + 1));
};

export const anteriorPasoPago = () => (dispatch, getStore) => {
    const store = getStore();

    const { paso_pago } = store.orden_venta;
    dispatch(setpaso_pago(paso - 1));
};

export const consultarNotaCredito = () => (dispatch, getStore) => {
    const store = getStore();
    const { codigo_nota_credito } = store.orden_venta;

    api.get("notas_credito_fel/consultar", { codigo_nota_credito })
        .catch((err) => {
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, espere un momento y vuelva a intentar",
                    "error"
                );
            }
            dispatch(setNotaCreditoCargada(null));
        })
        .then((resp) => {
            if (resp) {
                dispatch(setNotaCreditoCargada(resp));
                dispatch(setNotaCredito(resp.total_importe));
            } else {
                dispatch(setNotaCreditoCargada(null));
            }
        });
};

export const setNotaCredito = (monto) => (dispatch, getStore) => {
    const store = getStore();
    const values = store.form.IngresoPagoOV.values;

    let nota_credito = 0;
    if (monto) {
        if (monto < 0) {
            nota_credito = 0;
        } else {
            nota_credito = monto;
        }
    }
    dispatch(
        initializeForm("IngresoPagoOV", {
            ...values,
            nota_credito,
        })
    );
};

export const limpiarNotaCredito = () => (dispatch, getStore) => {
    dispatch(setCodigoNotaCredito(""));
    dispatch(setNotaCreditoCargada(null));
    const store = getStore();
    const values = store.form.IngresoPagoOV.values;
    dispatch(
        initializeForm("IngresoPagoOV", {
            ...values,
            nota_credito: 0,
        })
    );
};

export const registrarPagoOV = () => (dispatch, getStore) => {
    dispatch(setLoaderPagoOV(true));
    const store = getStore();
    const { orden_venta, nota_credito_cargada } = store.orden_venta;
    const values = store.form.IngresoPagoOV.values;
    const efectivo = parseFloat(
        values.efectivo ? parseFloat(values.efectivo).toFixed(2) : 0
    );
    const tarjeta = parseFloat(
        values.tarjeta ? parseFloat(values.tarjeta).toFixed(2) : 0
    );
    const cheque = parseFloat(
        values.cheque ? parseFloat(values.cheque).toFixed(2) : 0
    );
    const deposito = parseFloat(
        values.deposito ? parseFloat(values.deposito).toFixed(2) : 0
    );
    const retencion_iva = parseFloat(
        values.retencion_iva ? parseFloat(values.retencion_iva).toFixed(2) : 0
    );
    const exencion_iva = parseFloat(
        values.exencion_iva ? parseFloat(values.exencion_iva).toFixed(2) : 0
    );
    const nota_credito = parseFloat(
        values.nota_credito ? parseFloat(values.nota_credito).toFixed(2) : 0
    );
    const total_pago = (
        efectivo +
        tarjeta +
        cheque +
        deposito +
        retencion_iva +
        exencion_iva +
        nota_credito
    ).toFixed(2);

    let body = {
        efectivo,
        tarjeta,
        cheque,
        deposito,
        retencion_iva,
        exencion_iva,
        nota_credito,
        codigo_nota_credito: nota_credito_cargada
            ? nota_credito_cargada.codigo
            : null,
        nota: values.nota ? values.nota : "",
    };

    if (orden_venta) {
        body.cliente = orden_venta.cliente.id;
        body.ordenes_ventas = [
            {
                id: orden_venta.id,
                monto: parseFloat(total_pago),
            },
        ];
    }

    api.post(`${endpoint}/abono`, body)
        .then((resp) => {
            dispatch(listarCuentaOV());
            dispatch(getDetalleOV(orden_venta.id));
            dispatch(setImpresionReciboOV(true));
            dispatch(setDatosPagoOV(resp));
            dispatch(printActions.print("reciboPagoOV"));
            ToastStore.success("Registro de abono efectuado correctamente.");
        })
        .catch((err) => {
            console.log(err);
            const msj = err
                ? err.detail
                    ? err.detail
                    : "No fue posible registrar el abono a la orden de venta, intenta más tarde"
                : "No fue posible registrar el abono a la orden de venta, intenta más tarde";

            Swal("ERROR", msj, "error");
        })
        .finally(() => {
            dispatch(setLoaderPagoOV(false));
        });
};

export const finalizarPagoOV = () => (dispatch, getStore) => {
    dispatch(setModalPagoOV(false));
    dispatch(setImpresionReciboOV(false));
    dispatch(setDatosPagoOV(null));
};

export const anularPartida = (id, motivo) => (dispatch, getStore) => {
    const store = getStore();
    const { orden_venta } = store.orden_venta;
    let html =
        "<ol>" +
        "<li class='text-left ml-1'>No podrá revertir esta acción</li>" +
        "<li class='text-left ml-1'>Al anular la partida se anulará cualquier otra partida relacionada.</li>" +
        "</ol>";

    Swal({
        title: "Confirmar anulación de partida del abono",
        html: html,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, anular!",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setLoaderOV(true));
            api.put(`${endpoint}/anular_partida_abono`, {
                id,
                motivo,
            })
                .catch((err) => {
                    if (err) {
                        Swal(
                            "ERROR",
                            err.detail ||
                                "Ha ocurrido un error, intenta más tarde.",
                            "error"
                        );
                    } else {
                        Swal(
                            "ERROR",
                            "Ha ocurrido un error, intenta más tarde.",
                            "error"
                        );
                    }
                })
                .then((resp) => {
                    if (resp) {
                        ToastStore.success("Partida anulada exitosamente");
                        dispatch(listarCuentaOV());
                        dispatch(getDetalleOV(orden_venta.id));
                    }
                })
                .finally(() => {
                    dispatch(setLoaderOV(false));
                });
        }
    });
};

export const getDatosPago = (id_partida) => (dispatch) => {
    dispatch(setDatosPagoOV(null));
    dispatch(setLoaderOV(true));
    api.get(`${endpoint}/get_movimiento_pago`, { id_partida })
        .catch((err) => {
            console.error(err);
            ToastStore.error("Error al obtener los datos del pago");
        })
        .then((resp) => {
            if (resp) {
                dispatch(setDatosPagoOV(resp));
                dispatch(
                    printActions.print("detalleOVReimprescionReciboPagoOV")
                );
            }
        })
        .finally(() => {
            dispatch(setLoaderOV(false));
        });
};

//  ENTREGA
export const seleccionImpresionEntrega = (id) => (dispatch) => {
    dispatch(setLoaderOV(true));
    dispatch(setMovEntrega(null));
    api.get(`${endpoint_entrega}/movimiento_entrega`, { id })
        .catch((err) => {
            Swal(
                "ERROR",
                "No fue posible obtenert los datos, intente más tarde",
                "error"
            );
        })
        .then((data) => {
            if (data) {
                dispatch(setMovEntrega(data));
                dispatch(
                    printActions.print("detalleOVReimprecionComprobanteEntrega")
                );
            }
        })
        .finally(() => {
            dispatch(setLoaderOV(false));
        });
};

const limpiarOV = () => (dispatch) => {
    dispatch(setOV(null));
};

export const setFechaFinal = (fechaFinal) => (dispatch) => {
    dispatch(_setFechaFinal(fechaFinal));
    dispatch(listarOV());
};

export const setFechaInicial = (fechaInicial) => (dispatch) => {
    dispatch(_setFechaInicial(fechaInicial));
    dispatch(listarOV());
};

export const setVendedor = (vendedor) => (dispatch) => {
    dispatch(_setVendedor(vendedor));
    dispatch(listarOV());
};

export const setCliente = (cliente) => (dispatch) => {
    dispatch(_setCliente(cliente));
    dispatch(listarOV());
};

export const setTipoVenta = (tipo_venta) => (dispatch) => {
    dispatch(_setTipoVenta(tipo_venta));
    dispatch(listarOV());
};
// ------------------------------------
// PureActions
// ------------------------------------
export const setTab = (tab) => ({
    type: TAB,
    tab,
});
export const setPageOV = (page_ov) => ({
    type: PAGE_OV,
    page_ov,
});
export const setBuscadorOV = (search_ov) => ({
    type: BUSCADOR_OV,
    search_ov,
});
export const setSortOV = (ordering_ov) => ({
    type: SORT_OV,
    ordering_ov,
});
export const setLoaderOV = (loader_ov) => ({
    type: LOADER_OV,
    loader_ov,
});
export const setDataOV = (data_ov) => ({
    type: DATA_OV,
    data_ov,
});
export const setOV = (orden_venta) => ({
    type: ORDEN_VENTA,
    orden_venta,
});
export const setDetallesOV = (detalles_ov) => ({
    type: DETALLES_OV,
    detalles_ov,
});

//  CUENTA CONTABLE OV
export const setDatosCuentaOV = (data_cuenta_ov) => ({
    type: DATOS_CUENTA_OV,
    data_cuenta_ov,
});
export const setPageCuentaOV = (page_cuenta_ov) => ({
    type: PAGE_CUENTA_OV,
    page_cuenta_ov,
});
export const setLoaderCuentaOV = (loader_cuenta_ov) => ({
    type: LOADER_CUENTA_OV,
    loader_cuenta_ov,
});

// NUEVA OV
export const setPage = (page) => ({
    type: PAGE,
    page,
});
export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});
export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});
const setPaso = (paso) => ({
    type: PASO,
    paso,
});
export const setDatos = (datos) => ({
    type: DATOS,
    datos,
});
export const setListado = (datos_listado) => ({
    type: LISTADO,
    datos_listado,
});
export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});
export const setProductos = (productos) => ({
    type: PRODUCTOS,
    productos,
});
export const setVerBuscador = (ver_buscador) => ({
    type: VER_BUSCADOR,
    ver_buscador,
});
export const setSeleccionados = (seleccionados) => ({
    type: SELECCIONADOS,
    seleccionados,
});
export const setUuidReqProductos = (uuid_req_productos) => ({
    type: UUID_REQ_PRODUCTOS,
    uuid_req_productos,
});
export const setDatosTipoPago = (datos_tipo_pago) => ({
    type: DATOS_TIPO_PAGO,
    datos_tipo_pago,
});

//  CLIENTES
export const setPageCliente = (page_cliente) => ({
    type: PAGE_CLIENTES,
    page_cliente,
});
export const setLoaderCliente = (loader_cliente) => ({
    type: LOADER_CLIENTES,
    loader_cliente,
});
export const setDataCliente = (data_cliente) => ({
    type: DATA_CLIENTES,
    data_cliente,
});
export const setBuscadorCliente = (search_cliente) => ({
    type: BUSCADOR_CLIENTES,
    search_cliente,
});
export const setModalNuevoCliente = (modal_cliente) => ({
    type: MODAL_NUEVO_CLIENTE,
    modal_cliente,
});
export const setModalListadoCliente = (modal_listado_cliente) => ({
    type: MODAL_LISTADO_CLIENTE,
    modal_listado_cliente,
});

//  SUCURSAL CLIENTES
export const setPageSucursal = (page_sucursal) => ({
    type: PAGE_SUCURSAL_CLIENTES,
    page_sucursal,
});
export const setLoaderSucursal = (loader_sucursal) => ({
    type: LOADER_SUCURSAL_CLIENTES,
    loader_sucursal,
});
export const setDataSucursal = (data_sucursal) => ({
    type: DATA_SUCURSAL_CLIENTES,
    data_sucursal,
});
export const setBuscadorSucursal = (search_sucursal) => ({
    type: BUSCADOR_SUCURSAL_CLIENTES,
    search_sucursal,
});
export const setModalNuevaSucursal = (modal_sucursal) => ({
    type: MODAL_NUEVO_SUCURSAL_CLIENTE,
    modal_sucursal,
});
export const setModalListadoSucursal = (modal_listado_sucursal) => ({
    type: MODAL_LISTADO_SUCURSAL_CLIENTE,
    modal_listado_sucursal,
});

//  NIT CLIENTE
export const setLoaderNit = (loader_nit) => ({
    type: LOADER_NIT,
    loader_nit,
});
export const setUuidNit = (uuid_req_nit) => ({
    type: UUID_NIT,
    uuid_req_nit,
});
export const setEstadoFormCliente = (estado_form_cliente) => ({
    type: ESTADO_FORM_CLIENTE,
    estado_form_cliente,
});

//  PAGO OV
export const setModalPagoOV = (modal_pago) => ({
    type: MODAL_PAGO,
    modal_pago,
});
export const setPasoPagoOV = (paso_pago) => ({
    type: PASO_PAGO,
    paso_pago,
});
export const setLoaderPagoOV = (loader_pago) => ({
    type: LOADER_PAGO,
    loader_pago,
});
export const setCodigoNotaCredito = (codigo_nota_credito) => ({
    type: SET_CODIGO_NOTA_CREDITO,
    codigo_nota_credito,
});
export const setNotaCreditoCargada = (nota_credito_cargada) => ({
    type: SET_NOTA_CREDITO_CARGADA,
    nota_credito_cargada,
});
export const setImpresionReciboOV = (impresion_recibo) => ({
    type: IMPRESION_RECIBO,
    impresion_recibo,
});
export const setDatosPagoOV = (datos_pago) => ({
    type: DATOS_PAGO,
    datos_pago,
});
//  ENTREGA
export const setMovEntrega = (movimiento_entrega) => ({
    type: MOVIMIENTO_ENTREGA,
    movimiento_entrega,
});

//ReImpresion
export const setVentaImpresion = (venta_impresion) => ({
    type: SET_IMPRESION,
    venta_impresion,
});

// filtros
export const _setFechaInicial = (fecha_inicial) => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});
export const _setFechaFinal = (fecha_final) => ({
    type: FECHA_FINAL,
    fecha_final,
});
export const _setVendedor = (vendedor) => ({
    type: VENDEDOR,
    vendedor,
});
export const _setCliente = (cliente) => ({
    type: CLIENTE,
    cliente,
});
export const _setTipoVenta = (tipo_venta) => ({
    type: TIPO_VENTA,
    tipo_venta,
});

export const actions = {
    anular,
    listarOV,
    buscarOV,
    finalizarOV,
    registrarOV,
    getDetalleOV,
    sortChangeOV,
    retimbradoFEL,
    setVerBuscador,
    changeTab,
    limpiarOrdenVentaForm,
    seleccionImpresionOFEL,
    limpiarOV,
    //  CUENTA CONTABLE
    listarCuentaOV,
    //  NUEVA OC
    listar,
    buscar,
    sortChange,
    anteriorPaso,
    siguientePaso,
    //  CLIENTE
    nuevoCliente,
    listarCliente,
    buscarCliente,
    seleccionarCliente,
    setModalNuevoCliente,
    setModalListadoCliente,
    //  PRODUCTO
    quitar,
    agregar,
    asignarPrecio,
    asignarCantidad,
    //  SUCURSALES CLIENTE
    nuevaSucursal,
    buscarSucursal,
    listarSucursales,
    seleccionarSucursal,
    setModalNuevaSucursal,
    setModalListadoSucursal,
    //  PAGO
    cambioPlazo,
    seleccionFechaLimite,
    setDireccionEntrega,
    //  NIT CLIENTE
    consultarNit,
    //  PAGO OV
    setModalPagoOV,
    anteriorPasoPago,
    siguientePasoPago,
    setNotaCredito,
    setCodigoNotaCredito,
    consultarNotaCredito,
    limpiarNotaCredito,
    registrarPagoOV,
    finalizarPagoOV,
    anularPartida,
    getDatosPago,
    //  ENTREGA
    seleccionImpresionEntrega,

    //ReImpresion

    // Filtros
    setFechaFinal,
    setFechaInicial,
    setVendedor,
    setCliente,
    setTipoVenta,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [TAB]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
    [PAGE_OV]: (state, { page_ov }) => {
        return {
            ...state,
            page_ov,
        };
    },
    [BUSCADOR_OV]: (state, { search_ov }) => {
        return {
            ...state,
            search_ov,
        };
    },
    [SORT_OV]: (state, { ordering_ov }) => {
        return {
            ...state,
            ordering_ov,
        };
    },
    [LOADER_OV]: (state, { loader_ov }) => {
        return {
            ...state,
            loader_ov,
        };
    },
    [DATA_OV]: (state, { data_ov }) => {
        return {
            ...state,
            data_ov,
        };
    },
    [ORDEN_VENTA]: (state, { orden_venta }) => {
        return {
            ...state,
            orden_venta,
        };
    },
    [DETALLES_OV]: (state, { detalles_ov }) => {
        return {
            ...state,
            detalles_ov,
        };
    },
    // CUENTA CONTABLE OV
    [DATOS_CUENTA_OV]: (state, { data_cuenta_ov }) => {
        return {
            ...state,
            data_cuenta_ov,
        };
    },
    [PAGE_CUENTA_OV]: (state, { page_cuenta_ov }) => {
        return {
            ...state,
            page_cuenta_ov,
        };
    },
    [LOADER_CUENTA_OV]: (state, { loader_cuenta_ov }) => {
        return {
            ...state,
            loader_cuenta_ov,
        };
    },
    // NUEVA OV
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [PASO]: (state, { paso }) => {
        return {
            ...state,
            paso,
        };
    },
    [DATOS]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [LISTADO]: (state, { datos_listado }) => {
        return {
            ...state,
            datos_listado,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [PRODUCTOS]: (state, { productos }) => {
        return {
            ...state,
            productos,
        };
    },
    [VER_BUSCADOR]: (state, { ver_buscador }) => {
        return {
            ...state,
            ver_buscador,
        };
    },
    [SELECCIONADOS]: (state, { seleccionados }) => {
        return {
            ...state,
            seleccionados,
        };
    },
    [UUID_REQ_PRODUCTOS]: (state, { uuid_req_productos }) => {
        return {
            ...state,
            uuid_req_productos,
        };
    },
    [DATOS_TIPO_PAGO]: (state, { datos_tipo_pago }) => {
        return {
            ...state,
            datos_tipo_pago,
        };
    },
    //  CLIENTE
    [PAGE_CLIENTES]: (state, { page_cliente }) => {
        return {
            ...state,
            page_cliente,
        };
    },
    [LOADER_CLIENTES]: (state, { loader_cliente }) => {
        return {
            ...state,
            loader_cliente,
        };
    },
    [DATA_CLIENTES]: (state, { data_cliente }) => {
        return {
            ...state,
            data_cliente,
        };
    },
    [BUSCADOR_CLIENTES]: (state, { search_cliente }) => {
        return {
            ...state,
            search_cliente,
        };
    },
    [MODAL_NUEVO_CLIENTE]: (state, { modal_cliente }) => {
        return {
            ...state,
            modal_cliente,
        };
    },
    [MODAL_LISTADO_CLIENTE]: (state, { modal_listado_cliente }) => {
        return {
            ...state,
            modal_listado_cliente,
        };
    },
    //  SUCURSAL CLIENTE
    [PAGE_SUCURSAL_CLIENTES]: (state, { page_sucursal }) => {
        return {
            ...state,
            page_sucursal,
        };
    },
    [LOADER_SUCURSAL_CLIENTES]: (state, { loader_sucursal }) => {
        return {
            ...state,
            loader_sucursal,
        };
    },
    [DATA_SUCURSAL_CLIENTES]: (state, { data_sucursal }) => {
        return {
            ...state,
            data_sucursal,
        };
    },
    [BUSCADOR_SUCURSAL_CLIENTES]: (state, { search_sucursal }) => {
        return {
            ...state,
            search_sucursal,
        };
    },
    [MODAL_NUEVO_SUCURSAL_CLIENTE]: (state, { modal_sucursal }) => {
        return {
            ...state,
            modal_sucursal,
        };
    },
    [MODAL_LISTADO_SUCURSAL_CLIENTE]: (state, { modal_listado_sucursal }) => {
        return {
            ...state,
            modal_listado_sucursal,
        };
    },
    //  NIT CLIENTE
    [LOADER_NIT]: (state, { loader_nit }) => {
        return {
            ...state,
            loader_nit,
        };
    },
    [UUID_NIT]: (state, { uuid_req_nit }) => {
        return {
            ...state,
            uuid_req_nit,
        };
    },
    [ESTADO_FORM_CLIENTE]: (state, { estado_form_cliente }) => {
        return {
            ...state,
            estado_form_cliente,
        };
    },
    //  PAGO OV
    [MODAL_PAGO]: (state, { modal_pago }) => {
        return {
            ...state,
            modal_pago,
        };
    },
    [PASO_PAGO]: (state, { paso_pago }) => {
        return {
            ...state,
            paso_pago,
        };
    },
    [SET_CODIGO_NOTA_CREDITO]: (state, { codigo_nota_credito }) => {
        return {
            ...state,
            codigo_nota_credito,
        };
    },
    [SET_NOTA_CREDITO_CARGADA]: (state, { nota_credito_cargada }) => {
        return {
            ...state,
            nota_credito_cargada,
        };
    },
    [LOADER_PAGO]: (state, { loader_pago }) => {
        return {
            ...state,
            loader_pago,
        };
    },
    [IMPRESION_RECIBO]: (state, { impresion_recibo }) => {
        return {
            ...state,
            impresion_recibo,
        };
    },
    [DATOS_PAGO]: (state, { datos_pago }) => {
        return {
            ...state,
            datos_pago,
        };
    },
    [MOVIMIENTO_ENTREGA]: (state, { movimiento_entrega }) => {
        return {
            ...state,
            movimiento_entrega,
        };
    },

    //ReImpresion
    [SET_IMPRESION]: (state, { venta_impresion }) => {
        return {
            ...state,
            venta_impresion,
        };
    },

    // FILTROS
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [VENDEDOR]: (state, { vendedor }) => {
        return {
            ...state,
            vendedor,
        };
    },
    [CLIENTE]: (state, { cliente }) => {
        return {
            ...state,
            cliente,
        };
    },
    [TIPO_VENTA]: (state, { tipo_venta }) => {
        return {
            ...state,
            tipo_venta,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------
export const initialState = {
    tab: 10,
    page_ov: 1,
    search_ov: "",
    ordering_ov: "",
    loader_ov: false,
    data_ov: {},
    page: 1,
    ordering: "",
    loader: false,
    datos_listado: {},
    search: "",
    productos: {},
    ver_buscador: false,
    seleccionados: [],
    uuid_req_productos: "",
    datos_tipo_pago: {
        fecha_limite: moment().format("YYYY-MM-DD"),
        plazo_pago: "inmediato",
    },
    paso: 1,
    datos: {
        fecha: moment().format("YYYY-MM-DD"),
        fecha_entrega: moment().format("YYYY-MM-DD"),
        fecha_limite: moment().format("YYYY-MM-DD"),
        plazo_pago: "inmediato",
    },
    orden_venta: null,
    detalles_ov: [],
    //  Cuenta contable OV
    data_cuenta_ov: {},
    page_cuenta_ov: 1,
    loader_cuenta_ov: false,
    //  Cliente
    page_cliente: 1,
    loader_cliente: false,
    data_cliente: {},
    search_cliente: "",
    modal_cliente: false,
    modal_listado_cliente: false,

    //  Sucursal Cliente
    page_sucursal: 1,
    loader_sucursal: false,
    data_sucursal: {},
    search_sucursal: "",
    modal_sucursal: false,
    modal_listado_sucursal: false,
    //  Nit cliente
    loader_nit: false,
    uuid_req_nit: "",
    estado_form_cliente: true,
    //  PAGO OV
    modal_pago: false,
    paso_pago: 1,
    codigo_nota_credito: "",
    nota_credito_cargada: null,
    loader_pago: false,
    impresion_recibo: false,
    datos_pago: null,
    //  ENTREGA
    movimiento_entrega: null,

    //ReImpresion
    venta_impresion: null,

    // FILTROS
    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
    vendedor: "",
    cliente: "",
    tipo_venta: "",
};

export default handleActions(reducers, initialState);
