import { api } from "api";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { CLIENTE } from "./ventas";
import { push } from "react-router-redux";
import { handleActions } from "redux-actions";
import { ToastStore } from "react-toasts";

const endpoint = "cuenta_cobrar";
const endpoint_lista = "cuenta_cobrar/reporte_lista";
const endpoint_reporte_recibo = "cuenta_cobrar/reporte_recibo";
const endpoint_resumen = "cuenta_cobrar/reporte_resumen";

export const DATA = "R_CUENTA_COBRAR_DATA";
export const SET_DETALLE = "R_CUENTA_COBRAR_SET_DETALLE";
export const DATA_RESUMEN = "R_CUENTA_COBRAR_DATA_RESUMEN";
export const LOADER = "R_CUENTA_COBRAR_LOADER";
export const SORT = "R_CUENTA_COBRAR_SORT";
export const SET_CLIENTE = "R_CUENTA_COBRAR_SET_CLIENTE";
export const SET_TIPO = "R_CUENTA_COBRAR_SET_TIPO";
export const PAGE = "R_CUENTA_COBRAR_PAGE";
export const FILTRO = "REPORTE_GASTOS_FILTRO";
export const FECHA_INICIAL = "R_CUENTA_COBRAR_FECHA_INICIAL";
export const FECHA_FINAL = "R_CUENTA_COBRAR_FECHA_FINAL";
export const ESTADO_DESCARGA = "R_CUENTA_COBRAR_ESTADO_DESCARGA";
export const ESTADISTICAS = "R_CUENTA_COBRAR_ESTADISTICAS";

export const TIPO_CLIENTE = "R_CUENTA_COBRAR_TIPO_CLIENTE";
export const VENDEDOR = "R_CUENTA_VENDEDOR";
export const TIPO_MOVIMIENTO = "R_CUENTA_TIPO_MOVIMIENTO";

export const TOTALES_RCC = "TOTALES_RCC";

// ------------------------------------
// Actions
// ------------------------------------
export const getDataResumen = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint_resumen}`)
        .catch((err) => {
            Swal("ERROR", "Ha ocurrido un error.", "error");
        })
        .then((resp) => {
            if (resp) {
                dispatch(setDataResumen(resp));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();
        const cliente = store.reporte_cuenta_cobrar.cliente;
        const {
            ordering,
            filtro,
            fecha_vencimiento_inicial,
            fecha_vencimiento_final,

            tipo_cliente,
            vendedor,
            tipo_movimiento,
        } = store.reporte_cuenta_cobrar;

        const tipo_movimiento_seleccionados = tipo_movimiento.map((mov) =>
            parseInt(mov.value)
        );

        const tipo_cliente_id = tipo_cliente ? tipo_cliente.id : "";
        let params = {
            ordering,
            filtro,
            page,
            fecha_vencimiento_inicial,
            fecha_vencimiento_final,

            tipo_cliente_id,
            vendedor,
            tipo_movimiento: tipo_movimiento_seleccionados,
        };
        if (cliente) params.cliente_id = cliente.id;
        api.get(`${endpoint_lista}`, params)
            .catch((err) => {
                Swal("ERROR", "Ha ocurrido un error.", "error");
            })
            .then((resp) => {
                if (resp) {
                    dispatch(setData(resp));
                    dispatch(setPage(page));
                    dispatch(setEstadisticas(resp.estadisticas));
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const listarReporteRecibo =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();
        const cliente = store.reporte_cuenta_cobrar.cliente;
        const {
            ordering,
            filtro,
            fecha_vencimiento_inicial,
            fecha_vencimiento_final,

            vendedor,
        } = store.reporte_cuenta_cobrar;

        let params = {
            ordering,
            filtro,
            page,
            fecha_vencimiento_inicial,
            fecha_vencimiento_final,

            vendedor,
        };
        if (cliente) params.cliente_id = cliente.id;

        api.get(`${endpoint_reporte_recibo}`, params)
            .catch((err) => {
                Swal("ERROR", "Ha ocurrido un error.", "error");
            })
            .then((resp) => {
                if (resp) {
                    dispatch(setTotalesRCC(resp.extra_data));
                    dispatch(setData(resp));
                    dispatch(setPage(page));
                    dispatch(setEstadisticas({}));
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`)
        .catch((err) => {
            dispatch(push("/reporte_cuenta_por_cobrar"));
        })
        .then((data) => {
            if (data) dispatch(setDetalle(data));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const setCliente = (value) => (dispatch) => {
    dispatch(_setCliente(value));
    // dispatch(listar());
};

export const setTipoCliente = (value) => (dispatch) => {
    dispatch(_setTipoCliente(value));
    // dispatch(listar());
};

export const setVendedor = (value) => (dispatch) => {
    dispatch(_setVendedor(value));
    // dispatch(listar());
};

export const setTipoMovimiento = (value) => (dispatch) => {
    dispatch(_setTipoMovimiento(value));
    // dispatch(listar());
};

export const aplicarFiltros =
    (aplicarFiltrosReporteRecibos = false) =>
    (dispatch) => {
        console.log("aplicarFiltros" + aplicarFiltrosReporteRecibos);
        if (aplicarFiltrosReporteRecibos) {
            dispatch(listarReporteRecibo());
        } else {
            dispatch(listar());
        }
    };

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.reporte_ventas.page;
    dispatch(listar(page));
    dispatch(listarReporteRecibo(page));
};

export const filtroChange = (tipo) => (dispatch) => {
    dispatch(setFiltro(tipo));
    dispatch(listar());
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const reporte_cuenta_cobrar = store.reporte_cuenta_cobrar;
    if (key === "Inicial") {
        const fecha_vencimiento_inicial = new Date(value);
        const fecha_vencimiento_final = new Date(
            reporte_cuenta_cobrar.fecha_vencimiento_final
        );
        // if (fecha_vencimiento_final >= fecha_vencimiento_inicial)
        dispatch(setFechaInicial(value));
    } else {
        const fecha_vencimiento_inicial = new Date(
            reporte_cuenta_cobrar.fecha_vencimiento_inicial
        );
        const fecha_vencimiento_final = new Date(value);
        if (fecha_vencimiento_final >= fecha_vencimiento_inicial)
            dispatch(setFechaFinal(value));
    }
    // dispatch(listar());
};

export const descargarListado =
    (esReporteRecibo = false) =>
    (dispatch, getStore) => {
        const store = getStore();
        const cliente = store.reporte_cuenta_cobrar.cliente;
        const {
            ordering,
            filtro,
            fecha_vencimiento_inicial,
            fecha_vencimiento_final,

            tipo_cliente,
            vendedor,
            tipo_movimiento,
        } = store.reporte_cuenta_cobrar;

        const tipo_movimiento_selccionados = tipo_movimiento.map((mov) =>
            parseInt(mov.value)
        );

        const tipo_cliente_id = tipo_cliente ? tipo_cliente.id : "";

        let params = {
            ordering,
            filtro,
            fecha_vencimiento_inicial,
            fecha_vencimiento_final,

            tipo_cliente_id,
            vendedor,
            tipo_movimiento: tipo_movimiento_selccionados,
        };
        if (cliente) params.cliente_id = cliente.id;
        dispatch(setEstadoDescarga(true));

        api.get(
            `cuenta_cobrar/${
                esReporteRecibo
                    ? "descargar_excel_reporte_recibo"
                    : "descargar_excel_reporte"
            }`,
            params
        )
            .catch((err) => {
                Swal(
                    "¡Error al descargar!",
                    "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                    "error"
                );
                dispatch(setEstadoDescarga(false));
            })
            .then((data) => {
                if (data) {
                    Swal(
                        "¡Descarga en proceso!",
                        "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                        "info"
                    );
                    dispatch(setEstadoDescarga(true));
                    dispatch(esperarDescarga(data.id));
                }
            });
    };

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Bajas_salidas_por_producto.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

export const limpiarFiltros =
    (enReporteRecibo = false) =>
    (dispatch) => {
        dispatch(setFechaInicial(moment().format("YYYY-MM-DD")));
        dispatch(setFechaFinal(moment().format("YYYY-MM-DD")));
        dispatch(setCliente(null));
        dispatch(setTipoCliente(""));
        dispatch(setVendedor(""));
        dispatch(setTipoMovimiento([]));
        dispatch(setFiltro(""));
        dispatch(setEstadisticas({}));
        dispatch(setData({}));
        if (!enReporteRecibo) {
            dispatch(listarReporteRecibo());
        }
    };

// ------------------------------------
// PureActions
// ------------------------------------
export const setData = (data) => ({
    type: DATA,
    data,
});

export const setDataResumen = (data_resumen) => ({
    type: DATA_RESUMEN,
    data_resumen,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const _setCliente = (cliente) => ({
    type: CLIENTE,
    cliente,
});

export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});

export const setDetalle = (detalle) => ({
    type: SET_DETALLE,
    detalle,
});

export const setFiltro = (filtro) => ({
    type: FILTRO,
    filtro,
});

export const setFechaInicial = (fecha_vencimiento_inicial) => ({
    type: FECHA_INICIAL,
    fecha_vencimiento_inicial,
});

export const setFechaFinal = (fecha_vencimiento_final) => ({
    type: FECHA_FINAL,
    fecha_vencimiento_final,
});

const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const _setTipoCliente = (tipo_cliente) => ({
    type: TIPO_CLIENTE,
    tipo_cliente,
});

export const _setVendedor = (vendedor) => ({
    type: VENDEDOR,
    vendedor,
});

export const _setTipoMovimiento = (tipo_movimiento) => ({
    type: TIPO_MOVIMIENTO,
    tipo_movimiento,
});

export const setEstadisticas = (estadisticas) => ({
    type: ESTADISTICAS,
    estadisticas,
});

export const setTotalesRCC = (totales_rcc) => ({
    type: TOTALES_RCC,
    totales_rcc,
});

export const actions = {
    listar,
    listarReporteRecibo,
    getDataResumen,
    setCliente,
    sortChange,
    leer,
    filtroChange,
    setFecha,

    setTipoCliente,
    setVendedor,
    setTipoMovimiento,
    aplicarFiltros,
    limpiarFiltros,

    descargarListado,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_RESUMEN]: (state, { data_resumen }) => {
        return {
            ...state,
            data_resumen,
        };
    },
    [SET_DETALLE]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [CLIENTE]: (state, { cliente }) => {
        return {
            ...state,
            cliente,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [FILTRO]: (state, { filtro }) => {
        return {
            ...state,
            filtro,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_vencimiento_inicial }) => {
        return {
            ...state,
            fecha_vencimiento_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_vencimiento_final }) => {
        return {
            ...state,
            fecha_vencimiento_final,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },

    [TIPO_CLIENTE]: (state, { tipo_cliente }) => {
        return {
            ...state,
            tipo_cliente,
        };
    },
    [VENDEDOR]: (state, { vendedor }) => {
        return {
            ...state,
            vendedor,
        };
    },
    [TIPO_MOVIMIENTO]: (state, { tipo_movimiento }) => {
        return {
            ...state,
            tipo_movimiento,
        };
    },
    [ESTADISTICAS]: (state, { estadisticas }) => {
        return {
            ...state,
            estadisticas,
        };
    },

    [TOTALES_RCC]: (state, { totales_rcc }) => {
        return {
            ...state,
            totales_rcc,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    page: 1,
    data_resumen: {},
    detalle: {},
    cliente: null,
    ordering: "",
    loader: false,
    filtro: "",
    fecha_vencimiento_inicial: moment().format("YYYY-MM-DD"),
    fecha_vencimiento_final: moment().format("YYYY-MM-DD"),
    estado_descarga: false,

    tipo_cliente: "",
    vendedor: "",
    tipo_movimiento: [],
    estadisticas: {},
    totales_rcc: {},
};

export default handleActions(reducers, initialState);
