import { connect } from 'react-redux'
import Crear from './EmpresaCrear'
import { crearEmpresa, cambioFormatoFactura, initCreacionEmpresa, getCategorias, asignarCategoria, setCategoriasExpanded, cambioTipoFormatoFactura } from '../../../../redux/modules/empresas/empresas'
import { print } from '../../../../redux/modules/print/print'

const ms2p = (state) => ({
    formato_factura: state.empresas.formato_factura,
    datos_ejemplo: state.empresas.datos_ejemplo,
    categorias: state.empresas.categorias,
    categorias_seleccionados: state.empresas.categorias_seleccionados,
    categorias_expanded: state.empresas.categorias_expanded,
    print_state: state.print
})

const md2p = {
    submit: archivos => dispatch => dispatch(crearEmpresa(archivos)),
    cambioFormatoFactura: cambioFormatoFactura,
    initCreacionEmpresa: initCreacionEmpresa,
    getCategorias: getCategorias,
    asignarCategoria: asignarCategoria,
    setCategoriasExpanded: setCategoriasExpanded,
    cambioTipoFormatoFactura: cambioTipoFormatoFactura,
    print: (name) => dispatch => dispatch(print(name)),
}

export default connect(ms2p, md2p)(Crear)
