
export const DEPARTAMENTOS = [
  { id: '01', label: 'Alta Verapaz' },
  { id: '02', label: 'Baja Verapaz' },
  { id: '03', label: 'Chimaltenango' },
  { id: '04', label: 'Chiquimula' },
  { id: '05', label: 'El Progreso' },
  { id: '06', label: 'Escuintla' },
  { id: '07', label: 'Guatemala' },
  { id: '08', label: 'Huehuetenango' },
  { id: '09', label: 'Izabal' },
  { id: '10', label: 'Jalapa' },
  { id: '11', label: 'Jutiapa' },
  { id: '12', label: 'Petén' },
  { id: '13', label: 'Quetzaltenango' },
  { id: '14', label: 'Quiché' },
  { id: '15', label: 'Retalhuleu' },
  { id: '16', label: 'Sacatepéquez' },
  { id: '17', label: 'San Marcos' },
  { id: '18', label: 'Santa Rosa' },
  { id: '19', label: 'Sololá' },
  { id: '20', label: 'Suchitepéquez' },
  { id: '21', label: 'Totonicapán' },
  { id: '22', label: 'Zacapa' },
];

export const MUNICIPIOS = [
  // ALTA VERAPAZ
    {id: '011', label: 'Chahal', departamento:"01"},
    {id: '012', label: 'Chisec', departamento:"01"},
    {id: '013', label: 'Cobán', departamento:"01"},
    {id: '014', label: 'Fray Bartolomé de las Casas', departamento:"01"},
    {id: '015', label: 'La Tinta', departamento:"01"},
    {id: '016', label: 'Lanquín', departamento:"01"},
    {id: '017', label: 'Panzós', departamento:"01"},
    {id: '018', label: 'Raxruhá', departamento:"01"},
    {id: '019', label: 'San Cristóbal Verapaz', departamento:"01"},
    {id: '0110', label: 'San Juan Chamelco', departamento:"01"},
    {id: '0111', label: 'San Pedro Carchá', departamento:"01"},
    {id: '0112', label: 'Santa Cruz Verapaz', departamento:"01"},
    {id: '0113', label: 'Santa María Cahabón', departamento:"01"},
    {id: '0114', label: 'Senahú', departamento:"01"},
    {id: '0115', label: 'Tamahú', departamento:"01"},
    {id: '0116', label: 'Tactic', departamento:"01"},
    {id: '0117', label: 'Tucurú', departamento:"01"},
    // Baja Verapaz
    {id: '021', label: 'Cubulco', departamento:"02"},
    {id: '022', label: 'Granados', departamento:"02"},
    {id: '023', label: 'Purulhá', departamento:"02"},
    {id: '024', label: 'Rabinal', departamento:"02"},
    {id: '025', label: 'Salamá', departamento:"02"},
    {id: '026', label: 'San Jerónimo', departamento:"02"},
    {id: '027', label: 'San Miguel Chicaj', departamento:"02"},
    {id: '028', label: 'Santa Cruz el Chol', departamento:"02"},
    // Chimaltenango
    {id: '031', label: 'Acatenango', departamento:"03"},
    {id: '032', label: 'Chimaltenango', departamento:"03"},
    {id: '033', label: 'El Tejar', departamento:"03"},
    {id: '034', label: 'Parramos', departamento:"03"},
    {id: '035', label: 'Patzicía', departamento:"03"},
    {id: '036', label: 'Patzún', departamento:"03"},
    {id: '037', label: 'Pochuta', departamento:"03"},
    {id: '038', label: 'San Andrés Itzapa', departamento:"03"},
    {id: '039', label: 'San José Poaquil', departamento:"03"},
    {id: '0310', label: 'San Juan Comalpa', departamento:"03"},
    {id: '0311', label: 'San Martín Jilotepeque', departamento:"03"},
    {id: '0312', label: 'Santa Apolonia', departamento:"03"},
    {id: '0313', label: 'Santa Cruz Balanyá', departamento:"03"},
    {id: '0314', label: 'Tecpán', departamento:"03"},
    {id: '0315', label: 'Yepocapa', departamento:"03"},
    {id: '0316', label: 'Zaragoza', departamento:"03"},
    // Chiquimula
    {id: '041', label: 'Camotán', departamento:"04"},
    {id: '042', label: 'Chiquimula', departamento:"04"},
    {id: '043', label: 'Concepción Las Minas', departamento:"04"},
    {id: '044', label: 'Esquipulas', departamento:"04"},
    {id: '045', label: 'Ipala', departamento:"04"},
    {id: '046', label: 'San Juan Ermita', departamento:"04"},
    {id: '047', label: 'Jocotán', departamento:"04"},
    {id: '048', label: 'Olopa', departamento:"04"},
    {id: '049', label: 'Quetzaltepeque', departamento:"04"},
    {id: '0410', label: 'San Jacinto', departamento:"04"},
    {id: '0411', label: 'San José la Arada', departamento:"04"},
    // El Progreso
    {id: '051', label: 'El Jícaro', departamento:"05"},
    {id: '052', label: 'Guastatoya', departamento:"05"},
    {id: '053', label: 'Morazán', departamento:"05"},
    {id: '054', label: 'San Agustín Acasaguastlán', departamento:"05"},
    {id: '058', label: 'San Antonio La Paz', departamento:"05"},
    {id: '056', label: 'San Cristóbal Acasaguastlán', departamento:"05"},
    {id: '057', label: 'Sanarate', departamento:"05"},
    {id: '058', label: 'Sansare', departamento:"05"},
    // Escuintla
    {id: '061', label: 'Escuintla', departamento:"06"},
    {id: '062', label: 'Guanagazapa', departamento:"06"},
    {id: '063', label: 'Iztapa', departamento:"06"},
    {id: '064', label: 'La Democracia', departamento:"06"},
    {id:'065', label: 'La Gomera', departamento:"06"},
    {id:'066', label: 'Masagua', departamento:"06"},
    {id:'067', label: 'Nueva Concepción', departamento:"06"},
    {id:'068', label: 'Palín', departamento:"06"},
    {id:'069', label: 'San José', departamento:"06"},
    {id:'0610', label: 'San Vincente Pacaya', departamento:"06"},
    {id:'0611', label: 'Santa Lucía Cotzumalguapa', departamento:"06"},
    {id:'0612', label: 'Sipacate', departamento:"06"},
    {id:'0613', label: 'Siquinilá', departamento:"06"},
    {id:'0614', label: 'Tiquisate', departamento:"06"},
    // Guatemala
    {id:'071', label: 'Amatitlán', departamento:"07"},
    {id:'072', label: 'Chinautla', departamento:"07"},
    {id:'073', label: 'Chuarrancho', departamento:"07"},
    {id:'074', label: 'Ciudad de Guatemala', departamento:"07"},
    {id:'075', label: 'Frajanes', departamento:"07"},
    {id:'076', label: 'Mixco', departamento:"07"},
    {id:'077', label: 'Palencia', departamento:"07"},
    {id: '078', label: 'San José del Golfo', departamento:"07"},
    {id: '079', label: 'San José Pinula', departamento:"07"},
    {id: '0710', label: 'San Juan Sacatepéquez', departamento:"07"},
    {id: '0711', label: 'San Miguel Petapa', departamento:"07"},
    {id: '0712', label: 'San Pedro Ayampuc', departamento:"07"},
    {id: '0713', label: 'San Pedro Sacatepéquez', departamento:"07"},
    {id: '0714', label: 'San Raymundo', departamento:"07"},
    {id: '0715', label: 'Santa Catarina Pinula', departamento:"07"},
    {id: '0716', label: 'Villa Canales', departamento:"07"},
    {id: '0717', label: 'Villa Nueva', departamento:"07"},
    // H uehuetenango
    {id: '081', label: 'Aguacatán', departamento:"08"},
    {id: '082', label: 'Chiantla', departamento:"08"},
    {id: '083', label: 'Colotenango', departamento:"08"},
    {id: '084', label: 'Concepción Huista', departamento:"08"},
    {id: '085', label: 'Cuilco', departamento:"08"},
    {id: '086', label: 'Huehuetenango', departamento:"08"},
    {id: '087', label: 'Jacaltenango', departamento:"08"},
    {id: '088', label: 'La Democracia', departamento:"08"},
    {id: '089', label: 'La Libertad', departamento:"08"},
    {id: '0810', label: 'Malacatancito', departamento:"08"},
    {id: '0811', label: 'Nentón', departamento:"08"},
    {id: '0812', label: 'Petatán', departamento:"08"},
    {id: '0813', label: 'San Antonio Huista', departamento:"08"},
    {id: '0814', label: 'San Gaspar Ixchil', departamento:"08"},
    {id: '0815', label: 'San Ildefonso Ixtahuacán', departamento:"08"},
    {id: '0816', label: 'San Juan Atitán', departamento:"08"},
    {id: '0817', label: 'San Juan Ixcoy', departamento:"08"},
    {id: '0818', label: 'San Mateo Ixtatán', departamento:"08"},
    {id: '0819', label: 'San Miguel Acatán', departamento:"08"},
    {id: '0820', label: 'San Pedro Nécta', departamento:"08"},
    {id: '0821', label: 'San Pedro Soloma', departamento:"08"},
    {id:'0822', label: 'San Rafael La Independencia', departamento:"08"},
    {id:'0823', label: 'San Rafael Pétzal', departamento:"08"},
    {id:'0824', label: 'San Sebastián Coatán', departamento:"08"},
    {id:'0825', label: 'San Sebastián Huehuetenango', departamento:"08"},
    {id:'0826', label: 'Santa Ana Huista', departamento:"08"},
    {id:'0827', label: 'Santa Bárbara', departamento:"08"},
    {id:'0828', label: 'Santa Cruz Barillas', departamento:"08"},
    {id:'0829', label: 'Santa Eulalia', departamento:"08"},
    {id:'0830', label: 'Santiago Chimaltenango', departamento:"08"},
    {id: '0831', label: 'Tectitán', departamento:"08"},
    {id: '0832', label: 'Todos Santos Cuchumatán', departamento:"08"},
    {id: '0833', label: 'Unión Cantinil', departamento:"08"},
    // I zabal
    {id: '091', label: 'El Estor', departamento:"09"},
    {id: '092', label: 'Livingston', departamento:"09"},
    {id: '093', label: 'Los Amates', departamento:"09"},
    {id: '094', label: 'Morales', departamento:"09"},
    {id: '095', label: 'Puerto Barrios', departamento:"09"},
    // J alapa
    {id: '101', label: 'Jalapa', departamento:"10"},
    {id: '102', label: 'Mataquescuintla', departamento:"10"},
    {id: '103', label: 'Monjas', departamento:"10"},
    {id: '104', label: 'San Carlos Alzatate', departamento:"10"},
    {id: '105', label: 'San Luis Jilotepeque', departamento:"10"},
    {id: '106', label: 'San Manuel Chaparrón', departamento:"10"},
    {id: '107', label: 'San Pedro Pinula', departamento:"10"},
    // Jutiapa
    {id: '111', label: 'Agua Blanca', departamento:"11"},
    {id: '112', label: 'Asunción Mita', departamento:"11"},
    {id: '113', label: 'Atescatempa', departamento:"11"},
    {id: '114', label: 'Comapa', departamento:"11"},
    {id: '115', label: 'Conguaco', departamento:"11"},
    {id: '116', label: 'El Adelanto', departamento:"11"},
    {id: '117', label: 'El Progreso', departamento:"11"},
    {id: '118', label: 'Jalpatagua', departamento:"11"},
    {id: '119', label: 'Jerez', departamento:"11"},
    {id: '1110', label: 'Jutiapa', departamento:"11"},
    {id: '1111', label: 'Moyuta', departamento:"11"},
    {id: '1112', label: 'Pasaco', departamento:"11"},
    {id: '1113', label: 'Quesada', departamento:"11"},
    {id:'1114', label: 'San José Acatempa', departamento:"11"},
    {id:'1115', label: 'Santa Catarina Mita', departamento:"11"},
    {id:'1116', label: 'Yupiltepeque', departamento:"11"},
    {id:'1117', label: 'Zapotitlán', departamento:"11"},
    // Petén
    {id:'121', label: 'Dolores', departamento:"12"},
    {id:'122', label: 'El Chal', departamento:"12"},
    {id:'123', label: 'Flores', departamento:"12"},
    {id:'124', label: 'La Libertad', departamento:"12"},
    {id:'125', label: 'Las Cruces', departamento:"12"},
    {id:'126', label: 'Melchor de Mencos', departamento:"12"},
    {id:'127', label: 'Poptún', departamento:"12"},
    {id:'128', label: 'San Andrés', departamento:"12"},
    {id:'129', label: 'San Benito', departamento:"12"},
    {id:'1210', label: 'San Francisco', departamento:"12"},
    {id:'1211', label: 'San José', departamento:"12"},
    {id:'1212', label: 'San Luis', departamento:"12"},
    {id:'1213', label: 'Santa Ana', departamento:"12"},
    {id:'1214', label: 'Sayaxché', departamento:"12"},
    // Quetzaltenango
    {id:'131', label: 'Almolonga', departamento:"13"},
    {id:'132', label: 'Cabricán', departamento:"13"},
    {id:'133', label: 'Cajolá', departamento:"13"},
    {id:'134', label: 'Cantel', departamento:"13"},
    {id:'135', label: 'Coatepeque', departamento:"13"},
    {id:'136', label: 'Colomba Costa Cuca', departamento:"13"},
    {id:'137', label: 'Concepción Chiquirichapa', departamento:"13"},
    {id:'138', label: 'El Palmar', departamento:"13"},
    {id:'139', label: 'Flores Costa Cuca', departamento:"13"},
    {id:'1310', label: 'Génova', departamento:"13"},
    {id:'1311', label: 'Huitán', departamento:"13"},
    {id:'1312', label: 'La Esperanza', departamento:"13"},
    {id:'1313', label: 'Olintepeque', departamento:"13"},
    {id:'1314', label: 'Palestina de Los Altos', departamento:"13"},
    {id:'1315', label: 'Quetzaltenango', departamento:"13"},
    {id:'1316', label: 'Salcajá', departamento:"13"},
    {id:'1317', label: 'San Carlos Sija', departamento:"13"},
    {id:'1318', label: 'San Francisco La Unión', departamento:"13"},
    {id:'1319', label: 'San Juan Ostuncalco', departamento:"13"},
    {id:'1320', label: 'San Martín Sacatepéquez', departamento:"13"},
    {id:'1321', label: 'San Mateo', departamento:"13"},
    {id:'1322', label: 'San Miguel Sigüilá', departamento:"13"},
    {id:'1323', label: 'Sibilia', departamento:"13"},
    {id:'1324', label: 'Zunil', departamento:"13"},
    // Quiché
    {id:'141', label: 'Canillá', departamento:"14"},
    {id:'142', label: 'Chajul', departamento:"14"},
    {id:'143', label: 'Chicamán', departamento:"14"},
    {id:'144', label: 'Chiché', departamento:"14"},
    {id:'145', label: 'Santo Tomás Chichicastenango', departamento:"14"},
    {id:'146', label: 'Chinique', departamento:"14"},
    {id:'147', label: 'Cunén', departamento:"14"},
    {id:'148', label: 'Ixcán', departamento:"14"},
    {id:'149', label: 'Joyabaj', departamento:"14"},
    {id:'1410', label: 'Nebaj', departamento:"14"},
    {id:'1411', label: 'Pachalum', departamento:"14"},
    {id:'1412', label: 'Patzité', departamento:"14"},
    {id:'1413', label: 'Sacapulas', departamento:"14"},
    {id:'1414', label: 'San Andrés Sajcabajá', departamento:"14"},
    {id:'1415', label: 'San Antonio Ilotenango', departamento:"14"},
    {id:'1416', label: 'San Bartolomé Jocotenango', departamento:"14"},
    {id:'1417', label: 'San Juan Cotzal', departamento:"14"},
    {id:'1418', label: 'San Pedro Jocopilas', departamento:"14"},
    {id:'1419', label: 'Santa Cruz del Quiché', departamento:"14"},
    {id:'1420', label: 'Uspantán', departamento:"14"},
    {id:'1421', label: 'Zacualpa', departamento:"14"},
    // Retalhuleu
    {id:'151', label: 'Champerico', departamento:"15"},
    {id:'152', label: 'El Asintal', departamento:"15"},
    {id:'153', label: 'Nuevo San Carlos', departamento:"15"},
    {id:'154', label: 'Retalhuleu', departamento:"15"},
    {id:'155', label: 'San Andrés Villa Seca', departamento:"15"},
    {id:'156', label: 'San Felipe', departamento:"15"},
    {id:'157', label: 'San Martín Zapotitlán', departamento:"15"},
    {id:'158', label: 'San Sebastián', departamento:"15"},
    {id:'159', label: 'Santa Cruz Muluá', departamento:"15"},
    // Sacatepéquez
    {id:'161', label: 'Alotenango', departamento:"16"},
    {id:'162', label: 'Ciudad Vieja', departamento:"16"},
    {id:'163', label: 'Jocotenango', departamento:"16"},
    {id:'164', label: 'Antigua Guatemala', departamento:"16"},
    {id:'165', label: 'Magdalena Milpas Altas', departamento:"16"},
    {id:'166', label: 'Pastores', departamento:"16"},
    {id:'167', label: 'San Antonio Aguas Calientes', departamento:"16"},
    {id:'168', label: 'San Bartolomé Milpas Altas', departamento:"16"},
    {id:'169', label: 'San Lucas Sacatepéquez', departamento:"16"},
    {id:'1610', label: 'San Miguel Dueñas', departamento:"16"},
    {id:'1611', label: 'Santa Catarina Barahona', departamento:"16"},
    {id:'1612', label: 'Santa Lucía Milpas Altas', departamento:"16"},
    {id:'1613', label: 'Santa María de Jesús', departamento:"16"},
    {id:'1614', label: 'Santiago Sacatepéquez', departamento:"16"},
    {id:'1615', label: 'Santo Domingo Xenacoj', departamento:"16"},
    {id:'1616', label: 'Sumpango', departamento:"16"},
    // San Marcos
    {id:'171', label:'Ayutla', departamento:"17"},
    {id:'172', label:'Catarina', departamento:"17"},
    {id:'173', label:'Comitancillo', departamento:"17"},
    {id:'174', label:'Concepción Tutuapa', departamento:"17"},
    {id:'175', label:'El Quetzal', departamento:"17"},
    {id:'176', label:'El Tumbador', departamento:"17"},
    {id:'177', label:'Esquipulas Palo Gordo', departamento:"17"},
    {id:'178', label:'Ixchiguán', departamento:"17"},
    {id:'179', label:'La Blanca', departamento:"17"},
    {id:'1710', label:'La Reforma', departamento:"17"},
    {id:'1711', label:'Malacatán', departamento:"17"},
    {id:'1712', label:'Nuevo Progreso', departamento:"17"},
    {id:'1713', label:'Ocós', departamento:"17"},
    {id:'1714', label:'Pajapita', departamento:"17"},
    {id:'1715', label:'Río Blanco', departamento:"17"},
    {id:'1716', label:'San Antonio Sacatepéquez', departamento:"17"},
    {id:'1717', label:'San Cristóbal Cucho', departamento:"17"},
    {id:'1718', label:'San José El Rodeo', departamento:"17"},
    {id:'1719', label:'San José Ojetenam', departamento:"17"},
    {id:'1720', label:'San Lorenzo', departamento:"17"},
    {id:'1721', label:'San Marcos', departamento:"17"},
    {id:'1722', label:'San Miguel Ixtahuacán', departamento:"17"},
    {id:'1723', label:'San Pablo', departamento:"17"},
    {id:'1724', label:'San Pedro Sacatepéquez', departamento:"17"},
    {id:'1725', label:'San Rafael Pie de la Cuesta', departamento:"17"},
    {id:'1726', label:'Sibinal', departamento:"17"},
    {id:'1727', label:'Sipacapa', departamento:"17"},
    {id:'1728', label:'Tacaná', departamento:"17"},
    {id:'1729', label:'Tajumulco', departamento:"17"},
    {id:'1730', label:'Tejutla', departamento:"17"},
    // Santa Rosa
    {id:'181', label: 'Barberena', departamento:"18"},
    {id:'182', label: 'Casillas', departamento:"18"},
    {id:'183', label: 'Chiquimulilla', departamento:"18"},
    {id:'184', label: 'Cuilapa', departamento:"18"},
    {id:'185', label: 'Guazacapán', departamento:"18"},
    {id:'186', label: 'Nueva Santa Rosa', departamento:"18"},
    {id:'187', label: 'Oratorio', departamento:"18"},
    {id:'188', label: 'Pueblo Nuevo Viñas', departamento:"18"},
    {id:'189', label: 'San Juan Tecuaco', departamento:"18"},
    {id:'1810', label: 'San Rafael las Flores', departamento:"18"},
    {id:'1811', label: 'Santa Cruz Naranjo', departamento:"18"},
    {id:'1812', label: 'Santa María Ixhuatán', departamento:"18"},
    {id:'1813', label: 'Santa Rosa de Lima', departamento:"18"},
    {id:'1814', label: 'Taxisco', departamento:"18"},
    // Sololá
    {id:'191', label: 'Concepción', departamento:"19"},
    {id:'192', label: 'Nahualá', departamento:"19"},
    {id:'193', label: 'Panajachel', departamento:"19"},
    {id:'194', label: 'San Andrés Semetabaj', departamento:"19"},
    {id:'195', label: 'San Antonio Palopó', departamento:"19"},
    {id:'196', label: 'San José Chacayá', departamento:"19"},
    {id:'197', label: 'San Juan La Laguna', departamento:"19"},
    {id:'198', label: 'San Lucas Tolimán', departamento:"19"},
    {id:'199', label: 'San Marcos La Laguna', departamento:"19"},
    {id:'1910', label: 'San Pablo La Laguna', departamento:"19"},
    {id:'1911', label: 'San Pedro La Laguna', departamento:"19"},
    {id:'1912', label: 'Santa Catarina Ixtahuacán', departamento:"19"},
    {id:'1913', label: 'Santa Catarina Palopó', departamento:"19"},
    {id:'1914', label: 'Santa Clara La Laguna', departamento:"19"},
    {id:'1915', label: 'Santa Cruz La Laguna', departamento:"19"},
    {id:'1916', label: 'Santa Lucía Utatlán', departamento:"19"},
    {id:'1917', label: 'Santa María Visitación', departamento:"19"},
    {id:'1918', label: 'Santiago Atitlán', departamento:"19"},
    {id:'1919', label: 'Sololá', departamento:"19"},
    // Suchitepéquez
    {id:'201', label: 'Chicacao', departamento:"20"},
    {id:'202', label: 'Cuyotenango', departamento:"20"},
    {id:'203', label: 'Mazatenango', departamento:"20"},
    {id:'204', label: 'Patulul', departamento:"20"},
    {id:'205', label: 'Pueblo Nuevo Suchitepéquez', departamento:"20"},
    {id:'206', label: 'Río Bravo', departamento:"20"},
    {id:'207', label: 'Samayac', departamento:"20"},
    {id:'208', label: 'San Antonio Suchitepéquez', departamento:"20"},
    {id:'209', label: 'San Bernardino', departamento:"20"},
    {id:'2010', label: 'San Francisco Zapotitlán', departamento:"20"},
    {id:'2011', label: 'San Gabriel', departamento:"20"},
    {id:'2012', label: 'San José El Idolo', departamento:"20"},
    {id:'2013', label: 'San José La Máquina', departamento:"20"},
    {id:'2014', label: 'San Juan Bautista', departamento:"20"},
    {id:'2015', label: 'San Lorenzo', departamento:"20"},
    {id:'2016', label: 'San Miguel Panán', departamento:"20"},
    {id:'2017', label: 'San Pablo Jocopilas', departamento:"20"},
    {id:'2018', label: 'Santa Bárbara', departamento:"20"},
    {id:'2019', label: 'Santo Domingo Suchitepéquez', departamento:"20"},
    {id:'2020', label: 'Santo Tomás La Unión', departamento:"20"},
    {id:'2021', label: 'Zunilito', departamento:"20"},
    // Totonicapán
    {id:'211', label: 'Momostenango', departamento:"21"},
    {id:'212', label: 'San Andrés Xecul', departamento:"21"},
    {id:'213', label: 'San Bartolo', departamento:"21"},
    {id:'214', label: 'San Cristóbal Totonicapán', departamento:"21"},
    {id:'215', label: 'San Francisco El Alto', departamento:"21"},
    {id:'216', label: 'Santa Lucía La Reforma', departamento:"21"},
    {id:'217', label: 'Santa María Chiquimula', departamento:"21"},
    {id:'218', label: 'Totonicapán', departamento:"21"},
    // Zacapa
    {id:'221', label: 'Cabañas', departamento:"22"},
    {id:'222', label: 'Estanzuela', departamento:"22"},
    {id:'223', label: 'Gualán', departamento:"22"},
    {id:'224', label: 'Huité', departamento:"22"},
    {id:'225', label: 'La Unión', departamento:"22"},
    {id:'226', label: 'Río Hondo', departamento:"22"},
    {id:'227', label: 'San Diego', departamento:"22"},
    {id:'228', label: 'San Jorge', departamento:"22"},
    {id:'229', label: 'Teculután', departamento:"22"},
    {id:'2210', label: 'Usumatlán', departamento:"22"},
    {id:'2211', label: 'Zacapa', departamento:"22"}
];
