import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "../../../../Util/Acciones/Acciones";
import { Link } from "react-router-dom";
import { tableOptions } from "Utils/tableOptions";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";

export class SucursalesListado extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        loader: PropTypes.bool.isRequired,
        eliminarSucursal: PropTypes.func.isRequired,
        idEmpresa: PropTypes.number.isRequired,
    };

    static defaultProps = {
        idEmpresa: 0,
    };

    constructor(props) {
        super(props);
    }

    render() {
        // state
        const { data, loader, idEmpresa } = this.props;

        // bind
        const { eliminarSucursal } = this.props;

        const options = _.cloneDeep(tableOptions);
        return (
            <div className="row" style={{marginTop: -20}}>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <LoadMask loading={loader} dark blur>
                        <BootstrapTable
                            data={loader ? [] : data}
                            remote
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                        >
                            <TableHeaderColumn dataField="nombre">
                                Nombre
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="direccion">
                                Dirección
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="telefono">
                                Teléfono
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="nit">
                                NIT
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey
                                dataAlign="center"
                                width="20%"
                                dataFormat={activeFormatter({
                                    eliminar: eliminarSucursal,
                                    editar: `/empresas/${idEmpresa}/sucursales/editar`,
                                })}
                            >
                                Acciones
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </LoadMask>
                </div>
            </div>
        );
    }
}
