import { connect } from 'react-redux';
import { actions } from "../../../../../../redux/modules/empresas/empresas";
import SucursalesCrear from './SucursalesCrear';


const ms2p = (state) => {
  return {
    ...state.sucursal,
    empresa: state.empresas.editando
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(SucursalesCrear);
