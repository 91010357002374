import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    RenderDateTime,
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";
import { pago_total, FACTURADO } from "utility/variables";
import "./recibo.css";

export default class Recibo extends Component {
    static propTypes = {
        sucursal: PropTypes.object.isRequired,
        movimiento: PropTypes.object.isRequired,
        footerText: PropTypes.string.isRequired,
        tipo: PropTypes.oneOf(["Ingreso", "Venta"]).isRequired,
    };
    constructor(props) {
        super(props);
    }

    render() {
        const {
            sucursal,
            movimiento,
            usuario,
            utiliza_fel,
            conf_habilitar_no_factura,
            orden_compra_data = false,
        } = this.props;
        const datos_empresa = sucursal.datos_empresa
            ? sucursal.datos_empresa
            : {};
        const datos_fel = movimiento ? movimiento.datos_fel : {};
        const detalles = movimiento ? movimiento.detalles : [];
        const { footerText, tipo } = this.props;
        let total = 0;

        const es_devolucion_proveedor = movimiento.proveedor ? true : false;

        let cliente_proveedor = "---";
        let usuario_nombre = "---";

        if (orden_compra_data) {
            if (
                orden_compra_data.proveedor &&
                orden_compra_data.proveedor.nombre
            )
                cliente_proveedor = orden_compra_data.proveedor.nombre;
        } else if (
            movimiento.cliente_proveedor &&
            movimiento.cliente_proveedor.nombre
        )
            cliente_proveedor = movimiento.cliente_proveedor.nombre;
        else if (movimiento.proveedor && movimiento.proveedor.nombre)
            cliente_proveedor = movimiento.proveedor.nombre;

        if (orden_compra_data) {
            if (
                orden_compra_data.emitida_por &&
                orden_compra_data.emitida_por.first_name
            )
                usuario_nombre = orden_compra_data.emitida_por.first_name;
        } else if (movimiento.usuario && movimiento.usuario.first_name)
            usuario_nombre = movimiento.usuario.first_name;
        else if (movimiento.emitida_por && movimiento.emitida_por.first_name)
            usuario_nombre = movimiento.emitida_por.first_name;

        return (
            <div
                className={`print-shadow ${
                    tipo == "Venta" ? "vista-recibo-80mm" : ""
                }`}
            >
                <div className="mt mb d-flex flex-column justify-content-center align-items-center fnt-12">
                    {datos_empresa.logo_ticket && tipo == "Venta" && (
                        <img
                            className="mb-3"
                            src={datos_empresa.logo_ticket}
                            width="100"
                        />
                    )}
                    {utiliza_fel ? (
                        <div className="w-100 text-center">
                            <b className="text-uppercase">
                                {sucursal.nombre_comercial
                                    ? sucursal.nombre_comercial
                                    : datos_empresa.nombre_comercial}
                            </b>
                        </div>
                    ) : (
                        <div className="w-100 text-center">
                            <div className="w-100 text-center">
                                <img
                                    src={require("../../../../../assets/img/remocar-logo.png")}
                                    width="25%"
                                />
                            </div>
                            <div className="w-100 text-center">
                                <b className="text-uppercase">
                                    {datos_empresa.nombre}
                                </b>
                            </div>
                        </div>
                    )}
                    {utiliza_fel ? (
                        <div className="w-100 text-center">
                            <b className="text-uppercase">
                                {datos_empresa.nombre_emisor}
                            </b>
                        </div>
                    ) : (
                        <div className="w-100 text-center">
                            <b className="text-uppercase">{sucursal.nombre}</b>
                        </div>
                    )}

                    <div className="w-100 text-center text-uppercase">
                        {sucursal.direccion}
                    </div>
                    {(sucursal.telefono || sucursal.telefono != "") && (
                        <div className="w-100 text-center">
                            Tel. {sucursal.telefono}
                        </div>
                    )}

                    <div className="w-100 text-center text-uppercase d-flex flex-column">
                        <b>Fecha y hora</b>
                        {utiliza_fel && datos_fel ? (
                            <RenderDateTime value={datos_fel.fecha_emision} />
                        ) : (
                            <RenderDateTime value={movimiento.creado} />
                        )}
                    </div>

                    {utiliza_fel && (
                        <div className="w-100 text-center">
                            <div className="w-100 text-center">
                                Nit. {datos_empresa.nit}
                            </div>
                        </div>
                    )}
                    {utiliza_fel && datos_fel && (
                        <div className="w-100 text-center mt-3">
                            {datos_fel.tipo == "FACT" ||
                            datos_fel.tipo == "FPEQ" ? (
                                <div className="w-100 text-center">
                                    <b className="text-uppercase">
                                        FEL - DOCUMENTO TRIBUTARIO ELECTRÓNICO
                                    </b>
                                </div>
                            ) : (
                                <div className="w-100 text-center">
                                    <b className="text-uppercase">
                                        FACTURA CAMBIARIA
                                    </b>
                                    <br />
                                    <b className="text-uppercase">
                                        {datos_fel.tipo} - DOCUMENTO TRIBUTARIO
                                        ELECTRÓNICO
                                    </b>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {utiliza_fel && datos_fel && (
                    <div className="w-100">
                        {datos_fel.estado_factura == FACTURADO && (
                            <div className="w-100">
                                <div className="d-flex flex-row fnt-12 mb05">
                                    <div className="flex1 d-flex flex-column">
                                        <b>Serie</b>
                                        {datos_fel.serie ? datos_fel.serie : ""}
                                    </div>
                                    <div className="flex1 d-flex flex-column">
                                        <b>Número</b>
                                        {datos_fel.numero
                                            ? datos_fel.numero
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className="d-flex flex-row fnt-12 mb05">
                    {tipo === "Venta" && (
                        <div className="flex1 d-flex flex-column">
                            <b>Código de venta</b>
                            {movimiento.codigo_venta
                                ? movimiento.codigo_venta
                                : ""}
                        </div>
                    )}
                </div>
                {movimiento.no_factura && conf_habilitar_no_factura ? (
                    <div className="d-flex flex-column fnt-12 mb">
                        <div className="flex1 d-flex flex-column">
                            <b>No. Factura</b>
                            {movimiento.no_factura}
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <div className="header-grid-recibo fnt-12 mb">
                    <div className="d-flex fnt-12 mb05">
                        {tipo !== "Ingreso" && (
                            <div className="flex1 d-flex flex-column">
                                <b>Nombre</b>
                                {movimiento.cliente_proveedor
                                    ? movimiento.cliente_proveedor
                                        ? movimiento.cliente_proveedor.nombre
                                        : "-----"
                                    : "-----"}
                            </div>
                        )}
                        {tipo === "Ingreso" && (
                            <div className="flex1 d-flex flex-column">
                                <b>
                                    {es_devolucion_proveedor
                                        ? "Proveedor"
                                        : "Cliente"}
                                </b>
                                {cliente_proveedor}
                            </div>
                        )}
                    </div>
                    <div className="d-flex flex-column">
                        <b>NIT</b>
                        {movimiento.cliente_proveedor
                            ? movimiento.cliente_proveedor.nit
                                ? movimiento.cliente_proveedor.nit
                                : "CF"
                            : "CF"}
                    </div>
                    <div className="d-flex flex-column mb05">
                        <b>Dirección</b>
                        {movimiento.cliente_proveedor
                            ? movimiento.cliente_proveedor.direccion
                                ? movimiento.cliente_proveedor.direccion
                                : "Ciudad"
                            : "Ciudad"}
                    </div>

                    {movimiento && movimiento.codigo && (
                        <div className="d-flex flex-column mb05">
                            <b>Correlativo</b>
                            {movimiento.codigo}
                        </div>
                    )}

                    {movimiento && movimiento.numero_documento && (
                        <div className="d-flex flex-column mb05">
                            <b>Numero documento</b>
                            {movimiento.numero_documento}
                        </div>
                    )}

                    {tipo === "Ingreso" && (
                        <div className="d-flex flex-column">
                            <b>
                                {orden_compra_data
                                    ? "Realizado por"
                                    : "Vendedor"}
                            </b>
                            {usuario_nombre}
                        </div>
                    )}
                    {usuario ? (
                        <div className="d-flex flex-column">
                            <b>Usuario</b>
                            {usuario}
                        </div>
                    ) : null}
                </div>

                {movimiento.referencia ? (
                    <div className="d-flex flex-column fnt-12 mb">
                        <div className="flex1 d-flex flex-column mb05">
                            <b>Referencia</b>
                            {movimiento.referencia}
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <div
                    className="d-flex flex-row justify-content-between fnt-16"
                    style={{ borderTop: "dotted 1px", paddingTop: "10px" }}
                >
                    <b className="flex-1">CÓDIGO</b>
                    <b className="flex-1">ARTÍCULOS</b>
                    <b className="flex-1">CANTIDAD</b>
                    <b className="d-flex justify-content-end flex-2">
                        SUB TOTAL
                    </b>
                </div>
                {detalles &&
                    detalles.map((detalle) => {
                        const sub_total = detalle.cantidad * detalle.precio;
                        total +=
                            detalle.sub_total <= 0
                                ? sub_total
                                : detalle.sub_total;

                        return (
                            <React.Fragment>
                                <div
                                    className="d-flex mb fnt-11"
                                    key={detalle.id}
                                >
                                    {!detalle.es_vale ? (
                                        <div className="width100">
                                            <div className="d-flex flex-row">
                                                <div className="flex-1 d-flex flex-row">
                                                    {`
                                                    ${
                                                        detalle.fraccion
                                                            .producto
                                                            ? `${detalle.fraccion.producto.codigo}`
                                                            : ""
                                                    }
                                                    `}
                                                </div>
                                                <div className="flex-1 d-flex flex-column">
                                                    {detalle.producto}
                                                </div>
                                                <div className="flex-3 d-flex flex-row">
                                                    <RenderCurrency
                                                        value={
                                                            tipo === "Ingreso"
                                                                ? detalle.costo
                                                                : detalle.precio_original &&
                                                                  detalle.desc_porcentaje
                                                                ? detalle.precio_original
                                                                : detalle.precio
                                                        }
                                                        className={"mr-1"}
                                                    />
                                                    <div className="mr-1">
                                                        X
                                                    </div>
                                                    {detalle.fraccion.producto
                                                        .a_granel ? (
                                                        <RenderNumber
                                                            value={
                                                                detalle.cantidad
                                                            }
                                                            decimalScale={3}
                                                            className={"mr-1"}
                                                        />
                                                    ) : (
                                                        <RenderNumber
                                                            value={
                                                                detalle.cantidad
                                                            }
                                                            className={"mr-1"}
                                                        />
                                                    )}
                                                    {detalle.precio_original &&
                                                    detalle.desc_porcentaje ? (
                                                        <RenderCurrency
                                                            value={
                                                                detalle.cantidad *
                                                                detalle.precio_original
                                                            }
                                                            className={
                                                                "ml-auto"
                                                            }
                                                        />
                                                    ) : (
                                                        <RenderCurrency
                                                            value={
                                                                detalle.sub_total <=
                                                                0
                                                                    ? sub_total
                                                                    : detalle.sub_total
                                                            }
                                                            className={
                                                                "ml-auto"
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            {/* region DESCUENTOS SOBRE CADA PRODUCTO */}
                                            {/* {detalle.precio_original &&
                                                detalle.desc_porcentaje > 0 && (
                                                    <div className="d-flex flex-column">
                                                        <div className="d-flex flex-row pl-20">
                                                            Descuento (
                                                            {
                                                                detalle.desc_porcentaje
                                                            }
                                                            %)
                                                        </div>
                                                        <div className="d-flex flex-row pl-20">
                                                            <span className="mr-1">
                                                                -{" "}
                                                                <RenderCurrency
                                                                    value={
                                                                        detalle.desc_monto
                                                                    }
                                                                />
                                                            </span>
                                                            <div className="mr-1">
                                                                X
                                                            </div>
                                                            {detalle.fraccion
                                                                .producto
                                                                .a_granel ? (
                                                                <RenderNumber
                                                                    value={
                                                                        detalle.cantidad
                                                                    }
                                                                    decimalScale={
                                                                        3
                                                                    }
                                                                    className={
                                                                        "mr-1"
                                                                    }
                                                                />
                                                            ) : (
                                                                <RenderNumber
                                                                    value={
                                                                        detalle.cantidad
                                                                    }
                                                                    className={
                                                                        "mr-1"
                                                                    }
                                                                />
                                                            )}
                                                            <span className="ml-auto">
                                                                -{" "}
                                                                <RenderCurrency
                                                                    value={
                                                                        detalle.cantidad *
                                                                        detalle.desc_monto
                                                                    }
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                )} */}
                                            {/* endregion DESCUENTOS SOBRE CADA PRODUCTO */}
                                        </div>
                                    ) : (
                                        <div className="width100">
                                            <div className="d-flex flex-column">
                                                <div className="d-flex flex-row">
                                                    Vale de descuento
                                                    {detalle.vale.codigo}
                                                </div>
                                                <div className="d-flex flex-row">
                                                    <RenderCurrency
                                                        value={
                                                            detalle.sub_total *
                                                            -1
                                                        }
                                                    />{" "}
                                                    X 1
                                                    <RenderCurrency
                                                        value={
                                                            detalle.sub_total *
                                                            -1
                                                        }
                                                        className={"ml-auto"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        );
                    })}
                {/* {movimiento.reciduo_redondeo != 0 && (
                    <div className="width100 mb">
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <div className="d-flex flex3 flex-row">
                                Redondeo
                            </div>
                            <div className="d-flex flex1 flex-row align-items-center justify-content-end">
                                <RenderCurrency
                                    value={movimiento.reciduo_redondeo}
                                />
                            </div>
                        </div>
                    </div>
                )} */}
                <div
                    className="d-flex flex-row justify-content-between fnt-16"
                    style={{ borderTop: "dotted 1px", paddingTop: "15px" }}
                >
                    <b>{footerText}</b>
                    <b>
                        <RenderCurrency value={total} className={"ml-auto"} />
                    </b>
                </div>
                {tipo === "Venta" && (
                    <div className="fnt-16">
                        {movimiento.tipo_pago == pago_total ? (
                            <div className="d-flex flex-row justify-content-between">
                                <b>PAGO RECIBIDO</b>
                                <b>
                                    <RenderCurrency
                                        value={movimiento.pago_recibido}
                                        className={"ml-auto"}
                                    />
                                </b>
                            </div>
                        ) : (
                            <div className="d-flex flex-row justify-content-between">
                                <b>ANTICIPO</b>
                                <b>
                                    <RenderCurrency
                                        value={movimiento.pago_recibido}
                                        className={"ml-auto"}
                                    />
                                </b>
                            </div>
                        )}
                        {movimiento.vuelto_efectivo !== null &&
                            movimiento.vuelto_efectivo !== 0 &&
                            movimiento.tipo_pago == pago_total && (
                                <div className="d-flex flex-row justify-content-between">
                                    <b>VUELTO</b>
                                    <b>
                                        <RenderCurrency
                                            value={movimiento.vuelto_efectivo}
                                            className={"ml-auto"}
                                        />
                                    </b>
                                </div>
                            )}
                        {utiliza_fel ? (
                            <div className="w-100 mt-3">
                                {/* DENOMINACION */}
                                {datos_fel && (
                                    <div className="d-flex flex-row justify-content-center fnt-14 mb-3 text-center font-weight-bold text-uppercase">
                                        {datos_fel.frase_fel}
                                    </div>
                                )}
                                {datos_fel &&
                                    (datos_fel.tipo == "FCAM" ||
                                        datos_fel.tipo == "FCAP") && (
                                        <div className="d-flex flex-row fnt-12 mb05">
                                            <div className="flex1 d-flex flex-column align-items-center">
                                                <b>COMPLEMENTOS</b>
                                                {datos_fel.tipo == "FCAM" ? (
                                                    <p className="text-uppercase">
                                                        Abonos de Factura
                                                        Cambiaria
                                                    </p>
                                                ) : (
                                                    <p className="text-uppercase">
                                                        Abonos de Factura
                                                        Cambiaria de Pequeño
                                                        Contribuyente
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {datos_fel && (
                                    <div className="w-100">
                                        <div className="d-flex flex-row fnt-12 mb05">
                                            <div className="flex1 d-flex flex-column align-items-center text-center">
                                                <b>AUTORIZACIÓN FEL</b>
                                                {datos_fel.uuid
                                                    ? datos_fel.uuid
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row fnt-12">
                                            <div className="flex1 d-flex flex-column align-items-center">
                                                <b>FECHA CERTIFICACIÓN</b>
                                                <RenderDateTime
                                                    value={
                                                        datos_fel.fechaCertificacion
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* DATOS CERTIFICADOR */}
                                <div className="d-flex flex-row fnt-12 mt-3">
                                    <div className="flex1 d-flex flex-column align-items-center">
                                        <b>CERTIFICADOR</b>
                                        INFILE, S.A. -- NIT: 12521337
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex flex-column fnt-14 align-items-center text-center mt-5 mb-2">
                                {/* <span>ESTE DOCUMENTO</span>
                                <span>NO ES UNA FACTURA</span> */}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
