import React, { Component } from 'react';

class Privado extends Component {
    render() {
        return (
            <div className="container mt-4">
                <h1>Bienvenido de nuevo</h1>
            </div>
        );
    }
}

export default Privado;
