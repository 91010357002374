import React, { useState } from "react";
import { Collapse } from "react-collapse";

import { listado_permisos } from "./listado_permisos";

import { CheckboxContenedor } from "./Contenedores/CheckboxContenedor";
import FieldContenedor from "./Contenedores/FieldContenedor";

import "./estilos.css";

const FormPermisos = ({ nombre_permiso, is_update, collapse, setCollapse }) => {
    // Obtener el listado de permisos, segun el nombre del permiso seleccionado y que no sea tipo personalizado
    const permisos =
        nombre_permiso && nombre_permiso !== "personalizado"
            ? listado_permisos[nombre_permiso]["permisos"]
            : {};

    // Map al rol es_motorista, ya que todos sus permisos estan en check false
    const permiso_personalizado = listado_permisos["es_motorista"]["permisos"];

    return (
        <React.Fragment>
            <div className="d-flex flex-column w-100">
                {nombre_permiso && (
                    <div className="d-flex w-100">
                        <h3 className="ml-2">Permisos</h3>
                        <button
                            type="button"
                            className="button-link"
                            onClick={() => setCollapse(!collapse)}
                        >
                            {collapse ? "Ocultar" : "Mostrar"} permisos
                        </button>
                    </div>
                )}

                {/* Si es un permiso, predefinido */}
                {nombre_permiso !== "personalizado" && (
                    <Collapse isOpened={collapse}>
                        <CheckboxContenedor
                            nombre_permiso={nombre_permiso}
                            permisos={permisos}
                        />
                    </Collapse>
                )}

                {/* Si es un permiso personalizado */}
                {nombre_permiso === "personalizado" && (
                    <Collapse isOpened={collapse}>
                        <FieldContenedor
                            nombre_permiso={nombre_permiso}
                            permisos={permiso_personalizado}
                        />
                    </Collapse>
                )}
            </div>
        </React.Fragment>
    );
};

export default FormPermisos;
