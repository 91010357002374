import { createAction, handleActions } from 'redux-actions';
import { push } from 'react-router-redux';
import { api } from "api";

// ------------------------------------
// Constantes
// ------------------------------------

const SUBMIT = 'SUBMIT';
const NAME_ERROR = 'NAME_ERROR';
const PASS_ERROR = 'PASS_ERROR';
const LOGIN_LOADER = 'LOGIN_LOADER';
const SUBMIT_ERROR = 'SUBMIT_ERROR';
const ME = 'ME';

export const constants = {
  SUBMIT,
};

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch, getStore) => {
  let canLog = true;
  dispatch({ type: LOGIN_LOADER, loader: true });
  dispatch({ type: SUBMIT_ERROR, submitError: false });
  if (data.email === '' || data.email === undefined) {
    canLog = false;
    dispatch({ type: LOGIN_LOADER, loader: false });
  }
  if (data.password === '' || data.password === undefined) {
    canLog = false;
    dispatch({ type: LOGIN_LOADER, loader: false });
  }
  if (canLog) {
    // Esto debiera hacer una peticion a un API
    api.post("usuarios/token", data).catch(() => {
      dispatch({ type: LOGIN_LOADER, loader: false });
      dispatch({ type: SUBMIT_ERROR, submitError: true });
    }).then((resp) => {
      if (resp){
        localStorage.setItem("token", resp.token);
        api.get("usuarios/me", {}).catch((err) => {
          localStorage.removeItem("token");
        }).then((me) => {
          if (me) {
            dispatch({ type: SUBMIT, autenticado: true });
            dispatch(setMe(me));
          }
          dispatch({ type: LOGIN_LOADER, loader: false });
        });
      }
    });
  } else {
    dispatch({ type: LOGIN_LOADER, loader: false });
    dispatch({ type: SUBMIT, autenticado: false });
  }
};

export const logOut = () => (dispatch) => {
  localStorage.removeItem('token');
  dispatch({ type: SUBMIT, autenticado: false });
};

export const setMe = me => ({
  type: ME,
  me,
});

export const actions = {
  onSubmit,
  logOut,
};

export const reducers = {
  [SUBMIT]: (state, { autenticado }) => {
    return {
      ...state,
      autenticado,
    };
  },
  [LOGIN_LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [SUBMIT_ERROR]: (state, { submitError }) => {
    return {
      ...state,
      submitError,
    };
  },
  [ME]: (state, { me }) => {
    return {
      ...state,
      me,
    };
  },
};

export const initialState = {
  submitError: false,
  autenticado: false,
  loader: false,
  me: {},
};

export default handleActions(reducers, initialState);
