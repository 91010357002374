import { api } from 'api';
export default class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        const imagen = {name: "imagen", file: this.loader.file};
        return api.postAttachments("upload_image", {}, [imagen]);
    }

    abort() {
        // Reject promise returned from upload() method.
    }
}
