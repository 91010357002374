import React from "react";
import FileMultiUploader from "./FileMultiUploader";
import "./multiUploadFile.css";

const MultiUploadFile = ({
    files,
    onChangeFile,
    onRemoveFile,
    onRemoveOldFile,
    data,
}) => {
    return (
        <React.Fragment>
            <FileMultiUploader onChange={onChangeFile} />
            <br />
            {data.length > 0 && (
                <div className="row">
                    {data.map((item) => (
                        <div
                            key={item.id}
                            className="col-12 col-md-6 col-lg-3 col-sm-12 main-container-g "
                        >
                            <a
                                href={item.documento}
                                download={item.nombre}
                                type="button"
                                className="btn btn-light btn-lg btn-old-files mr-3 mt-3 multi-file-box-a  text-truncate"
                            >
                                {item.nombre}
                            </a>
                            <span className="icon-exito  mt-3 multi-file-box-b">
                                <i
                                    onClick={() => {
                                        onRemoveOldFile(item);
                                    }}
                                    className="fa fa-times mt-3"
                                    aria-hidden="true"
                                ></i>
                            </span>
                        </div>
                    ))}
                </div>
            )}

            <br />
            {files.length > 0 && (
                <div className="row">
                    {files.map((item, index) => (
                        <div
                            key={index}
                            className="col-12 col-md-6 col-lg-3 col-sm-12 main-container-g"
                        >
                            <button
                                type="button"
                                className="btn btn-success btn-lg mr-3 mt-3 multi-file-box-a text-truncate"
                            >
                                {item.name}
                            </button>
                            <span className="icon-exito  mt-3 multi-file-box-b ">
                                <i
                                    onClick={() => {
                                        onRemoveFile(item);
                                    }}
                                    className="fa fa-times mt-3"
                                    aria-hidden="true"
                                ></i>
                            </span>
                        </div>
                    ))}
                </div>
            )}

            <br />
        </React.Fragment>
    );
};

export default MultiUploadFile;
