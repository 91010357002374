import React from "react";
import Select, { AsyncCreatable, Async } from "react-select";
import NumberFormat from "react-number-format";
import classNames from "classnames";
import Switch from "react-switch";
import _ from "lodash";
// import DatePicker from "Utils/DatePicker";
import { icons } from "icons";
import { RenderCurrency } from "./renderFieldsCurrency";
import "react-select/dist/react-select.css";
import PhotoUploader from "../PhotoUploader/PhotoUploader";
import DatePicker from "react-date-picker";
import moment from "moment";

export const renderField = ({
    input,
    label,
    type,
    meta: { touched, error },
    disabled,
    className,
    autoFocus,
    defaultValue = "",
}) => {
    const invalid = touched && error;

    return (
        <React.Fragment>
            <input
                {...input}
                placeholder={label}
                disabled={disabled}
                autoComplete="off"
                className={classNames(
                    !!className ? className : "form-control",
                    { "is-invalid": invalid }
                )}
                autoFocus={autoFocus ? autoFocus : false}
                type={type}
                value={defaultValue ? defaultValue : input.value}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderTextArea = ({
    input,
    label,
    rows,
    type,
    disabled,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <textarea
                {...input}
                placeholder={label}
                style={{ resize: "none" }}
                rows={rows ? rows : 3}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                disabled={disabled}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};
export const renderSearchSelect = ({
    input,
    disabled,
    loadOptions,
    valueKey,
    labelKey,
    meta: { touched, error },
    setFormValue = false,
    key,
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <Async
                disabled={disabled}
                id={input.name}
                key={key ? key : input.name}
                value={input.value}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                onChange={(e) => {
                    input.onChange(!!e ? e[valueKey] : null);
                    if (setFormValue && e) {
                        setFormValue(e);
                    }
                    if (!e) {
                        setFormValue({});
                    }
                }}
                inputProps={{
                    autoComplete: "off",
                    autoCorrect: "off",
                    spellCheck: "off",
                }}
                placeholder="Seleccionar"
                searchPromptText="Escriba para buscar"
                valueKey={valueKey}
                labelKey={labelKey}
                loadOptions={loadOptions}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                    {input.value}
                </div>
            )}
        </React.Fragment>
    );
};

export const AsyncSelectField = ({
    input,
    disabled,
    isClearable,
    isSearchable,
    loadOptions,
    placeholder,
    valueKey,
    labelKey,
    meta: { touched, error },
}) => {
    const invalid = touched && error;

    return (
        <React.Fragment>
            <Async
                isClearable={isClearable}
                cacheOptions
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                // styles={customStyles}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                valueKey={valueKey}
                labelKey={labelKey}
                loadOptions={loadOptions}
                placeholder={placeholder ? placeholder : false}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderSearchCreateSelect = ({
    input,
    disabled,
    loadOptions,
    valueKey,
    labelKey,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <AsyncCreatable
                disabled={disabled}
                value={input.value}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                onChange={(e) => {
                    input.onChange(!!e ? e[valueKey] : null);
                }}
                searchPromptText="Escriba para buscar"
                valueKey={valueKey}
                labelKey={labelKey}
                loadOptions={loadOptions}
                promptTextCreator={(label) => {
                    return `Crear opción ${label}`;
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                    {input.value}
                </div>
            )}
        </React.Fragment>
    );
};

export const renderSelectField = ({
    input,
    disabled,
    options,
    item_vacio,
    meta: { touched, error },
    value_assign,
    label_assign,
    assigned_value = false,
    assigned_label = false,
    border_red = false,
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <select
                {...input}
                disabled={disabled}
                className={classNames(
                    `${border_red ? "border-red" : ""} form-control`,
                    {
                        "is-invalid": invalid,
                    }
                )}
            >
                {item_vacio && <option></option>}

                {!assigned_value &&
                    options.map((opcion) => {
                        return (
                            <option
                                key={
                                    typeof opcion === "string"
                                        ? opcion
                                        : opcion.id
                                }
                                value={
                                    typeof opcion === "string"
                                        ? opcion
                                        : opcion.value
                                }
                            >
                                {typeof opcion === "string"
                                    ? opcion
                                    : opcion.label}
                            </option>
                        );
                    })}

                {assigned_value &&
                    !assigned_label &&
                    options.map((opcion) => {
                        return (
                            <option
                                key={
                                    typeof opcion === "string"
                                        ? opcion
                                        : opcion.id
                                }
                                value={
                                    typeof opcion === "string"
                                        ? opcion
                                        : opcion[value_assign]
                                }
                            >
                                {typeof opcion === "string"
                                    ? opcion
                                    : opcion.label}
                            </option>
                        );
                    })}

                {assigned_value &&
                    assigned_label &&
                    options.map((opcion) => {
                        return (
                            <option
                                key={
                                    typeof opcion === "string"
                                        ? opcion
                                        : opcion.id
                                }
                                value={
                                    typeof opcion === "string"
                                        ? opcion
                                        : opcion[value_assign]
                                }
                            >
                                {typeof opcion === "string"
                                    ? opcion
                                    : opcion[label_assign]}
                            </option>
                        );
                    })}
            </select>
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const customStyles = {
    option: (provided, state) => {
        return {
            ...provided,
            color: "#484848",
            padding: "0.5rem",
            backgroundColor: state.isFocused ? "#B1CBE7" : "#fff",
            minWidth: "100%",
            ":active": {
                backgroundColor: "#136AC6",
                color: "#fff",
            },
        };
    },
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? "#f1f1f1" : "#ffffff",
        border: "1px solid #e1e5eb",
        borderColor: state.isFocused ? "#ACACAC" : "#136AC6",
        height: 44,
        "&:hover": { borderColor: "#ACACAC" },
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";
        const color = "#ACACAC";
        const fontWeight = "normal";

        return { ...provided, opacity, transition, color, fontWeight };
    },
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: "#ACACAC",
            fontWeight: "normal",
        };
    },
};

export const SelectField = ({
    input,
    disabled,
    isClearable,
    isMulti,
    isSearchable,
    options,
    placeholder,
    labelKey = "label",
    valueKey = "value",
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const _options = [];
    options.forEach((option) => {
        _options.push({
            ...option,
            label: option[labelKey],
            value: option[valueKey],
        });
    });
    let value = input.value;
    if (value !== null && value !== undefined) {
        value = _.find(_options, { value });
    }

    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames(
                    `react-select-container ${invalid ? "select-invalid" : ""}`,
                    {
                        "is-invalid": invalid,
                    }
                )}
                // menuContainerStyle={{ backgroundColor: "#000" }}
                menuStyle={{
                    ":active": {
                        backgroundColor: "#136AC6",
                        color: "#fff",
                    },
                }}
                // styles={{ backgroundColor: "#000", color: "#555" }}
                wrapperStyle={{
                    minHeight: 44,
                    display: "flex",
                    alignItems: "center",
                }}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e[valueKey] : null);
                }}
                value={value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const renderNumber = ({
    input,
    label,
    type,
    decimalScale,
    disabled,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <NumberFormat
                disabled={disabled}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                decimalScale={decimalScale ? decimalScale : 0}
                fixedDecimalScale={true}
                value={input.value}
                thousandSeparator={true}
                prefix={""}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderNumberFormat = ({
    input,
    decimalScale,
    placeholder,
    disabled,
    meta: { touched, error },
    prefix = "",
    suffix = "",
    numberFormat,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                value={input.value}
                disabled={disabled || false}
                thousandSeparator
                prefix={prefix}
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderPorcentaje = ({
    input,
    label,
    type,
    decimalScale,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <NumberFormat
                className={classNames("form-control text-right", {
                    "is-invalid": invalid,
                })}
                placeholder="0 %"
                decimalScale={decimalScale ? decimalScale : 2}
                fixedDecimalScale={true}
                value={input.value}
                thousandSeparator={true}
                suffix={" %"}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderNumberConControles = (props) => {
    const { input } = props;
    const { minValue, maxValue, splitValue, onCambio, cssIcon = false } = props;
    return (
        <div
            className="d-flex flex-row"
            style={{ height: "25px", width: "90px" }}
        >
            <button
                className="btn btn-azul-venta btn-menos d-flex align-items-center justify-content-center"
                onClick={(e) => {
                    e.stopPropagation();
                    const actualValue = !isNaN(input.value)
                        ? parseFloat(input.value)
                        : 0;
                    let split = splitValue || 1;
                    if (
                        minValue !== undefined &&
                        minValue !== null &&
                        actualValue - split < minValue
                    ) {
                        split = 0;
                    }
                    const value = actualValue - split;
                    input.onChange(value);
                    !!onCambio && onCambio(value);
                }}
                type="button"
            >
                {cssIcon ? (
                    <i className="minus" />
                ) : (
                    <img
                        className="img-icono-cantidades"
                        src={icons.menos}
                        title="Disminuir"
                        alt="-"
                    />
                )}
            </button>
            <NumberFormat
                className="form-control venta-cantidad text-center h-100 px-0"
                decimalScale={0}
                fixedDecimalScale={true}
                value={input.value}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onChange={(values) => {
                    let newValue = parseFloat(values.value) || 0;
                    if (
                        minValue !== undefined &&
                        minValue !== null &&
                        newValue < minValue
                    ) {
                        newValue = minValue;
                    }
                    if (
                        maxValue !== undefined &&
                        maxValue !== null &&
                        newValue > maxValue
                    ) {
                        newValue = maxValue;
                    }
                    input.onChange(newValue);
                    !!onCambio && onCambio(newValue);
                }}
                {...input}
            />
            <button
                className="btn btn-azul-venta btn-mas d-flex align-items-center justify-content-center"
                onClick={(e) => {
                    e.stopPropagation();
                    const actualValue = !isNaN(input.value)
                        ? parseFloat(input.value)
                        : 0;
                    let split = splitValue || 1;
                    if (
                        maxValue !== undefined &&
                        maxValue !== null &&
                        actualValue + split > maxValue
                    ) {
                        split = 0;
                    }
                    const value = actualValue + split;
                    input.onChange(value);
                    !!onCambio && onCambio(value);
                }}
                type="button"
            >
                {cssIcon ? (
                    <i className="plus" />
                ) : (
                    <img
                        className="img-icono-cantidades"
                        src={icons.mas}
                        title="Aumentar"
                        alt="+"
                    />
                )}
            </button>
        </div>
    );
};

export const renderCurrency = ({
    className,
    input,
    disabled,
    label,
    type,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <RenderCurrency
            disabled={disabled}
            className={className}
            input={input}
            invalid={invalid}
            error={error}
        />
        // <React.Fragment>
        //     <NumberFormat className={classNames(className ? className : 'form-control', { 'is-invalid': invalid })}
        //         decimalScale={2} fixedDecimalScale={true}
        //         value={input.value} thousandSeparator={true} prefix={'Q '}
        //         onValueChange={(values) => {
        //             input.onChange(values.value);
        //         }}
        //     />
        //     {invalid && <div className="invalid-feedback">
        //         {error}
        //     </div>}
        // </React.Fragment>
    );
};

export const renderSwitch = ({
    input,
    label,
    type,
    meta: { touched, error },
    disabled,
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <Switch
                onColor="#E5BCBC"
                onHandleColor="#BF3638"
                offColor="#B1CBE7"
                offHandleColor="#136AC6"
                handleDiameter={23}
                uncheckedIcon={false}
                checkedIcon={false}
                // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                boxShadow="0px 3px 1px rgba(0, 0, 0, 0.2)"
                height={18}
                width={40}
                onChange={(value) => {
                    input.onChange(value);
                }}
                disabled={disabled || false}
                checked={input.value ? input.value : false}
                id="normal-switch"
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderDatePicker = ({
    input,
    maxDate,
    minDate,
    disabled,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <DatePicker
                onChange={(e) => {
                    var timestamp = Date.parse(e);
                    if (isNaN(timestamp) == false || e === null) {
                        input.onChange(e);
                    }
                }}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                value={
                    input.value
                        ? moment(input.value, "YYYY-MM-DD").toDate()
                        : null
                }
                className={classNames(
                    `form-control ${disabled ? "disabled-date-picker" : ""}`,
                    {
                        "is-invalid": invalid,
                    }
                )}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderInteger = ({
    input,
    decimalScale = 0,
    placeholder,
    disabled,
    meta: { touched, error },
    prefix = "",
    suffix = "",
    numberFormat,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                placeholder={placeholder}
                value={input.value}
                prefix={prefix}
                disabled={disabled || false}
                allowNegative={false}
                suffix={suffix}
                decimalScale={decimalScale}
                isNumericString={false}
                allowEmptyFormatting={true}
                fixedDecimalScale={false}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderPhotoUploader = ({
    className,
    disabled,
    placeholder,
    input,
    aceptar_archivos = false,
    meta: { touched, error },
}) => {
    const invalid = touched && error;

    return (
        <div className={classNames({ "is-invalid": invalid })}>
            <PhotoUploader
                onChange={(e) => {
                    input.onChange(e);
                }}
                disabled={disabled || false}
                file={input.value}
                aceptar_archivos={aceptar_archivos}
                className={className}
            />
            {invalid && <div className="d-flex invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderFieldRadio = ({
    input,
    label,
    checked,
    id = "",
    disabled,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="radio c-radio c-radio-nofont d-flex">
                <label className="txt-balck font-weight-normal">
                    <input
                        type="radio"
                        id={id}
                        disabled={disabled}
                        checked={checked}
                        {...input}
                        className={classNames("", { "is-invalid": invalid })}
                    />
                    <span />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderFieldCheck = ({
    input,
    label,
    value,
    disabled,
    type,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="needsclick">
                    <input
                        {...input}
                        type="checkbox"
                        disabled={disabled}
                        onChange={(e) => {
                            input.onChange(!input.value);
                        }}
                        checked={input.value ? input.value : false}
                        className={classNames("", { "is-invalid": invalid })}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderPercent = ({
    input,
    decimalScale,
    placeholder,
    disabled,
    meta: { touched, error },
    prefix = "",
    suffix = "",
    numberFormat,
    maxValue = 100,
    minValue = 1,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                value={input.value}
                disabled={disabled || false}
                thousandSeparator
                prefix={prefix}
                isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    if (floatValue == null) {
                        return formattedValue === "";
                    } else {
                        return floatValue >= minValue && floatValue <= maxValue;
                    }
                }}
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderSearchSelect2 = ({
    input,
    disabled,
    loadOptions,
    valueKey,
    labelKey,
    meta: { touched, error },
    setFormValue = false,
    getObjetvalue = false,
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <Async
                disabled={disabled}
                id={input.name}
                value={input.value}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                onChange={(e) => {
                    input.onChange(!!e ? e[valueKey] : null);
                    if (getObjetvalue) {
                        input.onChange(e);
                    }
                    if (setFormValue && e) {
                        setFormValue(e);
                    }
                    if (!e) {
                        setFormValue({});
                    }
                }}
                placeholder="Seleccionar"
                searchPromptText="Escriba para buscar"
                valueKey={valueKey}
                labelKey={labelKey}
                loadOptions={loadOptions}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                    {input.value}
                </div>
            )}
        </React.Fragment>
    );
};

export const renderFieldReadOnly = ({
    input,
    label,
    type,
    onClick,
    meta: { touched, error },
    className,
    autoFocus,
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <input
                {...input}
                onClick={onClick}
                readOnly
                placeholder={label}
                type={type}
                className={classNames(
                    !!className ? className : "form-control",
                    { "is-invalid": invalid }
                )}
                autoFocus={autoFocus ? autoFocus : false}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const RenderField = {
    renderField,
    renderTextArea,
    renderSearchSelect,
    renderSearchSelect2,
    renderSearchCreateSelect,
    renderSelectField,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderPorcentaje,
    renderInteger,
    renderPhotoUploader,
    renderFieldRadio,
    renderFieldCheck,
    renderPercent,
    renderFieldReadOnly,
};
