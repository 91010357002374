import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import Login from './common/components/Login/LoginContainer';
import Home from './common/components/Home/Home';

import Privado from './common/components/Privado/Privado';
import Navbar from './common/components/Navbar/NavbarContainer';

// Empresas
import EmpresasListado from './common/components/Empresas/Listado/EmpresasListadoContainer'
import EmpresasEditar from './common/components/Empresas/Editar/EmpresaEditarContainer'
import EmpresasCrear from './common/components/Empresas/Crear/EmpresaCrearContainer'

// Sucursales
import { SucursalesEditar, SucursalesCrear } from './common/components/Empresas/Editar/Sucursales'
// Usuarios
import { UsuarioEditar, UsuarioCrear } from './common/components/Empresas/Editar/Usuarios'

// interceptor
import PrivateRoute from './ProtectedRoute'

// Bienvenida
import { Bienvenida } from "./common/components/Bienvenida";
import { CreateEditBienvenida } from "./common/components/Bienvenida/CreateEdit";

//  Categoria
import { CategoriaList } from "./common/components/Categoria"

import '../assets/fonts/fonts.css';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
require('../style/index.css');

function isAuthenticated() {
    return localStorage.getItem('token');
}

module.exports = (
    <div className="container__content">
        <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute path="/page" component={Privado} />

            <PrivateRoute path="/empresas/:id/sucursales/editar/:idSucursal" component={SucursalesEditar} />
            <PrivateRoute path="/empresas/:id/sucursales/crear" component={SucursalesCrear} />

            <PrivateRoute path="/empresas/:id/usuarios/editar/:idUsuario" component={UsuarioEditar} />
            <PrivateRoute path="/empresas/:id/usuarios/crear" component={UsuarioCrear} />
            
            <PrivateRoute path="/empresas/editar/:id/" component={EmpresasEditar} />
            <PrivateRoute path="/empresas/crear/" component={EmpresasCrear} />
            <PrivateRoute path="/empresas/" component={EmpresasListado} />
            <PrivateRoute path="/bienvenida/editar/:id/" component={CreateEditBienvenida} />
            <PrivateRoute path="/bienvenida/crear/" component={CreateEditBienvenida} />
            <PrivateRoute path="/bienvenida/" component={Bienvenida} />
            <PrivateRoute path="/categorias/" component={CategoriaList} />
            <Route path="*" component={Login} />
        </Switch>
    </div>
);
