import { api } from "api";
import Swal from 'sweetalert2';
import { handleActions } from "redux-actions";
import { push, replace } from 'react-router-redux';


// ------------------------------------
// Constants
// ------------------------------------
const PAGE = 'PAGE_CATEGORIAS';
const DATA = 'DATA_CATEGORIAS';
const LOADER = 'LOADER_CATEGORIAS';

const endpoint = "categoria"
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------
export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(isLoading(true));

    // params
    const search = "";

    // api get
    api.get(`${endpoint}/tree`, { page, search }).catch((error) => {
        Swal(
            "ERROR",
            "No se ha podido listar las categorías, intenta más tarde"
        )
        dispatch(isLoading(false))
    }).then((data) => {
        if (data) {
            dispatch(changePage(page));
            dispatch(setData(data.data));
        }
        dispatch(isLoading(false))
    });
};
// ------------------------------------

// ------------------------------------
// PureActions
// ------------------------------------
export const setData = data => ({
    type: DATA,
    data,
});
export const isLoading = (loading = true) => ({
    type: LOADER, loader: loading
})
export const changePage = page => ({
    type: PAGE,
    page,
});
// ------------------------------------

export const actions = {
    listar,
}

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    }
}
// ------------------------------------


// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    datos_edicion: null,
    data: [],
    loader:false
}

export default handleActions(reducers, initialState);