import _ from "lodash";
import React, { useState, useEffect } from "react";
import { api } from "api";
import { icons } from "icons";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { ListadoAccion } from "Utils/ListadoAccion";
import { DetalleAccion } from "Utils/DetalleAccion";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import {
    renderTextArea,
    renderCurrency,
    renderSearchSelect,
    renderDatePicker,
} from "Utils/renderField/renderField";
import {
    formatoNombre,
    formatoExistencia,
    formatoAcciones,
    buscar,
    formatoProducto,
    formatoCostoDolares,
    formatoCodigo,
    formatoProveedor,
    formatoCostoQuetzales,
} from "Utils/CamposComunes";

import {
    formatoCantidad,
    formatoCosto,
    formatoSubtotal,
    formatoSubtotalUnitario,
    formatoCostoDolaresOC,
    formatoCostoQuetzalesOC,
} from "./formatoCamposOC";
import {
    renderField,
    renderNumberFormat,
    renderPhotoUploader,
    renderSelectField,
} from "../../Utils/renderField/renderField";
import { ORIGEN, TIPO_COMPRA } from "../../../../utility/variables";
export const listaProveedores = [];

// VALIDACIONES CUSTOMS
const validate = (values) => {
    const errors = {};

    if (!values.proveedor) errors.proveedor = "Campo requerido";
    if (!values.fecha) errors.fecha = "Campo requerido";

    // ***
    if (!values.origen) errors.origen = "Seleccione un origen";
    if (!values.tipo_compra)
        errors.tipo_compra = "Seleccione un tipo de compra";
    if (!values.tipo_cambio) errors.tipo_cambio = "Campo requerido";
    if (!values.costo_importacion) errors.costo_importacion = "Campo requerido";
    if (!values.numero_documento) errors.numero_documento = "Campo requerido";

    return errors;
};
const Form = (props) => {
    // State
    const {
        changeOrigen,
        ver_buscador,
        loader,
        productos,
        page,
        total,
        costo_importacion,
        origen: tipo_origen,
        setCostoImportacionValue,
        tipo_cambio,
        tipo_compra,
        setTipoCambioValue,
        tipo_cambio_value,
        setTipoCompraValue,
        setDetalleProveedor,
        detalle_proveedor,
        agregar,
        producto_actual,
    } = props;

    let { seleccionados } = props;

    // Bind
    const {
        handleSubmit,
        listar,
        sortChange,
        setVerBuscador,
        asignarDatos,
        limpiarDatosOC,
        quitar,
        setProductoActual,
    } = props;

    // Estado para el tipo de origen (local o imporatcion)
    const [origen, setOrigen] = useState("");

    const llavesListado = [
        {
            nombre: "producto__nombre",
            sort: true,
            caption: "Nombre",
            format: formatoNombre,
            align: "left",
        },

        {
            nombre: "producto__codigo",
            sort: false,
            caption: "Codigo",
            format: formatoCodigo,
            align: "left",
            // width: "100px",
        },
        {
            nombre: "costo",
            sort: false,
            caption: "Ultimo costo Q",
            format: formatoCostoQuetzales,
            align: "left",
            // width: "100px",
        },
        {
            nombre: "costo_dolares",
            sort: false,
            caption: "Ultimo costo $",
            format: formatoCostoDolares,
            align: "left",
            // width: "100px",
        },
        {
            nombre: "producto__ultimo_proveedor",
            sort: false,
            caption: "Proveedor",
            format: formatoProveedor,
            align: "left",
        },
        {
            nombre: "inventario",
            sort: false,
            caption: "Existencias",
            format: formatoExistencia,
            align: "left",
            // width: "100px",
        },
        {
            nombre: "acciones",
            sort: false,
            caption: "Agregar",
            format: formatoAcciones({
                ...props,
                cambio_buscador: setVerBuscador,
            }),
            align: "center",
            width: "90px",
        },
    ];

    const llavesDetalle = [
        { caption: "Codigo", nombre: "codigo", format: formatoCodigo },
        { caption: "Nombre", nombre: "producto", format: formatoProducto },
        {
            caption: "Cantidad",
            nombre: "cantidad",
            format: formatoCantidad(props),
        },
        {
            caption: "Costo (Unidad)",
            nombre: "costo",
            // format: formatoCosto(props),
        },
        {
            caption: "Costo anterior",
            nombre: "costo_producto",
            align: "right",
            format:
                tipo_origen === "1"
                    ? formatoCostoDolaresOC
                    : formatoCostoQuetzalesOC,
        },
        {
            caption: "Subtotal",
            nombre: "subtotal",
            format: (_, row) =>
                formatoSubtotal(
                    _,
                    row,
                    costo_importacion,
                    tipo_origen,
                    tipo_cambio_value
                ), // usando la funcion de formatoSubtotal, y mandandole como parametro el costo del input de importacion, el valor del input de origen y el valor del input de tipo de cambio
            align: "right",
        },
    ];

    // Si es tipo de origen, agregar a la tabla de productos en orden, el campo de costo en quetzales
    const llavesDetalleCostoQtz = [
        { caption: "Codigo", nombre: "codigo", format: formatoCodigo },
        { caption: "Nombre", nombre: "producto", format: formatoProducto },
        {
            caption: "Cantidad",
            nombre: "cantidad",
            format: formatoCantidad(props),
        },
        {
            caption: "Costo (Unidad)",
            nombre: "costo",
            // format: formatoCosto(props),
        },
        {
            caption: "Costo anterior",
            nombre: "costo_producto",
            align: "right",
            format:
                tipo_origen === "1"
                    ? formatoCostoDolaresOC
                    : formatoCostoQuetzalesOC,
        },
        {
            nombre: "costo",
            sort: false,
            caption: "Costo Unitario Q",
            format: formatoCostoQuetzalesOC,
            align: "right",
            // width: "100px",
        },
        {
            caption: "Subtotal Unitario",
            nombre: "subtotal_unitario",
            format: (_, row) =>
                formatoSubtotalUnitario(
                    _,
                    row,
                    costo_importacion,
                    tipo_origen,
                    tipo_cambio_value
                ),
            align: "right",
        },
        {
            caption: "Subtotal",
            nombre: "subtotal",
            format: (_, row) =>
                formatoSubtotal(
                    _,
                    row,
                    costo_importacion,
                    tipo_origen,
                    tipo_cambio_value
                ), // usando la funcion de formatoSubtotal, y mandandole como parametro el costo del input de importacion, el valor del input de origen y el valor del input de tipo de cambio
            align: "right",
        },
    ];

    const goBack = () => {
        props.history.goBack();
    };

    // Request Proveedores
    const getProveedores = (search) => {
        return api
            .get("proveedores", { search })
            .catch(() => {})
            .then((data) => {
                data.results.forEach((item) => {
                    if (!_.find(listaProveedores, { id: item.id }))
                        listaProveedores.push(item);
                });
                return { options: listaProveedores };
            });
    };

    const setTipoOrigen = (tipo_origen) => {
        // importacion
        switch (tipo_origen) {
            // Importacion
            case "1":
                setOrigen(1);
                break;

            // Local
            case "2":
                setOrigen(2);
                break;

            // Servicio
            case "3":
                setOrigen(3);
                break;

            default:
                setOrigen("");
                break;
        }
        return tipo_origen;
    };

    useEffect(() => {
        // si el tipo de origen es importacion (1), se setea el costo de imporatcion
        if (tipo_origen === "1") {
            setCostoImportacionValue(costo_importacion);
        } else {
            setCostoImportacionValue(0);
        }
    }, [costo_importacion, tipo_origen]);

    // Set estado de redux con el valor del input de tipo de cambio
    useEffect(() => {
        setTipoCambioValue(tipo_cambio);
    }, [tipo_cambio]);

    // Set estado de redux con el valor del input de tipo de compra
    useEffect(() => {
        setTipoCompraValue(tipo_compra);
    }, [tipo_compra]);

    useEffect(() => {
        if (detalle_proveedor && detalle_proveedor.tipo_proveedor) {
            changeOrigen(detalle_proveedor.tipo_proveedor);
            setTipoOrigen(String(detalle_proveedor.tipo_proveedor));
        }
    }, [detalle_proveedor]);

    useEffect(() => {
        return () => {
            // limpiarDatosOC();
            console.log("cleaned up");
        };
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group grid-container">
                        <div className="pl-lg-5 pr-lg-5 pt-lg-2 pb-lg-2 p-0 pt-1 pb-1">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="tipo_compra">
                                        Tipo de compra
                                    </label>

                                    <div className="d-flex flex-column flex-md-row pr-0">
                                        <div className="w-100 d-flex flex-column">
                                            <Field
                                                name="tipo_compra"
                                                component={renderSelectField}
                                                options={TIPO_COMPRA}
                                                placeholder="Seleccionar"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="fecha">Fecha</label>
                                    <div className="d-flex flex-column pr-0">
                                        <Field
                                            name="fecha"
                                            component={renderDatePicker}
                                            className="form-control"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="numero_documento">
                                        No. Documento
                                    </label>
                                    <div className="d-flex flex-column flex-md-row pr-0">
                                        <div className="w-100 d-flex flex-column">
                                            <Field
                                                name="numero_documento"
                                                component={renderField}
                                                placeholder="Seleccionar"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="proveedor">Proveedor</label>

                                    <div className="d-flex flex-column flex-md-row pr-0">
                                        <div className="w-100 d-flex flex-column">
                                            <Field
                                                name="proveedor"
                                                component={renderSearchSelect}
                                                loadOptions={getProveedores}
                                                labelKey="nombre"
                                                valueKey="id"
                                                className="form-control"
                                                parse={(id_proveedor) => {
                                                    setDetalleProveedor(
                                                        id_proveedor
                                                    );
                                                    return id_proveedor;
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="num_documento">
                                        Documento
                                    </label>
                                    <div className="d-flex flex-column pr-0">
                                        <Field
                                            name="foto"
                                            component={renderPhotoUploader}
                                            aceptar_archivos={true}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="descripcion">
                                        Observaciones
                                    </label>
                                    <div className="d-flex flex-column pr-0">
                                        <Field
                                            name="descripcion"
                                            rows={10}
                                            component={renderTextArea}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="origen">Origen</label>
                                    <div className="d-flex flex-column flex-md-row pr-0">
                                        <div className="w-100 d-flex flex-column">
                                            <Field
                                                name="origen"
                                                component={renderSelectField}
                                                options={ORIGEN}
                                                placeholder="Seleccionar"
                                                parse={setTipoOrigen}
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Campos de origen si es de importacion */}
                            {origen === 1 && (
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6 form-group">
                                        <label htmlFor="tipo_cambio">
                                            Tipo de cambio
                                        </label>

                                        <div className="d-flex flex-column flex-md-row pr-0">
                                            <div className="w-100 d-flex flex-column">
                                                <Field
                                                    name="tipo_cambio"
                                                    component={renderCurrency}
                                                    decimalScale={2}
                                                    type="number"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 form-group">
                                        <label htmlFor="costo_importacion">
                                            Costo de importación
                                        </label>
                                        <div className="d-flex flex-column pr-0">
                                            <Field
                                                name="costo_importacion"
                                                component={renderNumberFormat}
                                                placeholder="%"
                                                prefix={"%"}
                                                type="number"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container row" style={{ margin: "0" }}>
                {/* //*** Listado de productos */}
                <div
                    className={`col-lg-12 col-md-12 pr-md-0 mt-5 ${
                        ver_buscador ? "form-activo" : ""
                    } slide `}
                >
                    <ListadoAccion
                        buscar={buscar(props)}
                        loader={loader}
                        llaves={llavesListado}
                        data={productos}
                        listar={listar}
                        sortChange={sortChange}
                        titulo={
                            <div className="d-flex flex-column flex-lg-row m-2">
                                <span
                                    style={{ fontSize: "15px" }}
                                    className="align-self-end"
                                >
                                    Listado de Productos
                                </span>
                                <Link
                                    className="btn btn-primary align-self-center ml-auto"
                                    target="_blank"
                                    to={{
                                        pathname: "/productos/crear",
                                        state: {
                                            desdeRuta:
                                                "/ordenes_de_compra/nueva",
                                        },
                                    }}
                                    onClick={asignarDatos}
                                >
                                    <i className="fa fa-plus" /> Agregar
                                    Producto
                                </Link>
                            </div>
                        }
                        page={page}
                    />
                </div>

                {/* //*** Productos en ordenes de compra */}
                <div
                    className={`col-lg-12 col-md-12 mt-5 ${
                        !ver_buscador ? "form-activo" : ""
                    } form-trasero`}
                >
                    <DetalleAccion
                        data={seleccionados}
                        titulo={"Productos en orden de compra"}
                        llaves={
                            origen === 1 ? llavesDetalleCostoQtz : llavesDetalle
                        }
                        loader={loader}
                        agregar={agregar}
                        quitar={quitar}
                        producto_form={true}
                        tipo_origen={tipo_origen}
                        producto_actual={producto_actual}
                        setProductoActual={setProductoActual}
                    />
                    <div
                        style={{
                            borderRadius: "0",
                            padding: "4px 2px 2px 2px",
                            borderTop: "none",
                        }}
                        className="grid-container border-top mt0 d-flex flex-column flex-md-row justify-content-between mb-5"
                    >
                        <div
                            className="d-flex justify-content-around align-items-end p-1 p-md-2 flex-column flex-1"
                            style={{ backgroundColor: "#FFF" }}
                        >
                            <div className="text-right">
                                <div>Monto total</div>
                                <h5>
                                    <b>
                                        <RenderCurrency value={total} />
                                    </b>
                                </h5>
                            </div>
                        </div>
                        <div
                            className="d-flex justify-content-around align-items-center p-1 p-md-2 flex-column flex-3"
                            style={{ backgroundColor: "#FFF" }}
                        >
                            <div className="align-items-center mt0 padding-10 d-flex justify-content-around flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                <button
                                    type="button"
                                    className="btn btn-tertiary mr-1"
                                    onClick={() => {
                                        limpiarDatosOC();
                                        goBack();
                                    }}
                                >
                                    <img src={icons.cancelar} alt="" /> Cancelar
                                </button>
                                <button
                                    className="btn btn-primary ml-1"
                                    type="submit"
                                >
                                    <i className="fa fa-check mr" />
                                    Siguiente
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const FormNuevaOC = reduxForm({
    form: "NuevaOrdenCompra",
    validate,
})(Form);

export default FormNuevaOC;
