import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {icons} from "icons";
import UploadAdapter from "upload_img";

// Draft
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

class Bienvenida extends Component {
    constructor(props) {
        super(props);
        const html = `<p></p>
        <img src="${icons.icono_bienvenida}" alt="undefined" style="float:none;height: 105px;width: 105px"/>
        <p style="text-align:center;"><span style="font-size: 18px;font-family: Arial;">Te damos la bienvenida a <strong>Remocar 1.1</strong></span></p>
        <p style="text-align:center;"><span style="font-size: 18px;font-family: Arial;">con las nuevas actualizaciones.</span></p>
        <ul>
        <li><span style="font-size: 16px;font-family: Arial;">Lorem fistrum jarl consectetur a gramenawer amatomaa</span>&nbsp;</li>
        <li><span style="font-size: 16px;font-family: Arial;">Incididunt diodenoo no puedor ese que llega</span>&nbsp;</li>
        <li><span style="font-size: 16px;font-family: Arial;">Lorem ipsum dolor sit amet consectetur, adipiscing elit mollis velit</span>&nbsp;</li>
        <li><span style="font-size: 16px;font-family: Arial;">Ornare facilisis convallis diam feugiat, lobortis tortor</span>&nbsp;&nbsp;</li>
        </ul>`;
        const contentBlock = htmlToDraft(html);
        let editorState  = EditorState.createEmpty();
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            editorState = EditorState.createWithContent(contentState);
        }
        this.state = {
            // formato: `<p><img src="${icons.icono_bienvenida}" style="width: 104px;" class="fr-fic fr-dib"></p><p style="text-align: center;"><span style="font-family: Arial,Helvetica,sans-serif;">Te damos la cordial bienvenida a <strong>Vende m&aacute;s 1.1</strong><br>con las nuevas actualizaciones</span></p><ul style="margin-left: 100px;"><li style="text-align: left;">Lorem fistrum jarl consectetur a gramenawer amatomaa</li><li style="text-align: left;">Incididunt diodenoo no puedor ese que llega</li><li style="text-align: left;">Ullamco elit labore se calle ust&eacute;e condemor</li><li style="text-align: left;">Esse torpedo por la gloria de mi madre</li></ul><p style="text-align: center;"><br></p`,
            formato: html,
            version: "",
            habilitado: false,
            edit: false,
            editorState,
        };
    }

    componentWillMount() {
        const id = this.props.match.params.id;
        if (id){
            this.setState({edit:true})
            this.props.getBienvenida(id);
        }
        else{
            this.setState({edit:false})
        }
    }

    componentDidMount() {

    }

    handleFormatoChange = (editorState) => {
        const formato = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        this.setState({
            editorState,
            formato
        });
    }
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    changeVersion = (version) => {
        this.setState({version})
    }
    changeHabilitado = (habilitado) => {
        this.setState({habilitado: habilitado})
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.datos_edicion !== this.props.datos_edicion) {
            const contentBlock = htmlToDraft(nextProps.datos_edicion.formato);
            let editorState = EditorState.createEmpty();
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                editorState = EditorState.createWithContent(contentState);
            }
            this.setState({
                habilitado: nextProps.datos_edicion.habilitado,
                version: nextProps.datos_edicion.version,
                formato: nextProps.datos_edicion.formato,
                editorState
            })
        }
    }

    uploadImagen = (file) => {
        const uploadAdapter  = new UploadAdapter({file});
        return uploadAdapter.upload();
    }

    submit = () => {
        if (!this.state.edit)
            this.props.crear(this.state);
        else
            this.props.editar(this.props.datos_edicion.id, this.state);
    }

    render() {
        // State
        const {datos_edicion} = this.props;
        const { edit, habilitado, formato, version, editorState } = this.state;

        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="grid-container">
                        <div className="grid-titulo padding-15">
                            {!edit ? "Crear": "Editar"} vista de bienvenida
                        </div>
                        <div className="mt">
                            <div className="form-group row">
                                <div className="col-md-3 col-sm-12">
                                    <label >Versión *</label>
                                    <input className="form-control" value={version} placeholder="Ingresa la versión" type="text" onChange={e => {
                                        this.changeVersion(e.target.value ? e.target.value : "")
                                    }}/>
                                </div>
                                <div className="col-md-3 col-sm-12 d-flex">
                                    <label >Habilitado </label>
                                    <input className="form-control" style={{width: "30%"}} checked={habilitado} type="checkbox" onChange={e => {
                                        this.changeHabilitado(e.target.checked ? e.target.checked : false)
                                    }}/>
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                <Editor
                                    editorState={editorState}
                                    // readOnly={true}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.handleFormatoChange}
                                    toolbar={{
                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'image', 'list', 'textAlign', 'colorPicker', 'link', 'history'],
                                        inline: {
                                            inDropdown: true,
                                            className: undefined,
                                            component: undefined,
                                            dropdownClassName: undefined,
                                            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript']
                                        },
                                        textAlign: {
                                            inDropdown: true,
                                            className: undefined,
                                            component: undefined,
                                            dropdownClassName: undefined,
                                            options: ['left', 'center', 'right', 'justify']
                                        },
                                        image: {
                                            uploadCallback: this.uploadImagen,
                                            previewImage: true,
                                            alt:""
                                        }
                                    }}
                                />

                                {/* <FroalaEditor
                                    tag='textarea'
                                    config={this.config}
                                    model={formato}
                                    onModelChange={this.handleModelChange}
                                /> */}
                            </div>
                            <div className="col-sm-12 mt text-center mb-4">
                                <button type="button" className="btn btn-primary" onClick={() => {
                                    this.submit()
                                }}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Bienvenida.propTypes = {

};

export default Bienvenida;
