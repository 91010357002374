import _ from "lodash";
import { api } from "api";
import uuidv1 from "uuid/v1";
import Swal from "sweetalert2";
import { ToastStore } from "react-toasts";
import { goBack } from "react-router-redux";
import { push } from "react-router-redux";
import { handleActions } from "redux-actions";
import moment from "moment";

import { envio, factura, credito, cotizacion } from "utility/constantes";
import {
    actions as ventaActions,
    setBuscador,
    setCotizacionCargada,
    setNitCliente,
    setNombreCliente,
    setTipoVenta,
} from "../venta/venta";
// ------------------------------------
// Constants
// ------------------------------------
export const DATA = "COTIZACIONES_DATA";
export const SORT = "COTIZACIONES_SORT";
export const PAGE = "COTIZACIONES_PAGE";
export const LOADER = "COTIZACIONES_LOADER";
export const BUSCADOR = "COTIZACIONES_BUSCADOR";
export const UUID_REQ_COTIZACIONES = "COTIZACIONES_UUID_REQ_COTIZACIONES";

// filtros
export const FECHA_INICIAL = "COTIZACIONES_FECHA_INICIAL";
export const FECHA_FINAL = "COTIZACIONES_FECHA_FINAL";
export const VENDEDOR = "COTIZACIONES_VENDEDOR";
export const CLIENTE = "COTIZACIONES_CLIENTE";
export const TIPO_COTIZACION = "COTIZACIONES_TIPO_COTIZACION";

const endpoint = "cotizaciones";

// ------------------------------------
// Actions
// ------------------------------------
export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();
        const {
            ordering,
            search,
            // filtros
            fecha_inicial,
            fecha_final,
            vendedor,
            cliente,
            tipo_cotizacion,
        } = store.cotizaciones;

        const vendedor_id = vendedor ? vendedor.id : "";
        const cliente_id = cliente ? cliente.id : "";

        const params = {
            ordering,
            search,
            page,
            estado: 10,
            enviado_caja: false,
            fecha_inicial,
            fecha_final,
            vendedor_id,
            cliente_id,
            tipo_cotizacion,
        };
        //  GENERAR EL UUID
        const uuid = uuidv1();
        dispatch(setUuidReqCotizacion(uuid));
        api.get(`${endpoint}`, params)
            .catch((err) => {})
            .then((data) => {
                if (data) {
                    const otroUuid = getStore().cotizaciones.uuid_req;
                    if (otroUuid === uuid) {
                        dispatch(seData(data));
                        dispatch(setPage(page));
                    }
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.sucursal.page;
    dispatch(listar(page));
};

export const buscar = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

/**
 * Action para cargar el detalle de la cotizacion
 * @param {*} cotizacion
 */
export const cargarCotizacionPuntoVenta =
    (cotizacion) => (dispatch, getStore) => {
        dispatch(ventaActions.setSeleccionados([]));
        dispatch(setBuscador(""));
        dispatch(setLoader(true));
        api.get(`${endpoint}/detalles`, { cotizacion_id: cotizacion.id })
            .then((resp) => {
                if (resp) {
                    const store = getStore();
                    const descuento = resp.results[0].descuento;
                    const venta_al_costo = resp.results[0].venta_al_costo;
                    const descripcion = resp.results[0].descripcion;
                    const cliente = resp.results[0].cliente;
                    const datos_cliente = {
                        nombre: resp.results[0].cotizacion.nombre,
                        direccion: resp.results[0].cotizacion.direccion,
                    };
                    const fecha = resp.results[0].creado;

                    const seleccionados = [];
                    resp.results.forEach((detalle) => {
                        const detalle_cotizacion = _.cloneDeep(
                            store.venta.seleccionados
                        );
                        const items = _.filter(detalle_cotizacion, {
                            id: detalle.fraccion.id,
                        });

                        let item = {
                            detalle_id: detalle.id,
                            id: detalle.fraccion.id,
                            id_det_mov: detalle.id,
                            key: uuidv1(),
                            collapsed: false,
                            costo: detalle.costo,
                            es_vale: false,
                            producto: detalle.fraccion.producto,
                            codigo_barras: detalle.fraccion.codigo_barras,
                            cantidad_total: 0,
                            inventario: detalle.fraccion.inventario,
                            inventario_total: 0,
                            precio: detalle.precio,
                            desc_monto: detalle.desc_monto,
                            desc_porcentaje: detalle.desc_porcentaje,
                            precio_original: detalle.precio_original,
                            cantidad: detalle.cantidad,
                            inventario_calculado:
                                detalle.fraccion.inventario_calculado,
                            nombre: detalle.fraccion.nombre,
                            codigo: null, // Para vales
                            unidad_de_medida: detalle.fraccion.unidad_de_medida,
                        };
                        if (items.length) {
                            item.inventario_total = items[0].inventario_total;
                        } else {
                            item.inventario_total = item.inventario_calculado;
                        }

                        //  SE CALCULA EL INVENTARIO DEL MISMO PRODUCTO SI SE INGRESO VARIOS
                        let cantidad_total = item.cantidad;

                        //  SE SUMA TODAS LAS CANTIDADES DE LOS PRODUCTOS ENCONTRADOS
                        items.forEach((itemRep) => {
                            cantidad_total += itemRep.cantidad;
                        });

                        //  SE CALCULA EL INVENTARIO FINAL
                        const inventario_final =
                            item.inventario_total - cantidad_total;

                        //  SI SE ENCUENTRAN PRODUCTOS SE ACTUALIZAN SUS INVENTARIO
                        items.forEach((itemRep) => {
                            const indexSeleccionado = _.indexOf(
                                detalle_cotizacion,
                                itemRep
                            );
                            itemRep.inventario =
                                inventario_final >= 0 ? inventario_final : 0;
                            itemRep.cantidad_total = cantidad_total;
                            detalle_cotizacion.splice(
                                indexSeleccionado,
                                1,
                                itemRep
                            );
                        });

                        item.cantidad_total = cantidad_total;
                        item.inventario =
                            inventario_final >= 0 ? inventario_final : 0;
                        item.id_unidad_de_medida = item.producto.a_granel
                            ? item.unidad_de_medida
                            : null;

                        seleccionados.push(item);
                    });
                    dispatch(ventaActions.setSeleccionados(seleccionados));
                    dispatch(setCotizacionCargada(cotizacion));
                    if (cotizacion.nit != "CF") {
                        dispatch(setNitCliente(cotizacion.nit));
                    }
                    dispatch(setNombreCliente(cotizacion.nombre));
                    dispatch(setLoader(false));
                    dispatch(ventaActions.setTipoVenta(envio));

                    // VALIDACION DE REDIRECCIONAMIENTO
                    //dispatch(goBack());
                    dispatch(push(`/ordenes_de_venta/editar/${cotizacion.id}`));
                    //ordenes_de_venta/nueva/

                    dispatch(
                        ventaActions.changewizardCrearOVForm(
                            descuento,
                            venta_al_costo,
                            descripcion,
                            cliente,
                            datos_cliente,
                            fecha
                        )
                    );
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(setLoader(false));
            });
    };

export const eliminarCotizacion = (id) => (dispatch, getStore) => {
    Swal({
        title: "¿Eliminar cotización?",
        text: "¡No podrá revertir esta acción!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, eliminar!",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.eliminar(`${endpoint}/${id}`).then(
                (response) => {
                    ToastStore.success("Cotizacion eliminada.");

                    dispatch(setLoader(false));
                    dispatch(listar());
                },
                (error) => {
                    Swal(
                        "¡Error!",
                        "No se ha eliminado la otización, intenta más tarde",
                        "error"
                    ).then(() => {});

                    dispatch(setLoader(false));
                    dispatch(listar());
                }
            );
        }
    });
};

export const escanearCodigoBarras = (search) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const { ordering } = store.cotizaciones;
    const params = { ordering, search, estado: 10, enviado_caja: false };
    api.get(`${endpoint}`, params)
        .catch((err) => {})
        .then((data) => {
            if (data) {
                if (data.count === 1) {
                    dispatch(cargarCotizacionPuntoVenta(data.results[0]));
                }
            }
        })
        .finally(() => {
            dispatch(setSearch(""));
            dispatch(listar());
            dispatch(setLoader(false));
        });
};

export const setFechaFinal = (fechaFinal) => (dispatch) => {
    dispatch(_setFechaFinal(fechaFinal));
    dispatch(listar());
};

export const setFechaInicial = (fechaInicial) => (dispatch) => {
    dispatch(_setFechaInicial(fechaInicial));
    dispatch(listar());
};

export const setVendedor = (vendedor) => (dispatch) => {
    dispatch(_setVendedor(vendedor));
    dispatch(listar());
};

export const setCliente = (cliente) => (dispatch) => {
    dispatch(_setCliente(cliente));
    dispatch(listar());
};

export const setTipoCotizacion = (tipo_cotizacion) => (dispatch) => {
    dispatch(_setTipoCotizacion(tipo_cotizacion));
    dispatch(listar());
};

// ------------------------------------
// PureActions
// ------------------------------------
export const seData = (data) => ({
    type: DATA,
    data,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setSearch = (search) => ({
    type: BUSCADOR,
    search,
});

export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});
export const setUuidReqCotizacion = (uuid_req) => ({
    type: UUID_REQ_COTIZACIONES,
    uuid_req,
});

export const _setFechaInicial = (fecha_inicial) => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});
export const _setFechaFinal = (fecha_final) => ({
    type: FECHA_FINAL,
    fecha_final,
});
export const _setVendedor = (vendedor) => ({
    type: VENDEDOR,
    vendedor,
});
export const _setCliente = (cliente) => ({
    type: CLIENTE,
    cliente,
});
export const _setTipoCotizacion = (tipo_cotizacion) => ({
    type: TIPO_COTIZACION,
    tipo_cotizacion,
});

export const actions = {
    listar,
    buscar,
    sortChange,
    eliminarCotizacion,
    escanearCodigoBarras,
    cargarCotizacionPuntoVenta,
    setFechaFinal,
    setFechaInicial,
    setVendedor,
    setCliente,
    setTipoCotizacion,
    //reImprimirCotizacion,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [UUID_REQ_COTIZACIONES]: (state, { uuid_req }) => {
        return {
            ...state,
            uuid_req,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [VENDEDOR]: (state, { vendedor }) => {
        return {
            ...state,
            vendedor,
        };
    },
    [CLIENTE]: (state, { cliente }) => {
        return {
            ...state,
            cliente,
        };
    },
    [TIPO_COTIZACION]: (state, { tipo_cotizacion }) => {
        return {
            ...state,
            tipo_cotizacion,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    loader: false,
    page: 1,
    search: "",
    ordering: "-creado",
    uuid_req: "",

    // filtros
    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
    vendedor: "",
    cliente: "",
    tipo_cotizacion: "",
};

export default handleActions(reducers, initialState);
